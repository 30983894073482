/**
 * 德语
 */
module.exports = {
  lang: {
    lang: {
      v0: "DE",
      v1: "简体中文-ZH",
      v2: "繁體中文-ZH",
      v3: "English-EN",
      v4: "Deutsch-DE",
      v5: "Español-ES",
      v6: "Français-FR",
      v7: "한국어 - KO",
    },
    contact: {
      v0: "Kontaktieren Sie uns",
      v1: "Kontaktieren Sie uns",
      v2: "Zusammenarbeit von Unternehmen",
      v3: "E-Mail-Addresse: ",
      v4: "Verkaufsteam",
      v5: "Inlandsverkäufe: ",
      v6: "Überseeverkäufe: ",
      v7: "Kundendienst",
      v8: "Service Hotline: ",
      v9: "Datenschutz",
      v10: "E-Mail-Addresse: ",
    },
    law: {
      0: "Privacy and Laws",
      1: "Privacy Policy",
      2: "Date of update",
      3: "February 12, 2021",
      4: "Effective date",
      5: "March 12, 2021",
      6: "Preface",
      7: "iRiding (Xiamen) Technology Co., Ltd. and its affiliated companies (hereinafter referred to as 'QiCYCLE APP' or 'We') take your personal information seriously and ensure that you have control over your personal information. We will use powerful encryption technology to protect your privacy and formulate strict policies to manage all your data. Before you visit QiCYCLE APP or use our services, you shall agree to the provisions of this policy on how we collect, use, store and share your relevant information. If you do not agree on any content in this privacy policy, you are requested to stop using or accessing our products and services immediately. If you have any questions about this policy, please contact us by emailing to privacy@miriding.com. The key points of this privacy policy are as follows:",
      8: "1.We will explain the types of your personal information we collect and their corresponding usage one by one, so that you can understand the categories, reasons for use and collection methods of the specific personal information we collect for a specific function.",
      9: "2.When you are using some functions, we will collect your sensitive information after obtaining your consent, unless it shall be collected pursuant to relevant laws and regulations. Refusing to provide such information will only make you unable to use some specific functions, but will not affect your normal use of the other functions of QiCYCLE APP.",
      10: "3.QiCYCLE APP will not share or transfer your personal information actively to a third party other than QiCYCLE APP. If there are other situations of sharing or transferring your personal information or if you need us to share or transfer your personal information to a third party other than QiCYCLE APP, we will obtain your express consent directly or confirm that the third party obtains your express consent for the above behaviors. In addition, we will conduct a risk assessment of our act of providing information to the outside world.",
      11: "4.QiCYCLE APP will not obtain your personal information actively from a third party other than QiCYCLE APP. If you need to obtain your personal information indirectly from a third party for business development in the future, we will explain to you the source, type and scope of use of your personal information before obtaining it. For example, if the personal information processing activities required by QiCYCLE APP for business purposes exceed the scope of your original authorization and consent upon providing personal information to a third party, we will obtain your express consent before processing such personal information of yours. In addition, we will strictly abide by relevant laws and regulations, and require the third party to guarantee the legality of the information that is provided.",
      12: "5.You can access, correct and delete your personal information, cancel your consent, cancel your account, complain and report, and set up privacy functions through the channels listed in this guide.",
      13: "If you want to know more detailed information, you are requested to read the corresponding chapters according to the following index:",
      14: "1.How do we collect and use your information",
      15: "2.How do we use Cookies and similar technologies",
      16: "3.How to update, change, export and delete your information",
      17: "4.How do we protect your personal information",
      18: "5.How do we share, transfer and publicly disclose your personal information",
      19: "6.Storage of information",
      20: "7.Third-party service provider",
      21: "8.Update of this policy",
      22: "9.Applicable scope",
      23: "10.How to contact us",
      24: "I. How do we collect and use your information?",
      25: "1. How do we collect your information?",
      26: "When we provide the services, we may collect, store and use the following information:",
      27: "1.1 The information you provide",
      28: "1.1.1 The information provided to us that can be used to identify the users' personal identity when you register or use our services, such as name, e-mail address, telephone number, bank card number, ID number, address, communication record, or other data that we can find through such links lawfully. If you want to make the most use of the sharing features we offer, you may also need to create publicly displayed personal data that may contain your name and photos.",
      29: "1.1.2 The shared information you provide to other parties through our services, and the information that you upload, submit, store, send or receive when you are using our services.",
      30: "1.1.2 The shared information you provide to other parties through our services, and the information that you upload, submit, store, send or receive when you are using our services.",
      31: "We will collect the information about the services you use and how you use them, such as related information about your using QiCYCLE APP sports, commenting in iRiding community or participating in clock-in movement, participating in iRiding events and challenges, becoming iRiding members and joining training camps, as well as that that may be generated when you use services of iRiding members and training camps. Such information includes:",
      32: "1.2.1 Equipment information",
      33: "We will receive and record the information related to the device you use according to your specific rights in the software installation and/or use authorization. For example, hardware and software characteristics such as your device model, operating system version information, device settings, mobile device version, device ID (such as IMEI/ Android ID/IDFA/OPENUDID/GUID/OAID, SIM card IMSI, ICCID information, etc.), screen resolution, environment of equipment,and relevant information regarding location of device, such as IP address, GPS location and the WLAN access points that can provide relevant information and the information for Bluetooth, base station and sensor. If you refuse to grant us the corresponding permissions during installation and/or use, we will not record the above kinds of information.",
      34: "1.2.21.2.2 Log information",
      35: "(1) Information for device or software, such as the configuration information for your mobile device, web browser or other programs used to access our services, your IP address and the version and device ID used by your mobile device (such as IMEI/ Android ID/IDFA/OPENUDID/GUID/OAID, SIM card IMSI, ICCID information, etc.);",
      36: "(2) The information that you search for or browse when you are using our services, such as web search terms you use, URL addresses of social media pages you visit, and other information and details that you browse or request to provide when you are using our services.",
      37: "1.2.3 IP address",
      38: "(1) The information about the mobile applications (APPs) and other software you have used, and the information about your having used such mobile applications and software;",
      39: "(2) The information you communicate through our services, such as the account number you have communicated with, as well as the communication time, data and duration;",
      40: "(3) The information (metadata) contained in the content you share through our services, such as the date, time or place of the shared photos or videos taken or uploaded.",
      41: "1.2.4 Pictures in cameras, microphones, devices, etc",
      42: "In order to provide you with a full range of sports experience, after you have authorized, our server will extract the video, picture, software name, version number, etc. from your device, and upload them to the server after encryption for comparison, so as to match the display function corresponding to the video, picture and software. At the same time, you can also refuse/cancel the permission at any time through relevant function settings. Refusing to provide this permission will only make you unable to use the above functions, but will not affect the normal use of other functions and services of QiCYCLE APP. However, after you cancel the authorization, we will no longer collect the information, nor can we provide you with the above services. However, unless you delete your information in accordance with the law, your cancellation will not affect our processing and storage of information based on your previous authorization.",
      43: "When you use the function of taking photos and recording videos, we will access the permissions of your devices such as camera and microphone, and collect the pictures and videos you provide to us after taking photos and recording videos. If you refuse to provide the permissions and contents, you will not be able to use this function, but it will not affect your normal use of other functions of iRiding. At the same time, you can cancel the permissions at any time through relevant function settings. After you cancel the authorization, we will no longer collect the information, nor can we provide you with the above services. However, unless you delete your information in accordance with the law, your cancellation will not affect our processing and storage of information based on your previous authorization.",
      44: "1.2.5 Location information",
      45: "We will access your location information after you enable the location permission, and provide you with more personalized products and/or services based on your location information, such as recording your GPS movement data and viewing the surrounding movement route. Of course, you can also stop us from collecting your geographic location information at any time by disabling the location function. You can give us access to your activity and physical training records and record your step frequency and step count information by reading your activity and physical training. At the same time, you can also cancel this permission at any time through relevant function settings. After you cancel the authorization, we will no longer collect the information, nor can we provide you with the above services. However, unless you delete your information in accordance with the provisions of the law, your cancellation will not affect the processing and storage of your information based on your previous authorization.",
      46: "We provide sports community service. When you use sports music function, we will request your authorization to read local music files for playing music while you are doing sports.",
      47: "1.2.6 The information from third parties",
      48: "Under some circumstances permitted by law, we may obtain your personal information from a third party. For example:",
      49: "(1) For the purpose of security and fraud prevention, verify your information (such as telephone number) from legitimate sources with your authorization for specific account number, financial transaction, etc;",
      50: "(2) Provide you with corresponding advertising services by a specified unique identifier (such as IMEI / Android / IDFA / OPENUDID / GUID / OAID, SIM CARD IMSI, ICCID information and so on) ;",
      51: "(3) We may also get some information from third-party social network services, such as accounts, nicknames (for example, when you log in to QiCYCLE APP service with a social network account);",
      52: "(4) The information related to you provided by others, such as your receiving address filled by other users while using QiCYCLE APP mall.",
      53: "1.2.7 Non-personal information",
      54: "We may also collect other information that cannot identify a specific individual and does not belong to personal information pursuant to local applicable laws, such as statistical data generated when you use a specific service, anonymous device related information, daily life events, page access events, page access duration events and session events,network monitoring data such as request duration, number of requests and wrong requests, etc, as well as application crash events. The purpose of collecting such information is to improve our services for you. The type and amount of information collected depends on how you use our products or services.",
      55: "We will collect information about your activities on our website or mobile app, such as unique device identifier, device model, system version number, etc. We will aggregate this information to help us provide customers with more useful information so that we can learn which parts of our website, products or services that they are most interested in. Aggregate data is treated as non-personal information as far as this privacy policy is concerned. If we combine non-personal information with personal information, such information will be regarded as personal information during the period of combination.",
      56: "2. How do we use your information",
      57: "The purpose of our collecting your personal information is to provide you with products or services and to ensure that we comply with applicable laws, regulations and other normative documents. We may use the information collected above for the following purposes:",
      58: "2.1 ovide, handle, maintain, improve and develop our products or services for you, such as delivery, verification, after-sales, customer support and advertising service;",
      59: "2.2 When we provide services, use it for authentication, customer service, security protection, fraud monitoring, archiving and backup purposes to ensure the security of the products and services we provide to you;",
      60: "2.3 Internal purposes, such as data analysis, research and development of statistical information related to the use of our products or services so that we can improve our products or services;",
      61: "2.4 Help us design new services and improve our existing services;",
      62: "2.5 rovide you with information push of more relevant activities, and you can choose to enable or disable it according to your preference;",
      63: "2.6 Evaluate and improve the effectiveness of advertising and other promotional and publicity activities in our services;",
      64: "2.7 Perform software verification and upgrade services;",
      65: "2.8 Provide information to our affiliated companies, third parties or other users when we provide specific services according to users' special requirements;",
      66: "2.9 Other situations that do not violate any mandatory laws and regulations;",
      67: "2.10 Store and maintain the information related to you for our business operation (e.g. business statistics) or fulfillment of legal obligations;",
      68: "2.11 Other purposes with your consent.",
      69: "More detailed examples are provided below on how we use your information (which may include personal information):",
      70: "(1) Log in and use QiCYCLE APP products or services;",
      71: "(2) Create and maintain your QiCYCLE APP account. The personal information provided when you create a QiCYCLE APP account through the website or a mobile device can be used to create your personal QiCYCLE APP account and personal data;",
      72: "(3) Participate in the community activities. Personal information related to the community or other similar social platforms of QiCYCLE APP may be used to display personal data pages, interact with other users, and participate in posting;",
      73: "(4) Provide location-based services. When you are using QiCYCLE APP service, we or third-party service providers and business partners (please refer to 'How do we share, transfer and publicly disclose your personal information' below for more details) may use location information to provide services for you, and provide better user experience based on the accurate location, such as obtaining accurate running track, and you can disable the location based service in the settings of QiCYCLE APP at any time;",
      74: "(5) Improve the user experience. Some optional add-in features, such as user experience plans, allow us to analyze how users use mobile phones and QiCYCLE APP services to improve user experience, as well as sending crash reports;",
      75: "(6) Collect user feedback. The feedback you choose to provide is important to help QiCYCLE APP to improve its services. To handle the feedback you provide, QiCYCLE APP may contact you with the personal information you provide, and keep records for problem solving and improvement of services;",
      76: "(7) Send a notification. We may use your personal information to send important notices, such as changes to our terms, conditions and policies;",
      77: "(8) Carry out promotional activities. If you participate in the prize-drawing activity and competition through QiCYCLE APP, we may send such rewards to you using the personal information you provide;",
      78: "(9) Analyze the device so as to provide a better user experience. QiCYCLE APP will analyze devices to further enhance the experience of them.",
      79: "You have the right to refuse personal information processing and automated decision-making for direct marketing purposes. In order to exercise the above rights, you can send an email to privacy@miriding.com or refer to the control mechanism described in the separate privacy policy for each product or service.",
      80: "3. Our commitment",
      81: "3.1 We will not present personalized advertisements to you based on sensitive categories (such as race, religion, sexual orientation or health status);",
      82: "3.2 e will not share with advertisers the information that can be used to identify you, such as your name or e-mail address (unless authorized and consented by you);",
      83: "3.3 We will not sell your personal information to any third party at any time and under any circumstance. We will only use the information obtained under this Agreement to the extent permitted by the law. We will formulate strict policies to protect your personal information, and will not disclose your personal information to the public or provide it to any third party unless your prior authorization is obtained or otherwise specified in this statement.",
      84: "4. Affiliated companies or other trusted cooperative partners",
      85: "To ensure the quality of service, we may provide you with the services you require or the content you may be interested in by our affiliated companies or other trusted partners. With your authorization and consent, we will share the personal information necessary for the products and services with our affiliated companies or partners. We will require our affiliated companies and partners to ensure the security of your personal information in accordance with our instructions, privacy policy and any other appropriate confidentiality and security measures. Except for this, we will not provide or share your information to any third party without your authorization and consent.",
      86: "5. HeathKit",
      87: "If you are using an Apple mobile phone, in order to calculate exercise consumption and record relevant exercise data for you, with your authorization, we will receive your exercise data from Apple's Heathkit and send to it. To simplify your input process, we will read your motion data from Heathkit. At the same time, the training data generated by your QiCYCLE APP will be synchronized with Apple's Heathkit. Any information obtained by using Heathkit, such as steps and heart rate data, will not be shared or sold to any third party, including advertisers and other agents. We will not use Heathkit's information in marketing, advertising and similar services.",
      88: "6. Third-party entities",
      89: "The third-party service in QiCYCLE APP services is provided by an external third-party entity. QiCYCLE APP cannot obtain the information generated when you use this kind of third-party service. However, if you have explicitly agreed that the third party can obtain your geographic location information, the third party will obtain your terminal geographic location information through the QiCYCLE APP interface. This information is sensitive. Refusing to provide this information will only make you unable to use the above third-party services, but will not affect your normal use of QiCYCLE APP.",
      90: "Apart from these, pursuant to relevant laws, regulations and national standards, we may collect and use your personal information in the following situations without obtaining your authorization and consent:",
      91: "6.1 That directly related to national interests including national security and national defense security and that directly related to major public interests such as public security, public health, public knowledge and so on;",
      92: "6.2 That directly related to criminal investigation, prosecution, trial and execution of judgment;",
      93: "6.3 That for the purpose of safeguarding personal life, property and reputation of yours or others’ and it is difficult to obtain your consent;",
      94: "6.4 The collected personal information is disclosed to the public by yourself;",
      95: "6.5 Where personal information is collected from the lawfully disclosed information, such as lawful news coverage, disclosure of government information and so forth;",
      96: "6.6 That necessary for signing and performing the contract according to your requirements;",
      97: "6.7 That necessary to maintain the safe and stable operation of the products or services provided, such as finding and handling the faults of products or services;",
      98: "6.8 That necessary for lawful news coverage;",
      99: "6.9 That necessary to carry out statistical or academic research out of public interest, and when the results of academic research or description are provided to the outside world, the personal information contained in the results is to be de-identified.",
      100: "6.10 Transfer for the purpose of completing merger, division, acquisition or asset transfer;",
      101: "6.11 Other circumstances stipulated by laws and regulations.",
      102: "You are requested to understand that the functions and services we provide to you are constantly updated and developed. If a certain function or service is not included in the above description and your information is collected, we will explain the content, scope and purpose of information collection to you through page prompt, interactive process and website announcement separately so as to obtain your consent.",
      103: "Please note that QiCYCLE APP will not obtain your personal information from a third party other than QiCYCLE APP actively at present. If you need to obtain your personal information indirectly from a third party for business development in the future, we will explain to you the source, type and scope of use of your personal information before obtaining it. If the personal information processing activities required by QiCYCLE APP to carry out business exceed the scope of your original authorization and consent when your personal information is provided to a third party, we will obtain your express consent before processing your personal information. In addition, we will strictly abide by relevant laws and regulations, and require the third party to guarantee the legality of the information provided.",
      104: "7. Sensitive personal information",
      105: "We will not provide your sensitive personal information to anyone except with your prior consent.",
      106: "8. Anonymization of personal information",
      107: "After collecting your personal information, we will anonymize the data through technical means in a timely manner. On the condition that your personal information is not to be leaked, QiCYCLE APP has the right to mine, analyze and utilize the anonymized user database (including for commercial use), to make statistics on the use of products/services and share desensitization statistics with the public/third parties.",
      108: "II. How do we use Cookie and similar technologies",
      109: "Cookies are small files containing character strings sent to your computer when you visit the website. When you visit the website again, the Cookie enables the site to identify your browser. Cookies also store user preferences and other information. You can reset your browser to reject all cookies, or prompt when cookies are being sent. But without cookies, some web features or services may not work properly.",
      110: "Cookie and the same type of technologies are common technologies in the Internet. When you use related services of iRiding, we may use relevant technologies to send one or more cookies or anonymous identifiers to your device to collect and store information when you access and use iRiding. We treat information collected through cookies and other technologies as non-personal information. However, if the local law treats an Internet Protocol (IP) address or similar identification marks as personal information, we also treat such identification marks as personal information. We use cookies and similar technologies to achieve the following functions or services:",
      111: "1. Ensure safe and efficient operation of the products and services",
      112: "We may set cookies or anonymous identifiers for authentication and security purposes, so that we can confirm whether you are safe to log in to the service, or whether you encounter illegal acts such as embezzlement and fraud. These technologies will also help us improve service efficiency and login and response speed.",
      113: "2. Help you acquire a more relaxed visit experience",
      114: "Using this kind of technology can help you avoid the steps and processes of repeated completion of personal information and entering search content, and achieve one-click login and record search history.",
      115: "3. Recommend, display and push the contents you may be interested in",
      116: "We may use cookies and similar technologies to learn about your preferences and use habits, and conduct consultation or data analysis to improve product services and user experience.",
      117: "4. Log files",
      118: "As most websites do, we collect specific information and keep it in a log file. Such information may include Internet Protocol (IP) address, browser type, Internet service provider (ISP), reference/exit page, operating system, date/time stamp and/or clickstream data. We don't associate automatically collected log data with the other information we collect.",
      119: "5. Mobile analysis",
      120: "In some mobile applications, we use mobile analysis software to learn better about the functions of our mobile software in your mobile phone. This software may record information such as how often you use the application, events within the application, cumulative usage, performance data, and where the application crash occurs. We will not associate the information stored in the analysis software with any personal information you submit in your mobile application.",
      121: "6. Local storage - HTML5/Flash",
      122: "We use local storage objects (LSO), such as HTML5 or flash, to store contents. The third parties that cooperate with us to provide some functions on QiCYCLE APP, or to advertise according to your browsing behaviors, will also collect and store relevant information through HTML5 or Flash cookie. Various browsers provide their own management tools to delete HTML5 local storage objects.",
      123: "III. How do we update, modify, export and delete your information",
      124: "Pursuant to the laws of some jurisdictions:",
      125: "1. Provide true personal information",
      126: "Prior to your using our products and services, you will be required to provide your true personal information. You should be responsible for the authenticity, legality, validity and integrity of the information you provide, and update and maintain it in time to ensure its authenticity, legality and effectiveness. You can manage your personal information, such as your personal account registration information, at any time.",
      127: "2. Delete content or personal information",
      128: "You can delete your content or personal information from iRiding account by yourself. You can apply for account cancellation through [My]-[Settings] - [About the account] -[Account cancellation]. Within fifteen (15) working days, we will delete or anonymize your personal information.",
      129: "3. Data retention within limited time",
      130: "In some cases, we will keep the data for a limited period of time for legal purposes or legitimate business needs. We will do our best to ensure that the services of iRiding can protect the information from accidental or malicious deletion. Therefore, if you delete something, we may delete its copy from our current and standby systems within fifteen (15) working days.",
      131: "4. Back up content or use it for non-iRiding services",
      132: "You can always back up the contents in your personal account or use it for non-iRiding services. You can contact us by email at privacy@miriding.com and make your request.",
      133: "5. Withdrawal of consent",
      134: "You may withdraw your consent previously granted to us by submitting a request for specific purposes, including collection, use and/or disclosure of your personal information in our possession or control. Depending on the specific service you use, you can visit the account management center of the QiCYCLE APP or send an email to privacy@miriding.com for related operations. We will process your request within a reasonable period of time after you make the request and will not collect, use and/or disclose your personal information upon your request thereafter.",
      135: "Depending on the extent to which you withdraw your consent, you may not be able to continue to enjoy QiCYCLE APP's products or services. However, your decision to withdraw your consent or authorization will not affect the effectiveness of our previous personal information processing activities based on your consent.",
      136: "6. Cancellation of the service or account",
      137: "If you want to cancel a specific product or service, you can cancel your account through [My]- [Settings] - [Account security] - [Cancel account]. After you cancel your account, we will delete or anonymize your personal information.",
      138: "Within fifteen (15) business days, we will delete or anonymize your personal information. Once the account is canceled, it will not be recoverable. You are requested to back up all the information and data related to your iRiding account by yourself before the operation.",
      139: "IV. How do we protect your personal information",
      140: "1. Strict information management policy",
      141: "We have drawn up strict information management policies, equipped with professional technical teams, and have adopted a series of reasonable precautions to protect your personal information from unauthorized browsing, disclosure, abuse, change, destruction and loss, including:",
      142: "(1) Use encryption technology to ensure that your data is kept private during transmission;",
      143: "(2) Provide a variety of security functions to help you protect your account security;",
      144: "(3) Review our practices in the collection, storage and processing of information, including physical security measures, in order to prevent unauthorized access to our systems;",
      145: "(4) All the iRiding employees, contractors and agents who have to contact personal information for the purpose of providing services shall abide by the strict confidentiality obligations stipulated in the contract. Otherwise, they may be punished or fired.",
      146: "2. Technical limitation and malicious means",
      147: "Although the above reasonable and effective measures have been taken and the standards required by relevant laws and regulations have been followed, you are requested to understand that it is impossible to guarantee 100% security of information in the Internet industry all the time due to the limitations of technologies and various malicious means that may exist even if we do our best to strengthen our security measures. We will try our best to ensure the security of the personal information you provide us. You shall confirm that you know and understand that the system and communication network you use to access our services may have problems due to factors beyond our control. Therefore, we strongly recommend that you take positive measures to protect the security of your personal information, including but not limited to using complex passwords, changing passwords regularly, and not disclosing your own account password and other personal information to others.",
      148: "3. Emergency plan",
      149: "We will work out an emergency plan and trigger it immediately in case of any user information security incident, so as to prevent the expansion of the impact and consequences of such security incident. After the unfortunate occurrence of a user information security incident (leakage, loss, etc.), we will inform you of the basic situation and possible impact of the security incident, the measures we have taken or will take to cope with it, the suggestions that you can prevent and reduce the risks on your own, and the remedial measures for you in accordance with the requirements of laws and regulations in time. We will inform you of relevant information of the incident by mail, letter, telephone, push notice, etc. And when it is difficult to inform the subject of personal information one by one, we will take reasonable and effective ways to issue an announcement. At the same time, we will report the handling of the personal information security incident in accordance with the requirements of regulatory authorities.",
      150: "4. What can you do?",
      151: "You shall not disclose your login password or account information to anyone (unless this person is officially authorized by you) and you can set a unique password for QiCYCLE APP to prevent other websites from leaking your password that may endanger the security of your account in QiCYCLE APP. At any time, be sure not to disclose the verification code you receive to anyone (including those who claim to be customer service personnel of QiCYCLE APP).",
      152: "QiCYCLE APP will not be responsible for the security negligence caused by any third party's access to your personal information due to your failure to maintain the privacy of your personal information. Notwithstanding the above provisions, in the event of any other Internet user’s use of your account without authorization or having any other security vulnerabilities, you should immediately inform us.",
      153: "Your assistance will help us protect the privacy of your personal information.",
      154: "V. How do we share, transfer and disclose your personal information",
      155: "1. Sharing",
      156: "We will not sell any personal information to a third party.",
      157: "Sometimes we may share your personal information with third parties in order to provide or improve our products or services, including providing products or services according to your requirements. If you do not want us to share this information any longer, you are requested to send an email to privacy@miriding.com and contact us.",
      158: "1.1 Share out of your active choice",
      159: "Share the information to the extent of your authorization/request with the third party designated by you after your explicit consent or active choice. For example, you can quickly log in to a third-party website or APP by using your QiCYCLE APP account.",
      160: "We will provide social networking services. When you use this type of service, the information you upload or publish (including your public personal information, your address book, your circle of friends), your response to the information uploaded or published by others, as well as the log information related to these information, etc. So long as you do not delete the information, it will always be kept in the public domain, and shared and browsed by other users of our service. Even if you delete the information, it may still be independently cached, copied or stored by other users or unrelated third parties not under our control, or stored in the public domain by other users or third parties.",
      161: "Please consider carefully the information uploaded, published and exchanged through our service. In some cases, you can control the range of users who have the right to browse your shared information through the privacy settings of some of our services. You can delete the information you upload, publish or share by yourself. If you want us to delete your relevant information for you, please contact us by sending an email to privacy@miriding.com and put forward your request, we will finish checking and handling it within fifteen (15) working days after receiving it.",
      162: "1.2 Share information with our affiliated companies",
      163: "We may share your personal information with other related companies of QiCYCLE APP from time to time in order that we can be engaged in our business operation smoothly and provide you with all the functions of products or services.",
      164: "1.3 Share with third party service providers and business partners",
      165: "In order to provide you with the services described in this privacy policy, we may share necessary personal information with third parties such as third party service providers and business partners.",
      166: "They include our data center, data storage facilities, advertising and marketing service providers and other business partners. These third parties may process your personal information on behalf of QiCYCLE APP or for one or more items of this privacy policy. We guarantee to share the personal information necessary for your service only for justifiable, legitimate, necessary, specific and definite purposes. QiCYCLE APP will conduct due diligence and sign a contract to ensure that the third party service provider complies with applicable privacy laws in your jurisdiction. Third party service providers may also have their sub-processors.",
      167: "In order to provide performance measurement, analysis and other commercial services, we may also share information (non-personal information) with third parties (such as our advertisers) in the form of summary. We will use the information that we grasp to help advertisers and other partners assess the effectiveness and coverage of their advertising and services, and understand the types of people who use their services and how people interact with their websites, apps and services. We may also share with them general trends in the use of our services, such as the number of customers in a particular human group who purchase certain products or engage in certain transactions.",
      168: "Third-party SDK name",
      169: "Owned Company",
      170: "Data Collection",
      171: "Purpose of Usage",
      172: "Privacy Policy/Official Website",
      173: "weibo.com SDK",
      174: "sina.com",
      175: "External storage status, latest geographic location information, continuous positioning, network type, network service operator, IMEI, MAC address, WiFi information",
      176: "Weibo login, share",
      177: "https://open.weibo.com/wiki/%E5%BC%80%E5%8F%91%E8%80%85%E5%8D%8F%E8%AE%AE#.E9.9A.90.E7.A7.81.E7.9B.B8.E5.85.B3.E6.9D.A1.E6.AC.BE",
      178: "Xiaomi account open platform SDK ",
      179: "Xiaomi",
      180: "External storage status, network type, network service operator, IMEI, MAC address, WiFi information",
      181: "Xiaomi account login",
      182: "https://dev.mi.com/console/",
      183: "Umeng + SDK",
      184: "Youmeng Tongxin (Beijing) Technology Co., Ltd.",
      185: "Device status and identity and get your IMEI, IMSI information",
      186: "App statistical analysis scenario ",
      187: "https://www.umeng.com/",
      188: "Bugly SDK",
      189: "Tencent",
      190: "Device status and identity and get your IMEI, IMSI information, ANDROID ID",
      191: "Exception report",
      192: "https://bugly.qq.com/v2/index",
      193: "Qiniu Cloud SDK ",
      194: "Shanghai Qiniu Information Technology Co., Ltd.",
      195: "Device status and identity and get your IMEI, IMSI information, external storage status",
      196: "Amap Map SDK",
      197: "https://www.qiniu.com/?ref=developer.qiniu.com",
      198: "Amap Map SDK",
      199: "AMAP",
      200: "External storage status, latest geographic location information, continuous positioning, network type, network service operator, IMEI, MAC address, WiFi information",
      201: "Positioning, map display and poi query",
      202: "https://developer.amap.com/",
      203: "Wechat SDK",
      204: "Tencent",
      205: "External storage status, latest geographic location information, continuous positioning, network type, network service operator, IMEI, MAC address, WiFi information",
      206: "WeChat login, share",
      207: "https://developers.weixin.qq.com/doc/oplatform/Mobile_App/WeChat_Login/Development_Guide.html",
      208: "Xiaomi Push SDK",
      209: "Xiaomi",
      210: "Device status and identity and get your IMEI, IMSI information",
      211: "Push/send notification message",
      212: "https://dev.mi.com/console/",
      213: "QQ SDK",
      214: "Tencent",
      215: "System language, external storage status, latest geographic location information, network location information, continuous positioning, network type, mobile phone number, SIM card serial number, network service operator, IMEI, MAC address, ANDROID ID, IP address, WiFi information, Application installation list, read clipboard",
      216: "QQ login, share",
      217: "https://connect.qq.com/",
      218: "Regarding access to third-party services, we may access to third-party SDK services and share some of your information collected in accordance with this policy with such third-party service providers, so as to improve our customer service and user experience.",
      219: "Currently, the main types of third-party service providers we have access to include the following:",
      220: "(1) Used for message push. When you use our products or services,Xiaomi Push may access your device status and identity and get your IMEI and IMSI information, for pushing/sending notification messages. This information is necessary for the push function,and refusal to provide it will result in your inability to use the function,but will not affect the normal use of other functions;",
      221: "(2) Used to obtain device location permissions and collect device information and log information with your consent;",
      222: "(3) Used for third-party authorization services,including login using third-party accounts and sharing related content to third-party products.，QQ and WeChat SDKs may access your ANDROID_ID and other information;",
      223: "(4) including content storage and positioning.including hardware distribution capability enhancement, server cost reduction, and application exception statistics reporting.Data such as your device information and network location information may be collected;",
      224: "(5) Used to optimize product performance,including hardware distribution capability enhancement, server cost reduction, and application exception statistics reporting. Data such as your device information and network location information may be collected",
      225: "(6) Used for services related to account security and product reinforcement,including network monitoring, domain name resolution, anti-hijacking, anti-spam/anti-cheating, and encryption/decryption services.",
      226: "We will only share your information for justifiable, necessary and specific purposes. For the third-party service providers with whom we share information, we will require them to perform relevant confidentiality obligations and take corresponding security measures.",
      227: "Third-party social media or other services are operated by relevant third parties. Your use of such third party's social media services or other services (including any information you provide to such third party) is subject to the third party's own terms of service and information protection statement (other than this policy), and you shall read the terms carefully. This policy only applies to the personal information we collect, and does not apply to the services provided by any third party or the information use rules of any third party. If you find that there are risks in these third-party social media or other services, you are recommended to terminate relevant operations to protect your legitimate rights and interests.",
      228: "1.4 Miscellaneous",
      229: "QiCYCLE APP may have to disclose your personal information in accordance with the requirements of laws and regulations, legal procedures, lawsuits and/or legal requirements of public institutions and government departments. We may also disclose the information about you if we determine that such disclosure is necessary or appropriate for national security, law enforcement or other matters of public importance.",
      230: "If we determine that the disclosure is reasonably necessary to enforce our terms or to protect our operations, rights, assets or products, or to protect users, or achieve the following purposes (detection, prevention and resolving of fraud, unauthorized use of products, violation of our terms or policies, or other harmful or illegal activities), we may also disclose information about you.(For the avoidance of doubt, QiCYCLE APP will only collect, use or disclose your personal information without your consent to the extent and only under such circumstances as expressly permitted by local data protection laws.) This may include providing information to public or government authorities, exchanging information about the reliability of your account with third-party partners to prevent fraud, violations and other harmful behaviors inside and outside our products.",
      231: "In addition, we may share your personal information with the following persons:",
      232: "(1) Our accountants, auditors, lawyers or similar advisers when we require them to give professional advice;",
      233: "(2) and Investors and other relevant third parties if there is an actual or potential sale or transaction of any other company, and it is related to the QiCYCLE APP entity;",
      234: "(3) and Any other third party, who discloses and shares specific information as authorized by you.",
      235: "2. Transfer",
      236: "Except for the following circumstances, QiCYCLE APP will not transfer your information to any entity:",
      237: "(1) Your express consent has been obtained;",
      238: "(2)(2) If QiCYCLE APP is involved in any merger, acquisition or sale of all or part of its assets, we will notify you of any change in the ownership and use of your personal information, as well as any options you may have regarding your personal information by e-mail and/or on our website in an eye-catching way.",
      239: "3. Public disclosure",
      240: "Except for desensitized display of your QiCYCLE APP account and user name when the list of award-winning activities is announced, QiCYCLE APP will only publicly disclose your personal information under the following circumstances:",
      241: "(1) Your express consent has been obtained;",
      242: "(2) Public disclosure based on laws or reasonable grounds: including laws, regulations, legal procedures, lawsuits or at the request of the competent government authorities.",
      243: "VI. Storage of information",
      244: "In general, we will only retain your personal information for so long as is necessary for our purposes, such as:",
      245: "Mobile phone number: If you need to use QiCYCLE APP services, we need to keep your mobile phone number all the time to ensure your normal use of the services. After you cancel your account, we will delete the corresponding information;",
      246: "Community information: when you send a community share, we need to save your community information so as to ensure that you use the community function normally, and we will delete the corresponding information after you delete your community information.",
      247: "When the operation of our products or services is stopped, we will notify you in the form of push notification, announcement, etc., and delete your personal information or anonymize it within a reasonable period.",
      248: "Since QiCYCLE APP is a global product, some of our activities include a global race of cycling, and we will store some of the data overseas.",
      249: "VII. Third-party service provider",
      250: "1.To facilitate your visit and enrich your experience, there may be products or services provided by third parties. You can choose whether to access such contents or links, or whether to use the third party's products or services. But we have no control over the products or services provided by third parties. We have no control over any of your personal information held by a third party. The information protection problems in the process of using the third party products or services does not apply to the management of this policy. This policy does not apply to any information you choose to provide to third parties, either. Please check the privacy policy of this third party. 。",
      251: "2.Participate in marketing activities. When you choose to participate in our relevant marketing activities, you need to provide your name, address, contact information, bank account number and so on according to the needs of the activities, so that the third party can provide you with prizes in time. When you choose to participate in our marketing activities, you need to provide your name, address, contact information, bank account number and so on according to the needs of the activities. With your express consent, we will share the above information with a third party, so that we can entrust the third party to provide you with prizes in time.",
      252: "VIII. Update of the policy",
      253: "1.We may modify and update this Privacy Policy from time to time. We will release the update of the Privacy Policy via notice or announcement in QiCYCLE APP, and you can visit QiCYCLE APP to search for the latest version of the Privacy Policy. For major changes, we will send you a formal notice (for some services, we will also send a notice by email to elaborate the changes to the Privacy Policy).",
      254: "IX. Applicable scope",
      255: "1.Our privacy policy applies to all the services provided by iRiding (Xiamen) Technology Co., Ltd. and its affiliated companies, including the services provided by QiCYCLE APP and at www.iriding.cc, except for the services with independent privacy policies (such as products and services provided by third parties).",
      256: "2.Our privacy policy does not apply to products and services provided by third parties, such as the products and services provided by third parties in QiCYCLE APP and/or at www.iriding.cc, as well as the other websites linked to our services, which will be regulated by independent privacy policies. Please refer to the corresponding policies and regulations separately.",
      257: "3.Regarding other companies and organizations conducting advertising and publicity for our services and may use our Cookies or other log information to launch and use advertisements, our Privacy Policy does not cover their information processing policies.",
      258: "X. How to Contact Us",
      259: "You can contact us in the following ways:",
      260: "1.If you have any question, opinion or suggestion about this policy, you can feed back to us through the background of QiCYCLE APP and contact us through the background of WeChat's official account of iRiding.",
      261: "2.If you find that your personal information may be leaked, you can complain and report through QiCYCLE APP, and we will contact you and complete the verification and handling within fifteen (15) working days;",
      262: "3.We have also set up a feedback email for comments and suggestions, and you can contact us by emailing to support@miriding.com, and we will contact you within fifteen (15) working days and complete the verification and handling.",
      263: "4.If you have any question about this policy, please contact us by emailing to privacy@miriding.com, and we will contact you and complete the verification and handling within fifteen (15) working days.",
    },
  },
  downLoad: {
    v1: "The QiCYCLE APP",
    v2: "Die technische Neuerung für maximalen Fahrradspaß",
    v3: "Es gilt für das chinesische Festland",
    v4: "Es gilt für andere Regionen",
    v5: "Anwendung Mall",
    v6: "Lokale Downloads",
  },
  index: {
    v1: {
      text0: "iRiding",
      text1: "Startseite",
      text2: "Fahrrad mit Motorunterstützung",
      text3: "Zubehör",
      text4: "APP",
      text5: "Unterstützung",
      text6: "Unternehmensprofil",
      text7: "Gründung",
      text8:
        "Im Jahr 2011 wurde iRiding (Xiamen) Technology Co., Ltd. in Xiamen gegründet.",
      text9: "Lauf",
      text10:
        "2013 wurde die Radsport-Social-Software iRiding APP 1.0 offiziell eingeführt.",
      text11:
        "Nach zwei Jahren überstieg die Zahl der installierten und registrierten Benutzer eine Million, was sie zur bevorzugten Fahrsoftware für Radsportbenutzer macht.",
      text12:
        "Bisher sind Nutzer insgesamt 130 Millionen Kilometer gefahren, das entspricht 175 Rundfahrten von der Erde bis zum Mond.",
      text13: "Beschleunigen",
      text14:
        "Im September 2014 wurde das Unternehmen zum ökologischen Kettenunternehmen von Xiaomi.",
      text15:
        "iRiding begann mit der Hardware-Forschung und -Entwicklung und brachte nacheinander eine Vielzahl von Produkten auf den Markt, darunter R1-Rennräder aus Kohlefaser auf Tour de France-Niveau, Mijia iRiding-Klappfahrräder mit elektrischer Unterstützung, Mi Rabbit-Kinderfahrräder und aufblasbare Mijia-Schätze.",
      text16: "Segel setzen",
      text17:
        "Im Jahr 2019 werden nacheinander die elektrisch unterstützten Klappräder von Xiaomi und aufblasbare Schätze von Mijia auf den Markt gebracht.",
      text18:
        "Zur Zeit werden die Produkte von iRiding in mehr als zehn Ländern und Regionen in Asien, Europa, Ozeanien und Südamerika verkauft.",
      text19:
        "Im Jahr 2020 wurden iRiding Elektrofahrräder und Zubehör auf den Markt gebracht und damit eine neue Reise für die eigene Marke eingeleitet.",
      text20: "Niederschlag",
      text21:
        "iRiding wird sich auch in Zukunft auf die Entwicklung zukunftsorientierter Sportreiseprodukte und durch solide Produktentwicklungs- und Designfähigkeiten konzentrieren, um den Benutzern ein besseres Reiseerlebnis zu bieten.",
      text22: "Entwicklungsgeschichte",
      text23:
        "Abschluss der Zertifizierung von in Taiwan hergestellten neuen europäischen Standard-Falträdern mit elektrischer Unterstützung und Markteinführung in Europa im August",
      text24:
        "EF1 war das erste Elektrofahrrad einer chinesischen Marke, das die EN15194-2017-Zertifizierung abgeschlossen und in Europa verkauft wurde",
      text25:
        "Betroffen von der Epidemie und den europäischen Einkaufssubventionen boomt der Fahrradverkauf und die Bestellungen für Xiaomi-Klappräder mit Elektrounterstützung steigen von Monat zu Monat",
      text26:
        "Der aufblasbare Mijia-Schatz zum Preis von 199 wurde online auf Xiaomi verkauft Mijia Luftpumpe steht online bei Xiaomi für 199 RMB zum Verkauf",
      text27:
        " 'Mi Rabbit 14-Zoll Kinderfahrrad' wurde auf der Xiaomi Mall vorgestellt und gewann den deutschen Red Dot Design Award 2020",
      text28:
        "Die neuen iRiding-Elektrofahrräder nach nationalem Standard werden online auf Xiaomi verkauft und es wurden 18 Patente einschließlich der Fahrradrahmentechnologie erhalten",
      text29:
        "Mijia iRiding Elektrofahrräder verkauften sich in China 51.800 Einheiten mit einem kumulierten Umsatz von 135 Millionen Yuan, wodurch eine neue Kategorie von Elektrofahrrädern für inländische E-Commerce-Unternehmen geschaffen wurde",
      text30:
        "Nr. 1 bei Einzelproduktverkäufen in zwei aufeinander folgenden Jahren mit einem Marktanteil von 91,3 % im Jahr 2017",
      text31: "Das zerlegbare Elektrofahrrad Mijia von iFixit wird hoch gelobt",
      text32:
        "Die iRiding Firma wurde vom Amt für geistiges Eigentum in Xiamen als das Industriedesign-Unternehmen der Stadt zum Vorteil des geistigen Eigentums anerkannt",
      text33:
        "Entwicklung von After-Sales-Apps zur Erfüllung des tatsächlichen Bedarfs wie Ersatzteile, Wartungshinweise und Kostenabrechnung",
      text34:
        "Das Unternehmen hat 272 iRiding-Stadtpartner unterzeichnet, um einen After-Sales-Service für iRiding-Produkte anzubieten",
      text35:
        "Mijia Elektrisches faltbares Fahrrad wurde von Xiaomis Mitbegründer Liu De veröffentlicht und das Produkt wurde von Xiaomi CEO Lei Jun gelobt",
      text36:
        "Die erste Anwendung der Schmiedetechnologie aus Aluminiumlegierungen bei Fahrrädern",
      text37: "Die erste Anwendung der Can-Bus-Technologie am Fahrrad",
      text38:
        "Erlangung von 11 verwandten Patenten wie einem längs faltbaren Fahrrad",
      text39: "Erste Massenproduktion eines TMM-Drehmomentsensors",
      text40: "Crowdfunding QiCYCLE R1 Powerbike aus Kohlefaser auf Xiaomi",
      text41: "QiCYCLE R1 hat zwei Red Dot Design Awards gewonnen",
      text42:
        "R1 gewann den Taipei Bicycle Innovation Award für seine umfassenden Funktionen zur Erfassung von Sportdaten",
      text43:
        "iRiding wurde Mitglied der Xiaomi-Ökokette, die für die Forschung und Entwicklung sowie die ökologische Auslegung intelligenter Hardwareprodukte in Richtung Leben und Reisen verantwortlich ist.",
      text44:
        "iRiding Gesellschaft änderte seinen Namen in iRiding (Xiamen) Technology Co., Ltd.",
      text45: "iRiding erhält die Angel-Rundenfinanzierung von Xiaomi",
      text46: "iRiding ist der ökologischen Kette von Xiaomi beigetreten",
      text47: "Fahrradsoftware 'iRiding' APP entwickeln und in Betrieb nehmen",
      text48: "Die Zahl der Nutzer der iRiding APP überstieg 1,24 Millionen",
      text49:
        "Die kumulierte Radfahrstrecke der iRiding-Nutzer erreichte 65 Millionen km und ist damit die größte Radsportgemeinschaft des Landes",
      text50: "iRiding (Xiamen) Technology Co., Ltd. wurde in Xiamen gegründet",
      text51: "Geniale Produkte",
      text52: "Innovatives Design",
      text53:
        "Das Produkt wurde mit dem German Red Dot Design Award, Design iF Award und anderen Preisen ausgezeichnet und hat eine Reihe von Erfindungspatenten erhalten",
      text54: "Fokus auf Forschung und Entwicklung",
      text55:
        "Das von Branchenexperten geleitete F&E-Team wird auf Details achten und die Innovation von Schlüsseltechnologien weiter vorantreiben",
      text56: "Strenge Qualitätskontrolle",
      text57:
        "Mit mehreren Qualitätsprüfungsprozessen wird die Qualität des gesamten Fahrrades auf allen Ebenen überprüft und den Nutzern hochwertige Produkte zur Verfügung gestellt",
      text58: "Effizienter AfterSales",
      text59:
        "Das Unternehmen erforscht weiterhin intimere Offline-Servicemodelle, die es den Benutzern ermöglichen, ein besseres Produkterlebnis zu erhalten",
      text60: "Adresse",
      text61: "In den folgenden Städten",
      text62: "Beijing",
      text63: "Nr. 16, Anningzhuang East Road, Bezirk Haidian, Peking, Gebäude 2, Bezirk Ost, Rongke Rongzhi · Wabenfabrik",
      text64: "Changzhou",
      text65:
        "Chuangxin Avenue 89, Bezirk Xinbei, Stadt Changzhou, Provinz Jiangsu",
      text66: "Shanghai",
      text67: "104B, Gebäude G, Tianlin Weg 142, Bezirk Xuhui, Stadt Shanghai",
      text68: "Shenzhen",
      text69:
        "Raum 1708, Shenzhen Industrie-Universitäts-Forschungsgebäude, Sun Yat-Sen Universität, Nr. 1, Yuexing 4. Weg, Hightech-Zone, Yuehai Straße, Bezirk Nanshan, Stadt Shenzhen, Provinz Guangdong, China",
      text70: "Frankfurt",
      text71:
        "Adam-Opel-Str. 10 im Hof, Geb. A, bei eKey 60386 Frankfurt am Main,Germany",
      text72: "Xiamen",
      text73:
        "Raum 403, Qishan Nordweg 768, Bezirk Huli, Stadt Xiamen, Provinz Fujian",
      text74: "Hongkong",
      text75:
        "Zimmer 1102, Hang Seng Castle Peak Road Building, Castle Peak Weg 339, Cheung Sha Wan, Kowloon, Hongkong",
      text76: "Suzhou",
      text77:
        "Zi. 606, Suzhou Gaoxin Internationale Geschäftszone, Shishan Str.28, Bezirk Gaoxin, Suzhou, China",
      text78: "Soziale Medien",
      text79: "Service Hotline",
      text80: "Kontaktieren Sie uns",
      text81: "Zusammenarbeit von Unternehmen",
      text82: "Inlandsverkäufe",
      text83: "Überseeverkäufe",
      text84: "Kundendienst",
      text85: "Datenschutz",
      text86: "iRiding © 2013-2021 Min ICP Bei Nr. 13003500",
      text87: "Datenschutz und Recht",
      text88: "Ihre Cookie-Einstellungen",
      text89: "Willkommen bei iRiding!",
      text90:
        "Um Ihnen ein besseres Website-Erlebnis zu bieten, hoffen wir, Cookies zu verwenden, um unsere Website zu verbessern.",
      text91:
        "Die von Cookies gesammelten Informationen werden Sie nicht persönlich identifizieren.",
      text92:
        "Weitere Informationen zu den von uns verwendeten Arten von Cookies und zu unseren Cookies finden Sie in unserer Cookie-Richtlinie.",
      text93: "Ablehnen",
      text94: "Annehmen",
      text100:
        "Um Ihnen ein besseres Website-Erlebnis zu bieten, hoffen wir, Cookies zu verwenden, um unsere Website zu verbessern.Die von Cookies gesammelten Informationen werden Sie nicht persönlich identifizieren.Weitere Informationen zu den von uns verwendeten Arten von Cookies und zu unseren ",
      text101: "Cookies finden Sie in unserer Cookie-Richtlinie",
      text102: "iRiding",
      text103: "Rechtsinformation",
      text104: "QICYCLE",
    },
  },
  product: {
    v1: {
      productMove: "Mehr Informationen",
      productCpd: "QiCYCLE Tragbarer elektrischer Inflator P1",
    },
  },
  contact: {
    // 德文
    v1: "Kontaktieren Sie uns",
    v2: "Zusammenarbeit von Unternehmen",
    v3: "E-Mail-Addresse: market@iriding.com",
    v4: "Verkaufsteam",
    v5: "Inlandsverkäufe: sales@iriding.com",
    v6: "Überseeverkäufe: sales@iriding.com",
    v7: "Kundendienst",
    v8: "Service Hotline: 400-1144-177",
    v9: "Datenschutz",
    v10: "E-Mail-Addresse: dpo@iriding.com",
  },
  details: {
    v1: "QiCYCLE Tragbarer elektrischer Inflator P1",
    v2: "Farbe",
    v3: "Jetzt kaufen",
    v4: "Beschreibung",
    v5: "Technische Daten",
    v6: "Kundendienst",
    v7: "Präzise aufgepumpter Velociraptor",
    v8: "Schnelles aufpumpen, in ca. 60 Sekunden",
    v9: "Aufblasdruck bis zu 150 psi",
    v10: "Aufpumpen des Reifens in ca. 5 Minuten",
    v11: "Automatischer Stopp bei voreingestelltem Druck",
    v12: "5 Verwendungsmodi",
    v13: "Notstrombank",
    v14: "Starke Leistung",
    v998: "bahnbrechende aufblasbare Phantasie",
    v15: "Leistungsstarke Bewegung und leistungsstarke. Motoren",
    v16: "Reifendruck von Null auf Voll in etwa 5 Minuten",
    v16a: "Reifendruck von Null auf Voll in etwa 5 Minuten",
    v1611: "Reifendruck von Null auf Voll in etwa",
    v1611a: "Akku-Leisung zum Aufpumpen von ca.",
    v1621: "5",
    v1631: "Die Daten in der Tabelle sind die Testdaten im Volllastzustand",
    v1631b: "Autoreifen",
    v1711: "ca.",
    v1711a: "ca.",
    v1721a: "Minuten/Reifen",
    v1721: "Autoreifen,",
    v17: "Akku-Leisung zum Aufpumpen von ca. 5 Autoreifen , ",
    v999: "ca. 5 Minuten/Reifen",
    v18: "Klein und einfach zu verstauen",
    v19: "Körpergröße ca. 20cm",
    v20: "Einhändige Kontrolle.",
    v21: "Kleine und flexible Lagerung.",
    v22: "Einfaches Radfahren.",
    v23: "Hochpräziser Zylinder, ",
    v231: "große Geschwindigkeit und hoher Luftdruck",
    v24: "Aufblasdruck bis zu 150 psi,",
    v24a: "Aufblasdruck bis zu 150 psi",
    v25: "Hoher Luftdruck für Rennradreifen usw.",
    v26: "Mit wenig Aufwand aufblasbar.",
    v261: "Pumpt mit einer vollen Ladung bis zu",
    v262: "16",
    v263: "Rennradreifen in ca.",
    v2631: "Rennradreifen in ",
    v27: "Pumpt mit einer vollen Ladung bis zu 16 Rennradreifen in ca. ",
    v9971: " ",
    v9972: "63",
    v9973: "Sekunden pro Reifen auf",
    v997: "Sekunden pro Reifen auf",
    v271: "präzises aufpumpen und automatischer Stopp",
    v272: "Voreingestellter Druck, ",
    v28: "Stellen Sie den Luftdruck nach Bedarf ein",
    v29: "Stoppt automatisch, wenn das Aufpumpen abgeschlossen ist",
    v30: "Präzise Druckmessung, jederzeit und überall",
    v31: "Genauigkeit des Luftdrucksensors ",
    v311: "±1psi",
    v312: "Genauigkeit des Luftdrucksensors ±1psi",
    v32: "5 Nutzungsmodi, frei wählbar",
    v33: "Autos, Motorräder, Fahrräder, Bälle,  Pro-Modus",
    v34: " Begrenzter Luftdruckbereich ",
    v35: "Auswahl des Aufblasmodus für sicheren Betrieb.",
    v362: "Standarddruck",
    v361: "Fahrzeugmodus",
    v3611: "Standard：2.5bar",
    v3612: "Standard：2.4bar",
    v363: "2,5 bar",
    v364: "2.4 bar",
    v37: "Einstellbarer Bereich:1.8-3.5bar",
    v371: "1.8-3.5bar",
    v3711: "Einstellbarer Bereich:1.8-3.0bar",
    v38: "Motorrad-Modus",
    v381: "Standard 2.4bar",
    v39: "Einstellbarer Bereich",
    v391: "1.8-3.0bar",
    v40: "Fahrradmodus",
    v41: "Standard",
    v411: "45 psi",
    v451: "Standard:3-150psi",
    v451a: "3-150psi",
    v451b: "0.2-10.3bar",
    v4111: "Standard：45psi",
    v581: "Dauerbetriebszeit ohne Belastung bis zu 35 Minuten",
    v412: "Einstellbarer Bereich",
    v4121: "Einstellbarer Bereich:30-145psi",
    v2121: "Strenge Tests für dauerh,",
    v611: "Carga del adaptador USB",
    v612: "Carga del cargador de coche",
    v613: "Batería recargable",
    v2122: "afte Qualität",
    v413: "30-145psi",
    v42: "Ballon-Modus ",
    v423: "Einstellbarer Bereich:4-16psi",
    v422: "Standard:8psi",
    v421: "8 psi",
    v462: "Einstellbarer Bereich: 0.2-10.3bar",
    v43: "4-16 psi",
    v44: "Profi-Modus",
    v45: "3-150psi",
    v46: "0.2-10.3bar",
    v47: "Eingebaute 18650 Lithium-Batterie",
    v48: "Uneingeschränkte Kontrolle",
    v49: "Es begleitet Sie sowohl auf dem Land als auch in den Bergen.",
    v50: "Keine externe Stromversorgung erforderlich",
    v51: "Aufpumpen ohne Komplikationen.",
    v52: "Edle Details,die Ihr Leben beleben",
    v53: "Mit Beleuchtung, Benutzerfreundlich",
    v54: "LED-Beleuchtung, Nachts arbeiten, ohne Angst vor der Dunkelheit.",
    v55: "SOS-Blinklicht, Mehr Sicherheit in Notlagen.",
    v56: "Doppelte Kühlung für Dauerbetrieb",
    v57: "Hochleistungslüfter mit Kühllöchern.",
    v58: "Dauerbetriebszeit ohne Belastung bis zu 35 Minuten.",
    v59: "Pumpe und Powerbank",
    v60: "Globaler Typ-C-Anschluss für sorgenfreies Aufladen.",
    v61: "USB-A-Anschluss zum Aufladen von Mobiltelefonen.",
    v62: "Großer digitaler Bildschirm mit Echtzeitanzeige",
    v63: "Großes 4,4HD-Display",
    v631: "zeigt den aktuellen Reifendruck, den voreingestellten Reifendruck, den Nutzungsmodus und andere Daten in Echtzeit an.",
    v64: "Komplettes Zubehör, durchdachte Lagerung",
    v65: "Aufbewahrungstasche zum Schutz des Gerätes.",
    v66: "Ablagefach für Luftdüsen, Nadeln und Ladekabel.",
    v67: "9. Hat eine Reihe strenger Tests bestanden [5]",
    v68: "Funktionsprüfung des Überdruckschutzes",
    v69: "Prüfung der Zugfestigkeit der Pneumatikschläuche",
    v70: "Prüfung der elektrischen Festigkeit",
    v71: "Kaltbiegeversuch für Pneumatikschläuche bei niedrigen Temperaturen",
    v72: "Test im freien Fall ",
    v73: "Dauerarbeitszeittest",
    v74: "Kompressionstest",
    v75: "Ladetest bei hoher/niedriger Temperatur",
    v76: "QiCYCLE Tragbarer elektrischer Inflator P1",
    v77: "1 bar = 14,5 psi = 100 kPa",
    v78: "Aufblasbares Objekt",
    v79: "Autoreifen",
    v80: "175/70 R14 (Gasaufschlag)",
    v81: "Wuling Hongguang",
    v82: "(175/70 R14)",
    v83: "Buick Hinlang",
    v84: "(195/65 R16)",
    v85: "VW Golf",
    v86: "(205/55 R16)",
    v87: "Toyota Camry",
    v88: "(215/55 R17)",
    v89: "Landrover Range Rover",
    v90: "(255/55 R20)",
    v91: "BMW 730",
    v92: "(275/40 R19)",
    v93: "Motorradreifen",
    v94: "(150/60 R17)",
    v95: "Motorradreifen",
    v96: "(110/70 R17)",
    v97: "Rennradreifen",
    v98: "(700 × 25C)",
    v99: "Mountainbike-Reifen",
    v100: "(27.5 × 1.95)",
    v101: "Elektroroller-Reifen",
    v102: "(8 1/2 x 2)",
    v103: "Autoreifen auswuchten",
    v104: "(9 x 3.0-6)",
    v105: "Basketball Nr. 7",
    v106: "Fußball Nummer 5",
    v107: "Volleyball Nr. 5",
    v108: "Fülldruck",
    v109: "2.0-2.5bar",
    v110: "0-2.5bar",
    v111: "0-2.4bar",
    v112: "0-120 psi",
    v113: "0-50 psi",
    v114: "0-40 psi",
    v115: "0-30 psi",
    v116: "0-9psi",
    v117: "0-11psi",
    v118: "0-6psi",
    v119: "Anzahl der Aufblasungen",
    v120: "≈ 18",
    v121: "≈ 5",
    v122: "≈ 4",
    v123: "≈ 3",
    v124: "≈ 2",
    v125: "≈ 7",
    v126: "≈ 15",
    v127: "≈ 16",
    v128: "≈ 20",
    v129: "≈ 201",
    v130: "≈ 97",
    v131: "≈ 92",
    v132: "≈ 86",
    v133: "≈ 197",
    v134: "Aufblasgeschwindigkeit",
    v135: "≈ 60 sekunden/bar",
    v136: "≈ 4 Minuten 58 sekunden/bar",
    v137: "≈ 6 Minuten 3 sekunden/bar",
    v138: "≈ 5 Minuten 52 sekunden/bar",
    v139: "≈ 7 Minuten 20 sekunden/bar",
    v140: "≈ 12 Minuten 30 sekunden/bar",
    v141: "≈ 9 Minuten 10 sekunden/bar",
    v142: "≈ 2 Minuten 50 sekunden/bar",
    v143: "≈ 1 Minute 30 sekunden/bar",
    v144: "≈ 63 sekunden/bar",
    v145: "≈ 58 sekunden/bar",
    v146: "≈ 10 sekunden/bar",
    v147: "≈ 14 sekunden/bar",
    v148: "≈ 18 sekunden/stück",
    v149: "≈ 20 sekunden/stück",
    v150: "≈ 10 sekunden/stück",
    v1510: "[1] ",
    v1520: "[2] ",
    v1530: "[3] ",
    v1540: "[4] ",
    v1550: "[5] ",
    v1560: "[6] ",
    v1570:
      "Wenn das Produkt vollständig aufgeladen ist, Kann 18 Autoreifen 175/70 R14 von 2,0 bar auf 2,5 bar aufpumpen, Ca. 60 Sekunden zum Befüllen eines Reifens.",
    v1580:
      "Wenn das Produkt vollständig aufgeladen ist, Kann ca. 5 Autoreifen 175/70 R14 von 0 bar auf 2,5 bar aufpumpen, Ca. 5 Minuten zum Aufpumpen eines Reifens.",
    v1590:
      "Der Druckaufrechterhaltungsmodus muss mit Werkzeugen wie z. B. einem Farbspritzstift, einem PA-Topf, einem Luftkissenheber usw. verwendet werden.Bitte erwerben Sie Ihr eigenes.",
    v1591:
      "Der Ausgangsparameter der USB-A-Schnittstelle beträgt 5V⎓2A. Kann als 5000mAh Powerbank verwendet werden.",
    v1592:
      "Die acht Prüfdaten stammen von Süd-Certification & Testing (China) Co.Ltd. und dem QICYCLE Laboratorium. Prüfberichtsnummer:QJSZ20210731.01,QJSZ20210806.01,QJSZ20210729.01,QJSZ20210831.01,QJSZ20210519.01,QJSZ20210803.01,68.189.21.0074.01",
    v1593:
      "Die Daten in der Tabelle sind die Testdaten im Volllastzustand. Der tatsächliche Wert kann in Abhängigkeit von Faktoren wie Umgebung, Temperatur, Stromverbrauch und Nutzungsgewohnheiten leicht abweichen.",
    v1594:
      "Anmerkung: Die Daten auf dieser Seite, sofern nicht anders angegeben alles aus dem iRiding.",
    v151: "Wenn das Produkt vollständig aufgeladen ist",
    v152: "Kann 18 Autoreifen 175/70 R14 von 2,0 bar auf 2,5 bar aufpumpen",
    v153: "Ca. 60 Sekunden zum Befüllen eines Reifens",
    v154: "Wenn das Produkt vollständig aufgeladen ist",
    v155: "Kann ca. 5 Autoreifen 175/70 R14 von 0 bar auf 2,5 bar aufpumpen",
    v156: "Ca. 5 Minuten zum Aufpumpen eines Reifens",
    v157: "Der Druckaufrechterhaltungsmodus muss mit Werkzeugen wie z. B. einem Farbspritzstift, einem PA-Topf, einem Luftkissenheber usw. verwendet werden.",
    v158: "Bitte erwerben Sie Ihr eigenes",
    v159: "Der Ausgangsparameter der USB-A-Schnittstelle beträgt 5V⎓2A",
    v160: "Kann als 5000mAh Powerbank verwendet werden",
    v161: "Die acht Prüfdaten stammen von Süd-Certification & Testing (China) Co, Ltd. und dem iRiding Laboratorium",
    v162: "Prüfberichtsnummer:QJSZ20210731.01，QJSZ20210806.01，QJSZ20210729.01，QJSZ20210831.01，QJSZ20210519.01，QJSZ20210803.01，68.189.21.0074.01",
    v163: "Die Daten in der Tabelle sind die Testdaten im Volllastzustand.",
    v164: "Der tatsächliche Wert kann in Abhängigkeit von Faktoren wie Umgebung, Temperatur, Stromverbrauch und Nutzungsgewohnheiten leicht abweichen.",
    v165: "Anmerkung:",
    v166: "Die Daten auf dieser Seite, sofern nicht anders angegeben alles aus dem iRiding.",
    v167: "alles aus dem iRiding.",
    v168: "Gebrauchsanweisung×1",
    v169: "Aufbewahrungstasche×1",
    v170: "Hochdruck-Luftschlauch×1",
    v171: "Ladekabel×1",
    v172: "Nadelventiladapter×1",
    v173: "Presta-Ventiladapter×1",
    v174: "QiCYCLE Tragbarer elektrischer Inflator P1×1",
    v175: "Liste der Pakete",
    v176: "Automatischer Druckaufbau",
    v177: "Innovative Druckhalte-Modelle",
    v178: "Die Druckbeaufschlagung beginnt sofort unterhalb des eingestellten Wertes,Automatische Aufrechterhaltung des Behälterdrucks,Malerarbeiten, Bewässerung, Autowäsche.Einfach und mühelos",
    v191: "Drücken und halten Sie die Lichttaste nach dem Einschalten",
    v192: "und drücken sie「+」schlüssel,bis",
    v193: "iconsauftritte.",
    v194: "Produktname",
    v195: "QiCYCLE Tragbarer Elektrischer Inflator P1",
    v196: "Produkttyp",
    v197: "QJCQBP1",
    v198: "Größe des Produkts",
    v199a: "200,5 × 63,5 × 63,5 mm",
    v199b: "(Blanke Metallgröße, ohne Luftschlauch)",
    v200: "Möglicher Druckbereich",
    v201: "3-150psi / 0.2-10.3bar",
    v202: "Akkutyp",
    v203: "Lithium-Ionen",
    v204: "Akkuleistung",
    v2041: "28.86 Wh / 11.1V",
    v205: "28.86Wh/11.1V",
    v206: "Sensorgenauigkeit",
    v207: "±1.5 psi",
    v208: "Ladedauer",
    v209: "Weniger als 4 Stunden",
    v210: "",
    v211: "",
    v212: "Strenge Tests für dauerhafte Qualität",
    v213: "Vielfalt der Szenen,freiheit zum Wechseln",
    v2131: "Vielfalt der Szenen",
    v2132: "freiheit zum Wechseln",
    v214: "Frei von Bindungen,",
    v214a: "Frei von Bindungen",
    v215: "Schwarz",
    v216: "Gutes Aussehen",
  },
  C2: {
    v0: "QiCYCLE C2",
    v1: "QiCYCLE C2 Fahrrad mit elektronischer Unterstützung",
    v1m: "QiCYCLE C2 Fahrrad mit <br/> elektronischer Unterstützung",
    v2: "Stadtdurchquerung unbegrenzte Freiheit",
    v2a: " unbegrenzte Freiheit",
    v3: "Farbe",
    v4: "Schwarz",
    v5: "Leicht und kompakt",
    v6: "intelligente Scheinwerfer",
    v7: "Lange Akkulaufzeit",
    v8: "Leistungsstarker Lithium-Akku",
    v9: "Acht Gänge",
    v10: "Großer Bildschirm",
    v11: "Klappbares Lenkrad",
    v12: "",
    v13: "Übersicht",
    v14: "Spezifikationen",
    v14a: "Gebrauchsanweisung",
    v15: "Unterstützung",
    v16: "Modisches Aussehen und hoher Wert <br/> Überall C-Suite",
    v17: "Überall C-Suite",
    v18: "Klassisches Design mit eleganter Form, ein gutes und auffälliges Modell für alle Fälle.",
    v19: "genießen Sie ein komfortables Fahrerlebnis",
    v20: "Ergonomisches Design",
    v21: "Die ergonomische Rahmenstruktur ist bequem und funktionell und hilft Ihnen, mit dem Fahrrad eins zu werden.",
    m21a: "Die ergonomische Rahmenstruktur ist bequem und funktionell und hilft Ihnen,",
    m21b: "mit dem Fahrrad eins zu werden.",
    v22: "*Elektrofahrräder müssen für den Straßenverkehr fotografiert werden. Bitte beachten Sie die Verkehrsregeln und fahren Sie sicher.",
    v23: "Ultraleichtes Gehäuse <br/>leicht zu bedienen",
    v24: "Mehrkammerrahmen",
    v24p: "aus einer Aluminiumlegierung in Luftfahrtqualität, mit flexibler Lenkung, in kleinen Räumen leicht zu bewegen.",
    v24c: "Mehrkammerrahmen &nbsp;&nbsp;<sup style='position: absolute;color: rgb(255, 255, 255) !important;margin-left: -0.3rem;margin-top: -0.05rem;'class='font15'>「1」</sup>&nbsp;aus einer Aluminiumlegierung in Luftfahrtqualität, mit flexibler Lenkung, in kleinen Räumen leicht zu bewegen.",
    v25: "Mit seinem leichten Gehäuse kann er <br/> auch schwere Gewichte befördern",
    v25m: "Mit seinem leichten Gehäuse kann er auch schwere Gewichte befördern",
    v26: "Das Fahrrad wiegt etwa 20 kg, kann sicher 120 kg tragen und von Männern, Frauen und Kindern gefahren werden .<sup style='font-size:0.15rem' :style=' ios? 'position: absolute;margin-top: -0.05rem;margin-left: -0.1rem;': 'position: absolute;margin-top: -0.05rem;margin-left: 0;''>「2」</sup>",
    v26d: ".",
    v26c: "*Weiteres Zubehör: Frontkorb, Heckbox, Schaumstoffsattel und Kindersitz.",
    m26a: "Das Fahrrad wiegt etwa 20 kg, kann sicher 120 kg tragen und von Männern,",
    m26b: "Frauen und Kindern gefahren werden .",
    v27: "Mit hocheffektiver und leistungsstarker ",
    v27a: "Unterstützung für ein angenehmes Fahrerlebnis",
    v27b: "Mit hocheffektiver und <br/> leistungsstarker Unterstützung  <br/> für ein angenehmes Fahrerlebnis",
    v28: "Hochpräzise Sensoren",
    v29: "Jeden Pedaltritt kennen",
    v30: "Eingebaut mit Mittelachsen-Drehmomentsensor, womit Tretdaten präzise zu erfassen, und eine kurze Beschleunigungszeit versprechend, folgt das Auto Ihrem Herzen, und  verspricht sichere Fahrt nach Ihrem Belieben.",
    m30a: "Präzise Erfassung der Pedaldaten, ",
    m30b: "verbessert Beschleunigung und Effizienz.",
    v31: "C2 PRO",
    m31: "Prinzip des Sensors",
    v32: "Drehmomentsensoren",
    v33: "C2",
    v34: "Drehzahlsensoren",
    v35: "1.Fahrtdaten anzeigen",
    v36: "2.Kontrolle der Boosterleistung",
    v37: "3.Erfassen der Tretkraft",
    v38: "4.Antrieb Motorlauf",
    v39: "Auswahl mehrerer Gänge für verschiedene Szenarien",
    v40: "Freies Umschalten zwischen drei Leistungsmodi,  lange Akkulaufzeit von bis zu 60 km ",
    v40m: "Freies Umschalten zwischen drei Leistungsmodi,  lange Akkulaufzeit von bis zu 60 km <sup style='margin: -0.05rem 0 0 -0.1rem' class='font15 positionA color3A'>「3」</sup>",
    v40c: ".",
    v41: "Arbeitskräfte:",
    v42: "Elektrisch unterstützt:",
    v43: "Elektrischer Output",
    v44: "1 Gang",
    v45: "Geringer Stromverbrauch und lange Batterielebensdauer",
    v46: "2 Gänge",
    v47: "Kombination von Reichweite und Leistung",
    v48: "3 Gang",
    v49: "Kraftvolle und schnelle Beschleunigung",
    v50: "Gehhilfe-Taste zum Anschieben",
    v51: "Auf der ansteigenden Straße lässt sich der Wagen leicht von Handen des ausgestiegenen (Mit)Fahrers anschieben.",
    v52: "Acht-Gang-Getriebe,",
    v52c: ",",
    v52a: "präzise und leichtgängig",
    v52b: "Acht-Gang-Getriebe, präzise und leichtgängig",
    v53: "Schalten Sie frei in jeden der 8 Gänge. Trotzen Sie dem `Teufel` mit elektrischer Unterstützung, indem Sie bergauf und gegen den Wind fahren.",
    m53a: "Schalten Sie frei in jeden der 8 Gänge. Trotzen Sie dem `Teufel` mit elektrischer Unterstützung,",
    m53b: "indem Sie bergauf und gegen den Wind fahren.",
    v54: "Acht-Gang-Getriebe, präzise und leichtgängig",
    v55: "Reiten Sie durch die Stadt",
    v56: "Ihre Sicherheit ist mein Anliegen",
    v57: "Schwarze Lithium-Ionen-Technologie <br/> mit separatem Akkufach",
    v57m: "Schwarze Lithium-Ionen-Technologie mit separatem Akkufach",
    v58: "Lithiumbatterie mit 30 Einheiten, wobei Stromkreis und Batterie sicher isoliert sind, um die elektrische Sicherheit zu gewährleisten.",
    v58m: "Lithiumbatterie mit 30 Einheiten, wobei Stromkreis und Batterie sicher isoliert sind, um die elektrische Sicherheit zu gewährleisten.",
    v59: "*Dieses Produkt hat eine eingebaute Batterie.",
    v60: "Nehmen Sie das Gerät nicht selbst auseinander.",
    v61: "Energiemanagement für die Fahrzeugindustrie",
    v62: "BMS-Sicherheitsmanagementsystem für die Automobilindustrie, das den Ladevorgang bei vollem Akku stoppt, um die Sicherheit des Akkus zu gewährleisten.",
    m62a: "BMS-Sicherheitsmanagementsystem für die Automobilindustrie, das den Ladevorgang bei vollem Akku stoppt, ",
    m62b: "um die Sicherheit des Akkus zu gewährleisten.",
    v63: "Prüfung der Kapazität bei niedrigen Temperaturen",
    v64: "Prüfung der Leistungsfähigkeit bei hohen Temperaturen",
    v65: "IPX5-Test",
    v66: "Test im freien Fall",
    v67: "Schutz vor Überladung",
    v68: "Schutz vor Überentladung",
    v69: "Schocktest bei Beschleunigung",
    v70: "Kurzschlussschutz",
    v71: "Schlagprüfung bei hohen und niedrigen Temperaturen",
    v72: "Prüfung der Vibrationsfestigkeit",
    v73: "130°C Hochtemperaturtest",
    v74: "Kapazität der Ladungsspeicherung",
    v75: "Drucktasten, Fingerpads, direkt zur Hand",
    v76: "Die Funktionen sind klar, die Bedienung ist einfach und auch für ältere Menschen leicht zu handhaben.",
    v77: "Zentrales lichtempfindliches Display",
    v78: "Automatische, intelligente Helligkeitsanpassung des Bildschirms, Gang-, Leistungs- und Geschwindigkeitsdaten sind tagsüber gut sichtbar und blenden nicht in der Nacht.",
    v78a: "des Bildschirms, Gang-, Leistungs- und Geschwindigkeitsdaten sind tagsüber gut sichtbar und blenden nicht in der Nacht.",
    m78a: "Automatische, intelligente Helligkeitsanpassung",
    m78b: "des Bildschirms, Gang-, Leistungs- und Geschwindigkeitsdaten sind tagsüber gut sichtbar und blenden nicht in der Nacht.",
    v79: "Automatischer Lichtsensor <sup style='position: absolute;color: rgb(255, 255, 255) !important;margin-top: 0.05rem;'class='font15'>「5」</sup>",
    v80: "Lichtempfindliche Steuerung der vorderen und hinteren Lichtschalter.",
    v81: "Leuchtet automatisch auf oder erlischt.",
    m81a: "Leuchtet automatisch auf oder erlischt.Bremsenrücklicht",
    v82: "Bremsenrücklicht auf hoch.",
    m82a: "auf hoch.",
    v83: "Reiten ohne Ablenkung.",
    v84: "Sicherer fahren.",
    v85: "Hinzufügen oder Entfernen von Zubehör",
    v85a: " um ein veränderbares Sie zu erstellen",
    v85m: "Hinzufügen oder Entfernen von Zubehör , um ein veränderbares Sie zu erstellen",
    v86: "Unternehmer, Dekorateure, modische Mütter ...... fügen Sie Accessoires hinzu oder entfernen Sie sie, um Ihren eigenen Look zu vervollständigen.",
    m86a: "Unternehmer, Dekorateure, modische Mütter ...... fügen Sie Accessoires hinzu oder entfernen Sie sie,",
    v86b: "um Ihren eigenen Look zu vervollständigen.",
    v87: "Hinzufügen und Entfernen von Zubehör",
    v88: "Verbindung mit der Ride Notes App",
    v89: "Momente im Leben dokumentieren",
    v90: "Greifen Sie auf die QiCYCLE-Anwendung zu, zeigt Daten wie Geschwindigkeit, zurückgelegte Strecke usw. an, zeichnen Sie sie auf und lassen Sie andere an Ihrem Fahrradleben teilhaben.",
    v90c: "Greifen Sie auf die QiCYCLE-Anwendung zu, zeigt Daten wie Geschwindigkeit, zurückgelegte Strecke usw. an, zeichnen Sie sie auf und lassen Sie andere an Ihrem Fahrradleben teilhaben.",
    v90a: "zeichnen Sie sie auf und lassen Sie andere an Ihrem Fahrradleben teilhaben.",
    m90a: "Greifen Sie auf die QiCYCLE-Anwendung zu, zeigt Daten wie Geschwindigkeit, zurückgelegte Strecke usw. an, zeichnen Sie sie auf und lassen Sie andere an Ihrem Fahrradleben teilhaben.",
    v91: "",
    v92: "Verbindung mit der Ride Notes App",
    v93: "Geniales Design zum Erstellen von `QiCYCLE`",
    v94: "Bürstenlose Dauermagnetmotoren",
    v95: "Auslösen eines Energieschubs",
    v96: "20 Zoll Raddurchmesser",
    v97: "Flexibles Fahren genießen",
    v98: "Klappbares Lenkrad",
    v99: "Sei der Reisende im Kofferraum",
    v100: "Vordere und hintere Scheibenbremsen",
    v101: "Die Bremsen sind bei allen Geschwindigkeiten stabil",
    v102: "Schnellverstellbare Sattelklemme",
    v103: "Sie entscheiden, wie hoch oder wie niedrig",
    v104: "Ausgewählter Luftreifen",
    v105: "so stabil wie ein Berg",
    //v106: "[1]    CCC-Zertifizierungsstelle: China Quality Certification Centre, Zertifizierungsnummer:2021011119391871",
    v107: "[1]    Der Fahrradrahmen mit mehreren Kavitäten hat eine Reihe von Gebrauchsmusterpatenten erhalten, die Patentnummern sind:ZL201820720214.X,ZL201822033605.7,ZL201821913718.X.",
    v108: "[2]    Dieses Produkt ist für eine Körpergröße von 155-185 cm geeignet.",
    v109: "[3]    60 km im Dauertest: Unterstützungsmodus 2, ausgeschaltetes Licht, 70 kg Last, auf städtischem Belag mit einer Durchschnittsgeschwindigkeit von 20 km/h.Die tatsächliche Lebensdauer der Batterie hängt von Faktoren wie Belastung, Temperatur, Straßenbedingungen und Betriebsgewohnheiten ab.",
    v110: "[4]    Daten aus dem Prüfzentrum von Fujian Scud Power Technology Co.",
    v111: "[5]    Im Straßenverkehr werden die Front- und Heckleuchten je nach Umgebungslicht automatisch ein- und ausgeschaltet. ",
    v112: "[6]    Weiteres Zubehör: Frontkorb, Heckbox, Schaumstoffsattel und Kindersitz. ",
    // v113:   "[8]    延保服务详情请拨打售后热线 400-1144-177。",
    v114: "[7]    Bitte stellen Sie die Sitzhöhe zwischen der höchsten und niedrigsten Sicherheitslinie des Sitzrohrs ein.",
    v115: "[8]   Das auf dieser Seite abgebildete Reifenprofil dient nur als Referenz. Aufgrund unterschiedlicher Chargen von Reifen kann es zu leichten Abweichungen kommen.Bitte beziehen Sie sich auf den tatsächlichen Artikel.",
    v117: "Anmerkung:",
    v118: "Die Daten auf dieser Seite stammen, sofern nicht anders angegeben,",
    v118a:
      "Anmerkung:Die Daten auf dieser Seite stammen, sofern nicht anders angegeben,",
    v119: "von den iRiding-Labors.",
    v120: "Parameter für elektrisch unterstützte Fahrräder",
    v121: "Produktparameter",
    v121a: "Name des Produkts",
    v122: "Fahrräder mit Elektrounterstützung",
    v123: "Maximale Entwurfsgeschwindigkeit",
    v124: "25Km/h",
    v125: "Produkttyp",
    v126: "TDN09Z",
    v127: "Verfahren zum Bremsen",
    v128: "Mechanische Scheibenbremsen",
    v129: "Abmessungen des Produkts (vor dem Falten)",
    v130: "1530×600×1060 mm",
    v131: "Scheiben",
    v132: "56Z x 170mm Aluminium Kurbel",
    v133: "Produktabmessungen (in gefaltetem Zustand)",
    v134: "1530×430×725 mm",
    v135: "Blumentrommel",
    v136: "Bujes de aluminio",
    v137: "Gesamtmasse des Fahrzeugs",
    v138: "Ca. 20 kg",
    v139: "Aufladezeit",
    v140: "Ca. 5,5 Stunden",
    v141: "Radsatzgröße",
    v142: "20 Zoll",
    v143: "Wasserdichtigkeit des Gehäuses",
    v144: "IPX 3",
    v145: "Gewichtskapazität",
    v146: "120 kg",
    v147: "Bildschirmgröße",
    v148: "53×32 mm",
    v149: "Kapazität der Batterie",
    v150: "7.5 Ah",
    v151: "Drahtlose Konnektivität",
    v152: "Bluetooth 5.0",
    v153: "Packliste",
    v154: "Fahrrad mit elektrischem Hilfsantrieb x 1",
    v155: "4, 5, 6 mm <br/> Innensechskantschlüssel x 1",
    v155c: "4, 5, 6 mm Innensechskantschlüssel x 1",
    v156: "Linke und rechte Fußstütze x 1",
    v157: "Netzadapter x 1",
    //v158:	"",
    v159: "M5 Schrauben x 4",
    v160: "Gebrauchsanweisung x 1",
    //v161: "",
    //v162: "",
  },
  F2: {
    v0: "QiCYCLE F2",
    v1: "QiCYCLE F2 klappbares Fahrrad mit Elektrounterstützung",
    v1m: "QiCYCLE F2 klappbares Fahrrad <br/> mit Elektrounterstützung",
    v2: "Beginnen Sie, die Stadt zu entfalten",
    v3: "Farbe",
    v4: "Schwarz",
    v5: "Drehmomenterfassung",
    v6: "Klappbar und tragbar",
    v7: "Abnehmbarer Akku",
    v8: "Akkulaufzeit",
    v9: "Bordcomputer",
    v10: "Tempomat",
    v11: "Drei innere Geschwindigkeiten",
    v12: "16 Zoll Raddurchmesser",
    v13: "Übersicht",
    v14: "Spezifikationen",
    v15: "Unterstützung",
    v16: "Hilfe auf Abruf",
    v17: "versteht jeden Ihrer Pedaltritte",
    v18: "Der Drehmomentsensor sammelt Daten über die Tretkraft und steuert die Höhe des Ladedrucks.",
    v18a: "Der Drehmomentsensor sammelt Daten über die Tretkraft und steuert die Höhe des Ladedrucks.Das Nerv-Empfindliche",
    v18b: "Hilfssystems hilft Ihnen, eins mit dem Fahrrad zu werden.",
    m18a: "Der Drehmomentsensor sammelt Daten über die Tretkraft und steuert die Höhe des Ladedrucks.",
    m18b: "Das Nerv-Empfindliche Hilfssystems hilft Ihnen, eins mit dem Fahrrad zu werden.",
    m18c: "Von nun an gibt es keine Angst mehr vor Steigungen, sie zu bezwingen ist wie ein Spaziergang auf ebenem Boden.",
    v19: "Das Nerv-Empfindliche Hilfssystems hilft Ihnen, eins mit dem Fahrrad zu werden.",
    v20: "Von nun an gibt es keine Angst mehr vor Steigungen, sie zu bezwingen ist wie ein Spaziergang auf ebenem Boden.",
    v21: "Prinzip der Drehmomentmesssysteme",
    v22: "1.Fahrtdaten anzeigen",
    v23: "2.Kontrolle der Boosterleistung",
    v24: "3.Erfassen der Tretkraft",
    v25: "4.Antrieb Motorlauf",
    v26: "zusammengeklappt und kompakter zu verstauen",
    v27: "ein ständiger Begleiter im Kofferraum",
    v27m: "zusammengeklappt und kompakter <br/> zu verstauen ein ständiger  <br/> Begleiter im Kofferraum",
    v28: "Patentiertes vertikales Klappdesign",
    v28m: "Patentiertes vertikales Klappdesign<sup style='font-size:0.15rem;margin-left:-0.04rem' :style='ios13? 'margin-top: -0.45rem; margin-left: 2.4rem': ios15? 'margin-top: -0.5rem; margin-left: 2.4rem': 'margin-top: -0.45rem; margin-left: 2.3rem''class='font15 positionA color3A'>「1」</sup>",
    v29: "der Hauptrahmen kann zusammengeklappt werden,ohne dass Teile demontiert werden müssen. Die Vorder- und Hinterradgabeln aus geschmiedeter Aluminiumlegierung sind leicht und stabil, mit einer Tragfähigkeit von bis zu 100 kg, was das Fahren sicherer macht",
    v30: "ohne dass Teile demontiert werden müssen. Die Vorder- und Hinterradgabeln aus geschmiedeter Aluminiumlegierung sind leicht und stabil,",
    v30a: ",ohne dass Teile demontiert werden müssen. ",
    v30b: "Die Vorder- und Hinterradgabeln aus geschmiedeter Aluminiumlegierung sind leicht und stabil,",
    m30a: "ohne dass Teile demontiert werden müssen.",
    v31: "mit einer Tragfähigkeit von bis zu 100 kg,",
    v32: "was das Fahren sicherer macht",
    v33: "Patentnummer: 201420243615.2",
    v34: "Gefaltetes Volumen",
    m34a: "ca. 0.25m",
    v35: "Abnehmbarer Akku",
    v36: "bequemeres Aufladen",
    v37: "In den Rahmen sind 20 Lithiumbatterien mit einer Leistung von 18650 eingebaut.",
    v38: "Das BMS-Sicherheitsmanagementsystem für Kraftfahrzeuge mit Voll- und",
    v38a: "Stoppfunktion gewährleistet eine sichere Nutzung der Elektrizität.",
    m38b: "Sicherheitsmanagementsystem,",
    m38a: "Das BMS-",
    v39: "Der Akku ist leicht zu montieren und zu demontieren ",
    v40: "und kann direkt am Fahrrad ",
    v41: "oder separat aufgeladen werden.",
    v42: "Batterie abnehmbar",
    v43: "SHIMANO Drei-Gang-Schaltsystem",
    v43a: "Drei-Gang-Schaltsystem<br/>für präzise Schaltvorgänge",
    v43m: "Drei-Gang-Schaltsystem,für präzise Schaltvorgänge",
    v44: "für präzise Schaltvorgänge",
    v45: "SHIMANO Drei-Gang-Schaltsystem",
    v46: " für präzise und langlebige Schaltvorgänge.",
    v47: "Robust und langlebig.",
    m47a: "Robust und langlebig.Mit",
    m47b: "elektrischer Unterstützung trotzen",
    m47c: " Sie dem `Teufel` bergauf und gegen den Wind.",
    v48: "Mit elektrischer Unterstützung trotzen",
    v49: "Sie dem `Teufel` bergauf und gegen den Wind.",
    v50: "Drei Fahrmodi ",
    v51: "schneller und mit mehr Kontrolle",
    v51m: "schneller und mit mehr Kontrolle",
    v52: "Modus mit konstanter Geschwindigkeit<br/> Ungezwungen und entspannt",
    v52m: "Modus mit konstanter Geschwindigkeit <br/> Ungezwungen und entspannt",
    v53: "Ungezwungen und entspannt",
    v54: "Aufrechterhaltung der Fahrgeschwindigkeit mit einem einzigen Klick, ohne in die Pedale treten zu müssen, für ein leichteres Fahren",
    v54m: "Aufrechterhaltung der Fahrgeschwindigkeit mit einem einzigen Klick, ohne in die Pedale treten zu müssen, für ein leichteres Fahren",
    v55: "Fester Geschwindigkeitsmodus",
    v56: "Unterstützungs-Modus",
    v56m: "Unterstützungs-Modus <br/> Energie und Strom sparen",
    v57: "Energie und Strom sparen",
    v58: "Beliebiges Umschalten zwischen drei Leistungsmodi Die Unterstützung im 2. Gang hat eine Reichweite von ca. 60 km <sup style='margin-left: -0.05rem; margin-top: -0.05rem'class='font15 color3A positionA'>「2」</sup>",
    v58m: "Beliebiges Umschalten zwischen drei Leistungsmodi Die Unterstützung im 2. Gang hat eine Reichweite von ca. 60 km",
    v58c: ".",
    v59: "Die Unterstützung im 2. Gang hat eine Reichweite von ca. 60 km &nbsp; &nbsp; <sup style='margin-left: -0.1rem; margin-top: 0.1rem'class='font15 color3A positionA'>「2」</sup>",
    v60: "Manneskraft:",
    v61: "Elektrisch unterstützt:",
    v62: "1. Gang: ",
    v62a: "geringer Stromverbrauch, lange Akkulaufzeit",
    v63: "2. Gang: ",
    v63a: "Gleichgewicht zwischen Reichweite und Leistung",
    v64: "3. Gang: ",
    v64a: "Leistungsstark, schnelle Beschleunigung",
    v65: "Elektrische Hilfsleistung.",
    v66: "Fahrrad-Modus.",
    v67: "Stärken Sie Ihren Körper",
    v68: "N-Modus ohne Unterstützung, geeignet für Radfahren als Übung, so einfach zu fahren wie jedes andere Fahrrad",
    v68a: "N-Modus ohne Unterstützung, geeignet für Radfahren als Übung,",
    v68b: "so einfach zu fahren wie jedes andere Fahrrad.",
    m68a: "N-Modus ohne Unterstützung, geeignet für Radfahren als Übung,",
    m68b: "so einfach zu fahren wie jedes andere Fahrrad.",
    v69: "Bordcomputer, Kontrolle der Fahrdaten",
    v69b: "Bordcomputer",
    v69a: "Kontrolle der Fahrdaten",
    v70: "Einfacher Wechsel des Fahrmodus über den Bordcomputer mit Farbdisplay das Geschwindigkeit, Gang, Batterie und Fahrtinformationen anzeigt Die Handlungen sind gut dokumentiert und in Ihrem Kopf.",
    v71: "das Geschwindigkeit, Gang, Batterie und Fahrtinformationen anzeigt",
    v71b: "das Geschwindigkeit, Gang, Batterie",
    v71a: "und Fahrtinformationen anzeigt.",
    m71a: "das Geschwindigkeit, Gang, Batterie",
    m71b: "und Fahrtinformationen anzeigt.",
    v72: "Die Handlungen sind gut dokumentiert und in Ihrem Kopf.",
    v73: "Steuerung des Assistenzmodus",
    v74: "Erkennung von Radfahrdaten",
    v75: "Verbindung zur QiCYCLE-Anwendung <br/> für eine intelligente Fahrt",
    v75a: "für eine intelligente Fahrt",
    v76: "Die App zeigt Daten wie Geschwindigkeit, zurückgelegte Strecke, Herzfrequenz, Trittfrequenz <sup style='margin:0 -0.1rem 0 -0.1rem' class='font15  color3A'>「3」</sup> usw. an, zeichnet sie auf und synchronisiert sie über Ihr Mobiltelefon mit der Cloud, so dass Sie jederzeit über die Daten informiert sind und begleitet Ihr Training wissenschaftlich.",
    v76m: "Die App zeigt Daten wie Geschwindigkeit, zurückgelegte Strecke,Herzfrequenz,Trittfrequenz <sup style='margin:0 -0.1rem 0 -0.1rem' class='font15  color3A'>「3」</sup> usw. an, zeichnet sie auf und synchronisiert sie über Ihr Mobiltelefon mit der Cloud, so dass Sie jederzeit über die Daten informiert sind und begleitet Ihr Training wissenschaftlich.",
    v76n: "Die App zeigt Daten wie Geschwindigkeit, zurückgelegte Strecke, Herzfrequenz, <br/> Trittfrequenz <sup style='margin:0 -0.1rem 0 -0.1rem' class='font15  color3A'>「3」</sup> usw. an, zeichnet sie auf und synchronisiert sie über Ihr Mobiltelefon mit der Cloud, so dass Sie jederzeit über die Daten informiert sind und begleitet Ihr Training wissenschaftlich.",
    v76b: "an, zeichnet ,sie über Ihr Mobiltelefon mit der Cloud, so dass Sie jederzeit über die Daten informiert sind",
    v76a: "und begleitet Ihr Training wissenschaftlich.",
    m76a: "Die App zeigt Daten wie Geschwindigkeit, zurückgelegte Strecke, Herzfrequenz, Trittfrequenz usw. ",
    m76b: "sie über Ihr Mobiltelefon mit der Cloud, so dass Sie jederzeit über die Daten informiert sind",
    m76c: " und begleitet Ihr Training wissenschaftlich.",
    v77: "1",
    v78: "Verbindung mit der Ride Notes App",
    v79: "Ausgeklügeltes Design liebevolle Gestaltung der Details",
    v79m: "Ausgeklügeltes Design liebevolle<br/>  Gestaltung der Details",
    v80: "Lichtstarke LED-Scheinwerfer",
    v81: "Beleuchten Sie die Straße in der Nacht",
    v82: "Einzigartige ringförmige Rückleuchten",
    v83: "Sicherheit beim Radfahren",
    v84: '16"-Räder für die Stadt ',
    v85: "Wir helfen Ihnen, stetig voranzukommen",
    v86: '<img style="width: 2.02rem; height: 0.32rem" src="https://image.iriding.cc/f2/m-2.png"> Rollenbremse',
    v86m: "Rollenbremse",
    v87: "Lebenslang wartungsfrei",
    v88: "Ultraleichter geschmiedeter Aluminiumrahmen",
    v89: "Kompakt und flexibel mit einer hervorragenden Akkulaufzeit",
    v90: 'Wasserdichtes Fahrzeug gemäß IPX4 <sup v-if="index === 5"style="margin-top: -0.05rem; margin-left: -0.05rem"class="font15 positionA">「4」</sup>',
    v90m: "Wasserdichtes Fahrzeug gemäß IPX4<sup style='margin-top: -0.05rem; margin-left: -0.05rem' class='font15 positionA'>「4」</sup>",
    v91: "Keine Angst bei Wind und Regen",
    v92: "Einziehbares Schutzblech",
    v93: "Einfaches Zusammenklappen des Fahrrads",
    v94: "Patentname des Gebrauchsmusters:",
    v95: "Längsschwenkbarer Klapprahmen",
    v96: "[1]      Patentname des Gebrauchsmusters: Längsschwenkbarer Klapprahmen Patentnummer: 201420243615.2",
    v97: "[2]      Reichweite bis zu 60 km: Fahren im Unterstützungsmodus 2, mit einer Last von 75 kg und einer konstanten Geschwindigkeit von 20 km/h auf asphaltierten städtischen Straßen.Die tatsächliche Lebensdauer der Batterie hängt von Faktoren wie Belastung, Temperatur, Straßenbedingungen und Betriebsgewohnheiten ab.",
    v98: "[3]      Um Herzfrequenz- und Trittfrequenzdaten zu erhalten, müssen Sie ein externes Bluetooth 5.0-fähiges Gerät anschließen, z. B. einen Herzfrequenz- und Trittfrequenzgurt, den Sie bitte separat erwerben.",
    v99: "[4]      Das gesamte Fahrrad hat den IPX4-Wassertest bestanden, so dass direktes Spritzwasser aus allen Richtungen die Arbeitsleistung des Fahrzeugs nicht beeinträchtigt. Tauchen Sie das Fahrzeug nicht in Wasser ein und verwenden Sie keinen Hochdruckreiniger zum Waschen des Fahrzeugs. Fahren Sie bei Regen nicht mit untergetauchtem Motor und lassen Sie den Wasserspiegel nie über die Position der Antriebswelle steigen. Prüfberichtsnummer: TH60150A/2019, Prüfstelle: SGS Taiwan Ltd.",
    v99a: "Anmerkung: ",
    v100: "1. Das auf dieser Seite abgebildete Reifenprofil dient nur als Referenz. Aufgrund unterschiedlicher Reifenchargen kann es zu leichten Abweichungen kommen.Bitte beziehen Sie sich auf das Original Produkt.",
    v106a:
      "2. Die Daten auf dieser Seite stammen, sofern nicht anders angegeben,von den iRiding-Labors.",
    v107: "Qicycle Klapprad mit elektrischer Unterstützung",
    v108: "Produktparameter",
    v109: "Produktparameter",
    v110: "Name des Produkts",
    v111: "Elektrische Fahrräder",
    v112: "Produkttyp",
    v113: "TDR08Z",
    v114: "Abmessungen des Produkts (vor dem Falten)",
    v115: "1290 × 540 × 950mm",
    v116: "Produktabmessungen (in gefaltetem Zustand)",
    v117: "1000 × 450 × 650mm",
    v118: "Gesamtmasse des Fahrzeugs",
    v119: "Ca. 14,5 kg",
    v120: "Radsatzgröße",
    v121: "16 Zoll",
    v122: "Gewichtskapazität",
    v123: "100kg",
    v124: "Kapazität der Batterie",
    v125: "6Ah",
    v126: "Aufladezeit",
    v127: "Ca. 4,5 Stunden",
    v128: "Wasserdichte Bewertung",
    v129: "IPX4",
    v130: "Bildschirmgröße",
    v131: "1,77 Zoll",
    v132: "Drahtlose Konnektivität",
    v133: "Bluetooth 5.0",
    v134: "Packliste",
    v135: "Faltrad mit Unterstützung x 1",
    v136: "4, 5, 6 mm ",
    v136a: " Innensechskantschlüssel x 1",
    v137: "Linke und rechte Fußstütze x 1",
    v138: "Netzadapter x 1",
    v139: "Gebrauchsanweisung x 1",
    //v140: "1",
  },
};
