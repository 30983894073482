const getDefaultLang = function () {
  let type = navigator.appName;
  let lang = "";
  if (localStorage.getItem("cookieStatus") === "1") {
    return localStorage.getItem("lang");
  }
  if (type == "Netscape") {
    lang = navigator.language; //获取浏览器配置语言，支持非IE浏览器
  } else {
    lang = navigator.userLanguage; //获取浏览器配置语言，支持IE5+ == navigator.systemLanguage
  }
  lang = lang.substr(0, 2).trim(); //获取浏览器配置语言前两位
  localStorage.setItem("lang", lang);
  return lang;
};
export { getDefaultLang };
