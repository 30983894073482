/**
 * 中文
 */
module.exports = {
  lang: {
    lang: {
      v0: "EN",
      v1: "简体中文-ZH",
      v2: "繁體中文-ZH",
      v3: "English-EN",
      v4: "Deutsch-DE",
      v5: "Español-ES",
      v6: "Français-FR",
      v7: "한국어 - KO",
    },
    contact: {
      v0: "联系我们",
      v1: "联系我们",
      v2: "商务合作",
      v3: "邮箱地址：",
      v4: "销售团队",
      v5: "国内销售：",
      v6: "海外销售：",
      v7: "客户服务",
      v8: "售后服务热线：",
      v9: "数据保护",
      v10: "邮箱地址：",
    },
    law: {
      1: "隐私与法律",
      2: "更新日期",
      3: "2021年02月12日",
      4: "生效日期",
      5: "2021年03月12日",
      6: "引言",
      7: "骑记（厦门）科技有限公司及关联公司（以下简称「骑记APP」或「我们」）非常重视您的个人信息，并让您对个人信息拥有控制权，我们会使用强大的加密技术来保护您的隐私，同时制定严格的政策来管理所有数据。当您访问骑记APP时，或使用我们提供的服务前，您需要同意本政策中关于我们如何收集、使用、储存和分享您的相关信息的规定。如果您不同意本隐私政策中的任何内容，请立即停止使用或访问我们的产品和服务。若您对本政策有任何问题，请联系：privacy@miriding.com。本隐私政策要点如下：",
      8: "1.我们将逐一说明我们收集的您的个人信息类型及其对应的用途，以便您了解我们针对某一特定功能所收集的具体个人信息的类别、使用理由及收集方式。",
      9: "2.当您使用一些功能时，我们会在获得您的同意后，收集您的一些敏感信息，除非按照相关法律法规要求必须收集，拒绝提供这些信息仅会使您无法使用相关特定功能，但不影响您正常使用骑记APP的其他功能。",
      10: "3.目前，骑记APP不会主动共享或转让您的个人信息至骑记APP外的第三方，如存在其他共享或转让您的个人信息或您需要我们将您的个人信息共享或转让至骑记APP外的第三方情形时，我们会直接或确认第三方征得您对上述行为的明示同意，此外，我们会对对外提供信息的行为进行风险评估。",
      11: "4.目前，骑记APP不会主动从骑记APP外的第三方获取您的个人信息。如未来为业务发展需要从第三方间接获取您的个人信息，我们会在获取前向您明示您个人信息的来源、类型及使用范围，如骑记APP开展业务需进行的个人信息处理活动超出您原本向第三方提供个人信息时的授权同意范围，我们将在处理您的该等个人信息前，征得您的明示同意；此外，我们也将会严格遵守相关法律法规的规定，并要求第三方保障其提供的信息的合法性。",
      12: "5.您可以通过本指引所列途径访问、更正、删除您的个人信息，撤销同意、注销帐号、投诉举报以及设置隐私功能。",
      13: "如您想了解更加详尽的信息，请根据以下索引阅读相应章节：",
      14: "1.我们如何搜集和使用您的信息",
      15: "2.我们如何使用 Cookie 和同类技术",
      16: "3.如何更新、变更、导出、删除您的信息",
      17: "4.我们如何保护您的个人信息",
      18: "5.我们如何共享、转让、公开披露您的个人信息",
      19: "6.信息的存储",
      20: "7.第三方服务提供商",
      21: "8.本政策的更新",
      22: "9.适用范围",
      23: "10.如何联系我们",
      24: "一. 我们如何搜集和使用您的信息？",
      25: "1. 我们如何搜集您的信息？",
      26: "我们提供服务时，可能会收集、储存和使用下列信息：",
      27: "1.1 您提供的信息",
      28: "1.1.1 您在注册账户或使用我们的服务时，向我们提供的可用于识别用户的个人身份的信息，例如姓名、电子邮件地址、电话号码、银行卡号、身份证号码、住址、通讯记录等信息，或我们可以正当地通过链接找到此类信息的其他数据。如果您想充分使用我们提供的各种分享功能，可能还需要创建公开显示的个人资料，其中可能会包含您的姓名和照片。",
      29: "1.1.2 您通过我们的服务向其他方提供的共享信息，以及您使用我们的服务时上传、提交、存储、发送或接收的信息。",
      30: "1.2 您在使用服务过程中，我们获取的信息",
      31: "我们会收集您使用的服务以及使用方式的信息，如您使用骑记APP运动、在骑记社区发表评论或参与打卡动态、参与骑记的赛事及挑战、加入骑记会员和训练营以及在使用骑记会员和训练营服务时可能产生的相关信息。此类信息包括：",
      32: "1.2.1 设备信息",
      33: "我们会根据您在软件安装及/或使用授权的具体权限，接收并记录您所使用的设备相关信息，例如您的设备型号、操作系统版本信息、设备设置、移动设备版本、设备识别码（如 IMEI/android id/IDFA/OPENUDID/GUID/OAID、SIM卡IMSI、ICCID信息 等）、屏幕分表率、设备环境等软硬件特征信息、设备所在位置相关信息，例如IP地址、GPS位置以及能够提供相关信息的WLAN接入点、蓝牙和基站等传感器信息。如果您在安装及/或使用过程中拒绝授予我们相应权限的，我们并不会记录您上述对应的信息。",
      34: "1.2.2 日志信息",
      35: "(1) 设备或软件信息，例如您的移动设备、网页浏览器或用于接入我们服务的其他程序所提供的配置信息、您的IP地址和移动设备所用的版本和设备识别码（如 IMEI/android id/IDFA/OPENUDID/GUID/OAID、SIM卡IMSI、ICCID信息 等）；",
      36: "(2) 在使用我们服务时搜索或浏览的信息，例如您使用的网页搜索词语、访问的社交媒体页面URL地址，以及您在使用我们服务时浏览或要求提供的其他信息和内容详情；",
      37: "1.2.3 IP地址",
      38: "(1) 有关您曾使用的移动应用（APP）和其他软件的信息，以及您曾经使用该等移动应用和软件的信息；",
      39: "(2) 您通过我们的服务进行通讯的信息，例如曾通讯的账号，以及通讯时间、数据和时长；",
      40: "(3) 您通过我们的服务分享的内容所包含的信息（元数据），例如拍摄或上传的共享照片或录像的日期、时间或地点等。",
      41: "1.2.4 相机、麦克风与设备中的图片等",
      42: "为了给您提供全方位的运动体验，经过您的授权，我们的服务器会提取您设备中的视频、图片、软件名、版本号等，并通过加密上传到服务器进行比对，用于匹配与该视频、图片、软件相对应的显示功能。同时，您也可以随时通过相关功能设置拒绝/取消该权限。拒绝提供该权限仅会使您无法使用上述功能，但不影响骑记APP其他功能与服务的正常使用；但当您取消该授权后，我们将不再收集该信息，也无法再为您提供上述与之对应的服务；但除非您依照法律的规定删除了您的信息，否则您的取消行为不会影响我们基于您之前的授权进行的信息的处理、存储。",
      43: "当您使用拍摄照片、录制视频功能，我们将访问您的设备相机、麦克风相关权限，并收集您提供的基于拍摄照片、录制视频后向我们上传的图片、视频信息。如您拒绝提供权限和内容的，仅会使您无法使用该功能，但并不影响您正常使用骑记的其他功能。同时，您也可以随时通过相关功能设置取消该权限。当您取消该授权后，我们将不再收集该信息，也无法再为您提供上述与之对应的服务；但除非您依照法律的规定删除了您的信息，否则您的取消行为不会影响我们基于您之前的授权进行的信息的处理、存储。",
      44: "1.2.5 位置信息",
      45: "我们会在您开启位置权限后访问获取您的位置信息，根据您的位置信息为您提供更个性化的产品及/或服务，比如用来记录您的GPS运动数据、还可查看周边运动路线等。当然，您也可以通过关闭定位功能随时停止我们对您的地理位置信息的收集。您可以允许我们访问您的活动与体能训练记录，通过读取您的活动与体能训练，记录步频和步数信息。同时，您也可以随时通过相关功能设置取消该权限。当您取消该授权后，我们将不再收集该信息，也无法再为您提供上述与之对应的服务；但除非您依照法律的规定删除了您的信息，否则您的取消行为不会影响我们基于您之前的授权进行的信息的处理、存储。",
      46: "我们提供运动社区服务，当您使用运动音乐功能时，我们会请求您授权读取本地音乐文件，用于在运动中播放音乐。",
      47: "1.2.6 来源于第三方的信息",
      48: "在一些法律允许的情况下，我们可能从第三方处获得您的个人信息。例如：",
      49: "(1) 出于安全和防欺诈的目的，针对特定账号、金融交易等服务，在您授权的前提下，通过合法来源核实您的信息（如电话号码）；",
      50: "(2) 通过指定的唯一标识符（如 IMEI/android id/IDFA/OPENUDID/GUID/OAID、SIM卡IMSI、ICCID信息 等）为您对应提供广告服务；",
      51: "(3) 我们还可能从第三方社交网络服务中获取某些信息，例如账户、昵称（例如，当您使用社交网络账户登录骑记APP 服务时）；",
      52: "(4) 他人提供的与您有关的信息，例如其他用户在使用骑记APP商城时填写的您的收货地址。",
      53: "1.2.7 非个人信息",
      54: "我们还可能收集其他无法识别到特定个人、根据当地适用法律不属于个人信息的信息，例如您使用特定服务时产生的统计类数据，如匿名设备相关信息、日活事件、页面访问事件、页面访问时长事件、会话事件；网络监控数据如请求时长、请求与错误请求数等；以及应用崩溃事件。收集此类信息的目的在于改善我们向您提供的服务。所收集信息的类别和数量取决于您如何使用我们产品或服务。",
      55: "我们会收集诸如唯一设备标识符、设备型号、系统版本号等与您在我们的网站或移动应用中的活动有关的信息。我们会将此类信息汇总，用于帮助我们向客户提供更有用的信息，了解客户对我们网站、产品或服务中的哪些部分最感兴趣。就本隐私政策而言，汇总数据被视为非个人信息。如果我们将非个人信息与个人信息结合使用，则在结合使用期间，此类信息将被视为个人信息。",
      56: "2. 我们如何使用您的信息",
      57: "收集个人信息的目的在于向您提供产品或服务，并且保证我们遵守适用的相关法律、法规及其他规范性文件。我们可能将上述收集的信息用作以下用途：",
      58: "2.1 向您提供、处理、维护、改善、开发我们的产品或提供给您的服务， 例如交付、验证、售后、客户支持和广告宣传；",
      59: "2.2 在我们提供服务时，用于身份验证、客户服务、安全防范、诈骗监测、存档和备份用途，确保我们向您提供的产品和服务的安全性；",
      60: "2.3 内部目的，如数据分析、研究和开发与我们产品或服务的使用相关的统计信息，以更好地改进我们的产品或服务；",
      61: "2.4 帮助我们设计新服务，改善我们现有服务；",
      62: "2.5 向您提供与您更加相关的活动等信息推送，您可根据喜好自行决定开启或关闭；",
      63: "2.6 评估我们服务中的广告和其他促销及推广活动的效果，并加以改善；",
      64: "2.7 执行软件验证、升级服务；",
      65: "2.8 应用户特殊要求而提供特定服务时，需要将信息提供给我们的关联公司、第三方或其他用户；",
      66: "2.9 其他不违反任何强制性法律法规的情况；",
      67: "2.10 储存并维护与您相关的信息，用于我们的业务运营 （例如业务统计）或履行法律义务；",
      68: "2.11 其他征得您同意的目的。",
      69: "关于我们如何使用您的信息（其中可能包含个人信息），下面提供了更多详细示例：",
      70: "(1) 登录使用骑记APP产品或服务；",
      71: "(2) 创建及维护您的骑记APP账号。您通过网站或移动设备创建骑记APP账号时提供的个人信息可用于建立您个人骑记APP账号和个人资料；",
      72: "(3) 参与社区。 与社区或骑记APP其他类似社交平台有关的个人信息可能被用于个人资料页面展示、与其他用户进行互动，参与发帖；",
      73: "(4) 提供基于位置的服务。在使用骑记APP服务时，我们或第三方服务提供商与业务合作伙伴（更多详细信息请参阅下方“我们如何共享，转移和公开披露您的个人信息”）可能会使用位置信息以便为您提供服务，并且基于准确的位置提供更好的用户体验，例如获取准确的跑步轨迹，您可以随时在设置中关闭骑记APP 位置服务；",
      74: "(5) 改善用户体验。某些选择性加入功能，例如用户体验计划，允许我们分析用户如何使用手机和骑记APP服务的数据，以改善用户体验，例如发送崩溃报告；",
      75: "(6) 收集用户反馈。 您选择提供的反馈对帮助骑记APP改进服务非常重要。为了处理您提供的反馈，骑记APP可能会使用您所提供的个人信息与您联系，并保留记录用于解决问题及改进服务；",
      76: "(7) 发送通知。 我们可能会将您的个人信息用于发送重要通知，例如有关我们的条款、条件和政策的变更；",
      77: "(8) 进行促销活动。 如果您通过骑记APP参与了抽奖、比赛，我们可能会使用您提供的个人信息以为您发送此类奖励；",
      78: "(9) 进行设备的分析以提供更好的用户体验。骑记APP将会进行分析，以进一步提升设备的使用体验。",
      79: "您有权拒绝以直接营销目的进行的个人信息处理以及自动化决策等。为了行使上述权利，您可以发送邮件至 privacy@miriding.com 或者参考各产品或服务的单独隐私政策中说明的控制机制。",
      80: "3. 我们承诺",
      81: "3.1 我们不会根据敏感类别（例如种族、宗教、性取向或健康状况）向您展示个性化广告；",
      82: "3.2 我们不会与广告主分享可用于识别您个人身份的信息，例如您的姓名或电子邮件地址（除非经您授权同意）；",
      83: "3.3 在任何时候、任何情况下都不会向任何第三方出售您的个人信息，我们只会在法律允许的范围内使用根据本协议获得的信息。我们会制定严格的政策保护您的个人信息，除非事先获得您的授权或本声明另有规定之外，不会将您的这些信息对外公开或向第三方提供。",
      84: "4. 关联公司或其他可信合作单位",
      85: "为保证服务质量，我们可能会由关联公司或其他可信合作单位向您提供您所要求的服务或您可能感兴趣的内容。经您授权同意后，我们将向关联公司或合作单位分享该产品、服务所必须的个人信息。我们会要求关联公司及合作单位按照我们的说明、隐私政策以及任何其他适当的保密和安全措施来保证您的个人信息安全。除此之外，未经您的授权同意，我们不会向任何第三方提供或分享您的信息。",
      86: "5. HeathKit",
      87: "若您使用的是苹果手机，为了计算运动消耗量、为您记录有关运动数据，经过您的授权，我们将从苹果公司的 HeathKit 接收并向其发送您的运动数据。 为了简化您的输入流程，我们从 HeathKit 中读取您的运动数据。同时您在骑记APP生成的训练数据将与苹果公司的 HeathKit 同步。任何通过使用 HeathKit 而获得的信息，如步数和心率数据等，将不会分享或销售给任何第三方，包括广告商及其他代理商。我们将不会使用 HeathKit 的信息用于市场、广告及类似服务中。",
      88: "6. 第三方主体",
      89: "骑记APP服务中的第三方服务由外部第三方主体提供，骑记APP无法获得您在使用该类第三方服务时产生的信息。但是，如果您已明示同意该第三方获得您的地理位置信息，该第三方将通过骑记APP接口获得您的终端地理位置信息。该信息属于敏感信息，拒绝提供该信息仅会使您无法使用上述第三方服务，但不影响您正常使用骑记APP的功能。",
      90: "另外，根据相关法律法规及国家标准，以下情形中，我们可能会收集、使用您的相关个人信息无需征求您的授权同意：",
      91: "6.1 与国家安全、国防安全等国家利益直接相关的；与公共安全、公共卫生、公众知情等重大公共利益直接相关的；",
      92: "6.2 与犯罪侦查、起诉、审判和判决执行等直接相关的；",
      93: "6.3 出于维护您或其他个人的生命、财产、声誉等重大合法权益但又很难得到本人同意的；",
      94: "6.4 所收集的个人信息是您自行向社会公众公开的；",
      95: "6.5 从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；",
      96: "6.6 根据您要求签订和履行合同所必需的；",
      97: "6.7 用于维护所提供的产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；",
      98: "6.8 为开展合法的新闻报道所必需的；",
      99: "6.9 出于公共利益开展统计或学术研究所必要，且其对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；",
      100: "6.10 为完成合并、分立、收购或资产转让而转移；",
      101: "6.11 法律法规规定的其他情形。",
      102: "请您理解，我们向您提供的功能和服务是不断更新和发展的，如果某一功能或服务未在前述说明中且收集了您的信息，我们会通过页面提示、交互流程、网站公告等方式另行向您说明信息收集的内容、范围和目的，以征得您的同意。",
      103: "请您注意，目前骑记APP不会主动从骑记APP外的第三方获取您的个人信息。如未来为业务发展需要从第三方间接获取您个人信息，我们会在获取前向您明示您个人信息的来源、类型及使用范围，如骑记APP开展业务需进行的个人信息处理活动超出您原本向第三方提供个人信息时的授权同意范围，我们将在处理您的该等个人信息前，征得您的明示同意；此外，我们也将会严格遵守相关法律法规的规定，并要求第三方保障其提供的信息的合法性。",
      104: "7. 敏感个人信息",
      105: "除非经过您的同意，否则我们不会向任何人提供您的敏感个人信息。",
      106: "8. 个人信息的匿名化处理",
      107: "在收集到您的个人信息后，我们将通过技术手段及时对数据进行匿名化处理。在不泄露您个人信息的前提下，骑记APP有权对匿名化处理后的用户数据库进行挖掘、分析和利用（包括商业性使用），有权对产品/服务使用情况进行统计并与公众/第三方共享脱敏的统计信息。",
      108: "二. 我们如何使用 Cookie 和同类技术",
      109: "Cookie 是包含字符串的小文件，会在您访问网站时发送到您的计算机上。当您再次访问网站时，Cookie 使该网站可以识别您的浏览器。Cookie 还能存储用户使用偏好及其他信息。您可以重置您的浏览器拒绝所有的Cookie ，或者在发送 Cookie 时提示。但如果没有 Cookie ，某些网站功能或服务可能无法正常工作。",
      110: "Cookie 和同类型技术是互联网中的通用常用技术。当您使用骑记相关服务时，我们可能会使用相关技术向您的设备发送一个或多个 Cookie 或匿名标识符，以收集和存储您访问、使用骑记时的信息。我们将通过Cookie和其他技术收集的信息视为非个人信息。但是，如果当地法律将互联网协议 (IP) 地址或类似识别标记视为个人信息，我们亦将此等识别标记视为个人信息。我们使用 Cookie 和同类技术主要为了实现以下功能或服务：",
      111: "1. 保障产品与服务的安全、高效运转",
      112: "我们可能会设置认证与保障安全性的 Cookie 或匿名标识符，使我们确认您是否安全登录服务，或者是否遇到盗用、欺诈等不法行为。这些技术还会帮助我们改进服务效率，提升登录和响应速度。",
      113: "2. 帮助您获得更轻松的访问体验",
      114: "使用此类技术可以帮助您省去重复填写个人信息、输入搜索内容的步骤和流程，例如实现一键登录流程、记录搜索历史。",
      115: "3. 为您推荐、展示、推送您可能感兴趣的内容",
      116: "我们可能会利用 Cookie 和同类技术了解您的偏好和使用习惯，进行咨询或数据分析，以改善产品服务及用户体验。",
      117: "4. 日志文件",
      118: "和大部分网站一样，我们收集特定信息并保存在日志文件中。此类信息可能包括互联网协议 (IP) 地址、浏览器类型、互联网服务供应商 (ISP)、引用/退出页面、操作系统、日期/时间戳和/或点击流数据。我们不会把自动收集的日志数据与我们收集的其他信息进行关联。",
      119: "5. 移动分析",
      120: "在某些移动应用中，我们使用移动分析软件，以更好地了解我们的移动软件在您的手机中的功能。此款软件可能记录以下信息，如您使用该应用程序的频率、该应用程序内发生的事件、累计使用、性能数据及应用程序崩溃发生的位置。我们不会将存储于分析软件内的信息与您在移动应用程序中提交的任何个人信息相关联。",
      121: "6. 本地存储- HTML5/Flash",
      122: "我们使用本地存储对象 (LSO)，例如HTML5或Flash，来存储内容。与我们合作在骑记APP上提供某些功能，或根据您的浏览行为投放广告的第三方也会通过HTML5或Flash Cookie来收集和存储相关信息。各种浏览器提供了各自的管理工具来删除HTML5本地存储对象。",
      123: "三. 如何更新、变更、导出、删除您的信息",
      124: "依据某些司法管辖区的法律规定：",
      125: "1. 提供真实的个人信息",
      126: "您在使用我们的产品和服务时，会被要求提供您真实的个人信息，您应当对您所提供信息的真实性、合法性、有效性及完整性负责，并及时更新和维护您的个人信息，以保证信息的真实、合法和有效。您可以随时管理您的个人信息，例如您的个人账户注册信息。",
      127: "2. 删除内容或个人信息",
      128: "您可以自行从骑记账号中删除您的内容或个人信息，您可以通过「我」-「设置」-「账号相关」-「注销账号」进行账号的注销申请。在15个工作日内，我们将删除或匿名化处理您的个人信息。",
      129: "3. 有限时间的数据保留",
      130: "在有些情况下，我们会出于法律目的或合法业务的需要将数据保留有限的一段时间。我们会尽力确保骑记的服务能够保护信息免遭意外或恶意删除。因此，如果您删除了某些内容，我们可能会在15个工作日内从我们现用的和备用的系统中删除其副本。",
      131: "4. 备份内容或用于非骑记服务",
      132: "您可以随时备份个人帐户名下的内容或将其用于非骑记服务，您可以发邮件到 privacy@miriding.com 与我们联络并提出您的要求。",
      133: "5. 撤销同意",
      134: "您可以通过提交请求撤销之前出于特定目的授予我们的同意，包括收集、使用和/或披露我们掌握或控制的您的个人信息。根据您所使用的具体服务，您可以通过访问骑记APP账号管理中心或通过发送邮件到 privacy@miriding.com 进行相关操作。我们将会在您做出请求后的合理时间内处理您的请求，并且会根据您的请求，此后不再收集、使用和/或披露您的个人信息。",
      135: "取决于您撤销同意的范围，其可能导致您不能继续享受骑记APP的产品或服务。但您撤回同意或授权的决定，不会影响我们此前基于您的同意而开展的个人信息处理活动的有效性。",
      136: "6. 注销服务或账号",
      137: "如您希望注销具体产品或服务，您可以通过「我的」-「设置」-「账号安全」-「注销账号」进行账号的注销。当您注销账号后，我们将删除或匿名化处理您的个人信息。",
      138: "在15个工作日内，我们将删除或匿名化处理您的个人信息。骑记账户一旦被注销将不可恢复，请您在操作之前自行备份骑记账户相关的所有信息和数据。",
      139: "四. 我们如何保护您的个人信息",
      140: "1. 严格的信息管理政策",
      141: "我们制定了严格的信息管理政策，配备专业的技术团队，采取了一系列合理的预防措施，以保护您的个人信息不会遭受未经授权的浏览、披露、滥用、变更、破坏以及损失，其中包括：",
      142: "(1) 使用加密技术来确保您的数据在传输过程中保持私密性；",
      143: "(2) 提供多种安全功能来协助您保护自己的帐号安全；",
      144: "(3) 审查我们在收集、存储和处理信息方面的做法（包括实体安全措施），以防未经授权的人员访问我们的系统；",
      145: "(4) 所有因提供服务而必须接触个人信息的骑记员工、承包商和代理商等，都需要遵守合同中规定的严格保密义务，否则可将被处分或被解约。",
      146: "2. 技术限制及恶意手段",
      147: "尽管已经采取了上述合理有效措施，并已经遵守了相关法律规定要求的标准，但请您理解，由于技术的限制以及可能存在的各种恶意手段，在互联网行业，即便竭尽所能加强安全措施，也不可能始终保证信息百分之百的安全，我们将尽力确保您提供给我们的个人信息的安全性。您确认知悉并理解，您接入我们的服务所用的系统和通讯网络，有可能因我们可控范围外的因素而出现问题。因此，我们强烈建议您采取积极措施保护个人信息的安全，包括但不限于使用复杂密码、定期修改密码、不将自己的账号密码等个人信息透露给他人。",
      148: "3. 应急处理预案",
      149: "我们会制定应急处理预案，并在发生用户信息安全事件时立即应急预案，努力阻止该等安全事件的影响和后果扩大。在不幸发生用户信息安全事件（泄露、丢失等）后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。事件相关情况我们将以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。",
      150: "4. 您能做什么？",
      151: "您可以通过不向任何人（除非此人经您正式授权）披露您的登录密码或账号信息，您可以为骑记APP设置唯一的密码，以防止其他网站密码泄露危害您在骑记APP的账号安全。无论何时，请不要向任何人（包括自称是骑记APP客服的人士）透露您收到的验证码。",
      152: "骑记APP不对因您未能保持个人信息的私密性而导致第三方访问您的个人信息进而造成的安全疏漏承担责任。尽管有上述规定，如果发生其他任何互联网用户未经授权使用您账号的情况或其他任何安全漏洞，您应当立即通知我们。",
      153: "您的协助将有助于我们保护您个人信息的私密性。",
      154: "五. 我们如何共享、转让、公开披露您的个人信息",
      155: "1. 共享",
      156: "我们不会将任何个人信息出售给第三方。",
      157: "我们有时可能会向第三方共享您的个人信息，以便提供或改进我们的产品或服务，包括根据您的要求提供产品或服务。如果您不再希望我们共享这些信息，请发送电子邮件到 privacy@miriding.com 联系我们。",
      158: "1.1 您主动选择的共享",
      159: "在获得您的明确同意或主动选择的情况下，与您指定的第三方共享您授权/请求范围内的信息。例如，您使用骑记APP账号快捷登录第三方网站或者APP。",
      160: "我们会提供社交网络服务，您在使用这一类型服务时，您所上传或发布的信息（包括您公开的个人信息、您的通讯录、好友圈）、您对他人上传或发布的信息作出的回应，以及与这些信息有关的日志信息等，只要您不删除这些信息，它们会一直留存在公共领域，并被使用我们服务的其他用户分享、浏览。即使您删除这些信息，有关信息仍可能由其他用户或不受我们控制的非关联第三方独立地缓存、复制或储存，或由其他用户或第三方在公共领域保存。",
      161: "请您谨慎考虑通过我们的服务上传、发布和交流的信息内容。在一些情况下，您可通过我们某些服务的隐私设定来控制有权浏览您共享信息的用户范围。您可以自行删除您上传、发布或分享的信息，如要求我们为您删除您的相关信息，请发送邮件到 {emialadds} 与我们联络并提出您的要求，我们会在收到后15个工作日内完成并核查和处理。",
      162: "1.2 与我们的关联公司共享信息",
      163: "为了顺利地从事商业经营，以向您提供产品或服务的全部功能，我们可能不时与其他的骑记APP关联公司共享您的个人信息。",
      164: "1.3 与第三方服务提供商与业务合作伙伴共享",
      165: "为保证向您提供本隐私政策目的所述的服务，我们可能会向第三方服务提供商与业务合作伙伴等第三方共享必要的个人信息。",
      166: "这包括我们的数据中心、数据存储设施、广告和营销服务供应商以及其他业务合作伙伴。这些第三方可能代表骑记APP或出于本隐私政策的一项或多项目的处理您的个人信息。我们保证仅出于正当、合法、必要、特定、明确的目的共享为您提供服务所必要的个人信息。骑记APP将进行尽职调查并签订合同，以确保第三方服务提供商遵守您所属司法管辖区中适用的隐私法律。第三方服务提供商也可能拥有其子处理者。",
      167: "为提供成效衡量、分析和其他商业服务，我们还可能以汇总的形式与第三方（例如向我们的广告商）共享信息（非个人信息）。我们使用所掌握的信息来帮助广告主和其他合作伙伴评估他们广告和服务的成效及覆盖情况，并了解使用他们服务的人群类型以及人们如何与其网站、应用和服务互动。我们也可能与其共享我们服务的一般使用趋势，例如购买某些产品或从事某些交易的特定人群中的客户数量。",
      168: "第三方SDK名称",
      169: "所属公司",
      170: "信息获取",
      171: "使用目的",
      172: "隐私协议链接/官网",
      173: "微博 SDK",
      174: "新浪",
      175: "外部存储状态，最近一次地理位置信息，持续定位，网络类型，网络服务运营商，IMEI，MAC地址，WiFi 信息",
      176: "微博登录、分享",
      177: "https://open.weibo.com/wiki/%E5%BC%80%E5%8F%91%E8%80%85%E5%8D%8F%E8%AE%AE#.E9.9A.90.E7.A7.81.E7.9B.B8.E5.85.B3.E6.9D.A1.E6.AC.BE",
      178: "小米账号开放平台 SDK",
      179: "小米",
      180: "外部存储状态，网络类型，网络服务运营商，IMEI，MAC地址，WiFi 信息",
      181: "小米账号登录",
      182: "https://dev.mi.com/console/",
      183: "友盟+ SDK",
      184: "友盟同欣（北京）科技有限公司",
      185: "设备状态和身份并获取您的IMEI、IMSI 信息",
      186: "App统计分析场景",
      187: "https://www.umeng.com/",
      188: "Bugly SDK",
      189: "腾讯",
      190: "设备状态和身份并获取您的IMEI、IMSI 信息，ANDROID ID",
      191: "异常上报",
      192: "https://bugly.qq.com/v2/index",
      193: "七牛云 SDK",
      194: "上海七牛信息技术有限公司",
      195: "设备状态和身份并获取您的IMEI、IMSI 信息，外部存储状态",
      196: "网络请求、DNS解析",
      197: "https://www.qiniu.com/?ref=developer.qiniu.com",
      198: "高德地图 SDK",
      199: "高德",
      200: "外部存储状态，最近一次地理位置信息，持续定位,网络类型，网络服务运营商，IMEI，MAC 地址，WiFi 信息",
      201: "定位、地图展示及poi查询",
      202: "https://developer.amap.com/",
      203: "微信 SDK",
      204: "腾讯",
      205: "外部存储状态，最近一次地理位置信息，持续定位，网络类型，网络服务运营商，IMEI，MAC地址，WiFi 信息",
      206: "微信登录、分享",
      207: "https://developers.weixin.qq.com/doc/oplatform/Mobile_App/WeChat_Login/Development_Guide.html",
      208: "小米推送 SDK",
      209: "小米",
      210: "设备状态和身份并获取您的IMEI、IMSI 信息",
      211: "推送/下发通知栏消息",
      212: "https://dev.mi.com/console/",
      213: "QQ SDK",
      214: "腾讯",
      215: "系统语言，外部存储状态，最近一次地理位置信息，网络位置信息，持续定位，网络类型，手机号码，SIM卡序列号，网络服务运营商，IMEI，MAC地址，ANDROID ID，IP 地址，WiFi 信息，应用安装列表，读取剪切板",
      216: "QQ登录、分享",
      217: "https://connect.qq.com/",
      218: "接入第三方服务的情形，我们可能会接入第三方SDK服务，并将我们依照本政策收集的您某些信息共享给该等第三方服务商，以便提高更好的客户服务和用户体验。",
      219: "目前，我们接入的第三方服务商主要包括以下几种类型：",
      220: "(1) 用于消息推送功能，您在使用我们的产品或服务时，小米推送可能会访问您的设备状态和身份及获取您的IMEI、IMSI信息，用于推送下发通知栏消息。这些信息是推送功能所必需的信息，拒绝提供将导致您无法使用该功能，但不影响其他功能的正常使用；",
      221: "(2) 用于在您同意的情况下获取设备位置权限、搜集设备信息和日志信息等；",
      222: "(3) 用于第三方授权服务，包括第三方账号登陆、将有关内容分享至第三方产品等，QQ SDK、微信SDK可能会访问您的 ANDROID_ID 等信息；",
      223: "(4) 用于支持产品功能模块，包括内容存储、定位等，可能会搜集您的设备外部存储状态、地理位置信息等数据；",
      224: "(5) 用于优化产品性能，包括提高硬件配网能力、减低服务器成本、应用异常统计上报等，可能会搜集您的设备信息、网络位置信息等数据；",
      225: "(6) 用于账号安全、产品加固相关服务，包括网络监测、域名解析、防劫持、反垃圾反作弊、加解密服务等。",
      226: "我们仅会出于正当、必要、特定的目的共享您的信息。对我们与之共享信息的第三方服务商，我们会要求他们履行相关保密义务并采取相应的安全措施。",
      227: "第三方社交媒体或其他服务由相关的第三方负责运营。您使用该等第三方的社交媒体服务或其他服务（包括您向该等第三方提供的任何信息），须受第三方自己的服务条款及信息保护声明（而非本政策）约束，您需要仔细阅读其条款。本政策仅适用于我们所收集的个人信息，并不适用于任何第三方提供的服务或第三方的信息使用规则。如您发现这些第三方社交媒体或其他服务存在风险时，建议您终止相关操作以保护您的合法权益。",
      228: "1.4 其他",
      229: "根据法律法规规定要求、法律程序、诉讼和/或公共机构和政府部门依法提出的要求，骑记APP可能有必要披露您的个人信息。如果我们确定就国家安全、执法或具有公众重要性的其他事宜而言，披露是必须的或适当的，我们也可能会披露关于您的信息。",
      230: "如果我们确定为了执行我们的条款或保护我们的经营、权利、资产或产品，或保护用户，或对于达成以下目的（检测、预防并解决欺诈、对产品的未授权使用、对我们条款或政策的违反或其他有害或非法活动）披露是合理必要的，我们也可能会披露关于您的信息。（为避免疑问，在当地数据保护法律明确许可的范围内且仅限在此情况下，骑记APP才会在未经您同意的情况下收集、使用或披露您的个人信息。）这可能包括向公共或政府机构提供信息；与第三方合作伙伴交流有关您账户可靠性方面的信息，以防止在我们产品内外所进行的欺诈、违规等其他有害行为。",
      231: "此外，我们可能与下列人员共享您的个人信息：",
      232: "(1) 我们的会计师、审计师、律师或类似的顾问，当我们要求他们提供专业建议时；以及",
      233: "(2) 投资者以及其他相关第三方，如果发生实际或潜在的出售或其他公司交易，且与骑记APP实体相关时；以及",
      234: "(3) 其他任何第三方，如经您授权就特定信息进行披露共享。",
      235: "2. 转让",
      236: "除以下情形外，骑记APP不会将您的信息转让给任何主体：",
      237: "(1) 获得您的明确同意；",
      238: "(2) 如果骑记APP参与兼并、收购或出售其资产的全部或部分，我们将通过电子邮件和/或在我们网站上发布醒目通知，告知对您个人信息所有权、使用方面的任何变化，以及您可能拥有的关于您个人信息的任何选择。",
      239: "3. 公开披露",
      240: "除在公布中奖活动名单时会脱敏展示骑记APP账号、用户名外，骑记APP仅会在以下情况下，公开披露您的个人信息：",
      241: "(1) 获得您的明确同意；",
      242: "(2) 基于法律或合理依据的公开披露：包括法律法规规范、法律程序、诉讼或应政府主管部门要求。",
      243: "六. 信息的存储",
      244: "一般而言，我们仅为实现目的所必需的时间保留您的个人信息，例如：",
      245: "手机号码：若您需要使用骑记APP服务，我们需要一直保存您的手机号码，以保证您正常使用该服务，当您注销骑记帐户后，我们将删除相应的信息；",
      246: "社区信息：当您发送了社区分享，我们需要保存您的社区信息，以保证您正常使用社区功能，当您删除您的社区信息后，我们将删除相应的信息。",
      247: "当我们的产品或服务发生停止运营的情形时，我们将以推送通知、公告等形式通知您，并在合理的期限内删除您的个人信息或进行匿名化处理。",
      248: "由于骑记是个全球化的产品，我们的部分活动包括了全球范围的步数比拼，我们会把部分相关数据存储在海外。",
      249: "七. 第三方服务提供商",
      250: "为方便您的访问并丰富您的体验，可能会有第三方提供的产品或服务。您可以选择是否访问这类内容或链接，或是否使用该第三方的产品或服务。但我们对于第三方提供的产品或服务没有控制权。我们无法控制第三方掌握的您的任何个人信息。您在使用第三方产品或服务过程中的信息保护问题，不适用于本政策的管理。本政策也不适用于您自行选择提供给第三方的任何信息。请您查看该第三方的隐私保护政策。",
      251: "参加营销推广活动。当您选择参加我们举办的有关营销活动时，根据活动需要您需要提供姓名、通信地址、联系方式、银行账号等信息，以便第三方能及时向您提供奖品。当您选择参加我们举办的有关营销活动时，根据活动需要您需要提供姓名、通信地址、联系方式、银行账号等信息。经过您的明示同意，我们会将上述信息与第三方共享，以便我们能委托第三方及时向您提供奖品。",
      252: "八. 本政策的更新",
      253: "我们可能会不定期修改、更新本隐私政策，有关隐私政策的更新，我们会在骑记APP 中以通知公告等形式发布，您可以访问骑记APP查询最新版本的隐私政策。对于重大变更，我们会向您发出郑重通知（包括对于某些服务，我们会通过电子邮件发送通知，说明对隐私政策进行的更改）。",
      254: "九. 适用范围",
      255: "我们的隐私政策适用于由骑记（厦门）科技有限公司及其关联公司提供的所有服务，包括骑记APP及 www.iriding.cc 上提供的服务，但是不包括附有独立隐私政策的服务（如第三方提供的产品和服务）。",
      256: "我们的隐私权政策不适用于第三方提供的产品和服务，例如在骑记APP及 www.iriding.cc 上由第三方提供的产品和服务，以及在我们的服务中链接到的其他网站，这些产品、服务或网站会有独立的隐私政策予以规范，请另行查阅相应的政策规定。",
      257: "对于为我们的服务进行广告宣传，以及可能使用我们的 Cookie 或其他日志信息来投放和使用广告的其他公司和组织，我们的隐私权政策并未涵盖其信息处理政策。",
      258: "十. 如何联系我们",
      259: "您可通过以下方式与我们联系：",
      260: "如对本政策内容有任何疑问、意见或建议，您可通过骑记APP后台反馈，骑记微信公众号后台与我们联系；",
      261: "如果发现个人信息可能被泄露，您可以通过骑记APP投诉举报，我们将在15个工作日内联络您并完成核查和处理；",
      262: "我们还设立了意见、建议反馈邮箱，您可以通过 support@miriding.com 与我们联系，我们将在15个工作日内联络您并完成核查和处理。",
      263: "若您对本政策有任何问题，请联系：privacy@miriding.com，我们将在15个工作日内联络您并完成核查和处理。",
    },
  },
  downLoad: {
    v1: "骑记APP",
    v2: "科 技 之 美， 成 就 骑 行 乐 趣",
    v3: "适用于中国大陆地区",
    v4: "适用于其他地区",
    v5: "应用商店 ",
    v6: "本地下载",
  },
  index: {
    v1: {
      text0: "骑记",
      text1: "首页",
      text2: "电助力车",
      text3: "配件",
      text4: "软件",
      text5: "支持",
      text6: "公司简介",
      text7: "诞生",
      text8: "2011年，骑记（厦门）科技有限公司在厦门成立。",
      text9: "奔跑",
      text10: "2013年，骑行运动社交软件骑记APP1.0正式上线。",
      text11:
        "历时两年，用户装机量和注册量超越百万，成为骑行用户首选的骑行软件。",
      text12: "截止目前，用户骑行总里程共1.3亿km，可从地球往返月球175次。",
      text13: "提速",
      text14: "2014年9月，成为小米生态链企业。",
      text15:
        "骑记投身硬件研发，陆续推出环法级别的R1碳纤维公路车、米家骑记电助力折叠车、米兔儿童车、米家充气宝等多款产品。",
      text16: "扬帆",
      text17: "2019年，小米电助力折叠自行车、米家充气宝陆续出海。",
      text18:
        "目前骑记产品在亚洲、欧洲、大洋洲、南美洲四大洲的十余个国家和地区销售。",
      text19:
        "2020年，推出骑记电助力自行车和配件产品，开启了自有品牌的新征程。",
      text20: "沉淀",
      text21:
        "未来，骑记将持续专注于研发面向未来的运动出行产品，通过扎实的产品研发和设计实力，为用户带来更好的出行体验。",
      text22: "发展历程",
      text23: "台湾产新欧标电助力折叠自行车完成认证，8月份在欧洲开售",
      text24:
        "EF1成为国内最早完成EN15194-2017认证并在欧洲销售的中国品牌电助力自行车",
      text25:
        "受疫情和欧洲购物补贴影响，自行车销售火爆，小米电助力折叠自行车订单逐月增加",
      text26: "售价199元人民币的米家充气宝在小米网上线销售",
      text27:
        "「米兔儿童车14寸」，在小米商城上线销售，并斩获2020德国红点设计奖",
      text28:
        "新国标骑记电动助力自行车小米网上线销售，并获取自行车车架工艺等相关专利18项",
      text29:
        "米家骑记电助力自行车国内售出51800台，累计销售额1.35亿元，在国内电商开创了电助力自行车的新品类",
      text30: "连续两年单品销量第一，2017年市场占有率高达91.3%",
      text31: "iFixit拆解米家电助力自行车给予高度赞扬",
      text32: "骑记公司被厦门知识产权局认定为市工业设计知识产权优势企业",
      text33: "开发售后APP，解决备件、维修指导、费用结算等实际需求",
      text34: "通过签约272家骑记城市合伙人为骑记产品提供售后服务",
      text35:
        "米家电助力折叠自行车由小米联合创始人刘德发布，产品获得小米CEO雷军点赞",
      text36: "铝合金锻造技术在自行车上的首次应用",
      text37: "Can总线技术在自行车上的首次应用",
      text38: "获取纵向折叠等自行车相关专利11项",
      text39: "TMM力矩传感器首次大规模量产",
      text40: "QiCYCLE R1 碳纤维功率自行车小米网众筹",
      text41: "QiCYCLE R1 斩获两项红点设计奖",
      text42: "R1因具备完整的运动数据获取能力获得台北自行车创新奖",
      text43:
        "骑记成为小米生态链的一员，负责生活出行方向的智能硬件产品研发和生态布局",
      text44: "骑记公司更名为骑记（厦门）科技有限公司",
      text45: "骑记获得小米天使轮融资",
      text46: "骑记加入小米生态链",
      text47: "开发骑行软件“骑记“APP并上线运营",
      text48: "骑记APP用户量突破124万",
      text49: "骑记用户累积骑行轨迹达到6500万km，成为全国最大骑行社区",
      text50: "骑记（厦门）科技有限公司于厦门成立",
      text51: "匠心产品",
      text52: "创新设计",
      text53: "产品斩获德国红点设计奖、设计iF奖等奖项，并获得多项发明专利",
      text54: "专注研发",
      text55: "由业内专家级成员带队的研发团队关注细节，持续推进关键技术创新",
      text56: "严格品控",
      text57: "多道质量检测流程，层层把关整车品质，向用户提供高质量的产品",
      text58: "高效售后",
      text59: "不断探索更贴心的线下服务模式，让用户获得更优质的产品体验",
      text60: "办公地点",
      text61: "下列城市",
      text62: "北京",
      text63: "北京市海淀区安宁庄东路16号 融科融智·蜂巢工场东区2号楼",
      text64: "常州",
      text65: "江苏省常州市新北区创新大道89号",
      text66: "上海",
      text67: "上海市徐汇区田林路142号G栋104B",
      text68: "深圳",
      text69:
        "深圳市南山区粤海街道高新区社区粤兴四道1号中山大学深圳产学研大厦17层",
      text70: "法兰克福",
      text71:
        "Adam-Opel-Str. 10 im Hof， Geb. A， bei eKey 60386 Frankfurt am Main，Germany",
      text72: "厦门",
      text73: "福建省厦门市湖里区岐山北路768号403室",
      text74: "香港",
      text75: "香港九龙长沙湾青山道339号恒生青山道大厦1102室",
      text76: "苏州",
      text77: "苏州市高新区狮山路28号苏州高新国际商务广场606",
      text78: "社交媒体",
      text79: "售后服务热线",
      text80: "联系我们",
      text81: "商务合作",
      text82: "国内销售",
      text83: "海外销售",
      text84: "客户服务",
      text85: "数据保护",
      text86: "骑记 © 2013-2022 粤ICP备2021130651号-2",
      text87: "隐私和法律",
      text88: "您的Cookies偏好",
      text89: "欢迎来到骑记！",
      text90:
        "为了为您提供更好的网站体验，我们希望使用Cookies来改善我们的网站。",
      text91: "Cookies收集的信息不会识别到您个人。",
      text92:
        "有关我们使用的Cookies的类型以及关于我们Cookies的更多信息，请阅读我们的Cookies政策。",
      text93: "拒绝",
      text94: "接受",
      text100:
        "为了为您提供更好的网站体验，我们希望使用Cookies来改善我们的网站。Cookies收集的信息不会识别到您个人。有关我们使用的Cookies的类型以及关于我们Cookies的更多信息，请阅读我们的",
      text101: "Cookies政策",
      text102: "骑记",
      text103: "法律信息",
      text104: "骑记",
    },
  },
  product: {
    v1: {
      productMove: "探索更多",
      productCpd: "骑记充气宝 P1",
    },
  },
  contact: {
    // 简体中文
    v1: "联系我们",
    v2: "商务合作",
    v3: "邮箱地址：market@iriding.com",
    v4: "销售团队",
    v5: "国内销售：sales@iriding.com",
    v6: "海外销售：sales@iriding.com",
    v7: "客户服务",
    v8: "售后服务热线：400-1144-177",
    v9: "数据保护",
    v10: "邮箱地址：dpo@iriding.com",
  },
  details: {
    v1: "骑记充气宝P1",
    v2: "颜色",
    v3: "立即购买",
    v4: "概述",
    v5: "规格参数",
    v6: "技术支持",
    v7: "精准充气的迅猛龙",
    v8: "快速补气约60秒",
    v9: "高压可达150psi",
    v10: "整胎充气约5分钟",
    v11: "预设压力自动停",
    v12: "5种使用模式",
    v13: "应急充电宝",
    v14: "强劲动力",
    v998: "突破充气想象",
    v15: "高性能机芯和大功率电机加持，",
    v16: "零胎压充满约5分钟",
    v16a: "5分钟整胎充气",
    v1611: "满电可为",
    v1611a: "满电可充约",
    v1621: "5",
    v1631a: "条轿车轮胎充气",
    v1631b: "条汽车轮胎",
    v17: "满电可为约5条轿车轮胎充气",
    v1711: "约",
    v1711a: "约",
    v1721: "分钟/条",
    v1721a: "分钟/条",
    v999: "约5分钟/条，",
    v18: "体型小巧易收纳",
    v19: "机身高度约20cm",
    v20: "单手可操控，",
    v21: "体型小巧收纳灵活",
    v22: "骑行自驾，轻装上阵",
    v23: "高精度气缸",
    v231: "速度快气压高",
    v24: "压力轻松达到150psi，",
    v24a: "压力轻松达到150psi",
    v24b: "高压可达150psi",
    v25: "满足公路车轮胎等所需的高气压，",
    v26: "充气不费「吹」灰之力",
    v261: "满电可为",
    v262: "16",
    v263: "条公路自行车轮胎充气",
    v2631: "条公路自行车轮胎",
    v27: "满电可为16条公路自行车轮胎充气",
    v9971: "约",
    v9972: "63",
    v9973: "秒/条",
    v997: "约63秒/条",
    v272: "预设压力值",
    v271: "精准充好自动停",
    v28: "按需设定气压值",
    v29: "充气完成自动停止",
    v30: "随时随地精准测压",
    v30a: "按需设定气压值充气完成自动停止",
    v30b: "随时随地精准测压",
    v31: "高精度气压传感器 ",
    v311: " ",
    v312: "气压传感器精度±1psi",
    v32: "5种使用模式，自由切换",
    v33: "汽车、摩托车、自行车、球类、专业模式，",
    v34: "限定气压范围。",
    v35: "选定模式充气，操作更安全",
    v362: "默认压力",
    v361: "汽车模式",
    v3611: "默认压力：2.5bar",
    v3612: "默认压力：2.4bar",
    v363: "2.5bar",
    v364: "2.4bar",
    v37: "可调整范围：1.8-3.5bar",
    v371: "1.8-3.5bar",
    v3711: "可调整范围：1.8-3.0bar",
    v38: "摩托车模式",
    v381: "默认2.4bar",
    v39: "可调整范围",
    v391: "1.8-3.0bar",
    v40: "自行车模式",
    v41: "默认压力",
    v411: "45psi",
    v4111: "默认压力：45psi",
    v412: "可调整范围",
    v413: "30-145psi",
    v4121: "可调整范围：30-145psi",
    v42: "球类模式",
    v423: "可调整范围：4-16psi",
    v422: "默认压力：8psi",
    v421: "8psi",
    v43: "4-16psi",
    v44: "专业模式",
    v45: "3-150psi",
    v451: "可调整范围：3-150psi",
    v451a: "3-150psi",
    v451b: "0.2-10.3bar",
    v46: "0.2-10.3bar",
    v462: " 0.2-10.3bar",
    v461: "3-150psi / 0.2-10.3bar",
    v47: "内置18650锂电池",
    v48: "操作不受“线”",
    v49: "无论您想去山间探索还是海边踏浪，",
    v50: "无需外接电源，",
    v51: "充气无拘无束",
    v52: "精致细节，为你的生活打气",
    v53: "自带照明，方便易用",
    v54: "LED照明，夜间操作不怕黑",
    v55: "SOS爆闪，危机时刻更从容",
    v56: "双重散热，连续工作",
    v57: "大功率风扇配合机身散热孔",
    v58: "无负载连续工作时长可达35分钟",
    v581: "无负载连续工作时长可达35分钟",
    v59: "是打气筒，也是充电宝",
    v60: "全球通用Type-C接口，充电无忧",
    v61: "USB-A接口可为手机充电",
    v611: "USB适配器充电",
    v612: "车载充电器充电",
    v613: "充电宝充电",
    v62: "数码大屏，实时显示",
    v63: "4.4英寸高清大屏实时显示",
    v631: "当前胎压、预设胎压、使用模式等数据",
    v64: "配件齐全，贴心收纳",
    v65: "收纳袋保护机身",
    v66: "夹层可存放气嘴、气针、充电线",
    v67: "9、通过多项严苛测试",
    v68: "过压力保护功能测试",
    v69: "气管拉力强度测试",
    v70: "电气强度试验",
    v71: "气管低温冷弯折试验",
    v72: "自由跌落测试",
    v73: "持续工作时间测试",
    v74: "抗压测试",
    v75: "高/低温充电测试",
    v76: "骑记充气宝P1性能参考",
    v77: "1bar=14.5psi=100kPa",
    v78: "充气对象",
    v79: "汽车轮胎",
    v791: "条汽车轮胎",
    v80: "175/70 R14（补气）",
    v81: "五菱宏光",
    v82: "（175/70 R14）",
    v83: "别克英朗",
    v84: "（195/65 R16）",
    v85: "大众高尔夫",
    v86: "（205/55 R16）",
    v87: "丰田凯美瑞",
    v88: "（215/55 R17）",
    v89: "路虎揽胜",
    v90: "（255/55 R20）",
    v91: "宝马730",
    v92: "（275/40 R19）",
    v93: "摩托车轮胎",
    v94: "（150/60 R17）",
    v95: "摩托车轮胎",
    v96: "（110/70 R17）",
    v97: "公路自行车轮胎",
    v98: "（700 × 25C）",
    v99: "山地自行车轮胎",
    v100: "（27.5 × 1.95）",
    v101: "电动滑板车轮胎",
    v102: "（8 1/2 x 2）",
    v103: "平衡车轮胎",
    v104: "（9 x 3.0-6）",
    v105: "7号篮球",
    v106: "5号足球",
    v107: "5号排球",
    v108: "充气压力",
    v109: "2.0-2.5bar",
    v110: "0-2.5bar",
    v111: "0-2.4bar",
    v112: "0-120psi",
    v113: "0-50psi",
    v114: "0-40psi",
    v115: "0-30psi",
    v116: "0-9psi",
    v117: "0-11psi",
    v118: "0-6psi",
    v119: "满电可充数量",
    v120: "≈ 18条",
    v121: "≈ 5条",
    v122: "≈ 4条",
    v123: "≈ 3条",
    v124: "≈ 2条",
    v125: "≈ 7条",
    v126: "≈ 15条",
    v127: "≈ 16条",
    v128: "≈ 20条",
    v129: "≈ 201条",
    v130: "≈ 97条",
    v131: "≈ 92个",
    v132: "≈ 86个",
    v133: "≈ 197个",
    v134: "充气速度",
    v135: "≈ 60秒/条",
    v136: "≈ 4分58秒/条",
    v137: "≈ 6分3秒/条",
    v138: "≈ 5分52秒/条",
    v139: "≈ 7分20秒/条",
    v140: "≈ 12分30秒/条",
    v141: "≈ 9分10秒/条",
    v142: "≈ 2分50秒/条",
    v143: "≈ 1分30秒/条",
    v144: "≈ 63秒/条",
    v145: "≈ 58秒/条",
    v146: "≈ 10秒/条",
    v147: "≈ 14秒/条",
    v148: "≈ 18秒/个",
    v149: "≈ 20秒/个",
    v150: "≈ 10秒/个",
    v1510: "[1]  ",
    v1520: "[2]  ",
    v1530: "[3]  ",
    v1540: "[4]  ",
    v1550: "[5]  ",
    v1560: "[6]  ",
    v1560a: "[7]  ",
    v1560b: "[8]  ",
    v1560c: "[9]  ",
    v1560d: "[10]  ",
    v1570:
      "本产品满电状态下，可将约18条175/70 R14 汽车轮胎从2.0bar补气至2.5bar，约60秒补满一条轮胎。",
    v1580:
      "本产品满电状态下，可将约5条175/70 R14 汽车轮胎从0bar充气至2.5bar，约5分钟充满一条轮胎。",
    v1590: "保压模式需配合模型喷漆笔、PA壶、气囊千斤顶等工具使用，请自行购买。",
    v1591: "USB-A接口输出参数为5V⎓2A，可作为5000mAh移动电源使用。",
    v1592:
      "此八项测试数据来自南德认证检测（中国）有限公司深圳分公司及骑记实验室。测试报告编号：QJSZ20210731.01，QJSZ20210806.01，QJSZ20210729.01，QJSZ20210831.01，QJSZ20210519.01，QJSZ20210803.01，68.189.21.0074.01。",
    v1593:
      "表格中数据均为满电状态下的测试数据，实际值与环境、温度、电量、使用习惯等因素不同可能略有差异。",
    v1594: "注：页面其他数据如无特殊说明，均来源于骑记实验室。",
    v151: "本产品满电状态下，",
    v152: "可将18条175/70 R14汽车轮胎从2.0bar补气至2.5bar，",
    v153: "约60秒补满一条轮胎。",
    v1521:
      "可将18条175/70 R14汽车轮胎从2.0bar补气至2.5ba，r,约60秒补满一条轮胎。",
    v154: "本产品满电状态下，",
    v155: "可将约5条175/70 R14汽车轮胎从0bar充气至2.5bar，",
    v156: "约5分钟充满一条轮胎。",
    v1541:
      "本产品满电状态下，可将约5条175/70 R14汽车轮胎从0bar充气至2.5bar，约5分钟充满一条轮胎。",
    v157: "保压模式需配合模型喷漆笔、PA壶、气囊千斤顶等工具使用，",
    v158: "请自行购买，",
    v1571: "保压模式需配合模型喷漆笔、PA壶、气囊千斤顶等工具使用，请自行购买，",
    v159: "USB-A接口输出参数为5V⎓2A，",
    v160: "可作为5000mAh移动电源使用",

    v161: "此八项测试数据来自南德认证检测（中国）有限公司深圳分公司及骑记实验室",
    v162: "测试报告编号",
    v163: "表格中数据均为满电状态下的测试数据",
    v164: "实际值与环境、温度、电量、使用习惯等因素不同可能略有差异",
    v1631:
      "表格中数据均为满电状态下的测试数据，实际值与环境、温度、电量、使用习惯等因素不同可能略有差异",
    v165: "注：",
    v166: "页面其他数据如无特殊说明，均来源于骑记实验室。",
    v1651: "注：页面其他数据如无特殊说明，均来源于骑记实验室",
    v167: "均来源于骑记实验室",
    v168: "说明书×1",
    v169: "收纳袋×1",
    v170: "高压气管（含美式气嘴）×1",
    v171: "充电线×1",
    v172: "气针×1",
    v173: "法式气嘴转换头×1",
    v174: "骑记充气宝P1×1",
    v175: "包装清单",
    v176: "可自动加压",
    v177: "创新保压模式",
    v178: "低于设置值立即开始加压，自动保持容器压力，喷漆、浇花、洗车，轻松省事",
    v191: "开机后按住灯光键",
    v192: "并按压「+」键盘，直到",
    v193: "图标出现",
    v194: "产品名称",
    v195: "骑记充气宝P1",
    v196: "产品型号",
    v1961:
      "此八项测试数据来自南德认证检测（中国）有限公司深圳分公司及骑记实验室。测试报告编号：QJSZ20210731.01，QJSZ20210806.01，QJSZ20210729.01，QJSZ20210831.01，QJSZ20210519.01，QJSZ20210803.01，68.189.21.0074.01。",
    v197: "QJCQBP1",
    v198: "产品尺寸",
    v199: "200.5 × 63.5 × 63.5 mm（裸机尺寸，不含气管）",
    v199a: "200.5 × 63.5 × 63.5 mm",
    v199b: "（裸机尺寸，不含气管）",
    v200: "充气压力范围",
    v201: "3-150psi / 0.2-10.3bar",
    v202: "电池种类",
    v203: "锂离子电池",
    v204: "电池容量",
    v2041: "28.86 Wh / 11.1V",
    v205: "28.86Wh/11.1V",
    v206: "传感器精度",
    v207: "±1.5 psi",
    v208: "充电用时",
    v209: "小于4小时",
    v210: "输入参数",
    v211: "5V ⎓ 3A",
    v212: "严苛测试，打造耐久品质",
    v2121: "严苛测试",
    v2122: "打造耐久品质",
    v213: "场景多样，自由切换",
    v2131: "场景多样",
    v2132: "自由切换",
    v214: "摆脱牵绊，",
    v214a: "摆脱牵绊",
    v215: "经典黑",
    v216: "颜值高",
    v217: "告别繁杂的脚踩手捏检查",
  },
  C2: {
    v0: "QiCYCLE C2",
    v1: "电动助力自行车      C2",
    v2: "城市穿行&nbsp;&nbsp;&nbsp;&nbsp;自由超乎想象",
    v3: "颜色",
    v4: "黑色",
    v5: "轻便小巧",
    v6: "智能大灯",
    v7: "超长续航",
    v8: "强劲锂电",
    v9: "八挡变速",
    v10: "光感大屏",
    v11: "折叠头管",
    v12: "新国标",
    v13: "概述",
    v14: "规格参数",
    v14a: "产品说明书",
    v15: "支持",
    v16: "外观时尚颜值高",
    v17: "到哪都是C位",
    v18: "车身比例经典、造型优雅，出街利器，一路吸睛。",
    v19: "骑行体验享舒适",
    v20: "人体工学设计",
    v21: "符合人体工学的车架结构，舒适省力，助你人车合一。",
    m21a: "符合人体工学的车架结构，舒适省力，助你人车合",
    m21b: "一",
    v22: "*电动自行车上路行驶需申领牌照，请遵守交通法规，注意骑行安全。",
    v23: "超轻车体，操控灵活",
    v24: "航空级铝合金打造的多型腔车架",
    v24a: "，  转向灵活、操控轻盈，",
    v24b: "小空间也能辗转腾挪。",
    v25: "轻量级车身，重量级承载",
    v26: "整车约重20kg, 可安全承重120kg, 男女老少均可驾驭",
    v26d: "  。",
    v26c: "*前车筐、后货架、海绵坐垫、儿童座椅等配件请根据实际需要另行选购。",
    m26a: "整车约重20kg, 可安全承重120kg, 男女老少均可",
    m26b: "驾驭",
    v27: "高效助力，如虎添翼",
    v27a: "尽享骑行乐趣",
    v28: "高精度传感器",
    v29: "懂你的每一次踩踏",
    v30: "敏锐捕捉蹬踏数据，提速高效，车随心动，任意而行。",
    m30a: "敏锐捕捉蹬踏数据，提速高效，车随心动，",
    m30b: "任意而行。",
    m31: "传感器原理",
    v31: "C2 PRO",
    v32: "力矩传感器",
    v33: "C2",
    v34: "速度传感器",
    v35: "1.显示骑行数据",
    v36: "2.控制助力输出",
    v37: "3.感应踩踏力度",
    v38: "4.驱动电机行驶",
    v39: "多挡位选择，多场景兼顾",
    v40: "三挡助力模式自由切换，超长续航约60km",
    v40c: "。",
    v41: "人力：",
    v42: "电助力：",
    v43: "电助力输出",
    v44: "1挡",
    v45: "耗电低，长续航",
    v46: "2挡",
    v47: "兼顾续航与动力",
    v48: "3挡",
    v49: "动力强，加速快",
    v50: "定速巡航，一键安逸",
    v51: "锁定骑行速度，无需踩踏，放松身心。",
    v52: "八挡变速器",
    v52d: "八挡变速器",
    v52c: "，",
    v52a: "精准流畅",
    v53: "八个挡位自由调节，配合电助力，迎风挑战“魔鬼”上坡。",
    m53a: "八个挡位自由调节，配合电助力，迎风挑战",
    m53b: "“魔鬼”上坡。",
    v54: "八挡变速",
    v55: "畅骑这座城市",
    v56: "你的安全我放心上",
    v57: "锂电黑科技",
    v57a: "电池仓独立",
    v58: "内置30节动力锂电池，线路与电池安全隔离，",
    v58a: "保障用电安全。",
    v59: "*本品为内置电池，",
    v60: "请勿自行拆卸。",
    v61: "汽车级电源管理",
    v62: "汽车级BMS安全管理系统，充满即停，保障充电安全。",
    m62a: "汽车级BMS安全管理系统，充满即停，保障充",
    m62b: "电安全。",
    v63: "低温容量测试",
    v64: "高温容量测试",
    v65: "IPX5测试",
    v66: "自由跌落测试",
    v67: "过充保护",
    v68: "过放保护",
    v69: "加速度冲击测试",
    v70: "短路保护",
    v71: "高低温冲击测试",
    v72: "耐振动测试",
    v73: "130℃高温测试",
    v74: "荷电保持能力",
    v75: "按键、指拨，就在手边",
    v76: "功能清晰，操控简单，长辈也能轻松掌握。",
    v77: "中央光感显示屏",
    v78: "自动化智能调节屏幕亮度，挡位、电量、速度数",
    v78a: "据尽现眼前，白天看得清，夜晚不刺眼。",
    m78a: "自动化智能调节屏幕亮度，挡位、电量、速度",
    m78b: "数据尽现眼前，白天看得清，夜晚不刺眼。",
    v79: "灯光自动感应",
    v80: "光感控制前后灯开关，",
    v81: "自动点亮或熄灭。",
    m81a: "自动点亮或熄灭。刹车",
    v82: "刹车尾灯高亮，",
    m82a: "尾灯高亮，",
    v83: "骑行不分心，",
    v84: "行车更安全。",
    v85: "增减配件",
    v85a: "打造百变的你",
    v86: "职场达人、家居能手、时尚辣妈......增减配件即可完成角色转变。",
    m86a: "职场达人、家居能手、时尚辣妈......增减配件即",
    v86b: "可完成角色转变。",
    v87: "增减配件",
    v88: "连接骑记App",
    v89: "记录生活点滴",
    v90: "接入骑记App，呈现并记录速度、里程等数据，分享骑行",
    v90a: "生活，一键升级固件。",
    m90a: "接入骑记App，呈现并记录速度、里程等数据，分",
    v90b: "享骑行生活，一键升级固件。",
    v91: "安装骑记App并绑定车辆，可延长一年保修服务",
    v92: "连接App",
    v93: "匠心设计，创造「骑记」",
    v94: "永磁无刷电机",
    v95: "触发澎湃动力",
    v96: "20英寸轮径",
    v97: "享灵活骑趣",
    v98: "头管可折叠",
    v99: "做后备箱的旅行家",
    v100: "前后碟刹",
    v101: "跑多快都刹得稳",
    v102: "快调座管夹",
    v103: "高低你说了算",
    v104: "精选充气轮胎",

    v105: "稳如泰山",
    v106: "[1]    CCC认证机构：中国质量认证中心，证书编号：2021011119391871",
    v107: "[2]    多型腔自行车车架，获得多项实用新型专利，专利编号分别为：ZL201820720214.X；ZL201822033605.7；ZL201821913718.X。",
    v108: "[3]    本产品适用身高155-185cm。",
    v109: "[4]    续航60km测试条件：2挡模式下，关闭照明灯，承重70kg，匀速20km/h在城市铺装路面上骑行。实际续航会因载重、温度、路面情况、操作习惯等因素不同略有差异。   ",
    v110: "[5]    数据来源于福建飞毛腿动力科技有限公司检测中心，报告编号：DC210073",
    v111: "[6]    骑行状态下，前后灯可根据环境光线变化自动开/关。",
    v112: "[7]    前车筐、后货架、海绵坐垫、儿童座椅等配件请根据实际需要另行选购。",
    v113: "[8]    延保服务详情请拨打售后热线 400-1144-177。",
    v114: "[9]    请在座管最高及最低安全线之间调节座垫高度。",
    v115: "[10]   本页面中产品轮胎纹路仅供参考，由于轮胎批次不同，实物可能略有差异，请以实物为准。",
    v116: "注：本页面所涉及的数据如无特殊说明，均来源于骑记实验室。",
    v120: "骑记电动助力自行车 参数",
    v121: "产品参数",
    v121a: "产品名称",
    v122: "电助力自行车",
    v123: "最高设计车速",
    v124: "25Km/h",
    v125: "产品型号",
    v126: "TDN09Z",
    v127: "刹车方式",
    v128: "机械碟刹",
    v129: "产品尺寸（折叠前）",
    v130: "1530×600×1060 mm",
    v131: "牙盘",
    v132: "56T×170mm 铝曲柄",
    v133: "产品尺寸（折叠后）",
    v134: "1530×430×725 mm",
    v135: "花鼓",
    v136: "铝花鼓",
    v137: "整车质量",
    v138: "约20 kg",
    v139: "充电时间",
    v140: "约5.5小时",
    v141: "轮组尺寸",
    v142: "20英寸",
    v143: "车身防水等级",
    v144: "IPX 3",
    v145: "载重量",
    v146: "120 kg",
    v147: "屏幕尺寸",
    v148: "53×32 mm",
    v149: "电池容量",
    v150: "7.5 Ah",
    v151: "无线连接",
    v152: "蓝牙 5.0",
    v153: "包装清单",
    v154: "电助力自行车×1",
    v155: "4、5、6 mm <br/>内六扳手×1",
    v155a: "内六扳手×1",
    v155c: "4、5、6 mm 内六扳手×1",
    v156: "左右脚踏×1",
    v157: "电源适配器×1",
    v158: "牌照架×1",
    v159: "M5螺丝×4",
    v160: "使用说明书×1",
    v161: "产品合格证×1",
    v162: "三包凭证×1",
  },
  F2: {
    v0: "QiCYCLE F2",
    v1: "骑记电助力折叠自行车     F2",
    v2: "展开折叠的城市",
    v3: "颜色",
    v4: "经典黑",
    v5: "力矩传感",
    v6: "折叠便携",
    v7: "电池可拆",
    v8: "超长续航",
    v9: "行车电脑",
    v10: "定速巡航",
    v11: "内三变速",
    v12: "16寸轮径",
    v13: "概述",
    v14: "规格参数",
    v15: "支持",
    v16: "助力随叫随到",
    v17: "懂你的每一次踩踏",
    v18: "力矩传感器采集踩踏力度数据，控制助力大小。",
    v18a: "力矩传感器采集踩踏力度数据，控制助力大小。神经般敏感",
    v18b: "的助力输出助你人车合一。",
    m18a: "力矩传感器采集踩踏力度数据，控制助力大小。神",
    m18b: "经般敏感的助力输出助你人车合一。从此无惧崎",
    m18c: "岖，爬坡如履平地。",
    v19: "神经般敏感的助力输出助你人车合一。",
    v20: "从此无惧崎岖，爬坡如履平地。",
    v21: "力矩传感系统原理",
    v22: "1.显示骑行数据",
    v23: "2.控制助力输出",
    v24: "3.感应踩踏力度",
    v25: "4.驱动电机行驶",
    v26: "折叠收纳更小巧",
    v27: "后备箱里的常备神器",
    v28: "纵向折叠专利设计 ",
    v29: "，主梁完整也能实现折叠",
    m29a: "主梁完整也能实现折叠，铝",
    v30: "铝合金锻造前、后叉轻量又坚固，",
    v30a: "， 铝合金锻造前、",
    v30b: "后叉轻量又坚固，",
    m30a: "合金锻造前",
    v31: "承重高达100kg，",
    v32: "骑行更安全。",
    v33: "专利号 ：201420243615.2",
    v34: "折叠后体积",
    m34a: "≈0.25m",
    v35: "电池可拆卸",
    v36: "充电更方便",
    v37: "车架内置20节18650动力锂电池。",
    v38: "汽车级BMS安全管理系统，",
    m38b: "全管理系统，",
    m38a: "汽车级BMS安",
    v38a: "充满即停，确保用电安全。",
    v39: "电池拆装简单，",
    v40: "既能整车直充，",
    v41: "也能电池单充。",
    v42: "电池可拆",
    v43: "SHIMANO",
    v43a: "内三速系统，变速精准",
    v44: "变速精准",
    v45: "禧玛诺内三速变速系统，",
    v46: "精准变速，",
    v47: "坚固耐用。",
    m47a: "坚固耐用。配",
    m47b: "合电助力，",
    m47c: "挑战“魔鬼”上坡。",
    v48: "配合电助力，",
    v49: "挑战“魔鬼”上坡。",
    v50: "三种骑行模式",
    v51: "更快、更远尽在掌控",
    v52: "定速模式，",
    v53: "慵懒轻松",
    v54: "一键锁定骑行速度，无需踩踏，骑行更轻松。",
    v55: "定速模式",
    v56: "助力模式，",
    v57: "省力省电",
    v58: "三挡助力模式自由切换，超长续航约60km",
    v58c: "。",
    v59: "2挡助力续航约60km[2]。",
    v60: "人力",
    v61: "电助力",
    v62: "1挡 ",
    v62a: "耗电低，长续航",
    v63: "2挡 ",
    v63a: "兼顾续航与动力",
    v64: "3挡 ",
    v64a: "动力强，加速快",
    v65: "电助力输出",
    v66: "单车模式，",
    v67: "强身健体",
    v68: "N挡零助力，适合骑行健身、锻炼，就像自行车一样轻松好骑。",
    v68a: "N挡零助力，适合骑行健身、锻炼，就像自行车一",
    v68b: "样轻松好骑。",
    m68a: "N挡零助力，适合骑行健身、锻炼，就像自行车",
    m68b: "一样轻松好骑。",
    v69: "行车电脑，掌控骑行数据",
    v69b: "行车电脑",
    v69a: "掌控骑行数据",
    v70: "骑行挡位轻松切换，彩屏行车电脑显示速度、挡位、电量、里程信息。",
    v71: "彩屏行车电脑显示速度、挡位、电量、里程信息。",
    v71b: "彩屏行车电脑显示速度、挡位、电量、里",
    v71a: "程信息。",
    m71a: "彩屏行车电脑显示速度、",
    m71b: "挡位、电量、里程信息。",
    v72: "行之有据，心中有底。",
    v73: "助力模式控制",
    v74: "骑行数据检测",
    v75: "连接骑记App",
    v75a: "指导科学骑行",
    v76: "App呈现并记录速度、里程、心率、踏频",
    v76b: "等数据，通过手",
    v76a: "机同步到云端，方便随时查看，科学指导你的骑行。",
    m76a: "App呈现并记录速度、里程、心率、踏频等数据，",
    m76b: "通过手机同步到云端，方便随时查看，科学指导你",
    m76c: "的骑行。",
    v77: "安装骑记App并绑定车辆，可延长一年保修服务。",
    v78: "连接App",
    v79: "匠心设计，精彩细节",
    v80: "高亮LED前大灯",
    v81: "照亮夜行路",
    v82: "独特环形尾灯",
    v83: "守护骑行安全",
    v84: "适合城市骑行的16寸车轮",
    v85: "助你稳步前行",
    v86: "罗拉刹车",
    v87: "终身免维护",
    v88: "超轻铝合金锻造车架",
    v89: "小巧灵活，成就超强续航",
    v90: "整车IPX4级防水",
    v91: "无惧风吹雨打",
    v92: "伸缩挡泥板",
    v93: "方便整车折叠",
    v94: "实用新型专利名称：",
    v95: "可纵向旋转的折叠车架，",
    v96: "[1]      实用新型专利名称：可纵向旋转的折叠车架，专利号：201420243615.2。",
    v97: "[2]      续航60km条件：助力为2挡模式下，承重75kg，匀速20km/h在城市铺装路面上骑行。实际续航会因载重、温度、路面情况、操作习惯等因素不同略有差异。",
    v98: "[3]      获取心率、踏频数据需要连接心率带、踏频器等符合蓝牙5.0协议的外接设备，请另行购买。",
    v99: "[4]      整车通过IPX4防水测试，任意方向直接受到水的溅射不影响车辆工作性能。请勿将整车在水里浸泡或使用高压水枪冲洗。雨天骑行时，禁止让电机在深水中行驶，严禁让水面超过电机轮轴位置。测试报告编号：TH60150A/2019，检测机构：SGS Taiwan Ltd.。",
    v100: "注：1.本页面中，产品轮胎纹路仅供参考，由于轮胎批次不同，实物可能略有差异，请以实物为准。",
    v106a: "  2.本页面数据如无特殊说明，均来源于骑记实验室。",
    v107: "骑记电助力折叠自行车  参数",
    v108: "参数",
    v109: "产品参数",
    v110: "产品名称",
    v111: "电动自行车",
    v112: "产品型号",
    v113: "TDR08Z",
    v114: "产品尺寸（折叠前）",
    v115: "1290 × 540 × 950mm",
    v116: "产品尺寸（折叠后）",
    v117: "1000 × 450 × 650mm",
    v118: "整车质量",
    v119: "约14.5kg",
    v120: "轮组尺寸",
    v121: "16英寸",
    v122: "载重量",
    v123: "100kg",
    v124: "电池容量",
    v125: "6Ah",
    v126: "充电时间",
    v127: "约4.5小时",
    v128: "防水等级",
    v129: "IPX4",
    v130: "屏幕尺寸",
    v131: "1.77英寸",
    v132: "无线连接",
    v133: "蓝牙5.0",
    v134: "包装清单",
    v135: "折叠助力自行车x 1",
    v136: "4、5、6mm",
    v136a: " 内六角扳手x 1",
    v136c: "4、5、6mm 内六角扳手x 1",
    v137: "左、右脚踏 x 1",
    v138: "电源适配器 x 1",
    v139: "使用说明书 x 1",
    v140: "产品合格证 x 1",
  },
};
