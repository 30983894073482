<template>
  <div class="foot">
    <div class="footerIsPhone">
      <div class="phone-footer">
        <div class="phone-footer-header">
          <span>{{ $t("index.v1.text78") }}</span>
          <div class="footerUrl">
            <div>
              <img
                class="showImg"
                src="@/assets/newQJ/index/image/wx@2x.png"
                alt=""
                @click.stop="showCode = !showCode"
              />
              <img
                class="ere"
                v-show="showCode"
                src="@/assets/newQJ/index/image/gzh.png"
                alt=""
              />
            </div>
            <a
              href="https://weibo.com/iriding"
              data-url="https://weibo.com/iriding"
            >
              <img src="@/assets/newQJ/index/image/wb@2x.png" alt="" />
            </a>
            <a
              href="https://www.facebook.com/iriding.qi"
              data-url="https://www.facebook.com/iriding.qi"
            >
              <img src="@/assets/newQJ/index/image/facebook@2x.png" alt="" />
            </a>
            <a
              href="http://instgram.com/qicycleoffcial"
              data-url="http://instgram.com/qicycleoffcial"
            >
              <img src="@/assets/newQJ/index/image/instagram@2x.png" alt="" />
            </a>
            <a
              href="https://v.douyin.com/R67rovV/"
              data-url="https://v.douyin.com/R67rovV/"
            >
              <img src="@/assets/newQJ/index/image/dy@2x.png" alt="" />
            </a>
          </div>
        </div>
        <div class="phone-footer-con">
          <span>{{ $t("index.v1.text79") }}</span
          ><span class="phone-footer-con-hone"
            ><span
              ><i
                style="
                  width: 1px;
                  overflow: hidden;
                  display: inline-flex;
                  height: 1px;
                "
                >这是一个</i
              >400</span
            ><i
              style="
                width: 1px;
                overflow: hidden;
                display: inline-flex;
                height: 1px;
              "
              >这是一个</i
            >
            -<span
              ><i
                style="
                  width: 1px;
                  overflow: hidden;
                  display: inline-flex;
                  height: 1px;
                "
                >这是一个</i
              >
              1144</span
            ><i
              style="
                width: 1px;
                overflow: hidden;
                display: inline-flex;
                height: 1px;
              "
              >这是一个</i
            >
            -<i
              style="
                width: 1px;
                overflow: hidden;
                display: inline-flex;
                height: 1px;
              "
              >这是一个</i
            >
            177</span
          >
        </div>
        <div>
          <div
            class="phone-footer-list-item"
            v-for="item in list"
            :key="item.id"
          >
            <div class="phone-footer-list-item-header">
              <span>{{ $t(item.title) }}</span
              ><img
                :src="activeList.includes(item.id) ? up : down"
                alt=""
                @click="changeImg(item.id)"
              />
            </div>
            <div
              class="phone-footer-list-item-content"
              :style="{
                display: activeList.includes(item.id) ? 'block' : 'none',
              }"
            >
              <div>
                <router-link
                  v-for="item1 in item.list"
                  :key="item1.id"
                  :to="`/${value}${item1.path}`"
                  target="_blank"
                  @click="changeImg(item.id)"
                  >{{ $t(item1.title) }}</router-link
                >
              </div>
            </div>
          </div>
        </div>
        <div class="phone-footer-link">
          <a target="_blank" href="https://beian.miit.gov.cn"
            ><span>{{ $t("index.v1.text0") }}</span> © 2013-2022
            <span class="qiji-bah">{{$store.state.record}}</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "pcFooter",
  data() {
    return {
      isShow: true,
      showCode: false,
      icp: this.$store.state.record,
      up: require("@/assets/newQJ/index/image/xiangshang.png"),
      down: require("@/assets/newQJ/index/image/xiangxia.png"),
      activeList: [],
      list: [
        {
          id: 0,
          title: "index.v1.text80",
          list: [
            {
              id: 1000,
              title: "index.v1.text81",
              path: "/contact",
            },
            {
              id: 1001,
              title: "index.v1.text82",
              path: "/contact",
            },
            {
              id: 1002,
              title: "index.v1.text83",
              path: "/contact",
            },
            {
              id: 1003,
              title: "index.v1.text84",
              path: "/contact",
            },
          ],
        },
        {
          id: 1,
          title: "index.v1.text103",
          list: [
            {
              id: 2000,
              title: "index.v1.text85",
              path: "/contact",
            },
            {
              id: 2001,
              title: "index.v1.text87",
              path: "/privacy",
            },
          ],
        },
      ],
      value: "",
    };
  },
  computed: {
    ...mapState(["lang"]),
  },
  created() {
    this.value = localStorage.getItem("lans")
      ? localStorage.getItem("lans")
      : "zh-cn";
    this.icp = this.$store.state.record;
  },
  mounted() {
    window.addEventListener("click", this.allClick);
  },
  methods: {
    changeImg(id) {
      if (this.activeList.includes(id)) {
        let index = this.activeList.findIndex((x) => x === id);
        this.activeList.splice(index, 1);
      } else {
        this.activeList.push(id);
      }
    },
    allClick() {
      this.showCode = false;
    },
  },
  beforeDestroy() {
    window.removeEventListener("click", this.allClick);
  },
};
</script>

<style scoped>
#footer {
  color: #333333 !important;
}
.footerIsPC {
  background-color: #fbfbfb;
}
.footerIsPhone {
  display: none;
  height: 100% !important;
  background-color: #fbfbfb;
}
.footer-header {
  font-size: 16px;
  font-weight: normal;
  color: #666666;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer-header > div {
  display: flex;
  align-items: center;
}
.footer-header > div > div {
  display: inline-flex;
  margin-left: 20px;
}
.footer-header > div > div > div {
  display: inline-block;
  position: relative;
}
.footer-header img.ere {
  width: 200px;
  height: 200px;
  position: absolute;
  top: -200px;
  left: 50%;
  transform: translate(-50%, 0);
}

.footer-header img {
  width: 22px;
  margin-left: 16px;
}
.footer-phone {
  color: #666;
  margin-left: 13px;
}
.footer-list {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}
.footer-list-left {
  display: flex;
}
.footer-list-left-item > div {
  margin-bottom: 16px;
  font-size: 16px;
  color: #666666;
}
.footer-list-left-item > div > a {
  color: #666666;
}
.footer-list-left-item {
  display: inline-block;
  margin-right: 100px;
}

a {
  text-decoration: none;
}
.footer-list-left-item ul li {
  font-size: 14px;
  font-weight: normal;
  color: #666666;
  margin-bottom: 10px;
  cursor: pointer;
}
.footer-list-left-item ul li > a {
  color: #666666;
}
.footer-list-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  place-self: flex-end;
}
.footer-list-right img {
  width: 155px;
  margin-bottom: 15px;
}
.footer-list-right a {
  color: #999999;
  font-size: 14px;
}
.foot {
  height: 100% !important;
  margin: 0 auto 0;
  padding-bottom: 40px;
  color: #333333;
  background-color: #fbfbfb;
}

@media screen and (max-width: 1550px) {
  .footer {
    width: 1165px;
  }
  .footer-header {
    font-size: 16px;
  }
  .footer-list-right img {
    width: 103px;
  }
  .footer-header img {
    width: 16px;
    margin-left: 10px;
    display: inline-block;
  }
  .footer-phone {
    font-size: 16px;
  }
  .footer-phone-title {
    font-size: 16px;
    position: relative;
    top: -1px;
  }
  .footer-list-left-item > div > a {
    font-size: 16px;
  }
  .footer-list-left-item ul li {
    font-size: 14px;
  }
}
@media screen and (max-width: 1280px) {
  .footer {
    width: 960px;
  }
  .footer-list-right img {
    width: 79px;
    height: 29px;
  }
  .footer-phone-title {
    font-size: 16px;
  }
  .footer-header {
    font-size: 16px;
  }
}
@media screen and (max-width: 980px) {
  .footer {
    width: 700px;
  }
}
@media screen and (max-width: 750px) {
  .footerIsPC {
    display: none;
  }
  .footerIsPhone {
    display: block;
    background-color: #fbfbfb;
  }
  .phone-footer {
    padding: 0 24px 15px;
    background-color: #fbfbfb;
    font-size: 14px;
  }
  .phone-footer-header {
    padding-top: 75px;
    padding-bottom: 15px;
  }
  .phone-footer-con {
    padding-top: 9px;
    padding-bottom: 15px;
  }
  .phone-footer-con-hone {
    font-size: 17px;
    font-family: MILanPro_MEDIUM;
    font-weight: normal;
    color: #606060;
    margin-left: 19px;
  }
  .phone-footer-list-item {
    border-top: 0 solid #eeeeee;
  }
  .phone-footer-list-item-header {
    display: flex;
    justify-content: space-between;
    padding-top: 19px;
    padding-bottom: 15px;
    font-size: 13px;
  }
  .phone-footer-list-item-header > img {
    width: 15px;
    height: 16px;
  }
  .phone-footer-list-item-content {
    font-size: 13px;
    margin-bottom: 16px;
  }
  .phone-footer-list-item-content > div > a {
    display: block;
    color: #666666;
    padding-bottom: 10px;
  }
  .phone-footer-link {
    padding: 10px 0;
  }
  .phone-footer-link > a {
    color: #666666;
    font-size: 13px;
  }
  .phone-footer-header {
    display: flex;
  }
  .phone-footer-header > div {
    display: flex;
    margin-left: 30px;
  }
  .phone-footer-header > div img {
    width: 20px;
    margin-left: 10px;
  }
  .phone-footer-header > div > div {
    position: relative;
  }
  .phone-footer-header > div > div > img:nth-of-type(2) {
    width: 100px;
    height: 100px;
    position: absolute;
    top: 25px;
    left: 50%;
    transform: translate(-50%, -5px);
  }
}
</style>
