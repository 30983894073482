import RouterView from "../views/pc/pcRputer";
//import Multi_c2 from "../views/pc/page/details/Multi_c2.vue";
//import pedelecs_c2 from "../views/pc/page/details/pedelecs_c2.vue";
/***
 * PC端路由集合
 * @type {*[]} 路由集合
 */
const children = [
  {
    path: "/",
    name: "index",
    component: () => import("../views/pc/page/index/index"),
    meta: { title: "首页" },
  },
  {
    path: "contact",
    name: "contact",
    component: () => import("../views/pc/page/contact/contact"),
    meta: { title: "联系我们" },
  },
  {
    path: "privacy",
    name: "privacy",
    component: () => import("../views/pc/page/privacy/privacy"),
    meta: { title: "隐私与法律" },
  },
  {
    path: "pedelecs",
    name: "pedelecs",
    component: () => import("../views/pc/page/pedelecs/pedelecs"),
    meta: { title: "电助力车" },
  },
  {
    path: "accessories",
    name: "accessories",
    component: () => import("../views/pc/page/Accessories/accessories"),
    meta: { title: "配件" },
  },
  {
    path: "download",
    name: "download",
    component: () => import("../views/pc/page/download/download"),
    meta: { title: "软件" },
  },
  {
    path: "accessories/p1",
    name: "p1",
    component: () => import("../views/pc/page/details/accessories_p1"),
    meta: { title: "配件详情页" },
  },
  {
    path: "pedelecs/f2",
    name: "pedelecs_f2",
    component: () => import(`../views/pc/page/details/pedelecs_f2.vue`),
    meta: { title: "F2详情页" },
  },
  {
    path: "pedelecs/c2",
    name: "pedelecs_c2",
    component: () => import("../views/pc/page/details/pedelecs_c2.vue"),
  },
];
const pcRouter = [
  {
    path: "/zh-cn",
    component: RouterView,
    children: children,
  },
  {
    path: "/es",
    component: RouterView,
    children: children,
  },
  {
    path: "/en",
    component: RouterView,
    children: children,
  },
  {
    path: "/fr",
    component: RouterView,
    children: children,
  },
  {
    path: "/zh-hk",
    component: RouterView,
    children: children,
  },
  {
    path: "/ko",
    component: RouterView,
    children: children,
  },
  {
    path: "/de",
    component: RouterView,
    children: children,
  },
  {
    path: "/pdf",
    name: "pdf",
    component: () => import("../views/pdf.vue"),
  },
];
export default pcRouter;
