<template>
  <div class="header">
    <header class="a_tag a_tag1 headerIsPC header">
      <div class="header-list">
        <div class="header-img">
          <router-link to="/">
            <img src="@/assets/public/logo.png" alt="" />
          </router-link>
        </div>
        <ul id="headerNav" class="header-center">
          <li
            :class="['header-center-item', item.isShow ? 'activeNav' : '']"
            @mouseover="mouseOver(index)"
            @mouseleave="mouseLeave(index)"
            v-for="(item, index) in list"
            :key="item.id"
          >
            <router-link
              :to="`${route}${item.router}`"
              :target="$route.path === item.router ? '_self' : '_blank'"
              >{{ $t(item.title) }}</router-link
            >
            <ul v-if="item.children">
              <li
                v-for="item1 in item.children"
                :key="item1.id"
                @click="changeChild(index)"
              >
                <router-link target="_self" :to="`${route}${item1.router}`">{{
                  $t(item1.title)
                }}</router-link>
              </li>
            </ul>
          </li>
        </ul>
        <div class="header-right">
          <div class="header-right-lang">
            <div
              class="header-right-lang-title"
              @mouseover="mouseOver(999)"
              @mouseleave="mouseLeave(999)"
            >
              <span>{{ $t("lang.lang.v0") }}</span
              ><span
                style="display: inline-block; width: 10px; text-align: center"
                >/</span
              ><span>中</span>
              <div
                :class="[
                  'header-right-lang-list',
                  showLanguage ? 'activeNav' : '',
                ]"
              >
                <ul class="langChange">
                  <li
                    v-for="item in languageList"
                    :key="item.id"
                    @click="changeLanguage(item.value)"
                  >
                    {{ item.title }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <div :class="['zz', showLading ? 'active' : '']"></div>
    <div :class="['zzz', showLading ? 'active' : '']"></div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  name: "pcHeader",
  data() {
    return {
      languageList: [
        { id: 0, title: this.$t("lang.lang.v1"), value: "zh-cn" },
        { id: 1, title: this.$t("lang.lang.v2"), value: "zh-hk" },
        { id: 2, title: this.$t("lang.lang.v3"), value: "en" },
        { id: 3, title: this.$t("lang.lang.v4"), value: "de" },
        { id: 4, title: this.$t("lang.lang.v5"), value: "es" },
        { id: 5, title: this.$t("lang.lang.v6"), value: "fr" },
        { id: 6, title: this.$t("lang.lang.v7"), value: "ko" },
      ],
      list: [
        {
          id: 1,
          title: "index.v1.text1",
          router: "/",
          isShow: false,
          children: [
            {
              id: 10,
              title: "index.v1.text6",
              router: "/?plan=company",
            },
            { id: 11, title: "index.v1.text22", router: "/?plan=lu", index: 1 },
            {
              id: 12,
              title: "index.v1.text51",
              router: "/?plan=product",
            },
            {
              id: 13,
              title: "index.v1.text60",
              router: "/?plan=location",
            },
          ],
        },
        {
          id: 2,
          title: "index.v1.text2",
          router: "/pedelecs",
          isShow: false,
          children: [
            {
              id: 1,
              title: "F2.v0",
              router: "/pedelecs/f2",
            },
            {
              id: 2,
              title: "C2.v0",
              router: "/pedelecs/c2",
            },
          ],
        },
        {
          id: 3,
          title: "index.v1.text3",
          router: "/accessories",
          isShow: false,
          children: [
            {
              id: 1,
              title: "details.v1",
              router: "/accessories/p1",
            },
          ],
        },
        {
          id: 4,
          title: "index.v1.text4",
          router: "/download",
          isShow: false,
        },
      ],
      showLading: false,
      showLanguage: false,
      route: "",
      value: "",
    };
  },
  inject: ["reload"],
  watch: {
    $route(item) {
      let value = item.path.split("/")[1];
      if (item.fullPath.split("=").includes("/?plan")) {
        //锚点定位路径
        value = localStorage.getItem("lans");
        localStorage.setItem("fullpath", item.fullPath);
      }
      this.$i18n.locale = value;
      this.showLading = false;
      this.showLanguage = false;
      this.changeLang(value);
      localStorage.setItem("lang", value);
      localStorage.setItem("lans", value);
    },
  },
  created() {
    if (
      localStorage.getItem("fullpath") &&
      localStorage.getItem("fullpath").split("=").includes("/?plan")
    ) {
      //首页锚点定位路径
      this.route = `/${localStorage.getItem("lans")}`;
    } else {
      this.route = `/${localStorage.getItem("lans")}`;
    }
  },
  mounted() {
    let lan = this.$route.path;
    let jj = lan.split("/");
    let kk = "";
    if (jj.length == 3) {
      //输入的不同国家语言对应的首页网址
      kk = jj[1];
      localStorage.setItem("lans", jj[1]);
    } else if (jj.length == 2 || jj[1] == "") {
      if (!localStorage.getItem("lans")) {
        kk = "zh-cn";
      } else {
        kk = localStorage.getItem("lans");
      }
    } else {
      kk = localStorage.getItem("lans");
    }
    this.changeLanguage(kk);
  },
  methods: {
    ...mapMutations({
      changeLang: "changeLang",
    }),
    mouseOver(index) {
      if (index === 999) {
        this.showLanguage = true;
        this.showLading = true;
      } else {
        const status = Boolean(this.list[index].children);
        if (status) {
          this.list[index].isShow = true;
          this.showLading = true;
        }
      }
    },
    mouseLeave(index) {
      if (index === 999) {
        this.showLanguage = false;
      } else {
        this.list[index].isShow = false;
      }
      this.showLading = false;
    },
    changeChild(index) {
      this.showLading = false;
      this.showLanguage = false;
      this.list[index].isShow = false;
    },
    /**
     * 切换语言
     * @param value 语言
     */
    changeLanguage(value) {
      this.$i18n.locale = value;
      this.showLading = false;
      this.showLanguage = false;
      this.changeLang(value);
      localStorage.setItem("lang", value);
      localStorage.setItem("lans", value);
      //当前路径
      let kl = this.$route.path.split("/")[1];
      //小语种页面加载
      if (this.$route.path == "/") {
        //首页 不带语言前缀路径切换语言
        console.log(898900, value);
      } else {
        if (kl != value) {
          this.reload(this.$route.path, value);
        }
      }
      localStorage.setItem("lans", value);
    },
  },
};
</script>

<style scoped lang="stylus">
.htmlHeader {
  /* margin-bottom: 72px; */
}

.a_tag {
  border: 1px solid #eee;
}

.headerIsPhone {
  display: none;
}

.header {
  height: 72px;
  color: #000000;
  position: fixed;
  width: 100%;
  background-color: #fff;
  top: 0;
  z-index: 5;
}

.header > .zz {
  position: fixed;
  top: 70px;
  height: 0;
  width: 100%;
  background-color: #ffffff;
  transition: all 0.3s;
  z-index: 3;
}

.header > .zzz {
  position: fixed;
  top: 70px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: -2;
  opacity: 0;
  transition: all 0.3s;
}

.header > .zz.active {
  height: 380px;
}

.header > .zzz.active {
  bottom: 0;
  z-index: 2;
  opacity: 1;
}

.header-list {
  margin: 0 auto;
  width: 1550px;
  height: 72px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-img > a > img {
  width: 131px;
  height: 24px;
  cursor: pointer;
}

.header-center {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  color: #000000;
  cursor: pointer;
  margin-bottom: 0;
}

.header-center-item {
  margin-left: 72px;
  height: 72px;
  position: relative;
}

.header-center-item > a {
  height: 72px;
  display: flex;
  align-items: center;
  color: #000000;
}

.header-center-item:after {
  content: '';
  width: 0;
  height: 3px;
  background: rgb(0, 0, 0);
  position: absolute;
  top: 60%;
  left: 50%;
  transition: all 0.3s;
  margin-top: 10px;
}

.header-center-item:hover:after {
  left: 0;
  width: 100%;
}

.header-center-item > ul {
  position: absolute;
  width: 250px;
  text-align: center;
  left: 50%;
  transform: translate(-50%, 0%);
  transition: height 0.3s;
  z-index: -1;
  margin-left: 0;
  padding-top: 5px;
  height: 0;
  overflow: hidden;
}

.header-center-item > ul > li {
  position: relative;
  left: 0;
  transition: left 0.3s;
}

.header-center-item > ul > li:hover {
  left: 10px;
  color: #000000;
}

.activeNav.header-center-item > ul {
  z-index: 9;
  height: 350px;
  margin-bottom: 0;
}

.header-center-item > ul > li > a {
  display: block;
  font-size: 16px;
  color: #2b2e2e;
  opacity: 0.7;
  padding: 13px 0;
  font-weight: 100;
  transition: all 0.3s;
}

.content-lu-main-box-aa {
  width: 1550px;
  margin: 0 auto;
  overflow-y: auto;
}

.header-right {
  cursor: pointer;
}

.header-right-lang {
}

.header-right-lang-title {
  text-align: center;
}

.header-right-lang-title > span {
  font-size: 14px;
}

.header-right-lang-title:hover {
  color: #000000;
  font-weight: bold;
}

.header-right-lang-list {
  position: relative;
}

.header-right-lang-list > ul {
  position: absolute;
  width: 150px;
  text-align: center;
  left: 50%;
  transform: translate(-50%, 0%);
  transition: height 0.3s;
  z-index: -1;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.activeNav.header-right-lang-list > ul {
  opacity: 1;
  z-index: 9;
  height: 380px;
}

.header-right-lang-list > ul > li {
  display: block;
  font-size: 16px;
  color: #2b2e2e;
  opacity: 0.7;
  height: 50px;
  line-height: 50px;
  position: relative;
  transition: all 0.3s;
  left: 0;
  font-weight: 100;
}

.header-right-lang-list > ul > li:hover {
  color: #000000;
  left: 10px;
  opacity: 1;
  font-weight: bold;
}

.header-center-item > ul > li > a:hover {
  color: #000000;
  opacity: 1;
  font-weight: bold;
}

.header a {
  line-height: 1.42857143;
}

@media screen and (max-width: 1550px) {
  .header-list {
    width: 1165px;
  }
}

/* 小于1550像素结束 */
@media screen and (max-width: 1440px) {
  .header-center {
    font-size: 15px;
  }
}

/* 小于 1280 - 960像素 开始 */
@media screen and (max-width: 1280px) {
  .htmlHeader {
    margin-bottom: 0px;
  }

  .header {
    height: 50px;
  }

  .header-img > a > img {
    width: 114px;
    height: 21px;
  }

  .header-list {
    width: 960px;
    height: 50px;
  }

  .header > .zz {
    top: 50px;
  }

  .header > .zz.active {
    height: 300px;
  }

  .activeNav.header-center-item > ul {
    height: 280px;
  }

  .activeNav.header-right-lang-list > ul {
    height: 300px;
  }

  .header-right-lang-list > ul > li {
    font-size: 14px;
    height: 40px;
  }

  .header-center-item {
    height: 50px;
  }

  .header-center-item > a {
    height: 50px;
    display: flex;
    align-items: center;
  }

  .header-center-item > ul > li > a {
    padding: 10px 0;
    font-size: 14px;
  }
}

/* 小于 1280 - 960 像素 结束 */
/* 像素 768 - 960 开始 */
@media screen and (max-width: 960px) {
  .header-list {
    width: 700px;
    height: 50px;
  }
}
</style>
