import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "element-ui/lib/theme-chalk/index.css";
import "./assets/css/reset.css";
import "./assets/css/font.css";
import VueI18n from "vue-i18n";
import ElementUI from "element-ui";
import "fullpage.js/vendors/scrolloverflow";
import VueFullPage from "vue-fullpage.js";

import VueLazyload from "vue-lazyload";
import { getDefaultLang } from "./assets/language";
Vue.config.productionTip = false;
Vue.use(VueI18n);
Vue.use(VueFullPage);
Vue.use(VueLazyload, {});
Vue.use(ElementUI, {
  size: "small",
});

import MetaInfo from 'vue-meta-info'
Vue.use(MetaInfo)

const i18n = new VueI18n({
  locale: getDefaultLang(), // 语言标识
  messages: {
    de: require("./assets/language/i18n_de"),
    en: require("./assets/language/i18n_en"),
    es: require("./assets/language/i18n_es"),
    fr: require("./assets/language/i18n_fr"),
    ko: require("./assets/language/i18n_ko"),
    "zh-hk": require("./assets/language/i18n_hk"),
    "zh-cn": require("./assets/language/i18n_zh.js"),
  },
  silentTranslationWarn: true,
});
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
