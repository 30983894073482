import RouterView from "../views/phone/phoneRouter";
/***
 * 移动端端路由集合
 * @type {*[]} 路由集合
 */
const children = [
  {
    path: "/",
    name: "phone_index",
    component: () => import("../views/phone/page/index/index"),
    meta: { title: "首页" },
  },
  {
    path: "contact",
    name: "phone_contact",
    component: () => import("../views/phone/page/contact/contact"),
    meta: { title: "联系我们" },
  },
  {
    path: "privacy",
    name: "phone_privacy",
    component: () => import("../views/phone/page/privacy/privacy"),
    meta: { title: "隐私与法律" },
  },
  {
    path: "pedelecs",
    name: "phone_product",
    component: () => import("../views/phone/page/pedelecs/pedelecs"),
    meta: { title: "电助力车" },
  },
  {
    path: "accessories",
    name: "phone_accessory",
    component: () => import("../views/phone/page/Accessories/accessories"),
    meta: { title: "配件" },
  },
  {
    path: "download",
    name: "phone_download",
    component: () => import("../views/phone/page/download/download"),
    meta: { title: "软件" },
  },
  {
    path: "accessories/p1",
    name: "accessories_p1",
    component: () => import("../views/phone/page/details/accessories_p1"),
    meta: { title: "配件详情页" },
  },
  {
    path: "pedelecs/f2",
    name: "pedelecs_f2",
    component: () => import("../views/phone/page/details/pedelecs_f2"),
    meta: { title: "F2详情页" },
  },
  {
    path: "pedelecs/c2",
    name: "pedelecs_c2",
    component: () => import("../views/phone/page/details/pedelecs_c2"),
    meta: { title: "C2详情页" },
  },
];
const phoneRouter = [
  {
    path: "/zh-cn",
    component: RouterView,
    children: children,
  },
  {
    path: "/es",
    component: RouterView,
    children: children,
  },
  {
    path: "/en",
    component: RouterView,
    children: children,
  },
  {
    path: "/fr",
    component: RouterView,
    children: children,
  },
  {
    path: "/zh-hk",
    component: RouterView,
    children: children,
  },
  {
    path: "/pdf",
    name: "pdf",
    component: () => import("../views/pdf.vue"),
  },
  {
    path: "/ko",
    component: RouterView,
    children: children,
  },
  {
    path: "/de",
    component: RouterView,
    children: children,
  },

];
export default phoneRouter;
