<template>
  <header class="headerIsPhone">
    <div class="headerIsPhone-header a_tag a_tag1">
      <img
        id="showLeft"
        src="@/assets/newQJ/index/image/ddd.png"
        alt=""
        @click="showLanguage = true"
      />
      <a href="/index.html"></a>
      <router-link :to="`/${value}`"
        ><img src="@/assets/newQJ/index/image/logo_phone.png" alt=""
      /></router-link>
    </div>
    <div
      :class="['zz_phone', showLanguage ? 'activePhone' : '']"
      @click="showLanguage = false"
    ></div>
    <div
      :class="[
        'headerIsPhone-left',
        'a_tag1',
        showLanguage ? 'activePhone' : '',
      ]"
    >
      <div class="headerIsPhone-left-link">
        <ul class="ul">
          <li
            v-for="item in list"
            :key="item.id"
            @click="showLanguage = false"
            style="color: #333333"
          >
            <router-link
              :to="`${route}${item.router}`"
              :target="$route.path === item.router ? '_self' : '_blank'"
              >{{ $t(item.title) }}</router-link
            >
          </li>
        </ul>
      </div>
      <div class="headerIsPhone-left-lang">
        <ul class="langChange">
          <li
            v-for="item in languageList"
            :key="item.id"
            @click="changeLanguage(item.value)"
          >
            {{ item.title }}
          </li>
        </ul>
      </div>
    </div>
  </header>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  name: "phoneHeader",
  data() {
    return {
      languageList: [
        { id: 0, title: "简体中文-ZH", value: "zh-cn" },
        { id: 1, title: "繁體中文-ZH", value: "zh-hk" },
        { id: 2, title: "English-EN", value: "en" },
        { id: 3, title: "Deutsch-DE", value: "de" },
        { id: 4, title: "Español-ES", value: "es" },
        { id: 5, title: "Français-FR", value: "fr" },
        { id: 6, title: "한국어 - KO", value: "ko" },
      ],
      list: [
        {
          id: 1,
          title: "index.v1.text1",
          router: "/",
          isShow: false,
        },
        {
          id: 2,
          title: "index.v1.text2",
          router: "/pedelecs",
          isShow: false,
        },
        {
          id: 3,
          title: "index.v1.text3",
          router: "/accessories",
          isShow: false,
        },
        {
          id: 4,
          title: "index.v1.text4",
          router: "/download",
          isShow: false,
        },
      ],
      showLanguage: false,
      route: "",
      value: "",
    };
  },
  inject: ["reload"],
  watch: {
    $route(item) {
      let value = item.path.split("/")[1];
      if (
        value != "zh-cn" &&
        value != "zh-hk" &&
        item.path.split("/")[2] === "accessoriesP1_show"
      ) {
        value = "zh-cn";
      }
      this.$i18n.locale = value;
      this.showLading = false;
      this.showLanguage = false;
      this.changeLang(value);
      localStorage.setItem("lang", value);
      localStorage.setItem("lans", value);
    },
  },
  created() {
    this.value = localStorage.getItem("lans")
      ? localStorage.getItem("lans")
      : "zh-cn";
    this.route = `/${
      localStorage.getItem("lans") ? localStorage.getItem("lans") : "zh-cn"
    }`;
  },
  mounted() {
    let lan = this.$route.path;
    let jj = lan.split("/");
    let kk = "";
    if (jj.length == 3) {
      //输入的不同国家语言对应的首页网址
      kk = jj[1];
      localStorage.setItem("lans", jj[1]);
    } else if (jj.length == 2 || jj[1] == "") {
      if (!localStorage.getItem("lans")) {
        kk = "zh-cn";
      } else {
        kk = localStorage.getItem("lans");
      }
    } else {
      kk = localStorage.getItem("lans");
    }
    if (kk != "zh-cn" && kk != "zh-hk" && jj[3] === "p1") {
      kk = "zh-cn";
    }
    this.changeLanguage(kk);
  },
  methods: {
    ...mapMutations({
      changeLang: "changeLang",
    }),
    /**
     * 切换语言
     * @param value 语言
     */
    changeLanguage(value) {
      this.$i18n.locale = value;
      this.showLading = false;
      this.showLanguage = false;
      this.changeLang(value);
      localStorage.setItem("lang", value);
      localStorage.setItem("lans", value);
      let kl = this.$route.path.split("/")[1];
      //带语言路径
      if (kl != value) {
        //切换语言修改对应路径，重加载
        this.reload(this.$route.path, value);
      }
    },
  },
};
</script>

<style scoped lang="stylus">
.a_tag {
  border: 1px solid #eee;
}

.headerIsPhone {
  display: none;
}

.header {
  height: 72px;
  color: #000000;
  position: fixed;
  width: 100%;
  background-color: #fff;
  top: 0;
  z-index: 2;
}

.header > .zz {
  position: fixed;
  top: 70px;
  height: 0;
  width: 100%;
  background-color: #ffffff;
  transition: height 0.3s;
  z-index: 3;
}

.header > .zzz {
  position: fixed;
  top: 70px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: -2;
  opacity: 0;
  transition: opacity 0.3s;
}

.header > .zz.active {
  height: 380px;
}

.header > .zzz.active {
  bottom: 0;
  z-index: 2;
  opacity: 1;
}

.header-list {
  margin: 0 auto;
  width: 1550px;
  height: 72px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-img > a > img {
  width: 131px;
  height: 24px;
  cursor: pointer;
}

.header-center {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  color: #000000;
  cursor: pointer;
  margin-bottom: 0;
}

.header-center-item {
  margin-left: 72px;
  height: 72px;
  position: relative;
}

.header-center-item > a {
  height: 72px;
  display: flex;
  align-items: center;
  color: #000000;
}

.header-center-item:after {
  content: '';
  width: 0;
  height: 3px;
  background: rgb(0, 0, 0);
  position: absolute;
  top: 60%;
  left: 50%;
  transition: all 0.3s;
  margin-top: 10px;
}

.header-center-item:hover:after {
  left: 0;
  width: 100%;
}

.header-center-item > ul {
  position: absolute;
  width: 250px;
  text-align: center;
  left: 50%;
  transform: translate(-50%, -2%);
  transition: height 0.3s;
  z-index: -1;
  margin-left: 0;
  height: 0;
  overflow: hidden;
}

.header-center-item > ul > li {
  position: relative;
  left: 0;
  transition: left 0.3s;
}

.header-center-item > ul > li:hover {
  left: 10px;
  color: #000000;
}

.activeNav.header-center-item > ul {
  z-index: 9;
  height: 350px;
  margin-bottom: 0;
}

.header-center-item > ul > li > a {
  display: block;
  font-size: 16px;
  color: #2b2e2e;
  opacity: 0.7;
  padding: 13px 0;
  font-weight: 100;
  transition: all 0.3s;
}

.content-lu-main-box-aa {
  width: 1550px;
  margin: 0 auto;
  overflow-y: auto;
}

.header-right {
  cursor: pointer;
}

.header-right-lang {
}

.header-right-lang-title {
  text-align: center;
}

.header-right-lang-title > span {
  font-size: 14px;
}

.header-right-lang-title:hover {
  color: #000000;
  font-weight: bold;
}

.header-right-lang-list {
  position: relative;
}

.header-right-lang-list > ul {
  position: absolute;
  width: 150px;
  text-align: center;
  left: 50%;
  transform: translate(-50%, 0%);
  transition: height 0.3s;
  z-index: -1;
  padding-top: 10px;
  height: 0;
  overflow: hidden;
}

.activeNav.header-right-lang-list > ul {
  opacity: 1;
  z-index: 9;
  height: 380px;
}

.header-right-lang-list > ul > li {
  display: block;
  font-size: 16px;
  color: #2b2e2e;
  opacity: 0.7;
  height: 50px;
  line-height: 50px;
  position: relative;
  transition: all 0.3s;
  left: 0;
  font-weight: 100;
}

.header-right-lang-list > ul > li:hover {
  color: #000000;
  left: 10px;
  opacity: 1;
  font-weight: bold;
}

.header-center-item > ul > li > a:hover {
  color: #000000;
  opacity: 1;
  font-weight: bold;
}

.header a {
  line-height: 1.42857143;
}

@media screen and (max-width: 1550px) {
  .header-list {
    width: 1165px;
  }
}

/* 小于1550像素结束 */
@media screen and (max-width: 1440px) {
  .header-center {
    font-size: 15px;
  }
}

/* 小于 1280 - 960像素 开始 */
@media screen and (max-width: 1280px) {
  .htmlHeader {
    margin-bottom: 0px;
  }

  .header {
    height: 50px;
  }

  .header-img > a > img {
    width: 114px;
    height: 21px;
  }

  .header-list {
    width: 960px;
    height: 50px;
  }

  .header > .zz {
    top: 50px;
  }

  .header > .zz.active {
    height: 300px;
  }

  .activeNav.header-center-item > ul {
    height: 280px;
  }

  .activeNav.header-right-lang-list > ul {
    height: 300px;
  }

  .header-right-lang-list > ul > li {
    font-size: 14px;
    height: 40px;
  }

  .header-center-item {
    height: 50px;
  }

  .header-center-item > a {
    height: 50px;
    display: flex;
    align-items: center;
  }

  .header-center-item > ul > li > a {
    padding: 10px 0;
    font-size: 14px;
  }
}

/* 小于 1280 - 960 像素 结束 */
/* 像素 768 - 960 开始 */
@media screen and (max-width: 960px) {
  .header-list {
    width: 700px;
    height: 50px;
  }
}

/* 像素 768 - 960 结束 */
/*  */
@media screen and (max-width: 750px) {
  .headerIsPC {
    display: none;
  }

  .headerIsPhone {
    display: block;
  }

  .headerIsPhone .phoneHeader {
    position: fixed;
    top: 0;
    height: 45px;
    line-height: 45px;
  }

  .headerIsPhone .phoneHeader > img {
    width: 12.5px;
    height: 12.5px;
    margin: 18px 20px;
  }

  .headerIsPhone .phoneHeader>a {
    position: absolute;
    left: 50%;
    top: 5px;
    transform: translate(-50%, -50%);
  }

  .headerIsPhone .phoneHeader > a > img {
    width: 65.5px;
  }

  .headerIsPhone-header {
    height: 50px;
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #fff;
    z-index: 9;
  }

  .htmlHeader {
    margin-bottom: 50px;
  }

  .headerIsPhone-header > img:nth-of-type(1) {
    width: 12.5px;
    height: 12.5px;
    margin: 18.5px 21px;
  }

  .headerIsPhone-header > a > img {
    width: 65.5px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .headerIsPhone-left {
    position: fixed;
    left: 0;
    right: 40%;
    bottom: 0;
    top: 0;
    background-color: #fff;
    z-index: 99;
    border-right: 0.5px solid #eee;
    transition: transform 0.5s;
    transform: translateX(-200%);
  }

  .headerIsPhone-left.activePhone {
    transform: translateX(0);
  }

  .headerIsPhone-left-link {
    padding: 20% 0 0 14%;
  }

  .headerIsPhone-left-link > ul > li {
    /* font-family: 'MI_LanTing_Thin'; */
    padding: 15px 0;
  }

  .headerIsPhone-left-link > ul > li > a {
    font-size: 15px;
  }

  ul > li > a {
    color: #333333;
  }

  .headerIsPhone-left-lang {
    position: absolute;
    left: 14%;
    bottom: 10%;
  }

  .headerIsPhone-left-lang > ul > li {
    display: inline-block;
    width: 47%;
    padding-bottom: 15px;
    color: #333333;
    font-size: 13px;
  }

  .phone-zz {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: #3333339c;
    z-index: 2;
  }

  .zz_phone {
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(77, 77, 77, 0.47);
    z-index: 99;
    display: none;
  }

  .zz_phone.activePhone {
    display: block;
  }
}
</style>
