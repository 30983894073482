<template>
  <div v-if="isRouterAlive">
    <div>
      <pc-header v-if="isShowHeader" id="Header"></pc-header>
    </div>
    <div class="pc">
      <router-view />
    </div>
    <div>
      <pc-footer v-show="isShowFooter"></pc-footer>
    </div>
  </div>
</template>

<script>
import pcHeader from "./component/header/pcHeader";
import pcFooter from "./component/footer/pcFooter";
export default {
  name: "adminRouter",
  components: {
    pcHeader,
    pcFooter,
  },
  data() {
    return {
      isRouterAlive: true,
    };
  },
  provide() {
    return {
      reload: this.reload,
    };
  },
  computed: {
    isShowHeader() {
      return this.$store.state.isShowHeader;
    },
    isShowFooter() {
      return this.$store.state.isShowFooter;
    },
  },
  methods: {
    reload(route, value) {
      let jg = "";
      let router = "";

      if (route.split("=").includes("/?plan")) {
        //锚点定位路径
        console.log(333, route);
      } else {
        //非锚点定位路径
        jg = route.split("/");
        jg[1] = value;
        router = jg.join("/");
      }
      this.isRouterAlive = false;
      this.$router.push({ path: router }).catch((err) => {
        console.log("error", err);
      });

      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
  },
};
</script>

<style scoped lang="stylus">
.pc {
  // margin-top: 72px;
}

@media screen and (max-width: 1280px) {
  .pc {
    margin-top: 50px;
  }
}
</style>
