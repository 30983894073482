/**
 * 韩语
 */
module.exports = {
  lang: {
    lang: {
      v0: "KO",
      v1: "简体中文-ZH",
      v2: "繁體中文-ZH",
      v3: "English-EN",
      v4: "Deutsch-DE",
      v5: "Español-ES",
      v6: "Français-FR",
      v7: "한국어 - KO",
    },
    contact: {
      v0: "당사와 연락하기",
      v1: "당사와 연락하기",
      v2: "비즈니스 협력",
      v3: "이메일주소: ",
      v4: "영업팀",
      v5: "국내영업: ",
      v6: "해외영업: ",
      v7: "고객서비스",
      v8: "애프터서비스: ",
      v9: "데이터 보호",
      v10: "이메일주소: ",
    },
    law: {
      0: "Privacy and Laws",
      1: "Privacy Policy",
      2: "Date of update",
      3: "February 12, 2021",
      4: "Effective date",
      5: "March 12, 2021",
      6: "Preface",
      7: 'iRiding (Xiamen) Technology Co., Ltd. and its affiliated companies (hereinafter referred to as "QiCYCLE APP" or "We") take your personal information seriously and ensure that you have control over your personal information. We will use powerful encryption technology to protect your privacy and formulate strict policies to manage all your data. Before you visit QiCYCLE APP or use our services, you shall agree to the provisions of this policy on how we collect, use, store and share your relevant information. If you do not agree on any content in this privacy policy, you are requested to stop using or accessing our products and services immediately. If you have any questions about this policy, please contact us by emailing to privacy@miriding.com. The key points of this privacy policy are as follows:',
      8: "1.We will explain the types of your personal information we collect and their corresponding usage one by one, so that you can understand the categories, reasons for use and collection methods of the specific personal information we collect for a specific function.",
      9: "2.When you are using some functions, we will collect your sensitive information after obtaining your consent, unless it shall be collected pursuant to relevant laws and regulations. Refusing to provide such information will only make you unable to use some specific functions, but will not affect your normal use of the other functions of QiCYCLE APP.",
      10: "3.QiCYCLE APP will not share or transfer your personal information actively to a third party other than QiCYCLE APP. If there are other situations of sharing or transferring your personal information or if you need us to share or transfer your personal information to a third party other than QiCYCLE APP, we will obtain your express consent directly or confirm that the third party obtains your express consent for the above behaviors. In addition, we will conduct a risk assessment of our act of providing information to the outside world.",
      11: "4.QiCYCLE APP will not obtain your personal information actively from a third party other than QiCYCLE APP. If you need to obtain your personal information indirectly from a third party for business development in the future, we will explain to you the source, type and scope of use of your personal information before obtaining it. For example, if the personal information processing activities required by QiCYCLE APP for business purposes exceed the scope of your original authorization and consent upon providing personal information to a third party, we will obtain your express consent before processing such personal information of yours. In addition, we will strictly abide by relevant laws and regulations, and require the third party to guarantee the legality of the information that is provided.",
      12: "5.You can access, correct and delete your personal information, cancel your consent, cancel your account, complain and report, and set up privacy functions through the channels listed in this guide.",
      13: "If you want to know more detailed information, you are requested to read the corresponding chapters according to the following index:",
      14: "1.How do we collect and use your information",
      15: "2.How do we use Cookies and similar technologies",
      16: "3.How to update, change, export and delete your information",
      17: "4.How do we protect your personal information",
      18: "5.How do we share, transfer and publicly disclose your personal information",
      19: "6.Storage of information",
      20: "7.Third-party service provider",
      21: "8.Update of this policy",
      22: "9.Applicable scope",
      23: "10.How to contact us",
      24: "I. How do we collect and use your information?",
      25: "1. How do we collect your information?",
      26: "When we provide the services, we may collect, store and use the following information:",
      27: "1.1 The information you provide",
      28: "1.1.1 The information provided to us that can be used to identify the users' personal identity when you register or use our services, such as name, e-mail address, telephone number, bank card number, ID number, address, communication record, or other data that we can find through such links lawfully. If you want to make the most use of the sharing features we offer, you may also need to create publicly displayed personal data that may contain your name and photos.",
      29: "1.1.2 The shared information you provide to other parties through our services, and the information that you upload, submit, store, send or receive when you are using our services.",
      30: "1.1.2 The shared information you provide to other parties through our services, and the information that you upload, submit, store, send or receive when you are using our services.",
      31: "We will collect the information about the services you use and how you use them, such as related information about your using QiCYCLE APP sports, commenting in iRiding community or participating in clock-in movement, participating in iRiding events and challenges, becoming iRiding members and joining training camps, as well as that that may be generated when you use services of iRiding members and training camps. Such information includes:",
      32: "1.2.1 Equipment information",
      33: "We will receive and record the information related to the device you use according to your specific rights in the software installation and/or use authorization. For example, hardware and software characteristics such as your device model, operating system version information, device settings, mobile device version, device ID (such as IMEI/ Android ID/IDFA/OPENUDID/GUID/OAID, SIM card IMSI, ICCID information, etc.), screen resolution, environment of equipment,and relevant information regarding location of device, such as IP address, GPS location and the WLAN access points that can provide relevant information and the information for Bluetooth, base station and sensor. If you refuse to grant us the corresponding permissions during installation and/or use, we will not record the above kinds of information.",
      34: "1.2.21.2.2 Log information",
      35: "(1) Information for device or software, such as the configuration information for your mobile device, web browser or other programs used to access our services, your IP address and the version and device ID used by your mobile device (such as IMEI/ Android ID/IDFA/OPENUDID/GUID/OAID, SIM card IMSI, ICCID information, etc.);",
      36: "(2) The information that you search for or browse when you are using our services, such as web search terms you use, URL addresses of social media pages you visit, and other information and details that you browse or request to provide when you are using our services.",
      37: "1.2.3 IP address",
      38: "(1) The information about the mobile applications (APPs) and other software you have used, and the information about your having used such mobile applications and software;",
      39: "(2) The information you communicate through our services, such as the account number you have communicated with, as well as the communication time, data and duration;",
      40: "(3) The information (metadata) contained in the content you share through our services, such as the date, time or place of the shared photos or videos taken or uploaded.",
      41: "1.2.4 Pictures in cameras, microphones, devices, etc",
      42: "In order to provide you with a full range of sports experience, after you have authorized, our server will extract the video, picture, software name, version number, etc. from your device, and upload them to the server after encryption for comparison, so as to match the display function corresponding to the video, picture and software. At the same time, you can also refuse/cancel the permission at any time through relevant function settings. Refusing to provide this permission will only make you unable to use the above functions, but will not affect the normal use of other functions and services of QiCYCLE APP. However, after you cancel the authorization, we will no longer collect the information, nor can we provide you with the above services. However, unless you delete your information in accordance with the law, your cancellation will not affect our processing and storage of information based on your previous authorization.",
      43: "When you use the function of taking photos and recording videos, we will access the permissions of your devices such as camera and microphone, and collect the pictures and videos you provide to us after taking photos and recording videos. If you refuse to provide the permissions and contents, you will not be able to use this function, but it will not affect your normal use of other functions of iRiding. At the same time, you can cancel the permissions at any time through relevant function settings. After you cancel the authorization, we will no longer collect the information, nor can we provide you with the above services. However, unless you delete your information in accordance with the law, your cancellation will not affect our processing and storage of information based on your previous authorization.",
      44: "1.2.5 Location information",
      45: "We will access your location information after you enable the location permission, and provide you with more personalized products and/or services based on your location information, such as recording your GPS movement data and viewing the surrounding movement route. Of course, you can also stop us from collecting your geographic location information at any time by disabling the location function. You can give us access to your activity and physical training records and record your step frequency and step count information by reading your activity and physical training. At the same time, you can also cancel this permission at any time through relevant function settings. After you cancel the authorization, we will no longer collect the information, nor can we provide you with the above services. However, unless you delete your information in accordance with the provisions of the law, your cancellation will not affect the processing and storage of your information based on your previous authorization.",
      46: "We provide sports community service. When you use sports music function, we will request your authorization to read local music files for playing music while you are doing sports.",
      47: "1.2.6 The information from third parties",
      48: "Under some circumstances permitted by law, we may obtain your personal information from a third party. For example:",
      49: "(1) For the purpose of security and fraud prevention, verify your information (such as telephone number) from legitimate sources with your authorization for specific account number, financial transaction, etc;",
      50: "(2) Provide you with corresponding advertising services by a specified unique identifier (such as IMEI / Android / IDFA / OPENUDID / GUID / OAID, SIM CARD IMSI, ICCID information and so on) ;",
      51: "(3) We may also get some information from third-party social network services, such as accounts, nicknames (for example, when you log in to QiCYCLE APP service with a social network account);",
      52: "(4) The information related to you provided by others, such as your receiving address filled by other users while using QiCYCLE APP mall.",
      53: "1.2.7 Non-personal information",
      54: "We may also collect other information that cannot identify a specific individual and does not belong to personal information pursuant to local applicable laws, such as statistical data generated when you use a specific service, anonymous device related information, daily life events, page access events, page access duration events and session events,network monitoring data such as request duration, number of requests and wrong requests, etc, as well as application crash events. The purpose of collecting such information is to improve our services for you. The type and amount of information collected depends on how you use our products or services.",
      55: "We will collect information about your activities on our website or mobile app, such as unique device identifier, device model, system version number, etc. We will aggregate this information to help us provide customers with more useful information so that we can learn which parts of our website, products or services that they are most interested in. Aggregate data is treated as non-personal information as far as this privacy policy is concerned. If we combine non-personal information with personal information, such information will be regarded as personal information during the period of combination.",
      56: "2. How do we use your information",
      57: "The purpose of our collecting your personal information is to provide you with products or services and to ensure that we comply with applicable laws, regulations and other normative documents. We may use the information collected above for the following purposes:",
      58: "2.1 ovide, handle, maintain, improve and develop our products or services for you, such as delivery, verification, after-sales, customer support and advertising service;",
      59: "2.2 When we provide services, use it for authentication, customer service, security protection, fraud monitoring, archiving and backup purposes to ensure the security of the products and services we provide to you;",
      60: "2.3 Internal purposes, such as data analysis, research and development of statistical information related to the use of our products or services so that we can improve our products or services;",
      61: "2.4 Help us design new services and improve our existing services;",
      62: "2.5 rovide you with information push of more relevant activities, and you can choose to enable or disable it according to your preference;",
      63: "2.6 Evaluate and improve the effectiveness of advertising and other promotional and publicity activities in our services;",
      64: "2.7 Perform software verification and upgrade services;",
      65: "2.8 Provide information to our affiliated companies, third parties or other users when we provide specific services according to users' special requirements;",
      66: "2.9 Other situations that do not violate any mandatory laws and regulations;",
      67: "2.10 Store and maintain the information related to you for our business operation (e.g. business statistics) or fulfillment of legal obligations;",
      68: "2.11 Other purposes with your consent.",
      69: "More detailed examples are provided below on how we use your information (which may include personal information):",
      70: "(1) Log in and use QiCYCLE APP products or services;",
      71: "(2) Create and maintain your QiCYCLE APP account. The personal information provided when you create a QiCYCLE APP account through the website or a mobile device can be used to create your personal QiCYCLE APP account and personal data;",
      72: "(3) Participate in the community activities. Personal information related to the community or other similar social platforms of QiCYCLE APP may be used to display personal data pages, interact with other users, and participate in posting;",
      73: '(4) Provide location-based services. When you are using QiCYCLE APP service, we or third-party service providers and business partners (please refer to "How do we share, transfer and publicly disclose your personal information" below for more details) may use location information to provide services for you, and provide better user experience based on the accurate location, such as obtaining accurate running track, and you can disable the location based service in the settings of QiCYCLE APP at any time;',
      74: "(5) Improve the user experience. Some optional add-in features, such as user experience plans, allow us to analyze how users use mobile phones and QiCYCLE APP services to improve user experience, as well as sending crash reports;",
      75: "(6) Collect user feedback. The feedback you choose to provide is important to help QiCYCLE APP to improve its services. To handle the feedback you provide, QiCYCLE APP may contact you with the personal information you provide, and keep records for problem solving and improvement of services;",
      76: "(7) Send a notification. We may use your personal information to send important notices, such as changes to our terms, conditions and policies;",
      77: "(8) Carry out promotional activities. If you participate in the prize-drawing activity and competition through QiCYCLE APP, we may send such rewards to you using the personal information you provide;",
      78: "(9) Analyze the device so as to provide a better user experience. QiCYCLE APP will analyze devices to further enhance the experience of them.",
      79: "You have the right to refuse personal information processing and automated decision-making for direct marketing purposes. In order to exercise the above rights, you can send an email to privacy@miriding.com or refer to the control mechanism described in the separate privacy policy for each product or service.",
      80: "3. Our commitment",
      81: "3.1 We will not present personalized advertisements to you based on sensitive categories (such as race, religion, sexual orientation or health status);",
      82: "3.2 e will not share with advertisers the information that can be used to identify you, such as your name or e-mail address (unless authorized and consented by you);",
      83: "3.3 We will not sell your personal information to any third party at any time and under any circumstance. We will only use the information obtained under this Agreement to the extent permitted by the law. We will formulate strict policies to protect your personal information, and will not disclose your personal information to the public or provide it to any third party unless your prior authorization is obtained or otherwise specified in this statement.",
      84: "4. Affiliated companies or other trusted cooperative partners",
      85: "To ensure the quality of service, we may provide you with the services you require or the content you may be interested in by our affiliated companies or other trusted partners. With your authorization and consent, we will share the personal information necessary for the products and services with our affiliated companies or partners. We will require our affiliated companies and partners to ensure the security of your personal information in accordance with our instructions, privacy policy and any other appropriate confidentiality and security measures. Except for this, we will not provide or share your information to any third party without your authorization and consent.",
      86: "5. HeathKit",
      87: "If you are using an Apple mobile phone, in order to calculate exercise consumption and record relevant exercise data for you, with your authorization, we will receive your exercise data from Apple's Heathkit and send to it. To simplify your input process, we will read your motion data from Heathkit. At the same time, the training data generated by your QiCYCLE APP will be synchronized with Apple's Heathkit. Any information obtained by using Heathkit, such as steps and heart rate data, will not be shared or sold to any third party, including advertisers and other agents. We will not use Heathkit's information in marketing, advertising and similar services.",
      88: "6. Third-party entities",
      89: "The third-party service in QiCYCLE APP services is provided by an external third-party entity. QiCYCLE APP cannot obtain the information generated when you use this kind of third-party service. However, if you have explicitly agreed that the third party can obtain your geographic location information, the third party will obtain your terminal geographic location information through the QiCYCLE APP interface. This information is sensitive. Refusing to provide this information will only make you unable to use the above third-party services, but will not affect your normal use of QiCYCLE APP.",
      90: "Apart from these, pursuant to relevant laws, regulations and national standards, we may collect and use your personal information in the following situations without obtaining your authorization and consent:",
      91: "6.1 That directly related to national interests including national security and national defense security and that directly related to major public interests such as public security, public health, public knowledge and so on;",
      92: "6.2 That directly related to criminal investigation, prosecution, trial and execution of judgment;",
      93: "6.3 That for the purpose of safeguarding personal life, property and reputation of yours or others’ and it is difficult to obtain your consent;",
      94: "6.4 The collected personal information is disclosed to the public by yourself;",
      95: "6.5 Where personal information is collected from the lawfully disclosed information, such as lawful news coverage, disclosure of government information and so forth;",
      96: "6.6 That necessary for signing and performing the contract according to your requirements;",
      97: "6.7 That necessary to maintain the safe and stable operation of the products or services provided, such as finding and handling the faults of products or services;",
      98: "6.8 That necessary for lawful news coverage;",
      99: "6.9 That necessary to carry out statistical or academic research out of public interest, and when the results of academic research or description are provided to the outside world, the personal information contained in the results is to be de-identified.",
      100: "6.10 Transfer for the purpose of completing merger, division, acquisition or asset transfer;",
      101: "6.11 Other circumstances stipulated by laws and regulations.",
      102: "You are requested to understand that the functions and services we provide to you are constantly updated and developed. If a certain function or service is not included in the above description and your information is collected, we will explain the content, scope and purpose of information collection to you through page prompt, interactive process and website announcement separately so as to obtain your consent.",
      103: "Please note that QiCYCLE APP will not obtain your personal information from a third party other than QiCYCLE APP actively at present. If you need to obtain your personal information indirectly from a third party for business development in the future, we will explain to you the source, type and scope of use of your personal information before obtaining it. If the personal information processing activities required by QiCYCLE APP to carry out business exceed the scope of your original authorization and consent when your personal information is provided to a third party, we will obtain your express consent before processing your personal information. In addition, we will strictly abide by relevant laws and regulations, and require the third party to guarantee the legality of the information provided.",
      104: "7. Sensitive personal information",
      105: "We will not provide your sensitive personal information to anyone except with your prior consent.",
      106: "8. Anonymization of personal information",
      107: "After collecting your personal information, we will anonymize the data through technical means in a timely manner. On the condition that your personal information is not to be leaked, QiCYCLE APP has the right to mine, analyze and utilize the anonymized user database (including for commercial use), to make statistics on the use of products/services and share desensitization statistics with the public/third parties.",
      108: "II. How do we use Cookie and similar technologies",
      109: "Cookies are small files containing character strings sent to your computer when you visit the website. When you visit the website again, the Cookie enables the site to identify your browser. Cookies also store user preferences and other information. You can reset your browser to reject all cookies, or prompt when cookies are being sent. But without cookies, some web features or services may not work properly.",
      110: "Cookie and the same type of technologies are common technologies in the Internet. When you use related services of iRiding, we may use relevant technologies to send one or more cookies or anonymous identifiers to your device to collect and store information when you access and use iRiding. We treat information collected through cookies and other technologies as non-personal information. However, if the local law treats an Internet Protocol (IP) address or similar identification marks as personal information, we also treat such identification marks as personal information. We use cookies and similar technologies to achieve the following functions or services:",
      111: "1. Ensure safe and efficient operation of the products and services",
      112: "We may set cookies or anonymous identifiers for authentication and security purposes, so that we can confirm whether you are safe to log in to the service, or whether you encounter illegal acts such as embezzlement and fraud. These technologies will also help us improve service efficiency and login and response speed.",
      113: "2. Help you acquire a more relaxed visit experience",
      114: "Using this kind of technology can help you avoid the steps and processes of repeated completion of personal information and entering search content, and achieve one-click login and record search history.",
      115: "3. Recommend, display and push the contents you may be interested in",
      116: "We may use cookies and similar technologies to learn about your preferences and use habits, and conduct consultation or data analysis to improve product services and user experience.",
      117: "4. Log files",
      118: "As most websites do, we collect specific information and keep it in a log file. Such information may include Internet Protocol (IP) address, browser type, Internet service provider (ISP), reference/exit page, operating system, date/time stamp and/or clickstream data. We don't associate automatically collected log data with the other information we collect.",
      119: "5. Mobile analysis",
      120: "In some mobile applications, we use mobile analysis software to learn better about the functions of our mobile software in your mobile phone. This software may record information such as how often you use the application, events within the application, cumulative usage, performance data, and where the application crash occurs. We will not associate the information stored in the analysis software with any personal information you submit in your mobile application.",
      121: "6. Local storage - HTML5/Flash",
      122: "We use local storage objects (LSO), such as HTML5 or flash, to store contents. The third parties that cooperate with us to provide some functions on QiCYCLE APP, or to advertise according to your browsing behaviors, will also collect and store relevant information through HTML5 or Flash cookie. Various browsers provide their own management tools to delete HTML5 local storage objects.",
      123: "III. How do we update, modify, export and delete your information",
      124: "Pursuant to the laws of some jurisdictions:",
      125: "1. Provide true personal information",
      126: "Prior to your using our products and services, you will be required to provide your true personal information. You should be responsible for the authenticity, legality, validity and integrity of the information you provide, and update and maintain it in time to ensure its authenticity, legality and effectiveness. You can manage your personal information, such as your personal account registration information, at any time.",
      127: "2. Delete content or personal information",
      128: "You can delete your content or personal information from iRiding account by yourself. You can apply for account cancellation through [My]-[Settings] - [About the account] -[Account cancellation]. Within fifteen (15) working days, we will delete or anonymize your personal information.",
      129: "3. Data retention within limited time",
      130: "In some cases, we will keep the data for a limited period of time for legal purposes or legitimate business needs. We will do our best to ensure that the services of iRiding can protect the information from accidental or malicious deletion. Therefore, if you delete something, we may delete its copy from our current and standby systems within fifteen (15) working days.",
      131: "4. Back up content or use it for non-iRiding services",
      132: "You can always back up the contents in your personal account or use it for non-iRiding services. You can contact us by email at privacy@miriding.com and make your request.",
      133: "5. Withdrawal of consent",
      134: "You may withdraw your consent previously granted to us by submitting a request for specific purposes, including collection, use and/or disclosure of your personal information in our possession or control. Depending on the specific service you use, you can visit the account management center of the QiCYCLE APP or send an email to privacy@miriding.com for related operations. We will process your request within a reasonable period of time after you make the request and will not collect, use and/or disclose your personal information upon your request thereafter.",
      135: "Depending on the extent to which you withdraw your consent, you may not be able to continue to enjoy QiCYCLE APP's products or services. However, your decision to withdraw your consent or authorization will not affect the effectiveness of our previous personal information processing activities based on your consent.",
      136: "6. Cancellation of the service or account",
      137: "If you want to cancel a specific product or service, you can cancel your account through [My]- [Settings] - [Account security] - [Cancel account]. After you cancel your account, we will delete or anonymize your personal information.",
      138: "Within fifteen (15) business days, we will delete or anonymize your personal information. Once the account is canceled, it will not be recoverable. You are requested to back up all the information and data related to your iRiding account by yourself before the operation.",
      139: "IV. How do we protect your personal information",
      140: "1. Strict information management policy",
      141: "We have drawn up strict information management policies, equipped with professional technical teams, and have adopted a series of reasonable precautions to protect your personal information from unauthorized browsing, disclosure, abuse, change, destruction and loss, including:",
      142: "(1) Use encryption technology to ensure that your data is kept private during transmission;",
      143: "(2) Provide a variety of security functions to help you protect your account security;",
      144: "(3) Review our practices in the collection, storage and processing of information, including physical security measures, in order to prevent unauthorized access to our systems;",
      145: "(4) All the iRiding employees, contractors and agents who have to contact personal information for the purpose of providing services shall abide by the strict confidentiality obligations stipulated in the contract. Otherwise, they may be punished or fired.",
      146: "2. Technical limitation and malicious means",
      147: "Although the above reasonable and effective measures have been taken and the standards required by relevant laws and regulations have been followed, you are requested to understand that it is impossible to guarantee 100% security of information in the Internet industry all the time due to the limitations of technologies and various malicious means that may exist even if we do our best to strengthen our security measures. We will try our best to ensure the security of the personal information you provide us. You shall confirm that you know and understand that the system and communication network you use to access our services may have problems due to factors beyond our control. Therefore, we strongly recommend that you take positive measures to protect the security of your personal information, including but not limited to using complex passwords, changing passwords regularly, and not disclosing your own account password and other personal information to others.",
      148: "3. Emergency plan",
      149: "We will work out an emergency plan and trigger it immediately in case of any user information security incident, so as to prevent the expansion of the impact and consequences of such security incident. After the unfortunate occurrence of a user information security incident (leakage, loss, etc.), we will inform you of the basic situation and possible impact of the security incident, the measures we have taken or will take to cope with it, the suggestions that you can prevent and reduce the risks on your own, and the remedial measures for you in accordance with the requirements of laws and regulations in time. We will inform you of relevant information of the incident by mail, letter, telephone, push notice, etc. And when it is difficult to inform the subject of personal information one by one, we will take reasonable and effective ways to issue an announcement. At the same time, we will report the handling of the personal information security incident in accordance with the requirements of regulatory authorities.",
      150: "4. What can you do?",
      151: "You shall not disclose your login password or account information to anyone (unless this person is officially authorized by you) and you can set a unique password for QiCYCLE APP to prevent other websites from leaking your password that may endanger the security of your account in QiCYCLE APP. At any time, be sure not to disclose the verification code you receive to anyone (including those who claim to be customer service personnel of QiCYCLE APP).",
      152: "QiCYCLE APP will not be responsible for the security negligence caused by any third party's access to your personal information due to your failure to maintain the privacy of your personal information. Notwithstanding the above provisions, in the event of any other Internet user’s use of your account without authorization or having any other security vulnerabilities, you should immediately inform us.",
      153: "Your assistance will help us protect the privacy of your personal information.",
      154: "V. How do we share, transfer and disclose your personal information",
      155: "1. Sharing",
      156: "We will not sell any personal information to a third party.",
      157: "Sometimes we may share your personal information with third parties in order to provide or improve our products or services, including providing products or services according to your requirements. If you do not want us to share this information any longer, you are requested to send an email to privacy@miriding.com and contact us.",
      158: "1.1 Share out of your active choice",
      159: "Share the information to the extent of your authorization/request with the third party designated by you after your explicit consent or active choice. For example, you can quickly log in to a third-party website or APP by using your QiCYCLE APP account.",
      160: "We will provide social networking services. When you use this type of service, the information you upload or publish (including your public personal information, your address book, your circle of friends), your response to the information uploaded or published by others, as well as the log information related to these information, etc. So long as you do not delete the information, it will always be kept in the public domain, and shared and browsed by other users of our service. Even if you delete the information, it may still be independently cached, copied or stored by other users or unrelated third parties not under our control, or stored in the public domain by other users or third parties.",
      161: "Please consider carefully the information uploaded, published and exchanged through our service. In some cases, you can control the range of users who have the right to browse your shared information through the privacy settings of some of our services. You can delete the information you upload, publish or share by yourself. If you want us to delete your relevant information for you, please contact us by sending an email to privacy@miriding.com and put forward your request, we will finish checking and handling it within fifteen (15) working days after receiving it.",
      162: "1.2 Share information with our affiliated companies",
      163: "We may share your personal information with other related companies of QiCYCLE APP from time to time in order that we can be engaged in our business operation smoothly and provide you with all the functions of products or services.",
      164: "1.3 Share with third party service providers and business partners",
      165: "In order to provide you with the services described in this privacy policy, we may share necessary personal information with third parties such as third party service providers and business partners.",
      166: "They include our data center, data storage facilities, advertising and marketing service providers and other business partners. These third parties may process your personal information on behalf of QiCYCLE APP or for one or more items of this privacy policy. We guarantee to share the personal information necessary for your service only for justifiable, legitimate, necessary, specific and definite purposes. QiCYCLE APP will conduct due diligence and sign a contract to ensure that the third party service provider complies with applicable privacy laws in your jurisdiction. Third party service providers may also have their sub-processors.",
      167: "In order to provide performance measurement, analysis and other commercial services, we may also share information (non-personal information) with third parties (such as our advertisers) in the form of summary. We will use the information that we grasp to help advertisers and other partners assess the effectiveness and coverage of their advertising and services, and understand the types of people who use their services and how people interact with their websites, apps and services. We may also share with them general trends in the use of our services, such as the number of customers in a particular human group who purchase certain products or engage in certain transactions.",
      168: "Third-party SDK name",
      169: "Owned Company",
      170: "Data Collection",
      171: "Purpose of Usage",
      172: "Privacy Policy/Official Website",
      173: "weibo.com SDK",
      174: "sina.com",
      175: "External storage status, latest geographic location information, continuous positioning, network type, network service operator, IMEI, MAC address, WiFi information",
      176: "Weibo login, share",
      177: "https://open.weibo.com/wiki/%E5%BC%80%E5%8F%91%E8%80%85%E5%8D%8F%E8%AE%AE#.E9.9A.90.E7.A7.81.E7.9B.B8.E5.85.B3.E6.9D.A1.E6.AC.BE",
      178: "Xiaomi account open platform SDK ",
      179: "Xiaomi",
      180: "External storage status, network type, network service operator, IMEI, MAC address, WiFi information",
      181: "Xiaomi account login",
      182: "https://dev.mi.com/console/",
      183: "Umeng + SDK",
      184: "Youmeng Tongxin (Beijing) Technology Co., Ltd.",
      185: "Device status and identity and get your IMEI, IMSI information",
      186: "App statistical analysis scenario ",
      187: "https://www.umeng.com/",
      188: "Bugly SDK",
      189: "Tencent",
      190: "Device status and identity and get your IMEI, IMSI information, ANDROID ID",
      191: "Exception report",
      192: "https://bugly.qq.com/v2/index",
      193: "Qiniu Cloud SDK ",
      194: "Shanghai Qiniu Information Technology Co., Ltd.",
      195: "Device status and identity and get your IMEI, IMSI information, external storage status",
      196: "Amap Map SDK",
      197: "https://www.qiniu.com/?ref=developer.qiniu.com",
      198: "Amap Map SDK",
      199: "AMAP",
      200: "External storage status, latest geographic location information, continuous positioning, network type, network service operator, IMEI, MAC address, WiFi information",
      201: "Positioning, map display and poi query",
      202: "https://developer.amap.com/",
      203: "Wechat SDK",
      204: "Tencent",
      205: "External storage status, latest geographic location information, continuous positioning, network type, network service operator, IMEI, MAC address, WiFi information",
      206: "WeChat login, share",
      207: "https://developers.weixin.qq.com/doc/oplatform/Mobile_App/WeChat_Login/Development_Guide.html",
      208: "Xiaomi Push SDK",
      209: "Xiaomi",
      210: "Device status and identity and get your IMEI, IMSI information",
      211: "Push/send notification message",
      212: "https://dev.mi.com/console/",
      213: "QQ SDK",
      214: "Tencent",
      215: "System language, external storage status, latest geographic location information, network location information, continuous positioning, network type, mobile phone number, SIM card serial number, network service operator, IMEI, MAC address, ANDROID ID, IP address, WiFi information, Application installation list, read clipboard",
      216: "QQ login, share",
      217: "https://connect.qq.com/",
      218: "Regarding access to third-party services, we may access to third-party SDK services and share some of your information collected in accordance with this policy with such third-party service providers, so as to improve our customer service and user experience.",
      219: "Currently, the main types of third-party service providers we have access to include the following:",
      220: "(1) Used for message push. When you use our products or services,Xiaomi Push may access your device status and identity and get your IMEI and IMSI information, for pushing/sending notification messages. This information is necessary for the push function,and refusal to provide it will result in your inability to use the function,but will not affect the normal use of other functions;",
      221: "(2) Used to obtain device location permissions and collect device information and log information with your consent;",
      222: "(3) Used for third-party authorization services,including login using third-party accounts and sharing related content to third-party products.QQ and WeChat SDKs may access your ANDROID_ID and other information;",
      223: "(4) including content storage and positioning.including hardware distribution capability enhancement, server cost reduction, and application exception statistics reporting.Data such as your device information and network location information may be collected;",
      224: "(5) Used to optimize product performance,including hardware distribution capability enhancement, server cost reduction, and application exception statistics reporting. Data such as your device information and network location information may be collected",
      225: "(6) Used for services related to account security and product reinforcement,including network monitoring, domain name resolution, anti-hijacking, anti-spam/anti-cheating, and encryption/decryption services.",
      226: "We will only share your information for justifiable, necessary and specific purposes. For the third-party service providers with whom we share information, we will require them to perform relevant confidentiality obligations and take corresponding security measures.",
      227: "Third-party social media or other services are operated by relevant third parties. Your use of such third party's social media services or other services (including any information you provide to such third party) is subject to the third party's own terms of service and information protection statement (other than this policy), and you shall read the terms carefully. This policy only applies to the personal information we collect, and does not apply to the services provided by any third party or the information use rules of any third party. If you find that there are risks in these third-party social media or other services, you are recommended to terminate relevant operations to protect your legitimate rights and interests.",
      228: "1.4 Miscellaneous",
      229: "QiCYCLE APP may have to disclose your personal information in accordance with the requirements of laws and regulations, legal procedures, lawsuits and/or legal requirements of public institutions and government departments. We may also disclose the information about you if we determine that such disclosure is necessary or appropriate for national security, law enforcement or other matters of public importance.",
      230: "If we determine that the disclosure is reasonably necessary to enforce our terms or to protect our operations, rights, assets or products, or to protect users, or achieve the following purposes (detection, prevention and resolving of fraud, unauthorized use of products, violation of our terms or policies, or other harmful or illegal activities), we may also disclose information about you.(For the avoidance of doubt, QiCYCLE APP will only collect, use or disclose your personal information without your consent to the extent and only under such circumstances as expressly permitted by local data protection laws.) This may include providing information to public or government authorities, exchanging information about the reliability of your account with third-party partners to prevent fraud, violations and other harmful behaviors inside and outside our products.",
      231: "In addition, we may share your personal information with the following persons:",
      232: "(1) Our accountants, auditors, lawyers or similar advisers when we require them to give professional advice;",
      233: "(2) and Investors and other relevant third parties if there is an actual or potential sale or transaction of any other company, and it is related to the QiCYCLE APP entity;",
      234: "(3) and Any other third party, who discloses and shares specific information as authorized by you.",
      235: "2. Transfer",
      236: "Except for the following circumstances, QiCYCLE APP will not transfer your information to any entity:",
      237: "(1) Your express consent has been obtained;",
      238: "(2)(2) If QiCYCLE APP is involved in any merger, acquisition or sale of all or part of its assets, we will notify you of any change in the ownership and use of your personal information, as well as any options you may have regarding your personal information by e-mail and/or on our website in an eye-catching way.",
      239: "3. Public disclosure",
      240: "Except for desensitized display of your QiCYCLE APP account and user name when the list of award-winning activities is announced, QiCYCLE APP will only publicly disclose your personal information under the following circumstances:",
      241: "(1) Your express consent has been obtained;",
      242: "(2) Public disclosure based on laws or reasonable grounds: including laws, regulations, legal procedures, lawsuits or at the request of the competent government authorities.",
      243: "VI. Storage of information",
      244: "In general, we will only retain your personal information for so long as is necessary for our purposes, such as:",
      245: "Mobile phone number: If you need to use QiCYCLE APP services, we need to keep your mobile phone number all the time to ensure your normal use of the services. After you cancel your account, we will delete the corresponding information;",
      246: "Community information: when you send a community share, we need to save your community information so as to ensure that you use the community function normally, and we will delete the corresponding information after you delete your community information.",
      247: "When the operation of our products or services is stopped, we will notify you in the form of push notification, announcement, etc., and delete your personal information or anonymize it within a reasonable period.",
      248: "Since QiCYCLE APP is a global product, some of our activities include a global race of cycling, and we will store some of the data overseas.",
      249: "VII. Third-party service provider",
      250: "1.To facilitate your visit and enrich your experience, there may be products or services provided by third parties. You can choose whether to access such contents or links, or whether to use the third party's products or services. But we have no control over the products or services provided by third parties. We have no control over any of your personal information held by a third party. The information protection problems in the process of using the third party products or services does not apply to the management of this policy. This policy does not apply to any information you choose to provide to third parties, either. Please check the privacy policy of this third party. 。",
      251: "2.Participate in marketing activities. When you choose to participate in our relevant marketing activities, you need to provide your name, address, contact information, bank account number and so on according to the needs of the activities, so that the third party can provide you with prizes in time. When you choose to participate in our marketing activities, you need to provide your name, address, contact information, bank account number and so on according to the needs of the activities. With your express consent, we will share the above information with a third party, so that we can entrust the third party to provide you with prizes in time.",
      252: "VIII. Update of the policy",
      253: "1.We may modify and update this Privacy Policy from time to time. We will release the update of the Privacy Policy via notice or announcement in QiCYCLE APP, and you can visit QiCYCLE APP to search for the latest version of the Privacy Policy. For major changes, we will send you a formal notice (for some services, we will also send a notice by email to elaborate the changes to the Privacy Policy).",
      254: "IX. Applicable scope",
      255: "1.Our privacy policy applies to all the services provided by iRiding (Xiamen) Technology Co., Ltd. and its affiliated companies, including the services provided by QiCYCLE APP and at www.iriding.cc, except for the services with independent privacy policies (such as products and services provided by third parties).",
      256: "2.Our privacy policy does not apply to products and services provided by third parties, such as the products and services provided by third parties in QiCYCLE APP and/or at www.iriding.cc, as well as the other websites linked to our services, which will be regulated by independent privacy policies. Please refer to the corresponding policies and regulations separately.",
      257: "3.Regarding other companies and organizations conducting advertising and publicity for our services and may use our Cookies or other log information to launch and use advertisements, our Privacy Policy does not cover their information processing policies.",
      258: "X. How to Contact Us",
      259: "You can contact us in the following ways:",
      260: "1.If you have any question, opinion or suggestion about this policy, you can feed back to us through the background of QiCYCLE APP and contact us through the background of WeChat's official account of iRiding.",
      261: "2.If you find that your personal information may be leaked, you can complain and report through QiCYCLE APP, and we will contact you and complete the verification and handling within fifteen (15) working days;",
      262: "3.We have also set up a feedback email for comments and suggestions, and you can contact us by emailing to support@miriding.com, and we will contact you within fifteen (15) working days and complete the verification and handling.",
      263: "4.If you have any question about this policy, please contact us by emailing to privacy@miriding.com, and we will contact you and complete the verification and handling within fifteen (15) working days.",
    },
  },
  downLoad: {
    v1: "QiCYCLE 앱",
    v2: "기술의 아름다움은 사이클링을 즐겁게 합니다",
    v3: "중국대륙지역에 적용됩니다",
    v4: "다른 지역에 적용됩니다",
    v5: "앱 스토어",
    v6: "로컬 다운로드",
  },
  index: {
    v1: {
      text0: "아이라이딩",
      text1: "홈",
      text2: "페델렉",
      text3: "부속품",
      text4: "APP",
      text5: "지원",
      text6: "회사소개",
      text7: "탄생",
      text8: "2011년,아이라이딩(샤먼)과기유한회사가 샤먼에서 설립되었습니다. ",
      text9: "발전",
      text10:
        "2013년, 라이딩 운동 쇼설 앱 아이라이딩APP 1.0이 정식 출시되었습니다. ",
      text11:
        "2년의 기간을 경과하여, 사용자 설치량과 등록량은 백만을 초과하여, 라이딩 사용자들이 우선적으로 선택하는 라이딩 앱으로 되었습니다. ",
      text12:
        "현재까지,사용자 라이딩 총 이동거리는 1.3억km에 달해, 지구를 175회 왕복한 것과 맞먹습니다. ",
      text13: "가속",
      text14: "2014년 9월, 샤오미 생태사슬 기업으로 되었습니다. ",
      text15:
        "아이라이딩은 하드웨어 연구개발에 뛰어들어, 육속 투르 드 프랑스 레벨의 R1 탄소섬유 도로용 자전거, 미쟈 아이라이딩 페델렉 접이형 자전거, 미투 어린이용 자전거, 미쟈 에어 인플레이터 등 여러가지 제품을 선보였습니다. ",
      text16: "돛달기",
      text17:
        "2019년, 샤오미 페델렉 접이형 자전거, 미쟈 에어 인플레이터가 육속 출항하였습니다. ",
      text18:
        "현재 아이라이딩 제품은 아시아, 유럽, 대양주, 남미주 4대주의 10여개 국가와 지역에서 판매되고 있습니다. ",
      text19:
        "2020년, 아이라이딩 페델렉 자전거와 부대 제품을 출시함으로써, 자전거 브랜드의 새로운 장정에 나섰습니다. ",
      text20: "응집",
      text21:
        "향후, 아이라이딩은 육속 미래형 운동 외출 제품의 연구개발에 주력하여, 튼튼한 제품의 연구개발과 설계 실력을 통해, 사용자를 위한 더 좋은 외출 체험을 제공해 드릴 것입니다. ",
      text22: "발전여정",
      text23:
        "타이완산 최신 유럽기준의 페델렉 접이형 자전거 인증을 거쳐, 8월부터 유럽에서 판매 개시",
      text24:
        "EF1은 국내 최초로 EN15194-2017 인증후 유럽에서 판매되는 중국 브랜드 페델렉 자전거",
      text25:
        "코로나19의 영향과 유럽 구매 보조금의 영향을 받아, 자전거 판매 폭발, 샤오미 페델렉 접이형 자전거 주문서 매달 증가",
      text26: "샤오미에어펌프 199위엔, 샤오미온라인매장에서 판매합니다",
      text27:
        "「미투 어린이 자전거 14인치」 샤오미숍에서 판매중, 2020 독일 레드닷 디자인상 수상 ",
      text28:
        "새 국가 표준의 아이라이딩 페델렉 자전거 샤오미 온라인숍에서 판매중, 자전거 틀 공법 등 관련 특허 18건 획득",
      text29:
        "미쟈 아이라이딩 페델렉 자전거는 국내 판매량 51800대 실현, 누계 매출은 1.35억위안, 국내 전자상거래에서 페델렉 자전거의 새 장 개척",
      text30: "연속 2년 단품 판매량 1위, 2017년 시장 점유율은 91.3% 도달",
      text31: "iFixit는 미쟈 페델렉 자전거에 대해 높은 찬사 보냄",
      text32:
        "아이라이딩회사 샤먼지식산권국에 의해 시 공업디자인 지적소유권 우세 기업으로 인정",
      text33:
        "에프터 APP을 개발, 예비부품, 정비지도, 비용결산 등 실제 수요 해결",
      text34:
        "272개 아이라이딩 도시 파트너와 계약을 체결하여 아이라이딩 제품을 위한 애프터 서비스를 제공",
      text35:
        "미쟈 페델렉 접이형 자전거 샤오미 류덕 공동 창시인이 발표, 제품은 샤오미 레이쥔 CEO의 찬사 받음",
      text36: "알루미늄 합금 단조 기술 자전거에서 최초로 응용",
      text37: "Can모선 기술은 최초로 자전거에서 응용",
      text38: "횡방향 접이 등 자전거 관련 특허 11건 취득",
      text39: "TMM모멘트 센서는 처음으로 대규모 양산 실현",
      text40: "QiCYCLE R1 탄소섬유 파워 자전거 샤오미넷 크라우드 펀딩",
      text41: "QiCYCLE R1 두가지 레드닷 디자인상 수상",
      text42:
        "R1는 완벽한 운동 데이터 획득 능력을 구비하여 타이페이 자전거 혁신상 수상",
      text43:
        "아이라이딩 샤오미 생태사슬 일원으로 되어, 생활 외출 방향의 스마트 하드웨어 제품의 연구개발과 생태 배치 실시",
      text44: "아이라이딩 회사명을 아이라이딩(샤먼)과기유한회사로 변경",
      text45: "아이라이딩 샤오미 천사륜 융자 획득",
      text46: "아이라이딩 샤오미 생태사슬 가입",
      text47: "라이딩 앱인 “아이라이딩“APP 개발 및 온라인 운영",
      text48: "아이라이딩APP 유저량 124만 돌파",
      text49:
        "아이라이딩 유저 누계 라이딩 궤적 6500만km에 도달, 전국 최대 규모의 라이딩 커뮤니티로 부상",
      text50: "아이라이딩(샤먼)과기유한회사 샤먼에서 설립",
      text51: "장인제품",
      text52: "혁신설계",
      text53:
        "제품 독일 레드닷 디자인상, 디자인 iF상 등 수상, 여러건의 발명특허 취득",
      text54: "연구개발에 주력",
      text55: "업계 전문가급 구성원이 이끄는 연구팀 핵심 기술 혁신 공략",
      text56: "엄격한 품질제어",
      text57:
        "여러 개의 품질 검사 프로세스, 구석구석 완성제품 품질을 장악하여, 유저에 양질의 제품 제공",
      text58: "고효율 애프터서비스",
      text59:
        "밀착형 오프라인 서비스 모식 부단히 탐구, 유저에 더 좋은 제품 체험 선사",
      text60: "사무장소",
      text61: "아래도시",
      text62: "베이징",
      text63: "북경 하이뎬 구 안닝좡 동로 16호 룽케 룽지 동구 2호 · 벌집 공장",
      text64: "창저우",
      text65: "쟝쑤 창저우시 신베이구 혁신대로 89호",
      text66: "상하이",
      text67: "상하이 쉬후위구 톈린로 142호 G동 104B",
      text68: "선전",
      text69:
        "선전시 난산구 아오하이가도 고신구 아오싱 4도 1호 중산대학 선전산학연빌딩 17층",
      text70: "프랑크푸르트",
      text71:
        "Adam-Opel-Str. 10 im Hof, Geb. A, bei eKey 60386 Frankfurt am Main, Germany",
      text72: "샤먼",
      text73: "푸젠성 샤먼시 후리구 치산북로 768호 403실",
      text74: "홍콩",
      text75: "홍콩 쥬룽 창사만 칭산로 339호 헝성칭산도빌딩 1102실",
      text76: "소주",
      text77: "소주시 고신구 스산로 28호 쑤저우 고신국제비즈니스광장 606",
      text78: "쇼설미디어",
      text79: "애프터서비스 핫라인",
      text80: "당사와 연락하기",
      text81: "비즈니스 협력",
      text82: "국내판매",
      text83: "해외판매",
      text84: "고객서비스",
      text85: "데이터보호",
      text86: "아이라이딩 © 2013-2021 민 ICP비13003500호",
      text87: "프라이버시와 법률",
      text88: "귀하의 Cookies 취향",
      text89: "아이라이딩에 오신 것을 환영합니다!",
      text90:
        "귀하께 더 좋은 온라인 체험을 선사하고자, 당사는 Cookies를 사용하여 당사의 웹사이트를 개선할 수 있기를 희망합니다. ",
      text91: "Cookies에서 수집한 정보는 귀하 개인을 식별할 수 없습니다. ",
      text92:
        "당사에서 사용하는 Cookies 유형 및 Cookies에 관한 더 많은 정보는 당사의 Cookies 정책을 열람하십시오. ",
      text93: "거부",
      text94: "접수",
      text100:
        "귀하께 더 좋은 온라인 체험을 선사하고자, 당사는 Cookies를 사용하여 당사의 웹사이트를 개선할 수 있기를 희망합니다. Cookies에서 수집한 정보는 귀하 개인을 식별할 수 없습니다.당사에서 사용하는 Cookies 유형 및 Cookies에 관한 더 많은 정보는 당사의",
      text101: "Cookies 정책을 열람하십시오 ",
      text102: "아이라이딩",
      text103: "법률 정보",
      text104: "QiCYCLE",
    },
  },
  product: {
    v1: {
      productMove: "세부정보 보기",
      productCpd: "QiCYCLE 휴대용 전기 공기 팽창기 P1",
    },
  },
  contact: {
    // 韩文
    v1: "당사와 연락하기",
    v2: "비즈니스 협력",
    v3: "이메일주소: market@iriding.com",
    v4: "영업팀",
    v5: "국내영업: sales@iriding.com",
    v6: "해외영업: sales@iriding.com",
    v7: "고객서비스",
    v8: "애프터서비스: 400-1144-177",
    v9: "데이터 보호",
    v10: "이메일주소: dpo@iriding.com",
  },
  details: {
    v1: "QiCYCLE 휴대용 전기 공기 팽창기 P1",
    v2: "색상",
    v3: "바로 구매",
    v4: "상세정보 ",
    v5: "규격 참고",
    v6: "기술 지원",
    v7: "정밀하게 공기를 주입하는 작은 영웅  ",
    v8: "60초 쾌속 공기 보충",
    v9: "최고 150psi 공기주입 ",
    v10: "5분만에 타이어 전체 충전 완료",
    v11: "공기주입자동중지",
    v12: "5종 사용 모드",
    v13: "비상보조배터리 ",
    v14: "강력한 파워로.",
    v998: " 펌프의 한계를 뛰어넘다",
    v15: "고성능 무브먼트와 고출력 모터 축복",
    v16: "약 5분 만에 타이어 공기압 제로",
    v16a: "약 5분 만에 타이어 공기압 제로",
    v1611: "약 ",
    v1611a: "충전이 완료된 상태에서 약 ",
    v1621: "5",
    v1631: "분 만에 타이어 공기압 제로",
    v1631b: "개의 자동차 타이어에 공기주입이 가능하며",
    v1711: " 각 타이어당 약 ",
    v1711a: "각 타이어당 약",
    v1721:
      "개의 자동차 타이어에 공기주입이 가능하며 각 타이어당 약 5분이 소요됩니다",
    v1721a: "분이 소요됩니다.",
    v17: "충전이 완료된 상태에서 약 5개의 자동차 타이어에 공기주입이 가능하며 각 타이어당 약 5분이 소요됩니다.",
    v999: "타이어당 약 5분이 소요됩니다",
    v18: "보관이 용이한 핸디한 크기",
    v19: "제품 세로 길이 약 20cm",
    v20: "한 손으로 제어",
    v21: "자전거와 가벼운 운전",
    v22: "자전거와 가벼운 운전",
    v23: "빠른 속도로 높은 ",
    v231: "압력을 주입하는 정밀한 설계",
    v24: "최고 150psi 공기주입,",
    v24a: "최고 150psi 공기주입",
    v25: "로드 타이어 등에 필요한 높은 공기압을 만족시킵니다.",
    v26: "인플레이션은 어렵지 않다",
    v261: "배터리 충전이 완료된 펌프기기는",
    v262: "16",
    v263: "대 로드 바이크 타이어에 공기를 주입할 수 있으며,",
    v2631: "대 로드 바이크 타이어에 공기를 주입할 수 있으며",
    v27: "배터리 충전이 완료된 펌프기기는 16대 로드 바이크 타이어에 공기를 주입할 수 있으며, ",
    v9971: "각 타이어당 약",
    v9972: "63",
    v9973: "초가 소요됩니다",
    v997: "각 타이어당 약 63초가 소요됩",
    v272: "미리 설정된 공기압에",
    v271: "따라 주입이 완료되면 자동 차단",

    v28: "필요에 따라 공기압 설정",
    v29: "팽창이 완료되면 자동 정지",
    v30: "언제 어디서나 정확한 압력 측정이 가능합니다.",
    v31: "기압 센서 정확도  ",
    v311: "±1psi",
    v312: "气기압 센서 정확도±1psi",
    v32: "6. 5 가지 사용 모드",
    v33: "자동차, 오토바이, 자전거, 공, 프로 모드",
    v34: "제한된 기압 범위",
    v35: "더 안전한 작동을 위한 선택된 모드 팽창",
    v362: "기본",
    v361: "자동차 모드",
    v3611: "자동차 모드：2.5bar",
    v3612: "자동차 모드：2.4bar",
    v363: "2.5bar",
    v364: "2.4bar",
    v36: "자동차 모드",
    v37: "조절 가능 범위:1.8-3.5bar",
    v371: "1.8-3.5bar",
    v3711: "조절 가능 범위:1.8-3.0bar",
    v38: "오토바이 모드",
    v381: "자동차 모드2.4bar",
    v39: "조절 가능 범위",
    v391: "1.8-3.0bar",
    v40: "자전거 모드",
    v41: "조절 가능 범위",
    v411: "45psi",
    v4111: "기본 45psi",
    v2121: "내구성 품질을 위한,",
    v2122: "엄격한 테스트",
    v412: "조절 가능 범위：",
    v4121: "조절 가능 범위: 30-145psi",
    v581: "무부하 연속 작업 시간 최대 35분",
    v413: "30-145psi",
    v42: "공 모드",
    v451: "공 모드 기본： 3-150psi",
    v451a: "3-150psi",
    v451b: "0.2-10.3bar",
    v421: "8psi",
    v423: "조절 가능 범위: 4-16psi",
    v462: "조절 가능 범위: 0.2-10.3bar",
    v422: "기본:8psi",
    v43: "4-16psi",
    v44: "자유 모드",
    v45: "조절 가능 범위",
    v46: "0.2-10.3bar",
    v461: "3-150psi / 0.2-10.3bar",
    v47: "내장 리튬 배터리 18650",
    v48: "장소에 제한 받지 않는 사용",
    v49: "산을 탐험하거나 파도를 타거나타거나",
    v50: "외부 전원 공급 장치가 필요하지 않음",
    v51: "구속 없이 팽창 가능",
    v52: "당신의 삶에 활력을,주는 정교한 디테일",
    v53: "사용하기 쉽고 편리한 조명 기능",
    v54: "LED 조명, 야간 작업은 어둠을 두려워하지 않습니다.",
    v55: "SOS 플래시, 위기의 시간에 더 침착.",
    v56: "이중 방열 기능",
    v57: "냉각 구멍이 있는 고출력 팬.",
    v58: "무부하 연속 작업 시간 최대 35분.",
    v59: "보조 배터리 기능",
    v60: "글로벌 Type-C 인터페이스, 걱정 없는 충전.",
    v61: "휴대폰 충전용 USB-A 포트.",
    v611: "USB 어댑터 충전",
    v612: "차량용 충전기 충전",
    v613: "충전 보물",
    v62: "실시간으로 디스플레이되는 빅 사이즈 디지털 스크린",
    v63: "4.4인치 고화질 빅사이즈 스크린은. ",
    v631: " 사전 설정된 타이어 공기압, 사용 모드 등의 데이터를 실시간으로 표시합니다.",
    v64: "보관이 편리한 완벽한 액세서리 구성",
    v65: "몸을 보호하는 수납가방.",
    v66: "중간층은 가스 노즐, 가스 바늘 및 충전 케이블을 저장할 수 있습니다.",
    v67: "QIJI에어 펌프가 통과한 8가지 엄격한 테스트",
    v68: "고압력 보호 기능 테스트",
    v69: "에어 파이프 탄성테스트",
    v70: "전압 강도 테스트",
    v71: "에어 파이프 저온시 휘어짐 테스트",
    v72: "낙하 충격 테스트",
    v73: "지속사용시간 테스트",
    v74: "저항력 테스트",
    v75: "고/저온 충전 테스트 ",
    v76: "QiCYCLE 휴대용 전기 공기 팽창기 P1",
    v77: "1bar=14.5psi=100kPa",
    v78: "공기주입대상",
    v79: "승용차 타이어",
    v80: "175/70 R14(공기 보충)",
    v81: "우링홍광 ",
    v82: "(175/70 R14)",
    v83: "뷰익 힝랑 ",
    v84: "(195/65 R16)",
    v85: "폭스바겐 골프",
    v86: "(205/55 R16)",
    v87: "도요타 캠리",
    v88: "(215/55 R17)",
    v89: "랜드로버 레인지로버",
    v90: "(255/55 R20)",
    v91: "BMW 730",
    v92: "(275/40 R19)",
    v93: "오토바이 타이어",
    v94: "(150/60 R17)",
    v95: "오토바이 타이어",
    v96: "(110/70 R17)",
    v97: "로드 바이크 타이어",
    v98: "(700 × 25C)",
    v99: "산악 자전거 타이어",
    v100: "(27.5 × 1.95)",
    v101: "전동 킥보드 타이어",
    v102: "(8 1/2 x 2)",
    v103: "밸런스 바이크 타이어",
    v104: "(9 x 3.0-6)",
    v105: "7호 농구공",
    v106: "5호 축구공",
    v107: "5호 배구공",
    v108: "공기주입 압력",
    v109: "2.0-2.5 bar",
    v110: "0-2.5 bar",
    v111: "0-2.4 bar",
    v112: "0-120psi",
    v113: "0-50psi",
    v114: "0-40psi",
    v115: "0-30psi",
    v116: "0-9psi",
    v117: "0-11psi",
    v118: "0-6psi",
    v119: "부풀려진 수량",
    v120: "≈ 18",
    v121: "≈ 5",
    v122: "≈ 4",
    v123: "≈ 3",
    v124: "≈ 2",
    v125: "≈ 7",
    v126: "≈ 15",
    v127: "≈ 16",
    v128: "≈ 20",
    v129: "≈ 201",
    v130: "≈ 97",
    v131: "≈ 92",
    v132: "≈ 86",
    v133: "≈ 197",
    v134: "충전 속도",
    v135: "≈ 60초/개",
    v136: "≈ 4분 58초/개",
    v137: "≈ 6분 3초/개",
    v138: "≈ 5분 52초/개",
    v139: "≈ 7분 20초/개",
    v140: "≈ 12분 30초/개",
    v141: "≈ 9분 10초/개",
    v142: "≈ 2분 50초/개",
    v143: "≈ 1분 30초/개",
    v144: "≈ 63초/개",
    v145: "≈ 58초/개",
    v146: "≈ 10초/개",
    v147: "≈ 14초/개",
    v148: "≈ 18초/개",
    v149: "≈ 20초/개",
    v150: "≈ 10초/개",
    v1510: "[1] ",
    v1520: "[2] ",
    v1530: "[3] ",
    v1540: "[4] ",
    v1550: "[5] ",
    v1560: "[6] ",
    v1570:
      "제품이 완전히 충전되었을 때,18개의 175/70 R14 자동차 타이어를 2.0bar에서 2.5bar로 팽창할 수 있습니다.약 60초 안에 타이어를 채우십시오.",
    v1580:
      "제품이 완전히 충전되었을 때,0bar에서 2.5bar까지 약 5개의 175/70 R14 자동차 타이어를 팽창시킬 수 있습니다.타이어를 채우는 데 약 5분.",
    v1590:
      "압력 유지 모드는 모델 스프레이 페인트 펜, PA 포트, 에어백 잭 등과 같은 도구와 함께 사용해야 합니다.따로 구매해주세요.",
    v1591:
      "USB-A 인터페이스의 출력 매개변수는 5V⎓2A입니다. 5000mAh 보조 배터리로 사용 가능.",
    v1592:
      "이 8개의 테스트 데이터는 SUD Certification and Testing (China) Co. Ltd.의 Shenzhen Branch와 iRiding Laboratory에서 가져온 것입니다.데이터 보고서 번호：QJSZ20210731.01,QJSZ20210806.01,QJSZ20210729.01,QJSZ20210831.01,QJSZ20210519.01,QJSZ20210803.01,68.189.21.0074.01.데이터 보고서 번호.",
    v1593:
      "보고서에 표시된 데이터는 본체의 충전이 완료된 상태에서 측정된 것입니다. 실제 데이터는 환경, 온도, 사용 습관 등의 요인으로 약간 다를 수 있습니다.",
    v1594:
      "이 페이지의 데이터는 달리 명시되지 않는 한 iRiding연구소에서 가져온 것입니다.",
    v151: "제품이 완전히 충전되었을 때",
    v152: "18개의 175/70 R14 자동차 타이어를 2.0bar에서 2.5bar로 팽창할 수 있습니다.",
    v153: "약 60초 안에 타이어를 채우십시오",
    v154: "제품이 완전히 충전되었을 때",
    v155: "0bar에서 2.5bar까지 약 5개의 175/70 R14 자동차 타이어를 팽창시킬 수 있습니다.",
    v156: "타이어를 채우는 데 약 5분",
    v157: "압력 유지 모드는 모델 스프레이 페인트 펜, PA 포트, 에어백 잭 등과 같은 도구와 함께 사용해야 합니다.",
    v158: "따로 구매해주세요. ",
    v159: "USB-A 인터페이스의 출력 매개변수는 5V⎓2A입니다.",
    v160: "5000mAh 보조 배터리로 사용 가능",
    v161: "이 8개의 테스트 데이터는 SUD Certification and Testing (China) Co., Ltd.의 Shenzhen Branch와 Qiji Laboratory에서 가져온 것입니다.",
    v162: "데이터 보고서 번호",
    v163: "보고서에 표시된 데이터는 본체의 충전이 완료된 상태에서 측정된 것입니다. ",
    v164: "실제 데이터는 환경, 온도, 사용 습관 등의 요인으로 약간 다를 수 있습니다",
    v165: "참고",
    v166: "이 페이지의 데이터는 달리 명시되지 않는 한 iRiding연구소에서 가져온 것입니다.",
    v167: "않는 한 QIJI연구소에서 가져온 것입니다",
    v168: "수동×1",
    v169: "수납백×1",
    v170: "고압 가스 파이프×1",
    v171: "충전 케이블×1",
    v172: "에어 니들×1",
    v173: "프랑스 식 에어 노즐 어댑터×1",
    v174: "QiCYCLE 휴대용 전기 공기 팽창기 P1×1",
    v175: "패킹리스트",
    v176: "자동 가압",
    v177: "혁신적인 압력 유지 모드",
    v178: "설정값 이하로 즉시 가압,자동으로 용기 압력 유지,그림 그리기, 꽃에 물주기, 세차하기,번거 로움 무료.",
    v191: "전원을 켠 후 라이트 버튼을 길게 누릅니다.",
    v192: "그리고 누르다「+」열쇠,~까지",
    v193: "상 나타나다",
    v194: "상품명",
    v195: "QiCYCLE 휴대용 전기 공기 팽창기 P1",
    v196: "产品型号",
    v197: "QJCQBP1",
    v198: "产제품 크기",
    v199a: "200.5 × 63.5 × 63.5mm",
    v199b: "(본체 크기, 에어 호스 제외)",
    v200: "충전 압력 범위",
    v201: "3-150psi / 0.2-10.3bar",
    v202: "배터리 유형:",
    v203: "리튬 이온",
    v204: "배터리 용량",
    v205: "28.86Wh/11.1V",
    v206: "센서 정확도",
    v207: "±1.5 psi",
    v208: "충전 소요시간",
    v209: "4시간 미만",
    v210: " ",
    v211: "",
    v212: "내구성 품질을 위한 엄격한 테스트",
    v213: "다양한 장면,자유롭게 전환",
    v2131: "场景多样",
    v2132: "自由切换",
    v214: "인연을 끊다,",
    v214a: "인연을 끊다",
    v215: "클래식 블랙",
    v216: "잘 생긴",
    v217: " ",
  },
  C2: {
    v0: "QiCYCLE C2",
    v1: "QiCYCLE 전기 자전거 C2",
    v1m: "QiCYCLE 전기 자전거 C2",
    v2: "도시를 가로지르는 상상 이상의 자유",
    v2a: "상상 이상의 자유",
    v3: "색깔",
    v4: "검은 색",
    v5: "작고 가벼운 바디",
    v6: "스마트 헤드라이트",
    v7: "긴 항속 거리",
    v8: "강력한 리튬 배터리",
    v9: "8단 변속기",
    v10: "대형 스크린",
    v11: "접이식 핸들 포스트",
    v12: "1",
    v13: "개요",
    v14: "명세서",
    v14a: "제품 설명서",
    v15: "지원하다",
    v16: "세련되고 우아한 디자인<br/> 모든 곳이 C",
    v16m: "세련되고 우아한 디자인<br/> 모든 곳이 C",
    v17: "모든 곳이 C",
    v18: "클래식한 비율의 우아한 디자인은 시선을 사로잡습니다.",
    v19: "편안하게 즐기는 라이딩",
    v19m: "편안하게 즐기는 라이딩",
    v20: "인체 공학적 설계",
    v21: "인체 공학적으로 설계된 프레임 구조는 자전거와 하나가 된 것 같은 편안함을 느낄 수 있게 해줍니다.",
    m21a: "인체 공학적으로 설계된 프레임 구조는 자전거와 하나가 된 것 같은 편안함을 느낄 수",
    m21b: "있게 해줍니다.",
    v22: "*전기자전거는 도로주행시 반드시 사진촬영이 필요하오니 교통법규를 준수하시고 안전운행에 유의하시기 바랍니다.",
    v23: "초경량 바디, 민첩한 핸들링",
    v24: "항공 등급의",
    v24p: "알루미늄 합금으로 만들어진 멀티 캐비티 프레임 은 스티어링이 유연하고 가벼우며 좁은 공간에서도 민첩하게 움직입니다. ",
    v24c: "항공 등급의 &nbsp; <sup style='position: absolute;color: rgb(255, 255, 255) !important;margin-left: -0.3rem;margin-top: -0.05rem;'class='font15'>「1」</sup>&nbsp;알루미늄 합금으로 만들어진 멀티 캐비티 프레임 은 스티어링이 유연하고 가벼우며 좁은 공간에서도 민첩하게 움직입니다. ",
    v25: "무거운 중량을 감당하는 가벼운 자전거",
    v25m: "무거운 중량을 감당하는 가벼운 자전거",
    v26: "약 20kg의 가벼운 자전거는 120kg의 무게를 견딜 수 있으므로, 남녀노소 모두 사용할 수 있습니다.<sup style='font-size:0.15rem' :style=' ios? 'position: absolute;margin-top: -0.05rem;margin-left: -0.1rem;': 'position: absolute;margin-top: -0.05rem;margin-left: 0;''>「2」</sup>",
    v26d: "  .",
    v26c: "*앞바구니, 뒤선반, 스펀지쿠션, 유아용 안장 등의 액세서리는 실제 필요에 따라 따로 구매하시기 바랍니다.",
    m26a: "약 20kg의 가벼운 자전거는 120kg의 무게를 견딜 수 있으므로, 남녀노소 모두 사용할",
    m26b: "수 있습니다.",
    v27: "효율을 높여주는 전동 어시스트로 ",
    v27b: "효율을 높여주는 전동 어시스트로<br/> 빠른 주행을 신나게 즐기세요",
    v27a: "빠른 주행을 신나게 즐기세요",
    v28: "고도로 정밀한 감지기",
    v29: "너의 밟을 때마다",
    v30: "내장된 중앙 차축 토크 센서는 페달링 데이터를 날카롭게 캡처하고 속도를 높이고 효율적으로 차가 유연하게 움직일 수 있습니다.",
    m30a: "고정밀 센서는 페달링 데이터를 재빠르게 수집하여 속도 및 효율성을 높여줍니다. 당신의 움직임을  따라",
    m30b: "민첩하게 반응합니다.",
    m31: "센서 원리",
    v31: "C2 PRO",
    v32: "토크 센서",
    v33: "C2",
    v34: "속도 센서",
    v35: "1.라이드 데이터 표시",
    v36: "2.제어 부스트 출력",
    v37: "3.페달링 힘 감지",
    v38: "4.구동 모터",
    v39: "다양한 시나리오를 고려한 다양한 기어 옵션",
    v40: "3속 증압 모드는 자유롭게 전환할 수 있다.초장거리 배터리의 수명은 약 60킬로미터이다",
    v40m: "3속 증압 모드는 자유롭게 전환할 수 있다.초장거리 배터리의 수명은 약 60킬로미터이다<sup style='margin: -0.05rem 0 0 -0.1rem' class='font15 positionA color3A'>「3」</sup>",
    v40c: ".",
    v41: "인력:",
    v42: "전동 어시스트:",
    v43: "전기 보조 출력",
    v44: "1단 기어",
    v45: "낮은 전력 소비, 긴 배터리 수명",
    v46: "2단 기어",
    v47: "배터리 수명과 전력의 균형",
    v48: "3단 기어",
    v49: "강력하고 빠른 가속",
    v50: "부스트 모드",
    v51: "도로가 오르막일 때 차에서 내리기 쉽습니다.",
    v52: "정확하고 부드러운 8단 기어",
    v52b: "정확하고 부드러운 8단 기어",
    v52d: "정확하고 부드러운",
    v52c: ",",
    v52a: "8단 기어",
    v53: "자유롭게 조절 가능한 8단 기어와 전동 어시스트를 함께 사용해 맞바람이 부는 오르막에 도전해 보세요.",
    m53a: "자유롭게 조절 가능한 8단 기어와 전동 어시스트를 함께 사용해 맞바람이 부는 오르막에 ",
    m53b: "도전해 보세요.",
    v54: "정확하고 부드러운 8단 기어",
    v55: "도시를 타고",
    v56: "나는 당신의 안전을 돌본다",
    v57: "독립적으로 내장되는 <br/> 하이테크놀로지 리튬 배터리",
    v57m: "독립적으로 내장되는 하이테크놀로지 리튬 배터리",
    v58: "30개의 리튬 배터리를 탑튜브 안에 장착시킴으로 전선에 닿지 않게 분리하여 안전한 사용을 보장합니다.",
    v58m: "30개의 리튬 배터리를 탑튜브 안에 장착시킴으로 전선에 닿지 않게 분리하여 안전한 사용을 보장합니다.",
    v59: "*이 제품은 배터리가 내장되어 있으며,",
    v60: "스스로 분해하지 마십시오.",
    v61: "자동차 등급 전력 관리",
    v62: "자동차 등급 BMS 안전 관리 시스템은 충전이 완료되면 자동 중지되도록 하여 안전하게 충전할 수 있습니다.",
    m62a: "자동차 등급 BMS 안전 관리 시스템은 충전이 완료되면 자동 중지되도록 ",
    m62b: "하여 안전하게 충전할 수 있습니다.",
    v63: "저온 용량 시험",
    v64: "고온 용량 시험",
    v65: "IPX5 테스트",
    v66: "무료 낙하 테스트",
    v67: "과충전 보호",
    v68: "과방전 보호",
    v69: "가속 충격 시험",
    v70: "단락 보호",
    v71: "고온 및 저온 충격 시험",
    v72: "내진동 시험",
    v73: "130℃ 고온 시험",
    v74: "전하 보유 능력",
    v75: "버튼과 다이얼은 당신의 손가락 바로 옆에 있습니다",
    v76: "어르신도 쉽게 이해하고 조종할 수 있는 직관적이고 명확한 설계로 제작되었습니다.",
    v77: "센터 디스플레이",
    v78: "화면 밝기를 자동으로 지능적으로 조정하면 기어, 동력,  ",
    v78a: "속도 데이터가 눈앞에 표시되어 주간에는 선명하게, 야간에는 눈부심 없이 볼 수 있습니다.",
    m78a: "화면 밝기를 자동으로 지능적으로 조정하면 기어, 동력, 속도 데이터가 ",
    m78b: "눈앞에 표시되어 주간에는 선명하게, 야간에는 눈부심 없이 볼 수 있습니다.",
    v79: "스마트 헤드라이트 <sup style='position: absolute;color: rgb(255, 255, 255) !important;margin-top: 0.05rem;'class='font15'>「5」</sup>",
    v79a: "스마트 헤드라이트<sup style='position: absolute;margin-left: -0.05rem;margin-top: 0.05rem;'class='font15'>「5」</sup>",
    v80: "광 센서 제어 전면 및 후면 조명 스위치,",
    v81: "자동으로 켜거나 끕니다.",
    m81a: "자동으로 켜거나 끕니다.브레이크",
    v82: "브레이크 후미등이 밝다.",
    m82a: "후미등이 밝다.",
    v83: "방해받지 않고 타고,",
    v84: "운전이 더 안전합니다.",
    v85: "액세러리를 추가하거나 빼서  나만의 자전거를 만드세요",
    v85m: "액세러리를 추가하거나 빼서  나만의 자전거를 만드세요",
    v85a: "빼서  나만의 자전거를 만드세요.",
    v86: "액세러리를 다양하게 활용하여 고수 직장인, 만능 가정주부, 패셔너블한 엄마  등 으로  변신할 수 있습니다.",
    m86a: "액세러리를 다양하게 활용하여 고수 직장인, 만능 가정주부, 패셔너블한 엄마",
    v86b: "등 으로  변신할 수 있습니다.",
    v87: "액세서리 추가 또는 제거",
    v88: "QiCYCLE 앱과 연결",
    v89: "인생을 기록하다",
    v90: "QiCYCLE  어플리케이션을 다운로드해 속도, 주행 거리 등의 정보를 받고 공유해보세요. ",
    m90a: "QiCYCLE  어플리케이션을 다운로드해 속도,주행 거리 등의 정보를 받고 공유해보세요.",
    v91: "",
    v92: "QiCYCLE 앱과 연결",
    v93: "독창적인 디자인, 라이딩의 기적을 창조하다",
    v94: "영구 자석 브러시리스 모터",
    v95: "서징 파워 트리거",
    v96: "20인치 휠",
    v97: "유연한 라이딩을 즐기세요",
    v98: "접이식 핸들 포스트",
    v99: "트렁크의 여행자",
    v100: "전/후 디스크 브레이크",
    v101: "아무리 빨리 달려도 멈춰",
    v102: "조정 가능한 싯 포스트",
    v103: "당신은 마지막 말을했습니다",
    v104: "엄선된 공압 타이어",
    v105: "태산처럼 꾸준히",
    //v106: "[1]    CCC 인증 기관:중국 품질 인증 센터,인증서 번호:2021011119391871",
    v107: "[1]    멀티 캐비티 자전거 프레임은다수의 실용신안 특허를 취득하였으며,특허 번호는 다음과 같습니다 ZL201820720214.X,ZL201822033605.7,ZL201821913718.X.",
    v108: "[2]    이 제품은 신장 155-185cm에 적합합니다.",
    v109: "[3]    항속 60km 테스트 조건:조명이 꺼진 상태에서 2단 기어로  70kg의 하중을 싣고 20km/h의 일정한 속도로 도시 포장도로를 주행 했을 때 실제 배터리 수명은 부하, 온도, 도로 조건 및 작동 습관과 같은 요인에 따라 약간 다를 수 있습니다.",
    v110: "[4]    데이터는 Fujian Scud Power Technology Co., Ltd.의 테스트 센터에서 가져온 것입니다.신고번호 DC210073",
    v111: "[5]    주행시 주변 빛의 변화에 따라 전방 및 후방 조명을 자동으로 켜고 끌 수 있습니다.",
    v112: "[6]    앞바구니, 뒤선반, 스펀지쿠션, 유아용 안장 등의 액세서리는 실제 필요에 따라 따로 구매하시기 바랍니다.",
    //v113:'',
    v114: "[7]    시트 튜브의 가장 높은 안전선과 가장 낮은 안전선 사이의 시트 높이를 조정하십시오.",
    v115: "[8]    이 페이지의 타이어 패턴은 참고용입니다.타이어의 배치가 다르기 때문에실제와  약간 다를 수 있습니다실제를 참조하십시오.",
    v116: "참조:이 페이지에 포함된 데이터는 달리 명시되지 않는 한 iRiding 연구소에서 가져온 것입니다.",
    v117: "",
    v120: "QiCYCLE 전기 보조 자전거 매개변수",
    v121: "제품 매개변수",
    v121a: "상품명",
    v122: "전기 자전거",
    v123: "최대 설계 속도",
    v124: "25Km/h",
    v125: "제품 번호",
    v126: "TDN09Z",
    v127: "브레이크 방식",
    v128: "기계식 디스크 브레이크",
    v129: "제품 사이즈(접기 전)",
    v130: "1530×600×1060 mm",
    v131: "치과용 플레이트",
    v132: "56T×170mm 알루미늄 크랭크",
    v133: "제품 사이즈(접었을 때)",
    v134: "1530×430×725 mm",
    v135: "바퀴통",
    v136: "알루미늄 허브",
    v137: "차량 품질",
    v138: "약 20kg",
    v139: "충전 시간",
    v140: "약 5.5시간",
    v141: "휠 사이즈",
    v142: "20인치",
    v143: "본체 방수 등급",
    v144: "IPX 3",
    v145: "부하 용량",
    v146: "120 kg",
    v147: "화면 크기",
    v148: "53×32 mm",
    v149: "배터리 용량",
    v150: "7.5 Ah",
    v151: "무선 연결",
    v152: "블루투스 5.0",
    v153: "패킹리스트",
    v154: "전동 보조 자전거 × 1",
    v155: "4, 5, 6mm<br/> 육각 렌치 × 1",
    v155a: "육각 렌치 × 1",
    v155c: "4, 5, 6mm 육각 렌치 × 1",
    v156: "좌우 페달 × 1",
    v157: "전원 어댑터 × 1",
    //v158:	  "1",
    v159: "M5 나사 × 4",
    v160: "사용 설명서 × 1",
    //v161:	  "1",
    //v162:	  "1",
  },
  F2: {
    v0: "QiCYCLE F2",
    v1: "접이식 전동 자전거 QICYCLE F2",
    v1m: "접이식 전동 자전거<br/> QICYCLE F2",
    v2: "도시 일상의 데일리 파트너",
    v3: "색깔",
    v4: "검은 색",
    v5: "토크 센서",
    v6: "접이식 설계",
    v7: "착탈식 배터리",
    v8: "대용량 배터리",
    v9: "스마트 스크린",
    v10: "정속 주행",
    v11: "3단 내부 기어",
    v12: "16인치 휠사이즈",
    v13: "개요",
    v14: "명세서",
    v15: "지원하다",
    v16: "통화 중 도움말",
    v17: "당신의 모든 단계를 이해",
    v18: "토크 센서는 페달링 힘 데이터를 수집하고 파워 어시스트를 제어합니다.",
    v18a: "토크 센서는 페달링 힘 데이터를 수집하고 파워 어시스트를 제어합니다.신경과도 같이 민감하게 반응하는",
    v18b: "자전거와 몸이 하나가 된 것같은 체험을 해보세요.",
    m18a: "토크 센서는 페달링 힘 데이터를 수집하고 파워 어시스트를 제어합니다.신경과도 같이",
    m18b: "민감하게 반응하는 자전거와 몸이 하나가 된 것같은 체험을 해보세요.이제는 높은 언덕도 두렵지 ",
    m18c: "않습니다.평지를 달리는 것처럼 가뿐하게 오를 수 있습니다.",
    v19: "신경과도 같이 민감하게 반응하는 자전거와 몸이 하나가 된 것같은 체험을 해보세요.",
    v20: "이제는 높은 언덕도 두렵지 않습니다. 평지를 달리는 것처럼 가뿐하게 오를 수 있습니다.",
    v21: "토크 감지 시스템의 원리",
    v22: "1.라이드 데이터 표시",
    v23: "2.제어 부스트 출력",
    v24: "3.페달링 힘 감지",
    v25: "4.구동 모터",
    v26: "더 컴팩트해진 접이식 수납공간",
    v27: "트렁크에 서 있는 인공물",
    v27m: "더 컴팩트해진 접이식 수납공간 <br/> 트렁크에 서 있는 인공물",
    v28: "세로로 접는 특허 디자인",
    v28m: "세로로 접는 특허 디자인 <sup style='font-size:0.15rem;margin-left:-0.15rem;' :style='ios13? 'margin-top: -0.45rem; margin-left: 2.4rem': ios15? 'margin-top: -0.5rem; margin-left: 2.4rem': 'margin-top: -0.45rem; margin-left: 2.3rem''class='font15 positionA color3A'>「1」</sup>",
    v29: "메인 빔이 완성되면 접힐 수도 있습니다.알루미늄 합금 단조 프론트 및 리어 포크는 가볍고 강합니다.최대 120kg의 하중 보다 안전한 라이딩",
    v30: "알루미늄 합금 단조 프론트 및 리어 포크는 가볍고 강합니다.",
    v30a: ",알루미늄 합금 단조 프론트 및",
    v30b: "리어 포크는 가볍고 강합니다.",
    m30a: "알루미늄 합금 단조 프론트",
    v31: "최대 120kg의 하중",
    v32: "보다 안전한 라이딩",
    v33: "특허 번호: 201420243615.2",
    v34: "접힌 후의 부피는",
    m34a: " 약 0.25m입니다.",
    v35: "분리형 배터리",
    v36: "더욱 편리한 충전",
    v37: "프레임에는 20개의 18650 전원 리튬 배터리가 내장되어 있습니다.",
    v38: "자동차 등급 BMS 안전 관리 시스템은 전력 소비의 ",
    m38b: "관리 시스템은 전력 소비의",
    m38a: "자동차 등급 BMS 안전 ",
    v38a: "안전을 보장하기 위해 완전히 충전되면 중지될 수 있습니다.",
    v39: "배터리는 쉽게 분해할 수 있으며,",
    v40: "차량 전체를 직접 충전할 수 있습니다,",
    v41: "단일 배터리 충전",
    v42: "착탈식 배터리",
    v43: "내장 3단 변속 시스템,",
    v43a: "시마노 내장 3단 변속 시스템 <br/> 변속이 정확하며 튼튼합니다.",
    v43m: "시마노 내장 3단 변속 시스템 변속이 정확하며 튼튼합니다",
    v44: "정확한 변속",
    v45: "시마노 내장 3단 변속 시스템은 변속이 정확하며 튼튼합니다. ",
    v46: "정확한 변속,",
    v47: "강하고 튼튼한.",
    m47a: "강하고 튼튼한.전기",
    m47b: "지원으로,",
    m47c: `바람은 오르막의 "악마" 에게 도전합니다.`,
    v48: "전기 지원으로,",
    v49: '바람은 오르막의 "악마"에게 도전합니다',
    v50: "세 가지 라이딩 모드",
    v51: '바람은 오르막의 "악마"에게 도전합니다',
    v51m: '바람은 오르막의 <br/>"악마"에게 도전합니다.',
    v52: "정속 모드 <br/> 게으르고 여유로운",
    v52m: "정속 모드 <br/> 게으르고 여유로운",
    v53: "게으르고 여유로운",
    v54: "원클릭 속도 잠금으로 페달링이 필요없는 편안한 주행이 가능합니다.",
    v54m: "원클릭 속도 잠금으로 페달링이 필요없는 편안한 주행이 가능합니다.",
    v55: "정속 모드",
    v56: "전동 어시스트 모드",
    v56m: "전동 어시스트 모드 <br/> 에너지 절약",
    v57: "에너지 절약",
    v58: "3단 부스트 모드를 자유롭게 전환 가능 2단 기어 어시스트의 범위는 약 60km <sup style='margin-left: -0.05rem; margin-top: -0.05rem'class='font15 color3A positionA'>「2」</sup>",
    v58m: "3단 부스트 모드를 자유롭게 전환 가능 2단 기어 어시스트의 범위는 약 60km",
    v58c: ".",
    v59: "2단 기어 어시스트의 범위는 약 60km<sup style='margin-left: -0.1rem; margin-top: 0.1rem'class='font15 color3A positionA'>「2」</sup>",
    v60: "페달링:",
    v61: "전동 어시스트:",
    v62: "1단: ",
    v62a: "전력 절감 모드",
    v63: "2단:",
    v63a: " 밸런스 파워 모드",
    v64: "3단: ",
    v64a: "전력 최대 모드",
    v65: "전동 어시스트 양.",
    v66: "자전거 모드.",
    v67: "체력",
    v68: "N단 제로 어시스트 모드는 사이클링, 피트니스, 운동에 적합하며 자전거처럼 편안하게 탈 수 있습니다.",
    v68a: "N단 제로 어시스트 모드는 사이클링, 피트니스, 운동에 적합하며 ",
    v68b: "자전거처럼 편안하게 탈 수 있습니다.",
    m68a: "N단 제로 어시스트 모드는 사이클링, 피트니스, 운동에 적합하며",
    m68b: "자전거처럼 편안하게 탈 수 있습니다.",
    v69: "주행 데이터가 ",
    v69b: "주행 데이터가",
    v69a: "표시되는 계기판",
    v70: "기어 변환은 매우 쉽고 간단합니다. 컬러 스크린에 속도와 기어, 배터리 양,  거리 정보가 항시 표시되니, 불안한 마음을 떨치고 편안하게 주행하세요. 그것은 근거가 있고 마음에 있습니다.",
    v70m: "기어 변환은 매우 쉽고 간단합니다<br/>그것은 근거가 있고 마음에 있습니다.",
    v71: "컬러 스크린에 속도와 기어, 배터리 양,  거리 정보가 항시 표시되니",
    v71b: "컬러 스크린에 속도와 기어, 배터리 양,",
    v71a: "거리 정보가 항시 표시되니",
    m71a: "컬러 스크린에 속도와 기어",
    m71b: "배터리 양,  거리 정보가 항시 표시되니",
    v72: "그것은 근거가 있고 마음에 있습니다.",
    v73: "어시스트 모드 제어",
    v74: "사이클링 데이터 감지",
    v75: "QICYCLE 어플리케이션과 <br/> 연동하는 과학적 주행",
    v75a: "연동하는 과학적 주행",
    v76: "QICYCLE 어플리케이션은 속도, 주행 거리, 심박수,  케이던스와 같은 데이터를 <sup style='margin:0 -0.1rem 0 -0.1rem' class='font15  color3A'>「3」</sup> 표시하고 기록합니다. 스마트폰을 통해 클라우드에 동기화하여 언제든지 쉽게 볼 수 있습니다. 과학이 당신의 주행을 관리해드립니다.  ",
    v76m: "QICYCLE 어플리케이션은 속도, 주행 거리, 심박수,  케이던스와 같은 데이터를 <sup style='margin:0 -0.1rem 0 -0.1rem' class='font15  color3A'>「3」</sup> 표시하고 기록합니다. 스마트폰을 통해 클라우드에 동기화하여 언제든지 쉽게 볼 수 있습니다. 과학이 당신의 주행을 관리해드립니다.  ",
    v76b: "표시하고 기록합니다.스마트폰을 통해",
    v76a: " 클라우드에 동기화하여 언제든지 쉽게 볼 수 있습니다. 과학이 당신의 주행을 관리해드립니다. ",
    m76a: "QICYCLE 어플리케이션은 속도, 주행 거리, 심박수,  케이던스와 같은 데이터를 표시하고 기록합니다. ",
    m76b: "스마트폰을 통해 클라우드에 동기화하여 언제든지 쉽게 볼 수 있습니다. 과학이 당신의 ",
    m76c: "주행을 관리해드립니다.",
    v77: "",
    v78: "QiCYCLE 앱과 연결",
    v79: "독창적인 디자인과 감탄스러운 디테일",
    v79m: "독창적인 디자인과 <br/>감탄스러운 디테일",
    v80: "밝은 LED 헤드라이트",
    v81: "밤길을 밝히다",
    v82: "독특한 링 테일 라이트",
    v83: "라이딩 안전 보호",
    v84: "시티 라이딩을 위한 16인치 휠",
    v85: "당신이 앞으로 나아갈 수 있도록 도와주세요",
    v86: '<img style="width: 2.02rem; height: 0.32rem" src="https://image.iriding.cc/f2/m-2.png"> 시마노 롤러 브레이크',
    v86m: "시마노 롤러 브레이크",
    v87: "평생 유지 보수 무료",
    v88: "초경량 알루미늄 합금 단조 프레임",
    v89: "작고 유연하여 슈퍼 배터리 수명 달성",
    v90: '차량 IPX4 방수  <sup v-if="index === 5"style="margin-top: -0.05rem; margin-left: -0.05rem"class="font15 positionA">「4」</sup>',
    v90m: "차량 IPX4 방수<sup style='margin-top: -0.05rem; margin-left: -0.08rem' class='font15 positionA'>「4」</sup>",
    v91: "바람과 비를 두려워하지 않는다",
    v92: "텔레스코픽 펜더",
    v93: "차량을 쉽게 접을 수 있습니다",
    v94: "실용신안 특허명:",
    v95: "세로 회전 접이식 프레임",
    v96: "[1]      실용신안 특허명: 세로 회전 접이식 프레임 특허 번호: 201420243615.2",
    v97: "[2]      항속 60km 조건:2단 기어로  70kg의 하중을 싣고 20km/h의 일정한 속도로 도시 포장도로를 주행 했을 때.실제 배터리 수명은 부하, 온도, 도로 조건 및 작동 습관과 같은 요인에 따라 약간 다를 수 있습니다.",
    v98: "[3]      심박수와  케이던스 데이터를 얻기 위해서는 심박수 벨트 및 케이던스 센서와 같은 Bluetooth 5.0 프로토콜을 준수하는 외부 장치를 연결해야 합니다.따로 구매해주세요.",
    v99: "[4]      전체 차량은 IPX4 방수 테스트를 통과했으며 어떤 방향으로든 직접 물이 튀더라도 차량의 작동 성능에 영향을 미치지 않습니다.차량을 물에 담그거나 고압 물총을 사용하여 세척하지 마십시오.우천 시 깊은 수심에서 모터를 작동시키지 마십시오.수면이 모터 액슬의 위치를.테스트 보고서 번호: TH60150A/2019,시험 기관: SGS Taiwan Ltd.",
    v99a: "참고: ",
    v100: "1.이 페이지의 타이어 패턴은 참고용입니다.타이어의 배치가 다르기 때문에실제 항목은 약간 다를 수 있습니다실제 참고하세요.",
    v106a:
      " 2.달리 명시되지 않는 한 이 페이지의 데이터,모두 Qicycle 연구소에서 왔습니다.",
    v107: "Qicycle 전력 접이식 자전거",
    v108: "제품 매개변수",
    v109: "제품 매개변수",
    v110: "상품명",
    v111: "전기 자전거",
    v112: "제품 번호",
    v113: "TDR08Z",
    v114: "제품 사이즈(접기 전)",
    v115: "1290 × 540 × 950mm",
    v116: "제품 사이즈(접었을 때)",
    v117: "1000 × 450 × 650mm",
    v118: "차량 품질",
    v119: "약 14.5kg",
    v120: "휠 사이즈",
    v121: "16인치",
    v122: "부하 용량",
    v123: "100kg",
    v124: "배터리 용량",
    v125: "6Ah",
    v126: "충전 시간",
    v127: "약 4.5시간",
    v128: "방수 수준",
    v129: "IPX4",
    v130: "화면 크기",
    v131: "1.77인치",
    v132: "무선 연결",
    v133: "블루투스 5.0",
    v134: "패킹리스트",
    v135: "접이식 파워 어시스트 바이크 x 1",
    v136: "4, 5, 6mm <br/> 육각 렌치 × 1",
    v136a: " 육각 렌치 × 1",
    v136c: "4, 5, 6mm 육각 렌치 × 1",
    v137: "좌우 페달 × 1",
    v138: "전원 어댑터 × 1",
    v139: "사용 설명서 × 1",
    // v140: "1",
    v141: "센서 원리",
  },
};
