/**
 * 西班牙
 */
module.exports = {
  lang: {
    lang: {
      v0: "ES",
      v1: "简体中文-ZH",
      v2: "繁體中文-ZH",
      v3: "English-EN",
      v4: "Deutsch-DE",
      v5: "Español-ES",
      v6: "Français-FR",
      v7: "한국어 - KO",
    },
    contact: {
      v0: "Contactar con nosotros",
      v1: "Contactar con nosotros",
      v2: "Cooperación empresarial",
      v3: "Correo electrónico: ",
      v4: "Equipo de ventas",
      v5: "Comercialización nacional: ",
      v6: "Comercialización internacional: ",
      v7: "Servicio de atención al cliente",
      v8: "Línea directa de servicio postventa: ",
      v9: "Protección de datos",
      v10: "Correo electrónico: ",
    },
    law: {
      0: "Privacy and Laws",
      1: "Privacy Policy",
      2: "Date of update",
      3: "February 12, 2021",
      4: "Effective date",
      5: "March 12, 2021",
      6: "Preface",
      7: 'iRiding (Xiamen) Technology Co., Ltd. and its affiliated companies (hereinafter referred to as "QiCYCLE APP" or "We") take your personal information seriously and ensure that you have control over your personal information. We will use powerful encryption technology to protect your privacy and formulate strict policies to manage all your data. Before you visit QiCYCLE APP or use our services, you shall agree to the provisions of this policy on how we collect, use, store and share your relevant information. If you do not agree on any content in this privacy policy, you are requested to stop using or accessing our products and services immediately. If you have any questions about this policy, please contact us by emailing to privacy@miriding.com. The key points of this privacy policy are as follows:',
      8: "1.We will explain the types of your personal information we collect and their corresponding usage one by one, so that you can understand the categories, reasons for use and collection methods of the specific personal information we collect for a specific function.",
      9: "2.When you are using some functions, we will collect your sensitive information after obtaining your consent, unless it shall be collected pursuant to relevant laws and regulations. Refusing to provide such information will only make you unable to use some specific functions, but will not affect your normal use of the other functions of QiCYCLE APP.",
      10: "3.QiCYCLE APP will not share or transfer your personal information actively to a third party other than QiCYCLE APP. If there are other situations of sharing or transferring your personal information or if you need us to share or transfer your personal information to a third party other than QiCYCLE APP, we will obtain your express consent directly or confirm that the third party obtains your express consent for the above behaviors. In addition, we will conduct a risk assessment of our act of providing information to the outside world.",
      11: "4.QiCYCLE APP will not obtain your personal information actively from a third party other than QiCYCLE APP. If you need to obtain your personal information indirectly from a third party for business development in the future, we will explain to you the source, type and scope of use of your personal information before obtaining it. For example, if the personal information processing activities required by QiCYCLE APP for business purposes exceed the scope of your original authorization and consent upon providing personal information to a third party, we will obtain your express consent before processing such personal information of yours. In addition, we will strictly abide by relevant laws and regulations, and require the third party to guarantee the legality of the information that is provided.",
      12: "5.You can access, correct and delete your personal information, cancel your consent, cancel your account, complain and report, and set up privacy functions through the channels listed in this guide.",
      13: "If you want to know more detailed information, you are requested to read the corresponding chapters according to the following index:",
      14: "1.How do we collect and use your information",
      15: "2.How do we use Cookies and similar technologies",
      16: "3.How to update, change, export and delete your information",
      17: "4.How do we protect your personal information",
      18: "5.How do we share, transfer and publicly disclose your personal information",
      19: "6.Storage of information",
      20: "7.Third-party service provider",
      21: "8.Update of this policy",
      22: "9.Applicable scope",
      23: "10.How to contact us",
      24: "I. How do we collect and use your information?",
      25: "1. How do we collect your information?",
      26: "When we provide the services, we may collect, store and use the following information:",
      27: "1.1 The information you provide",
      28: "1.1.1 The information provided to us that can be used to identify the users' personal identity when you register or use our services, such as name, e-mail address, telephone number, bank card number, ID number, address, communication record, or other data that we can find through such links lawfully. If you want to make the most use of the sharing features we offer, you may also need to create publicly displayed personal data that may contain your name and photos.",
      29: "1.1.2 The shared information you provide to other parties through our services, and the information that you upload, submit, store, send or receive when you are using our services.",
      30: "1.1.2 The shared information you provide to other parties through our services, and the information that you upload, submit, store, send or receive when you are using our services.",
      31: "We will collect the information about the services you use and how you use them, such as related information about your using QiCYCLE APP sports, commenting in iRiding community or participating in clock-in movement, participating in iRiding events and challenges, becoming iRiding members and joining training camps, as well as that that may be generated when you use services of iRiding members and training camps. Such information includes:",
      32: "1.2.1 Equipment information",
      33: "We will receive and record the information related to the device you use according to your specific rights in the software installation and/or use authorization. For example, hardware and software characteristics such as your device model, operating system version information, device settings, mobile device version, device ID (such as IMEI/ Android ID/IDFA/OPENUDID/GUID/OAID, SIM card IMSI, ICCID information, etc.), screen resolution, environment of equipment,and relevant information regarding location of device, such as IP address, GPS location and the WLAN access points that can provide relevant information and the information for Bluetooth, base station and sensor. If you refuse to grant us the corresponding permissions during installation and/or use, we will not record the above kinds of information.",
      34: "1.2.21.2.2 Log information",
      35: "(1) Information for device or software, such as the configuration information for your mobile device, web browser or other programs used to access our services, your IP address and the version and device ID used by your mobile device (such as IMEI/ Android ID/IDFA/OPENUDID/GUID/OAID, SIM card IMSI, ICCID information, etc.);",
      36: "(2) The information that you search for or browse when you are using our services, such as web search terms you use, URL addresses of social media pages you visit, and other information and details that you browse or request to provide when you are using our services.",
      37: "1.2.3 IP address",
      38: "(1) The information about the mobile applications (APPs) and other software you have used, and the information about your having used such mobile applications and software;",
      39: "(2) The information you communicate through our services, such as the account number you have communicated with, as well as the communication time, data and duration;",
      40: "(3) The information (metadata) contained in the content you share through our services, such as the date, time or place of the shared photos or videos taken or uploaded.",
      41: "1.2.4 Pictures in cameras, microphones, devices, etc",
      42: "In order to provide you with a full range of sports experience, after you have authorized, our server will extract the video, picture, software name, version number, etc. from your device, and upload them to the server after encryption for comparison, so as to match the display function corresponding to the video, picture and software. At the same time, you can also refuse/cancel the permission at any time through relevant function settings. Refusing to provide this permission will only make you unable to use the above functions, but will not affect the normal use of other functions and services of QiCYCLE APP. However, after you cancel the authorization, we will no longer collect the information, nor can we provide you with the above services. However, unless you delete your information in accordance with the law, your cancellation will not affect our processing and storage of information based on your previous authorization.",
      43: "When you use the function of taking photos and recording videos, we will access the permissions of your devices such as camera and microphone, and collect the pictures and videos you provide to us after taking photos and recording videos. If you refuse to provide the permissions and contents, you will not be able to use this function, but it will not affect your normal use of other functions of iRiding. At the same time, you can cancel the permissions at any time through relevant function settings. After you cancel the authorization, we will no longer collect the information, nor can we provide you with the above services. However, unless you delete your information in accordance with the law, your cancellation will not affect our processing and storage of information based on your previous authorization.",
      44: "1.2.5 Location information",
      45: "We will access your location information after you enable the location permission, and provide you with more personalized products and/or services based on your location information, such as recording your GPS movement data and viewing the surrounding movement route. Of course, you can also stop us from collecting your geographic location information at any time by disabling the location function. You can give us access to your activity and physical training records and record your step frequency and step count information by reading your activity and physical training. At the same time, you can also cancel this permission at any time through relevant function settings. After you cancel the authorization, we will no longer collect the information, nor can we provide you with the above services. However, unless you delete your information in accordance with the provisions of the law, your cancellation will not affect the processing and storage of your information based on your previous authorization.",
      46: "We provide sports community service. When you use sports music function, we will request your authorization to read local music files for playing music while you are doing sports.",
      47: "1.2.6 The information from third parties",
      48: "Under some circumstances permitted by law, we may obtain your personal information from a third party. For example:",
      49: "(1) For the purpose of security and fraud prevention, verify your information (such as telephone number) from legitimate sources with your authorization for specific account number, financial transaction, etc;",
      50: "(2) Provide you with corresponding advertising services by a specified unique identifier (such as IMEI / Android / IDFA / OPENUDID / GUID / OAID, SIM CARD IMSI, ICCID information and so on) ;",
      51: "(3) We may also get some information from third-party social network services, such as accounts, nicknames (for example, when you log in to QiCYCLE APP service with a social network account);",
      52: "(4) The information related to you provided by others, such as your receiving address filled by other users while using QiCYCLE APP mall.",
      53: "1.2.7 Non-personal information",
      54: "We may also collect other information that cannot identify a specific individual and does not belong to personal information pursuant to local applicable laws, such as statistical data generated when you use a specific service, anonymous device related information, daily life events, page access events, page access duration events and session events,network monitoring data such as request duration, number of requests and wrong requests, etc, as well as application crash events. The purpose of collecting such information is to improve our services for you. The type and amount of information collected depends on how you use our products or services.",
      55: "We will collect information about your activities on our website or mobile app, such as unique device identifier, device model, system version number, etc. We will aggregate this information to help us provide customers with more useful information so that we can learn which parts of our website, products or services that they are most interested in. Aggregate data is treated as non-personal information as far as this privacy policy is concerned. If we combine non-personal information with personal information, such information will be regarded as personal information during the period of combination.",
      56: "2. How do we use your information",
      57: "The purpose of our collecting your personal information is to provide you with products or services and to ensure that we comply with applicable laws, regulations and other normative documents. We may use the information collected above for the following purposes:",
      58: "2.1 ovide, handle, maintain, improve and develop our products or services for you, such as delivery, verification, after-sales, customer support and advertising service;",
      59: "2.2 When we provide services, use it for authentication, customer service, security protection, fraud monitoring, archiving and backup purposes to ensure the security of the products and services we provide to you;",
      60: "2.3 Internal purposes, such as data analysis, research and development of statistical information related to the use of our products or services so that we can improve our products or services;",
      61: "2.4 Help us design new services and improve our existing services;",
      62: "2.5 rovide you with information push of more relevant activities, and you can choose to enable or disable it according to your preference;",
      63: "2.6 Evaluate and improve the effectiveness of advertising and other promotional and publicity activities in our services;",
      64: "2.7 Perform software verification and upgrade services;",
      65: "2.8 Provide information to our affiliated companies, third parties or other users when we provide specific services according to users' special requirements;",
      66: "2.9 Other situations that do not violate any mandatory laws and regulations;",
      67: "2.10 Store and maintain the information related to you for our business operation (e.g. business statistics) or fulfillment of legal obligations;",
      68: "2.11 Other purposes with your consent.",
      69: "More detailed examples are provided below on how we use your information (which may include personal information):",
      70: "(1) Log in and use QiCYCLE APP products or services;",
      71: "(2) Create and maintain your QiCYCLE APP account. The personal information provided when you create a QiCYCLE APP account through the website or a mobile device can be used to create your personal QiCYCLE APP account and personal data;",
      72: "(3) Participate in the community activities. Personal information related to the community or other similar social platforms of QiCYCLE APP may be used to display personal data pages, interact with other users, and participate in posting;",
      73: '(4) Provide location-based services. When you are using QiCYCLE APP service, we or third-party service providers and business partners (please refer to "How do we share, transfer and publicly disclose your personal information" below for more details) may use location information to provide services for you, and provide better user experience based on the accurate location, such as obtaining accurate running track, and you can disable the location based service in the settings of QiCYCLE APP at any time;',
      74: "(5) Improve the user experience. Some optional add-in features, such as user experience plans, allow us to analyze how users use mobile phones and QiCYCLE APP services to improve user experience, as well as sending crash reports;",
      75: "(6) Collect user feedback. The feedback you choose to provide is important to help QiCYCLE APP to improve its services. To handle the feedback you provide, QiCYCLE APP may contact you with the personal information you provide, and keep records for problem solving and improvement of services;",
      76: "(7) Send a notification. We may use your personal information to send important notices, such as changes to our terms, conditions and policies;",
      77: "(8) Carry out promotional activities. If you participate in the prize-drawing activity and competition through QiCYCLE APP, we may send such rewards to you using the personal information you provide;",
      78: "(9) Analyze the device so as to provide a better user experience. QiCYCLE APP will analyze devices to further enhance the experience of them.",
      79: "You have the right to refuse personal information processing and automated decision-making for direct marketing purposes. In order to exercise the above rights, you can send an email to privacy@miriding.com or refer to the control mechanism described in the separate privacy policy for each product or service.",
      80: "3. Our commitment",
      81: "3.1 We will not present personalized advertisements to you based on sensitive categories (such as race, religion, sexual orientation or health status);",
      82: "3.2 e will not share with advertisers the information that can be used to identify you, such as your name or e-mail address (unless authorized and consented by you);",
      83: "3.3 We will not sell your personal information to any third party at any time and under any circumstance. We will only use the information obtained under this Agreement to the extent permitted by the law. We will formulate strict policies to protect your personal information, and will not disclose your personal information to the public or provide it to any third party unless your prior authorization is obtained or otherwise specified in this statement.",
      84: "4. Affiliated companies or other trusted cooperative partners",
      85: "To ensure the quality of service, we may provide you with the services you require or the content you may be interested in by our affiliated companies or other trusted partners. With your authorization and consent, we will share the personal information necessary for the products and services with our affiliated companies or partners. We will require our affiliated companies and partners to ensure the security of your personal information in accordance with our instructions, privacy policy and any other appropriate confidentiality and security measures. Except for this, we will not provide or share your information to any third party without your authorization and consent.",
      86: "5. HeathKit",
      87: "If you are using an Apple mobile phone, in order to calculate exercise consumption and record relevant exercise data for you, with your authorization, we will receive your exercise data from Apple's Heathkit and send to it. To simplify your input process, we will read your motion data from Heathkit. At the same time, the training data generated by your QiCYCLE APP will be synchronized with Apple's Heathkit. Any information obtained by using Heathkit, such as steps and heart rate data, will not be shared or sold to any third party, including advertisers and other agents. We will not use Heathkit's information in marketing, advertising and similar services.",
      88: "6. Third-party entities",
      89: "The third-party service in QiCYCLE APP services is provided by an external third-party entity. QiCYCLE APP cannot obtain the information generated when you use this kind of third-party service. However, if you have explicitly agreed that the third party can obtain your geographic location information, the third party will obtain your terminal geographic location information through the QiCYCLE APP interface. This information is sensitive. Refusing to provide this information will only make you unable to use the above third-party services, but will not affect your normal use of QiCYCLE APP.",
      90: "Apart from these, pursuant to relevant laws, regulations and national standards, we may collect and use your personal information in the following situations without obtaining your authorization and consent:",
      91: "6.1 That directly related to national interests including national security and national defense security and that directly related to major public interests such as public security, public health, public knowledge and so on;",
      92: "6.2 That directly related to criminal investigation, prosecution, trial and execution of judgment;",
      93: "6.3 That for the purpose of safeguarding personal life, property and reputation of yours or others’ and it is difficult to obtain your consent;",
      94: "6.4 The collected personal information is disclosed to the public by yourself;",
      95: "6.5 Where personal information is collected from the lawfully disclosed information, such as lawful news coverage, disclosure of government information and so forth;",
      96: "6.6 That necessary for signing and performing the contract according to your requirements;",
      97: "6.7 That necessary to maintain the safe and stable operation of the products or services provided, such as finding and handling the faults of products or services;",
      98: "6.8 That necessary for lawful news coverage;",
      99: "6.9 That necessary to carry out statistical or academic research out of public interest, and when the results of academic research or description are provided to the outside world, the personal information contained in the results is to be de-identified.",
      100: "6.10 Transfer for the purpose of completing merger, division, acquisition or asset transfer;",
      101: "6.11 Other circumstances stipulated by laws and regulations.",
      102: "You are requested to understand that the functions and services we provide to you are constantly updated and developed. If a certain function or service is not included in the above description and your information is collected, we will explain the content, scope and purpose of information collection to you through page prompt, interactive process and website announcement separately so as to obtain your consent.",
      103: "Please note that QiCYCLE APP will not obtain your personal information from a third party other than QiCYCLE APP actively at present. If you need to obtain your personal information indirectly from a third party for business development in the future, we will explain to you the source, type and scope of use of your personal information before obtaining it. If the personal information processing activities required by QiCYCLE APP to carry out business exceed the scope of your original authorization and consent when your personal information is provided to a third party, we will obtain your express consent before processing your personal information. In addition, we will strictly abide by relevant laws and regulations, and require the third party to guarantee the legality of the information provided.",
      104: "7. Sensitive personal information",
      105: "We will not provide your sensitive personal information to anyone except with your prior consent.",
      106: "8. Anonymization of personal information",
      107: "After collecting your personal information, we will anonymize the data through technical means in a timely manner. On the condition that your personal information is not to be leaked, QiCYCLE APP has the right to mine, analyze and utilize the anonymized user database (including for commercial use), to make statistics on the use of products/services and share desensitization statistics with the public/third parties.",
      108: "II. How do we use Cookie and similar technologies",
      109: "Cookies are small files containing character strings sent to your computer when you visit the website. When you visit the website again, the Cookie enables the site to identify your browser. Cookies also store user preferences and other information. You can reset your browser to reject all cookies, or prompt when cookies are being sent. But without cookies, some web features or services may not work properly.",
      110: "Cookie and the same type of technologies are common technologies in the Internet. When you use related services of iRiding, we may use relevant technologies to send one or more cookies or anonymous identifiers to your device to collect and store information when you access and use iRiding. We treat information collected through cookies and other technologies as non-personal information. However, if the local law treats an Internet Protocol (IP) address or similar identification marks as personal information, we also treat such identification marks as personal information. We use cookies and similar technologies to achieve the following functions or services:",
      111: "1. Ensure safe and efficient operation of the products and services",
      112: "We may set cookies or anonymous identifiers for authentication and security purposes, so that we can confirm whether you are safe to log in to the service, or whether you encounter illegal acts such as embezzlement and fraud. These technologies will also help us improve service efficiency and login and response speed.",
      113: "2. Help you acquire a more relaxed visit experience",
      114: "Using this kind of technology can help you avoid the steps and processes of repeated completion of personal information and entering search content, and achieve one-click login and record search history.",
      115: "3. Recommend, display and push the contents you may be interested in",
      116: "We may use cookies and similar technologies to learn about your preferences and use habits, and conduct consultation or data analysis to improve product services and user experience.",
      117: "4. Log files",
      118: "As most websites do, we collect specific information and keep it in a log file. Such information may include Internet Protocol (IP) address, browser type, Internet service provider (ISP), reference/exit page, operating system, date/time stamp and/or clickstream data. We don't associate automatically collected log data with the other information we collect.",
      119: "5. Mobile analysis",
      120: "In some mobile applications, we use mobile analysis software to learn better about the functions of our mobile software in your mobile phone. This software may record information such as how often you use the application, events within the application, cumulative usage, performance data, and where the application crash occurs. We will not associate the information stored in the analysis software with any personal information you submit in your mobile application.",
      121: "6. Local storage - HTML5/Flash",
      122: "We use local storage objects (LSO), such as HTML5 or flash, to store contents. The third parties that cooperate with us to provide some functions on QiCYCLE APP, or to advertise according to your browsing behaviors, will also collect and store relevant information through HTML5 or Flash cookie. Various browsers provide their own management tools to delete HTML5 local storage objects.",
      123: "III. How do we update, modify, export and delete your information",
      124: "Pursuant to the laws of some jurisdictions:",
      125: "1. Provide true personal information",
      126: "Prior to your using our products and services, you will be required to provide your true personal information. You should be responsible for the authenticity, legality, validity and integrity of the information you provide, and update and maintain it in time to ensure its authenticity, legality and effectiveness. You can manage your personal information, such as your personal account registration information, at any time.",
      127: "2. Delete content or personal information",
      128: "You can delete your content or personal information from iRiding account by yourself. You can apply for account cancellation through [My]-[Settings] - [About the account] -[Account cancellation]. Within fifteen (15) working days, we will delete or anonymize your personal information.",
      129: "3. Data retention within limited time",
      130: "In some cases, we will keep the data for a limited period of time for legal purposes or legitimate business needs. We will do our best to ensure that the services of iRiding can protect the information from accidental or malicious deletion. Therefore, if you delete something, we may delete its copy from our current and standby systems within fifteen (15) working days.",
      131: "4. Back up content or use it for non-iRiding services",
      132: "You can always back up the contents in your personal account or use it for non-iRiding services. You can contact us by email at privacy@miriding.com and make your request.",
      133: "5. Withdrawal of consent",
      134: "You may withdraw your consent previously granted to us by submitting a request for specific purposes, including collection, use and/or disclosure of your personal information in our possession or control. Depending on the specific service you use, you can visit the account management center of the QiCYCLE APP or send an email to privacy@miriding.com for related operations. We will process your request within a reasonable period of time after you make the request and will not collect, use and/or disclose your personal information upon your request thereafter.",
      135: "Depending on the extent to which you withdraw your consent, you may not be able to continue to enjoy QiCYCLE APP's products or services. However, your decision to withdraw your consent or authorization will not affect the effectiveness of our previous personal information processing activities based on your consent.",
      136: "6. Cancellation of the service or account",
      137: "If you want to cancel a specific product or service, you can cancel your account through [My]- [Settings] - [Account security] - [Cancel account]. After you cancel your account, we will delete or anonymize your personal information.",
      138: "Within fifteen (15) business days, we will delete or anonymize your personal information. Once the account is canceled, it will not be recoverable. You are requested to back up all the information and data related to your iRiding account by yourself before the operation.",
      139: "IV. How do we protect your personal information",
      140: "1. Strict information management policy",
      141: "We have drawn up strict information management policies, equipped with professional technical teams, and have adopted a series of reasonable precautions to protect your personal information from unauthorized browsing, disclosure, abuse, change, destruction and loss, including:",
      142: "(1) Use encryption technology to ensure that your data is kept private during transmission;",
      143: "(2) Provide a variety of security functions to help you protect your account security;",
      144: "(3) Review our practices in the collection, storage and processing of information, including physical security measures, in order to prevent unauthorized access to our systems;",
      145: "(4) All the iRiding employees, contractors and agents who have to contact personal information for the purpose of providing services shall abide by the strict confidentiality obligations stipulated in the contract. Otherwise, they may be punished or fired.",
      146: "2. Technical limitation and malicious means",
      147: "Although the above reasonable and effective measures have been taken and the standards required by relevant laws and regulations have been followed, you are requested to understand that it is impossible to guarantee 100% security of information in the Internet industry all the time due to the limitations of technologies and various malicious means that may exist even if we do our best to strengthen our security measures. We will try our best to ensure the security of the personal information you provide us. You shall confirm that you know and understand that the system and communication network you use to access our services may have problems due to factors beyond our control. Therefore, we strongly recommend that you take positive measures to protect the security of your personal information, including but not limited to using complex passwords, changing passwords regularly, and not disclosing your own account password and other personal information to others.",
      148: "3. Emergency plan",
      149: "We will work out an emergency plan and trigger it immediately in case of any user information security incident, so as to prevent the expansion of the impact and consequences of such security incident. After the unfortunate occurrence of a user information security incident (leakage, loss, etc.), we will inform you of the basic situation and possible impact of the security incident, the measures we have taken or will take to cope with it, the suggestions that you can prevent and reduce the risks on your own, and the remedial measures for you in accordance with the requirements of laws and regulations in time. We will inform you of relevant information of the incident by mail, letter, telephone, push notice, etc. And when it is difficult to inform the subject of personal information one by one, we will take reasonable and effective ways to issue an announcement. At the same time, we will report the handling of the personal information security incident in accordance with the requirements of regulatory authorities.",
      150: "4. What can you do?",
      151: "You shall not disclose your login password or account information to anyone (unless this person is officially authorized by you) and you can set a unique password for QiCYCLE APP to prevent other websites from leaking your password that may endanger the security of your account in QiCYCLE APP. At any time, be sure not to disclose the verification code you receive to anyone (including those who claim to be customer service personnel of QiCYCLE APP).",
      152: "QiCYCLE APP will not be responsible for the security negligence caused by any third party's access to your personal information due to your failure to maintain the privacy of your personal information. Notwithstanding the above provisions, in the event of any other Internet user’s use of your account without authorization or having any other security vulnerabilities, you should immediately inform us.",
      153: "Your assistance will help us protect the privacy of your personal information.",
      154: "V. How do we share, transfer and disclose your personal information",
      155: "1. Sharing",
      156: "We will not sell any personal information to a third party.",
      157: "Sometimes we may share your personal information with third parties in order to provide or improve our products or services, including providing products or services according to your requirements. If you do not want us to share this information any longer, you are requested to send an email to privacy@miriding.com and contact us.",
      158: "1.1 Share out of your active choice",
      159: "Share the information to the extent of your authorization/request with the third party designated by you after your explicit consent or active choice. For example, you can quickly log in to a third-party website or APP by using your QiCYCLE APP account.",
      160: "We will provide social networking services. When you use this type of service, the information you upload or publish (including your public personal information, your address book, your circle of friends), your response to the information uploaded or published by others, as well as the log information related to these information, etc. So long as you do not delete the information, it will always be kept in the public domain, and shared and browsed by other users of our service. Even if you delete the information, it may still be independently cached, copied or stored by other users or unrelated third parties not under our control, or stored in the public domain by other users or third parties.",
      161: "Please consider carefully the information uploaded, published and exchanged through our service. In some cases, you can control the range of users who have the right to browse your shared information through the privacy settings of some of our services. You can delete the information you upload, publish or share by yourself. If you want us to delete your relevant information for you, please contact us by sending an email to privacy@miriding.com and put forward your request, we will finish checking and handling it within fifteen (15) working days after receiving it.",
      162: "1.2 Share information with our affiliated companies",
      163: "We may share your personal information with other related companies of QiCYCLE APP from time to time in order that we can be engaged in our business operation smoothly and provide you with all the functions of products or services.",
      164: "1.3 Share with third party service providers and business partners",
      165: "In order to provide you with the services described in this privacy policy, we may share necessary personal information with third parties such as third party service providers and business partners.",
      166: "They include our data center, data storage facilities, advertising and marketing service providers and other business partners. These third parties may process your personal information on behalf of QiCYCLE APP or for one or more items of this privacy policy. We guarantee to share the personal information necessary for your service only for justifiable, legitimate, necessary, specific and definite purposes. QiCYCLE APP will conduct due diligence and sign a contract to ensure that the third party service provider complies with applicable privacy laws in your jurisdiction. Third party service providers may also have their sub-processors.",
      167: "In order to provide performance measurement, analysis and other commercial services, we may also share information (non-personal information) with third parties (such as our advertisers) in the form of summary. We will use the information that we grasp to help advertisers and other partners assess the effectiveness and coverage of their advertising and services, and understand the types of people who use their services and how people interact with their websites, apps and services. We may also share with them general trends in the use of our services, such as the number of customers in a particular human group who purchase certain products or engage in certain transactions.",
      168: "Third-party SDK name",
      169: "Owned Company",
      170: "Data Collection",
      171: "Purpose of Usage",
      172: "Privacy Policy/Official Website",
      173: "weibo.com SDK",
      174: "sina.com",
      175: "External storage status, latest geographic location information, continuous positioning, network type, network service operator, IMEI, MAC address, WiFi information",
      176: "Weibo login, share",
      177: "https://open.weibo.com/wiki/%E5%BC%80%E5%8F%91%E8%80%85%E5%8D%8F%E8%AE%AE#.E9.9A.90.E7.A7.81.E7.9B.B8.E5.85.B3.E6.9D.A1.E6.AC.BE",
      178: "Xiaomi account open platform SDK ",
      179: "Xiaomi",
      180: "External storage status, network type, network service operator, IMEI, MAC address, WiFi information",
      181: "Xiaomi account login",
      182: "https://dev.mi.com/console/",
      183: "Umeng + SDK",
      184: "Youmeng Tongxin (Beijing) Technology Co., Ltd.",
      185: "Device status and identity and get your IMEI, IMSI information",
      186: "App statistical analysis scenario ",
      187: "https://www.umeng.com/",
      188: "Bugly SDK",
      189: "Tencent",
      190: "Device status and identity and get your IMEI, IMSI information, ANDROID ID",
      191: "Exception report",
      192: "https://bugly.qq.com/v2/index",
      193: "Qiniu Cloud SDK ",
      194: "Shanghai Qiniu Information Technology Co., Ltd.",
      195: "Device status and identity and get your IMEI, IMSI information, external storage status",
      196: "Amap Map SDK",
      197: "https://www.qiniu.com/?ref=developer.qiniu.com",
      198: "Amap Map SDK",
      199: "AMAP",
      200: "External storage status, latest geographic location information, continuous positioning, network type, network service operator, IMEI, MAC address, WiFi information",
      201: "Positioning, map display and poi query",
      202: "https://developer.amap.com/",
      203: "Wechat SDK",
      204: "Tencent",
      205: "External storage status, latest geographic location information, continuous positioning, network type, network service operator, IMEI, MAC address, WiFi information",
      206: "WeChat login, share",
      207: "https://developers.weixin.qq.com/doc/oplatform/Mobile_App/WeChat_Login/Development_Guide.html",
      208: "Xiaomi Push SDK",
      209: "Xiaomi",
      210: "Device status and identity and get your IMEI, IMSI information",
      211: "Push/send notification message",
      212: "https://dev.mi.com/console/",
      213: "QQ SDK",
      214: "Tencent",
      215: "System language, external storage status, latest geographic location information, network location information, continuous positioning, network type, mobile phone number, SIM card serial number, network service operator, IMEI, MAC address, ANDROID ID, IP address, WiFi information, Application installation list, read clipboard",
      216: "QQ login, share",
      217: "https://connect.qq.com/",
      218: "Regarding access to third-party services, we may access to third-party SDK services and share some of your information collected in accordance with this policy with such third-party service providers, so as to improve our customer service and user experience.",
      219: "Currently, the main types of third-party service providers we have access to include the following:",
      220: "(1) Used for message push. When you use our products or services,Xiaomi Push may access your device status and identity and get your IMEI and IMSI information, for pushing/sending notification messages. This information is necessary for the push function,and refusal to provide it will result in your inability to use the function,but will not affect the normal use of other functions;",
      221: "(2) Used to obtain device location permissions and collect device information and log information with your consent;",
      222: "(3) Used for third-party authorization services,including login using third-party accounts and sharing related content to third-party products.，QQ and WeChat SDKs may access your ANDROID_ID and other information;",
      223: "(4) including content storage and positioning.including hardware distribution capability enhancement, server cost reduction, and application exception statistics reporting.Data such as your device information and network location information may be collected;",
      224: "(5) Used to optimize product performance,including hardware distribution capability enhancement, server cost reduction, and application exception statistics reporting. Data such as your device information and network location information may be collected",
      225: "(6) Used for services related to account security and product reinforcement,including network monitoring, domain name resolution, anti-hijacking, anti-spam/anti-cheating, and encryption/decryption services.",
      226: "We will only share your information for justifiable, necessary and specific purposes. For the third-party service providers with whom we share information, we will require them to perform relevant confidentiality obligations and take corresponding security measures.",
      227: "Third-party social media or other services are operated by relevant third parties. Your use of such third party's social media services or other services (including any information you provide to such third party) is subject to the third party's own terms of service and information protection statement (other than this policy), and you shall read the terms carefully. This policy only applies to the personal information we collect, and does not apply to the services provided by any third party or the information use rules of any third party. If you find that there are risks in these third-party social media or other services, you are recommended to terminate relevant operations to protect your legitimate rights and interests.",
      228: "1.4 Miscellaneous",
      229: "QiCYCLE APP may have to disclose your personal information in accordance with the requirements of laws and regulations, legal procedures, lawsuits and/or legal requirements of public institutions and government departments. We may also disclose the information about you if we determine that such disclosure is necessary or appropriate for national security, law enforcement or other matters of public importance.",
      230: "If we determine that the disclosure is reasonably necessary to enforce our terms or to protect our operations, rights, assets or products, or to protect users, or achieve the following purposes (detection, prevention and resolving of fraud, unauthorized use of products, violation of our terms or policies, or other harmful or illegal activities), we may also disclose information about you.(For the avoidance of doubt, QiCYCLE APP will only collect, use or disclose your personal information without your consent to the extent and only under such circumstances as expressly permitted by local data protection laws.) This may include providing information to public or government authorities, exchanging information about the reliability of your account with third-party partners to prevent fraud, violations and other harmful behaviors inside and outside our products.",
      231: "In addition, we may share your personal information with the following persons:",
      232: "(1) Our accountants, auditors, lawyers or similar advisers when we require them to give professional advice;",
      233: "(2) and Investors and other relevant third parties if there is an actual or potential sale or transaction of any other company, and it is related to the QiCYCLE APP entity;",
      234: "(3) and Any other third party, who discloses and shares specific information as authorized by you.",
      235: "2. Transfer",
      236: "Except for the following circumstances, QiCYCLE APP will not transfer your information to any entity:",
      237: "(1) Your express consent has been obtained;",
      238: "(2)(2) If QiCYCLE APP is involved in any merger, acquisition or sale of all or part of its assets, we will notify you of any change in the ownership and use of your personal information, as well as any options you may have regarding your personal information by e-mail and/or on our website in an eye-catching way.",
      239: "3. Public disclosure",
      240: "Except for desensitized display of your QiCYCLE APP account and user name when the list of award-winning activities is announced, QiCYCLE APP will only publicly disclose your personal information under the following circumstances:",
      241: "(1) Your express consent has been obtained;",
      242: "(2) Public disclosure based on laws or reasonable grounds: including laws, regulations, legal procedures, lawsuits or at the request of the competent government authorities.",
      243: "VI. Storage of information",
      244: "In general, we will only retain your personal information for so long as is necessary for our purposes, such as:",
      245: "Mobile phone number: If you need to use QiCYCLE APP services, we need to keep your mobile phone number all the time to ensure your normal use of the services. After you cancel your account, we will delete the corresponding information;",
      246: "Community information: when you send a community share, we need to save your community information so as to ensure that you use the community function normally, and we will delete the corresponding information after you delete your community information.",
      247: "When the operation of our products or services is stopped, we will notify you in the form of push notification, announcement, etc., and delete your personal information or anonymize it within a reasonable period.",
      248: "Since QiCYCLE APP is a global product, some of our activities include a global race of cycling, and we will store some of the data overseas.",
      249: "VII. Third-party service provider",
      250: "1.To facilitate your visit and enrich your experience, there may be products or services provided by third parties. You can choose whether to access such contents or links, or whether to use the third party's products or services. But we have no control over the products or services provided by third parties. We have no control over any of your personal information held by a third party. The information protection problems in the process of using the third party products or services does not apply to the management of this policy. This policy does not apply to any information you choose to provide to third parties, either. Please check the privacy policy of this third party. 。",
      251: "2.Participate in marketing activities. When you choose to participate in our relevant marketing activities, you need to provide your name, address, contact information, bank account number and so on according to the needs of the activities, so that the third party can provide you with prizes in time. When you choose to participate in our marketing activities, you need to provide your name, address, contact information, bank account number and so on according to the needs of the activities. With your express consent, we will share the above information with a third party, so that we can entrust the third party to provide you with prizes in time.",
      252: "VIII. Update of the policy",
      253: "1.We may modify and update this Privacy Policy from time to time. We will release the update of the Privacy Policy via notice or announcement in QiCYCLE APP, and you can visit QiCYCLE APP to search for the latest version of the Privacy Policy. For major changes, we will send you a formal notice (for some services, we will also send a notice by email to elaborate the changes to the Privacy Policy).",
      254: "IX. Applicable scope",
      255: "1.Our privacy policy applies to all the services provided by iRiding (Xiamen) Technology Co., Ltd. and its affiliated companies, including the services provided by QiCYCLE APP and at www.iriding.cc, except for the services with independent privacy policies (such as products and services provided by third parties).",
      256: "2.Our privacy policy does not apply to products and services provided by third parties, such as the products and services provided by third parties in QiCYCLE APP and/or at www.iriding.cc, as well as the other websites linked to our services, which will be regulated by independent privacy policies. Please refer to the corresponding policies and regulations separately.",
      257: "3.Regarding other companies and organizations conducting advertising and publicity for our services and may use our Cookies or other log information to launch and use advertisements, our Privacy Policy does not cover their information processing policies.",
      258: "X. How to Contact Us",
      259: "You can contact us in the following ways:",
      260: "1.If you have any question, opinion or suggestion about this policy, you can feed back to us through the background of QiCYCLE APP and contact us through the background of WeChat's official account of iRiding.",
      261: "2.If you find that your personal information may be leaked, you can complain and report through QiCYCLE APP, and we will contact you and complete the verification and handling within fifteen (15) working days;",
      262: "3.We have also set up a feedback email for comments and suggestions, and you can contact us by emailing to support@miriding.com, and we will contact you within fifteen (15) working days and complete the verification and handling.",
      263: "4.If you have any question about this policy, please contact us by emailing to privacy@miriding.com, and we will contact you and complete the verification and handling within fifteen (15) working days.",
    },
  },
  downLoad: {
    v1: "The QiCYCLE APP",
    v2: "Tecnología logra el placer de ciclismo",
    v3: "Es aplicable en China Continental",
    v4: "Es aplicable en otras regiones",
    v5: "Tienda de aplicaciones",
    v6: "Descarga local",
  },
  index: {
    v1: {
      text0: "iRiding",
      text1: "Página de inicio",
      text2: "Bicilec",
      text3: "Accesorios",
      text4: "App",
      text5: "Soporte",
      text6: "Perfil de empresa",
      text7: "Nacer",
      text8:
        "En 2011, iRiding (Xiamen) Technology Co., Ltd. se fundó en Xiamen.",
      text9: "Correr",
      text10:
        "En 2013, se lanzó oficialmente el software social de de ciclismo APP1.0 para QiCYCLE.",
      text11:
        "Después de dos años, la cantidad de instalación y registro de usuarios ha excedido un millón, convirtiéndola en el software de ciclismo preferido para los usuarios de ciclismo.",
      text12:
        "Hasta ahora, el kilometraje de ciclismo total de los usuarios es de 130 millones de kilómetros, equivalente a 175 veces de ida y vuelta de la Tierra a la Luna.",
      text13: "Acelerar",
      text14:
        "En septiembre de 2014, se convirtió en una empresa de la cadena ecológica Xiaomi.",
      text15:
        "iRiding se dedicó a la investigación y desarrollo de hardware, y lanzó sucesivamente una variedad de productos como la bicicleta de carretera de fibra de carbono R1 conforme a lo exigido del Tour de Francia, la bicilec plegable Mi iRiding, la bicicleta Mitu para niños, el inflador Mi, etc. ",
      text16: "Zarpar",
      text17:
        "En 2019, la bicilec plegable Mi y el inflador Mi se exportaron uno tras uno.",
      text18:
        "Actualmente los productos QiCYCLE se venden en más de diez países y regiones de Asia, Europa, Oceanía y Suramérica.",
      text19:
        "En 2020, se lanzaron la bicilec QiCYCLE y sus accesorios, iniciando un nuevo viaje para sus propias marcas. ",
      text20: "Sedimentar",
      text21:
        "En el futuro, iRiding seguirá concentrándose en la investigación y desarrollo de productos deportivos y de viaje orientados al futuro, y ofrecerá a los usuarios una mejor experiencia de viaje a través de su sólida capacidad de investigación, desarrollo y diseño de productos.",
      text22: "Historia",
      text23:
        "Se completó la certificación para la bicilec plegable hecha en Taiwán conforme al nuevo estándar europeo, y este producto se comenzó a vender en Europa en agosto",
      text24:
        "EF1 se convirtió en la primera bicilec de marca china que completó la certificación EN15194-2017 y se vendió en Europa",
      text25:
        "Afectada por la pandemia y los subsidios a compras de Europa, la venta de bicicletas está en auge y los pedidos de bicilec plegable Mi aumentan mes a mes",
      text26:
        "El inflador Mi con un precio de venta de 199 se comenzó a vender en el sitio web de Xiaomi",
      text27:
        "La 「bicicleta Mitu para niños de 14 pulgadas」 se comenzó a vender en Xiaomi Mall, y obtuvo el premio alemán de diseño Red Dot 2020",
      text28:
        "La bicilec QiCYCLE conforme al nuevo estándar nacional se comenzó a vender en el sitio web de Xiaomi, y obtuvo 18 patentes relacionadas con la tecnología de bastidor para bicicletas, etc.",
      text29:
        "Se vendieron 51800 bicilecs Mi QiCYCLE en China, con una venta acumulada de 135 millones de yuanes, creando así una nueva categoría de bicilecs en el comercio electrónico nacional ",
      text30:
        "Ocupó el primer lugar de venta de producto individual durante 2 años consecutivos, y representó el 91.3% de la cuota de mercado en 2017",
      text31: "iFixit desmontó la bicilec Mi e hizo un alto elogio",
      text32:
        "La empresa iRiding fue reconocida como empresa con ventajas de propiedad intelectual de diseño industrial de la ciudad por la Oficina de Propiedad Intelectual de Xiamen",
      text33:
        "Se desarrolló una aplicación de postventa para resolver necesidades reales como piezas de repuesto, orientación de reparación, liquidación de gastos, etc.",
      text34:
        "Prestará servicio postventa para productos iRiding mediante la firma de contratos con 272 socios urbanos de iRiding",
      text35:
        "La bicilec plegable Mi fue lanzada por Liu De, cofundador de Xiaomi, con elogio de Lei Jun, CEO de Xiaomi",
      text36:
        "Primera aplicación de la tecnología de forja de aleación de aluminio en bicicletas",
      text37: "Primera aplicación de la tecnología de Can bus en bicicletas",
      text38:
        "Obtuvo 11 patentes relacionadas con bicicletas como la bicicleta plegable vertical",
      text39: "El sensor de par TMM se produjo en masa por primera vez",
      text40:
        "Crowdfunding de la bicicleta de potencia de fibra de carbono QiCYCLE R1 en el sitio web de Xiaomi",
      text41: "QiCYCLE R1 ganó dos premios de diseño Red Dot",
      text42:
        "R1 ganó el premio de innovación de bicicletas de Taipei debido a su completa capacidad de adquisición de datos deportivos",
      text43:
        "iRiding se convirtió en un miembro de la cadena ecológica Xiaomi, responsable del desarrollo e investigación de productos de hardware inteligentes y de la distribución ecológica en la línea de desplazamientos diarios",
      text44:
        "La empresa iRiding cambió su nombre a iRiding (Xiamen) Technology Co., Ltd.",
      text45: "iRiding obtuvo la financiación de la ronda de ángeles de Xiaomi",
      text46: "iRiding se unió a la cadena ecológica Xiaomi",
      text47:
        'Se desarrolló el software de ciclismo App para "QiCYCLE" y se puso en servicio',
      text48:
        "La cantidad de usuarios de App para QiCYCLE ha excedido 1.24 millones",
      text49:
        "La trayectoria de ciclismo acumulada de los usuarios de iRiding ha alcanzado 65 millones de kilómetros, convirtiéndola en la comunidad de ciclismo más grande del país",
      text50: "iRiding (Xiamen) Technology Co., Ltd. se fundó en Xiamen",
      text51: "Productos ingeniosos",
      text52: "Diseño innovador",
      text53:
        "Los productos han ganado el premio alemán de diseño Red Dot, el premio de diseño innovador iF y varias patentes de invención",
      text54: "Concentrarse en la investigación y desarrollo",
      text55:
        "El equipo de investigación y desarrollo dirigido por miembros a nivel de experto industrial se enfoca en los detalles y promueve continuamente la innovación tecnológica clave",
      text56: "Estricto control de calidad",
      text57:
        "Varios procesos de detección de calidad para controlar la calidad de bicicletas completas en todos los pasos y brindar a los usuarios productos de alta calidad",
      text58: "Postventa eficiente",
      text59:
        "Seguimos explorando modos de servicio fuera de línea más íntimos para que los usuarios obtengan una mejor experiencia de productos",
      text60: "Dirección de la empresa",
      text61: "Las siguientes ciudades",
      text62: "Beijing",
      text63:
        "No. 16, Anningzhuang East Road, distrito de Haidian, Beijing, edificio 2, distrito este, Rongke Rongzhi · Fábrica de nido de abeja",
      text64: "Changzhou",
      text65:
        "No.89, Avenida de Chuangxin, Distrito de Xinbei, Ciudad de Changzhou, Provincia de Jiangsu",
      text66: "Shanghai",
      text67:
        "104B, Edificio G, No.142, Calle de Tianlin, Distrito de Xuhui, Ciudad de Shanghai",
      text68: "Shenzhen",
      text69:
        "Yuexingsidao No. 1, Plaza de Industria-Academia-Investigación de Shenzhen de la Universidad de Sun Yat-sen, No.1708, Comunidad del Distrito Gaoxin, Calle Yuehai, Distrito Nanshan, ciudad de Shenzhen de la provincia de Guangdong, China",
      text70: "Frankfurt",
      text71:
        "Adam-Opel-Str. 10 im Hof, Geb. A, bei eKey 60386 Frankfurt am Main,Alemania",
      text72: "Xiamen",
      text73:
        "Sala 403, No.768, Calle Norte de Qishan, Distrito de Huli, Ciudad de Xiamen, Provincia de Fujian",
      text74: "Hong Kong",
      text75:
        "Sala 1102, Edificio Hang Seng Castle Peak Road, No.339, Calle de Castle Peak, Cheung Sha Wan, Kowloon, Hong Kong",
      text76: "Suzhou",
      text77:
        "606, Plaza de Comercio Internacional de Alta Tecnología de Suzhou, Nº 28, Calle de Shishan, Zona Nacional de Desarrollo Industrial de Alta Tecnología, Ciudad de Suzhou, China",
      text78: "Redes sociales",
      text79: "Línea directa de servicio postventa:",
      text80: "Contactar con nosotros",
      text81: "Cooperación empresarial",
      text82: "Comercialización nacional",
      text83: "Comercialización internacional",
      text84: "Servicio de atención al cliente",
      text85: "Protección de datos",
      text86: "iRiding © 2013-2021 M.ICPB.Nº13003500",
      text87: "Privacidad y leyes",
      text88: "Su preferencia de Cookies",
      text89: "¡Bienvenido a iRiding!",
      text90:
        "Para ofrecerle una mejor experiencia en el sitio web, esperamos utilizar Cookies para mejorar nuestro sitio web.",
      text91: "La información recogida por Cookies no le identificará a usted.",
      text92:
        "Para los tipos de Cookies que utilizamos y más información sobre nuestras Cookies, lea nuestra política de Cookies.",
      text93: "Rechazar",
      text94: "Aceptar",
      text100:
        "Para ofrecerle una mejor experiencia en el sitio web, esperamos utilizar Cookies para mejorar nuestro sitio web.La información recogida por Cookies no le identificará a usted.Para los tipos de Cookies que utilizamos y más información sobre nuestras Cookies, ",
      text101: "lea nuestra política de Cookies",
      text102: "iRiding",
      text103: "Información legal",
      text104: "QiCYCLE",
    },
  },
  product: {
    v1: {
      productMove: "Explorar más",
      productCpd: "QiCYCLE bomba de airé eléctrica P1",
    },
  },
  contact: {
    // 西班牙文
    v1: "Contactar con nosotros",
    v2: "Cooperación empresarial",
    v3: "Correo electrónico: market@iriding.com",
    v4: "Equipo de ventas",
    v5: "Comercialización nacional: sales@iriding.com",
    v6: "Comercialización internacional: sales@iriding.com",
    v7: "Servicio de atención al cliente",
    v8: "Línea directa de servicio postventa: 400-1144-177",
    v9: "Protección de datos",
    v10: "Correo electrónico: dpo@iriding.com",
  },
  details: {
    v1: "Inflador eléctrico portátil QiCYCLE P1",
    v2: "Color",
    v3: "Compra ahora",
    v4: "Descripción",
    v5: "Características tecnicas",
    v6: "Servicio Técnico",
    v7: "Inflado con precisión y rapidez ",
    v8: "Infla rápidamente el neumático en 60 segundos ",
    v9: "Presión de inflado de hasta 150 psi.",
    v10: "Infla el neumático completamente en unos 5 minutos",
    v11: "Parada automática al alcanzar la presión preestablecida",
    v12: "5 modos de uso",
    v13: "Banco de energía de emergencia",
    v14: "Tan poderoso que supera tu imaginación",
    v15: "Movimiento de alto rendimiento y motor de alta potencia.",
    v16: "Hinchado de un neumatico desinchado en unos 5 minutos",
    v16a: "Hinchado de un neumatico desinchado en unos 5 minutos",
    v1611: "Puede inflar alrededor de",
    v1611a: "Puede inflar alrededor de ",
    v1621: "5",
    v1631: "minutos",
    v1631b: "neumáticos de bicicleta",
    v1711: "toma unos",
    v1711a: "toma unos",
    v1721: "neumáticos de bicicleta,",
    v1721a: "minutos por neumático",
    v17: "Puede inflar alrededor de 5 neumáticos de bicicleta , ",
    V999: "toma unos 5 minutos por neumático",
    v18: "Pequeña y fácil de almacenar",
    v19: "Tamaño de la máquina alrededor de 20cm",
    v20: "Fácil de controlar con una sola mano.",
    v21: "Pequeño y flexible para fácil almacenamiento.",
    v215: "Negro clásico",
    v22: "Permite viajar con una carga más ligera ",
    v23: "Cilindro de alta precisión, ",
    v611: "Carga del adaptador USB",
    v612: "Carga del cargador de coche",
    v613: "Batería recargable",
    v231: "alta velocidad y alta presión de aire",
    v24: "Presión de inflado de hasta 150 psi,",
    v24a: "Presión de inflado de hasta 150 psi",
    v25: "Satisface la alta presión de aire necesaria para.Los neumáticos de carretera.",
    v26: "Inflación sin mayor esfuerzo.",
    v261: "Una carga completa puede inflar",
    v262: "16",
    v263: "neumáticos de bicicleta,",
    v2631: "neumáticos de bicicleta",
    v27: "Una carga completa puede inflar 16 neumáticos de bicicleta, ",
    v9971: "toma unos ",
    v9972: "63",
    v9973: " segundos por neumático",
    v997: "toma unos 63 segundos por neumático",
    v272: "Presión preestablecida, ",
    v271: "carga precisa y parada automática",
    v28: "Ajuste la presión de aire según sea necesario",
    v29: "Parada automática al completar el inflado",
    v30: "Medida precisa de presión en cualquier momento y en cualquier lugar",
    v31: "Precisión del sensor de presión de aire",
    v311: " ±1psi",
    v312: "Precisión del sensor de presión de aire±1psi",
    v32: "6. Cambia fácilmente entre los 5 modos de uso",
    v33: "Coche, motocicleta, bicicleta, pelota, modo profesional.",
    v34: "Presión de aire limitada",
    v35: "Use el modo de inflado para garantizar su seguridad.",
    v361: "Modo coche",
    v3611: "Predeterminado：2.5bar",
    v3612: "Predeterminado：2.4bar",
    v362: "Predeterminado",
    v363: "2.5bar",
    v364: "2.4bar",
    v37: "Rango ajustable:1.8-3.5bar:1.8-3.5bar",
    v371: "1.8-3.5bar",
    v3711: "Rango ajustable:1.8-3.0bar",
    v38: "Modo de motocicleta",
    v381: "Predeterminado 2.4bar",
    v39: "Rango ajustable",
    v451: "Predeterminado:3-150psi",
    v451a: "3-150psi",
    v451b: "0.2-10.3bar",
    v391: "1.8-3.0bar",
    v40: "Modo de bicicleta",
    v2121: "Pruebas rigurosas,",
    v2122: "para una calidad duradera",
    v41: "Rango ajustable",
    v411: "45psi",
    v998: "",
    v4111: "Predeterminado：45psi",
    v581: "No es compatible con el uso continuo durante más de 35 minutos.",
    v412: "Rango ajustable",
    v4121: "Rango ajustable:30-145psi",
    v462: "Rango ajustable: 0.2-10.3bar",
    v413: "30-145psi",
    v42: "Modo balón",
    v216: "Buena apariencia",
    v423: "Rango ajustable:4-16psi",
    v422: "Predeterminado:8psi",
    v421: "8psi",
    v43: "4-16psi",
    v44: "Modo profesional",
    v45: "Rango ajustable",
    v46: "0,2-10,3 bares",
    v461: "3-150psi / 0.2-10.3bares",
    v47: "Batería de litio 18650 incorporada",
    v48: "Control sin ataduras",
    v49: "Te acompaña tanto al campo como a la montaña,",
    v50: "Sin necesidad de alimentación externa",
    v51: "Inflado sin complicaciones.",
    v52: "Detalles exquisitos,para alegrar tu vida",
    v53: "Iluminación autónoma, fácil de usar",
    v54: "Iluminación LED, fácil de usar durante la noche.",
    v55: "Luz SOS, te permite estar listo para cualquier emergencia.",
    v56: "Doble disipación de calor, poder continuo",
    v57: "Ventilador de alta potencia, diseñado con orificios de enfriamiento.",
    v58: "No es compatible con el uso continuo durante más de 40 minutos.",
    v59: "Es una bomba y un banco de energía",
    v60: "Puerto USB tipo C universal, carga sin preocupaciones.",
    v61: "Puerto USB-A para cargar su teléfono.",
    v62: "Pantalla grande digital, visualización en tiempo real",
    v63: "La pantalla grande de alta definición de 4,4 pulgadas muestra la presión actual de los neumáticos, la presión de los neumáticos preestablecida, el modo de uso y otros datos en tiempo real.",
    v64: "Accesorios incluidos, almacenamiento fácil ",
    v65: "Bolsa de almacenamiento para proteger la bomba.",
    v66: "La bolsa puede almacenar boquillas de aire, agujas y cables de carga.",
    v67: "Pasó una serie de pruebas rigurosas",
    v68: "Prueba de protección contra sobre presión",
    v69: "Prueba de resistencia a la tensión de la manguera de aire",
    v70: "Prueba de fuerza eléctrica",
    v71: "Prueba de flexión de manguera a baja temperatura",
    v72: "Prueba de caída libre",
    v73: "Prueba de tiempo de uso continuo",
    v74: "Prueba de comprensión",
    v75: "Prueba de carga de alta/baja temperatura",
    v76: "Referencia de rendimiento de Inflador eléctrico portátil QiCYCLE P1",
    v77: "1 barra = 14,5 psi = 100 kPa",
    v78: "Objeto inflable",
    v79: "Llantas de la bicicleta ",
    v80: "175/70 R14 (inflado)",
    v81: "Wuling Hongguang",
    v82: "(175/70 R14)",
    v83: "Buick Hinlang",
    v84: "195/65 R16)",
    v85: "VW Golf",
    v86: "(205/55 R16)",
    v87: "Toyota Camry",
    v88: "(215/55 R17)",
    v89: "Land Rover Range Rover",
    v90: "(255/55 R20)",
    v91: "BMW 730",
    v92: "(275/40 R19)",
    v93: "Llantas de moto",
    v94: "(150/60 R17)",
    v95: "Llantas de moto",
    v96: "(110/70 R17)",
    v97: "Neumáticos de bicicleta de carretera",
    v98: "(700 × 25C)",
    v99: "Neumáticos de bicicleta de montaña",
    v100: "(27.5 × 1.95)",
    v101: "Neumáticos para scooter eléctrica",
    v102: "(8 1/2 x 2)",
    v103: "Equilibrado de neumáticos de coche",
    v104: "(9 x 3.0-6)",
    v105: "Baloncesto No. 7",
    v106: "Fútbol No. 5",
    v107: "Voleibol No. 5",
    v108: "Presión de inflado",
    v109: "2.0-2.5bar",
    v110: "0-2.5bar",
    v111: "0-2.4bar",
    v112: "0-120psi",
    v113: "0-50psi",
    v114: "0-40psi",
    v115: "0-30psi",
    v116: "0-9psi",
    v117: "0-11psi",
    v118: "0-6psi",
    v119: "Cantidad de inflación",
    v120: "≈ 18",
    v121: "≈ 5",
    v122: "≈ 4",
    v123: "≈3",
    v124: "≈2",
    v125: "≈7",
    v126: "≈15",
    v127: "≈16",
    v128: "≈20",
    v129: "≈201",
    v130: "≈97",
    v131: "≈92",
    v132: "≈86",
    v133: "≈197",
    v134: "Velocidad de inflado",
    v135: "≈ 60 segundos/llanta",
    v136: "≈ 4 minutos 58 segundos/llanta",
    v137: "≈ 6 minutos 3 segundos/llanta",
    v138: "≈ 5 minutos 52 segundos/llanta",
    v139: "≈ 7 minutos 20 segundos/llanta",
    v140: "≈ 12 minutos 30 segundos/llanta",
    v141: "≈ 9 minutos 10 segundos/llanta",
    v142: "≈ 2 minutos 50 segundos/llanta",
    v143: "≈ 1 minutos 30 segundos/llanta",
    v144: "≈ 63 segundos/llanta",
    v145: "≈ 58 segundos/llanta",
    v146: "≈ 10 segundos/llanta",
    v147: "≈ 14 segundos/llanta",
    v148: "≈ 18 segundos/llanta",
    v149: "≈ 20 segundos/llanta",
    v150: "≈ 10 segundos/llanta",
    v1510: "[1] ",
    v1520: "[2] ",
    v1530: "[3] ",
    v1540: "[4] ",
    v1550: "[5] ",
    v1560: "[6] ",
    v1570:
      "Cuando el producto está completamente cargado.Puede inflar neumáticos de coche 18 175/70 R14 de 2,0 bar a 2,5 bar.Llena un neumático en unos 60 segundos.",
    v1580:
      "Cuando el producto está completamente cargado.Puede inflar unos 5 neumáticos de coche 175/70 R14 de 0 bar a 2,5 bar.Aproximadamente 5 minutos para llenar un neumático.",
    v1590:
      "El modo de mantenimiento de presión se debe utilizar con herramientas como la pluma de pintura en aerosol, la olla PA, el gato de la bolsa de aire, etc.Por favor cómprelo por separado.",
    v1591:
      "El parámetro de salida del puerto USB-A es 5V⎓2A.Se puede utilizar como banco de energía de 5000 mAh.",
    v1592:
      "Estos ocho datos de prueba son de la sucursal de Shenzhen de SUD Certification and Testing (China) Co. Ltd. y iRiding Laboratory.Número de informe de prueba:QJSZ20210731.01,QJSZ20210806.01,QJSZ20210729.01,QJSZ20210831.01,QJSZ20210519.01,QJSZ20210803.01,68.189.21.0074.01.",
    v1593:
      "Los datos de la tabla son los datos de prueba en el estado de carga completa.Los resultados reales pueden variar ligeramente según factores como el entorno, la temperatura, el consumo de energía y los hábitos de uso.",
    v1594:
      "Aviso Los datos en esta página, a menos que se especifique lo contrario, provienen de los laboratorios de iRiding.",
    v151: "Cuando el producto está completamente cargado",
    v152: "Puede inflar neumáticos de coche 18 175/70 R14 de 2,0 bar a 2,5 bar",
    v153: "Llena un neumático en unos 60 segundos",
    v154: "Cuando el producto está completamente cargado",
    v155: "Puede inflar unos 5 neumáticos de coche 175/70 R14 de 0 bar a 2,5 bar",
    v156: "Aproximadamente 5 minutos para llenar un neumático",
    v157: "El modo de mantenimiento de presión se debe utilizar con herramientas como la pluma de pintura en aerosol, la olla PA, el gato de la bolsa de aire, etc.",
    v158: "Por favor cómprelo por separado",
    v159: "El parámetro de salida del puerto USB-A es 5V⎓2A",
    v160: "Se puede utilizar como banco de energía de 5000 mAh.",
    v161: "Estos ocho datos de prueba son de la sucursal de Shenzhen de SUD Certification and Testing (China) Co., Ltd. y iRiding Laboratory",
    v162: "Número de informe de prueba",
    v163: "Los datos de la tabla son los datos de prueba en el estado de carga completa",
    v164: "Los resultados reales pueden variar ligeramente según factores como el entorno, la temperatura, el consumo de energía y los hábitos de uso.",
    v165: "Aviso",

    v166: "Los datos en esta página, a menos que se especifique lo contrario, provienen de los laboratorios de iRiding.",
    v167: "  ",
    v168: "Manual de instrucciones×1",
    v169: "Bolsa de Almacenamiento×1",
    v170: "Manguera de aire de alta presión1",
    v171: "Cable de Carga×1",
    v172: "Adaptador de Válvula Aguja×1",
    v173: "Adaptador de Válvula Presta×1",
    v174: "Inflador eléctrico portátil QiCYCLE P1×1",
    v175: "Lista de paquetes",
    v176: "Presurización automática",
    v177: "Innovadores modelos de retención de la presión",
    v178: "La presurización se inicia inmediatamente por debajo del valor ajustado,Mantenimiento automático de la presión del recipiente,Pintar, regar, lavar el coche,Fácil y sin complicaciones.",
    v191: "Mantenga pulsado el botón de la luz tras el encendido.",
    v192: "y pulse「+」clave，hasta",
    v193: "iconos comparecencias.",
    v194: "Nombre del producto",
    v195: "Inflador eléctrico portátil QiCYCLE P1",
    v196: "Tipo de producto",
    v197: "QJCQBP1",
    v198: "Tamaño del producto",
    v199a: "200,5 × 63,5 × 63,5 mm",
    v199b: "(dimensiones desnudas, sin manguera de aire)",
    v200: "Rango de Presión de Inflado",
    v201: "3-150psi / 0.2-10.3bar",
    v202: "Tipo de batería:",
    v203: "Ion de litio",
    v204: "Capacidad de La Batería:",
    v2041: "28.86 Wh / 11.1V",
    v205: "28.86Wh/11.1V",
    v206: "Precisión del Sensor",
    v207: "±1.5 psi",
    v208: "Tiempo de Carga",
    v209: "Menos de 4 horas",
    v210: "",
    v211: "",
    v212: "Pruebas rigurosas para una calidad duradera",
    v213: "Variedad de escenas,libertad para cambiar",
    v2131: "Variedad de escenas",
    v2132: "libertad para cambiar",
    v214: "Libre de ataduras,",
    v214a: "Libre de ataduras",
  },
  C2: {
    v0: "QiCYCLE C2",
    v1: "Bicicleta con asistencia eléctrica QiCYCLE C2",
    v1m: "Bicicleta con asistencia eléctrica<br/> QiCYCLE C2",
    v2: "Paseando por la ciudad <br/> libertad más allá de tu imaginación",
    v2a: "libertad más allá de tu imaginación",
    v3: "Color",
    v4: "Negro",
    v5: "Liviana y compacta",
    v6: "Faros inteligentes",
    v7: "Batería de larga duración",
    v8: "Potente batería de litio",
    v9: "Cambio de ocho velocidades",
    v10: "Gran pantalla de luz",
    v11: "Con telescopio plegable",
    v12: "1",
    v13: "Resumen",
    v14: "Especificaciones",
    v14a: "Manual del producto",
    v15: "Soporte",
    v16: "Apariencia elegante y atractiva <br/> La dirección en todas partes",
    v18: "Diseño clásico con forma elegante <br/> una buena y llamativa herramienta para todo el camino.",
    v19: "Disfruta de una experiencia de conducción cómoda",
    v20: "Diseño ergonómico",
    v21: "La estructura ergonómica del cuadro es cómoda y funcional ayudándote a ser uno con la bicicleta.",
    m21a: "La estructura ergonómica del cuadro es cómoda y funcional",
    m21b: "ayudándote a ser uno con la bicicleta.",
    v22: "*Las bicicletas eléctricas deben ser fotografiadas para su uso en carretera. Por favor, respete las normas de tráfico y circule con seguridad.",
    v23: "Cuerpo ultraligero <br/> fácil de manejar",
    v24: "Cuadro multicavidad",
    v24p: "fabricado en aleación de aluminio de calidad  aeronáutica con dirección flexible, de manejo ligero y fácil de mover en espacios pequeños.",
    v24c: "Cuadro multicavidad &nbsp;&nbsp;<sup style='position: absolute;color: rgb(255, 255, 255) !important;margin-left: -0.27rem;margin-top: -0.05rem;margin-right: 0.08rem;'class='font15'>「1」</sup>&nbsp;fabricado en aleación de aluminio de calidad  aeronáutica con dirección flexible, de manejo ligero y fácil de mover en espacios pequeños.",
    v25: "Con un cuerpo ligero <br/> puede transportar peso pesado",
    v25m: "Con un cuerpo ligero puede transportar peso pesado",
    v26: "La bicicleta pesa alrededor de 20 kg, puede soportar con seguridad 120 kg y ser conducido por hombres, mujeres y niños.<sup style='font-size:0.15rem' :style=' ios? 'position: absolute;margin-top: -0.05rem;margin-left: -0.1rem;': 'position: absolute;margin-top: -0.05rem;margin-left: 0;''>「2」</sup>",
    v26d: "  .",
    v26c: "*Accesorios adicionales:  cesta frontal, caja trasera, sillín de esponja y asiento para niños.",
    m26a: "La bicicleta pesa alrededor de 20 kg, puede soportar con seguridad 120 kg y ser conducido por hombres,",
    m26b: "mujeres y niños .",
    v27: "Con asistencia de gran efectividad y potencia,",
    v27a: " para disfrutar de una agradable conducción",
    v28: "Sensor de alta precisión",
    v29: "Conocer cada pedalada",
    v30: "Sensor de torque de eje central incorporado, puede obtener precisamente los datos de pedaleo y acelerar la velocidad eficientemente, el automóvil se puede mover como lo quieras.",
    m30a: "Captura con precisión los datos de pedaleo,",
    m30b: "mejora la aceleración y la eficiencia.",
    v31: "C2 PRO",
    v32: "Sensores de par",
    v33: "C2",
    v34: "Sensores de velocidad",
    v35: "1.Mostrar los datos del viaje",
    v36: "2.Control de la salida del booster",
    v37: "3.Sensores de fuerza de pedaleo",
    v38: "4.Recorrido del motor de accionamiento",
    v39: "Selección de varias marchas <br/> para múltiples escenarios",
    v40: "Cambia libremente a cualquiera de los 3 modos de asistencia eléctrica batería de duración larga de hasta 60 km",
    v40m: "Cambia libremente a cualquiera de los 3 modos de asistencia eléctrica batería de duración larga de hasta 60 km<sup style='margin: -0.05rem 0 0 -0.1rem' class='font15 positionA color3A'>「3」</sup>",
    v40c: ".",
    v41: "Mano de obra:",
    v42: "Con asistencia eléctrica:",
    v43: "Potencia eléctrica",
    v44: "1a marcha",
    v45: "Bajo consumo de energía y larga duración de la batería",
    v46: "2a marchas",
    v47: "Combinación de autonomía y potencia",
    v48: "3a marchas",
    v49: "Aceleración potente y rápida",
    v50: "Botón de apoyo al caminar",
    v51: "Cuando está en la subida, es fácil de bajar el automóvil.",
    v52: "Cambio de ocho velocidades",
    v52d: "Cambio de ocho velocidades",
    v52c: ",",
    v52a: " precisa y suave",
    v53: "Cambia libremente a cualquiera de las 8 velocidades. Con ayuda de la asistencia eléctrica, desafía al `diablo` yendo cuesta arriba y contra el viento.",
    m53a: "Cambia libremente a cualquiera de las 8 velocidades. Con ayuda de la asistencia eléctrica, ",
    m53b: "desafía al `diablo` yendo cuesta arriba y contra el viento.",
    v54: "Cambio de ocho velocidades, precisa y suave",
    v55: "Recorre la ciudad",
    v56: "Su seguridad me preocupa",
    v57: "Batería de litio tecnología  <br/> negra con compartimiento <br/> de batería independiente",
    v57m: "Batería de litio tecnología negra con compartimiento de batería independiente",
    v58: "Batería de litio de 30 unidades con el circuito <br/> y la batería aislados de forma segura para <br/> garantizar la seguridad eléctrica.",
    v58m: "Batería de litio de 30 unidades con el circuito y la batería aislados de forma segura para garantizar la seguridad eléctrica.",
    v59: "*Este producto tiene una batería incorporada.",
    v60: "No lo desmonte usted mismo.",
    v61: "Gestión de energía de grado automotriz",
    v62: "Sistema de gestión de seguridad BMS de grado automotriz, que detiene la carga de la batería cuando está completa, para garantizar su seguridad.",
    m62a: "Sistema de gestión de seguridad BMS de grado automotriz, que detiene la carga de la batería cuando está completa,",
    m62b: " para garantizar su seguridad.",
    v63: "Prueba de capacidad a baja temperatura",
    v64: "Prueba de capacidad a alta temperatura",
    v65: "Prueba IPX5",
    v66: "Prueba de caída libre",
    v67: "Protección contra sobrecarga",
    v68: "Protección contra sobrecarga",
    v69: "Prueba de choque de aceleración",
    v70: "Protección contra cortocircuitos",
    v71: "Pruebas de impacto a alta y baja temperatura",
    v72: "Pruebas de resistencia a las vibraciones",
    v73: "Prueba de alta temperatura a 130°C",
    v74: "Capacidad de retención de carga",
    v75: "Los botones y palancas están a mano",
    v76: "Las funciones son claras, el control es simple, fácil de usar para los ancianos.",
    v77: "Pantalla central sensible a la luz",
    v78: "El ajuste inteligente del brillo de la pantalla, la marcha, la potencia y los datos de velocidad son visibles, claros de día y no cegadores de noche.",
    v78a: "son visibles, claros de día y no cegadores de noche.",
    m78a: "El ajuste inteligente del brillo de la pantalla, la marcha, la potencia y los datos de velocidad",
    m78b: "son visibles, claros de día y no cegadores de noche.",
    v79: "Sensor de luz automático <sup style='position: absolute;color: rgb(255, 255, 255) !important;margin-top: 0.05rem;'class='font15'>「5」</sup>",
    v80: "Control sensible a la luz de los interruptores de las luces delanteras y traseras.",
    v81: "Se enciende o se apaga automáticamente.",
    m81a: "Se enciende o se apaga automáticamente.Luz trasera",
    v82: "Luz trasera de freno en alto.",
    m82a: "de freno en alto.",
    v83: "Conducir sin distracciones.",
    v84: "Conducción más segura.",
    v85: "Agregue o elimine los accesorios",
    v85a: " para crear su propio estilo",
    v85m: "Agregue o elimine los accesorios para crear su propio estilo",
    v86: "Emprendedores, decoradores, mamás a la moda …...<br/> agregue o elimine accesorios para completar su propio estilo.",
    m86a: "Emprendedores, decoradores, mamás a la moda …...agregue o elimine",
    v86b: "accesorios para completar su propio estilo.",
    v87: "Añadir y quitar accesorios",
    v88: "Conéctate a la aplicación Ride Notes",
    v89: "Documentar momentos de la vida",
    v90: "Acceda a la aplicación QiCYCLE, muestre y registre datos como velocidad, distancia recorrida, etc. y comparta la vida en bicicleta",
    m90a: "Acceda a la aplicación QiCYCLE, muestre y registre datos como velocidad, distancia recorrida, etc. y comparta la vida en bicicleta",
    v91: "",
    v92: "Conéctate a la aplicación Ride Notes",
    v93: "Diseño ingenioso para crear QiCYCLE",
    v94: "Motor sin escobillas de imán permanente",
    v95: "Desencadenar una oleada de energía",
    v96: "Ruedas con 20 pulgadas de diámetro",
    v97: "Disfruta de una conducción flexible",
    v98: "Telescopio plegable",
    v99: "Sé el viajero en el maletero",
    v100: "Frenos de disco delanteros y traseros",
    v101: "Los frenos son estables a cualquier velocidad",
    v102: "Abrazadera para tija de sillín de ajuste rápido",
    v103: "Tú decides cuán alto o cuán bajo",
    v104: "Neumático Destacado",
    v105: "tan estable como una montaña",
    //v106: "[1]    Organismo de certificación CCC: Centro de Certificación de Calidad de China, número certificado:2021011119391871",
    v107: "[1]    El cuadro de bicicleta multicavidad ha obtenido una serie de patentes de modelos de utilidad, los números de patente son ZL201820720214.X,ZL201822033605.7,ZL201821913718.X.",
    v108: "[2]    Este producto es adecuado para una altura de 155-185 cm.",
    v109: "[3]    Condiciones de prueba de resistencia a 60 km: en modo de asistencia 2, con las luces apagadas, una carga de 70kg, circulando por pavimento urbano a una velocidad media de 20 km/h.La duración real de la batería variará ligeramente según factores como la carga, temperatura, condiciones de la carretera y los hábitos de funcionamiento.",
    v110: "[4]    Los datos provienen del centro de pruebas de Fujian Scud Power Technology Co., Ltd.Reporte número DC210073",
    v111: "[5]    En circulación, las luces delanteras y traseras se encienden/apagan automáticamente según los cambios en la luz ambiental.",
    v112: "[6]    Accesorios adicionales:  cesta frontal, caja trasera, sillín de esponja y asiento para niños.",
    // v113:   "[8]    延保服务详情请拨打售后热线 400-1144-177。",
    v114: "[7]    Ajuste la altura del sillín entre las líneas de seguridad más alta y más baja de la tija.",
    v115: "[8]    El dibujo de los neumáticos mostrado en esta página es solo de referencia. Debido a los diferentes lotes de neumáticos, puede haber ligeras diferencias. Por favor, consulte el artículo real.",
    v116: "Atención:Los datos involucrados en esta página, a menos que se especifique lo contrario,son de los laboratorios de iRiding",
    v120: "Parámetros de la bicicleta eléctrica asistida",
    v121: "Parámetros del producto",
    v121a: "Nombre del producto",
    v122: "Bicicletas eléctricas",
    v123: "Velocidad máxima de diseño",
    v124: "25Km/h",
    v125: "Tipo de producto",
    v126: "TDN09Z",
    v127: "Método de frenado",
    v128: "Frenos de disco mecánicos",
    v129: "Dimensiones del producto (antes de doblar)",
    v130: "1530×600×1060 mm",
    v131: "Discos",
    v132: "Manivela de aluminio 56T x 170mm",
    v133: "Dimensiones del producto (plegado)",
    v134: "1530×430×725 mm",
    v135: "Tambor de flores",
    v136: "Bujes de aluminio",
    v137: "Masa total del vehículo",
    v138: "Aprox. 20 kg",
    v139: "Tiempo de carga",
    v140: "Aproximadamente 5,5 horas",
    v141: "Tamaño de las ruedas",
    v142: "20 pulgadas",
    v143: "Grado de impermeabilidad del cuerpo",
    v144: "IPX 3",
    v145: "Capacidad de peso",
    v146: "120 kg",
    v147: "Tamaño de la pantalla",
    v148: "53×32 mm",
    v149: "Capacidad de la batería",
    v150: "7.5 Ah",
    v151: "Conectividad inalámbrica",
    v152: "Bluetooth 5.0",
    v153: "Lista de embalaje",
    v154: "Bicicleta de asistencia eléctrica x 1",
    v155: "4, 5, 6 mm<br/>Llave hexagonal interior de  x 1 ",
    v155c: "Llave hexagonal interior de 4, 5, 6 mm x 1",
    v156: "Reposapiés izquierdo y derecho x 1",
    v157: "Adaptador de corriente x 1",
    //v158: "",
    v159: "Tornillos M5 x 4",
    v160: "Manual de instrucciones x 1",
    //v161: "",
    //v162: "",
  },
  F2: {
    v0: "QiCYCLE F2",
    v1: "Bicicleta con asistencia eléctrica plegable QiCYCLE F2",
    v1m: "Bicicleta con asistencia  <br/>eléctrica plegable QiCYCLE F2",
    v2: "Comienza a desplegar la ciudad",
    v3: "Color",
    v4: "Negro",
    v5: "Sensor de par",
    v6: "Plegable y portátil",
    v7: "Batería extraíble",
    v8: "Batería de larga duración",
    v9: "Ordenador de a bordo",
    v10: "Velocidad crucero",
    v11: "Tres modos de conducción",
    v12: "Neumáticos de 16 pulgadas",
    v13: "Resumen",
    v14: "Especificaciones",
    v15: "Soporte",
    v16: "Ayuda de guardia",
    v17: "conoce cada paso que des",
    v18: "El sensor de par recoge los datos de la fuerza de pedaleo y controla la cantidad de impulso.",
    v18a: "El sensor de par recoge los datos de la fuerza de pedaleo y controla la cantidad de impulso.La sensibilidad nerviosa del sistema",
    v18b: "de asistencia te ayuda a ser uno con la bicicleta",
    m18a: "El sensor de par recoge los datos de la fuerza de pedaleo y controla la cantidad de impulso.La sensibilidad nerviosa del sistema",
    m18b: "de asistencia te ayuda a ser uno con la bicicleta.A partir de ahora, no hay miedo a las pendientes,",
    m18c: "subirlas es como caminar sobre terreno llano.",
    v19: "La sensibilidad nerviosa del sistema de asistencia te ayuda a ser uno con la bicicleta",
    v20: "A partir de ahora, no hay miedo a las pendientes, subirlas es como caminar sobre terreno llano.",
    v21: "Principio de los sistemas de detección de par",
    v22: "1.Mostrar datos del recorrido",
    v23: "2.Control de la salida del booster",
    v24: "3.Sensores de fuerza de pedaleo",
    v25: "4.Recorrido del motor de accionamiento",
    v26: "Plegable, fácil de almacenar",
    v27: "puedes llevarlo en el maletero",
    v27m: "Plegable, fácil de almacenar <br/>puedes llevarlo en el maletero",
    v28: "Diseño patentado de plegado vertical ",
    v28m: "Diseño patentado de plegado vertical<sup style='font-size:0.15rem;margin-left: -0.04rem' :style='ios13? 'margin-top: -0.45rem; margin-left: 2.4rem': ios15? 'margin-top: -0.5rem; margin-left: 2.4rem': 'margin-top: -0.45rem;margin-left:-0.15rem ''class='font15 color3A'>「1」</sup>",
    v29: "el cuadro principal se puede plegar sin desmontar ninguna pieza, las horquillas delantera y trasera forjadas en aleación de aluminio son livianas y resistentes, con una capacidad de carga de hasta 100 kg, lo que hace que la conducción sea más segura",
    v30: "las horquillas delantera y trasera forjadas en aleación de aluminio son livianas y resistentes,",
    v30a: ",las horquillas delantera y trasera forjadas en",
    v30b: "aleación de aluminio son livianas y resistentes,",
    m30a: "delantera y trasera forjadas en aleación de aluminio son livianas y resistentes,",
    v31: "con una capacidad de carga de hasta 100 kg,",
    v32: "lo que hace que la conducción sea más segura",
    v33: "Número de patente: 201420243615.2",
    v34: "Volumen plegado ",
    m34a: "aprox. 0.25m",
    v35: "Batería desmontable",
    v36: "más fácil de cargar",
    v37: "El marco tiene 20 baterías de litio de potencia 18650 incorporadas.",
    v38: "El sistema de gestión de la seguridad BMS de grado automotriz, completo y con parada, garantiza el uso seguro de la electricidad.",
    m38b: " de grado automotriz, completo y con parada,",
    m38a: "El sistema de gestión de la seguridad BMS ",
    v38a: "garantiza el uso seguro de la electricidad.",
    v39: "La batería es fácil de montar y desmontar,",
    v40: "y se puede cargar directamente en la bicicleta",
    v41: "o por separado.",
    v42: "Batería extraíble",
    v43: "SHIMANO Sistema de cambio de tres velocidades,",
    v43a: "Sistema de cambio <br/> de tres velocidades <br/>  para un cambio preciso",
    v43m: "Sistema de cambio de tres velocidades  para un cambio preciso",
    v44: "para un cambio preciso",
    v45: "Sistema de cambio SHIMANO de tres velocidades,",
    v46: "para un cambio preciso y duradero.",
    v47: "Robusto y duradero.",
    m47a: "Robusto y duradero.Con ayuda de la",
    m47b: "asistencia eléctrica,",
    m47c: "desafía al `diablo` yendo cuesta arriba y contra el viento",
    v48: "Con ayuda de la asistencia eléctrica,",
    v49: "desafía al `diablo` yendo cuesta arriba y contra el viento",
    v50: "Tres modos de conducción,",
    v51: "más rápida y con mayor control",
    v51m: "más rápida y con mayor control",
    v52: "Modo de velocidad constante <br/> Relajado y sin prisas",
    v52m: "Modo de velocidad constante <br/> Relajado y sin prisas",
    v53: "Relajado y sin prisas",
    v54: "Mantén la velocidad de conducción con un solo clic, sin necesidad de pedalear, para una conducción más fácil",
    v54m: "Mantén la velocidad de conducción con un solo clic, sin necesidad de pedalear, para una conducción más fácil",
    v55: "Modo de velocidad fija",
    v56: "Modos de asistencia",
    v56m: "Modos de asistencia <br/> Ahorro de energía y potencia",
    v57: "Ahorro de energía y potencia",
    v58: "Cambia libremente a cualquiera de los 3 modos de asistencia eléctrica El modo de asistencia 2 tiene una autonomía de unos 60 km <sup style='margin-left: -0.05rem; margin-top: -0.05rem'class='font15 color3A positionA'>「2」</sup>",
    v58m: "Cambia libremente a cualquiera de los 3 modos de asistencia eléctrica El modo de asistencia 2 tiene una autonomía de unos 60 km",
    v58c: ".",
    v59: "El modo de asistencia 2 tiene una autonomía de unos 60 km <sup style='margin-left: -0.1rem; margin-top: -0.1rem'class='font15 color3A positionA'>「2」</sup>",
    v60: "Energía propia:",
    v61: "Asistencia eléctrica:",
    v62: "Modo 1: ",
    v62a: "bajo consumo de energía, larga duración de la batería",
    v63: "Modo 2: ",
    v63a: "equilibra la duración de la batería y la potencia",
    v64: "Modo 3: ",
    v64a: "aumenta la potencia, con una aceleración rápida",
    v65: "Anulación de asistencia eléctrica.",
    v66: "Modo bicicleta.",
    v67: "Fortalece tu cuerpo",
    v68: "Modo N sin asistencia, adecuado para ciclismo como ejercicio, tan fácil de conducir como cualquier bicicleta",
    v68a: "Modo N sin asistencia, adecuado para ciclismo como ejercicio,",
    v68b: "tan fácil de conducir como cualquier bicicleta.",
    m68a: "Modo N sin asistencia, adecuado para ciclismo como ejercicio,",
    m68b: "tan fácil de conducir como cualquier bicicleta.",
    v69: "Ordenador de a bordo, controla los datos de conducción",
    v69b: "Ordenador de a bordo",
    v69a: " controla los datos de conducción",
    v70: "Fácil de cambiar el modo de marcha en el ordenador de a bordo con pantalla a color,el cual muestra información sobre la velocidad, marcha, batería y recorrido Las acciones están bien documentadas y en su mente.",
    v71: "el cual muestra información sobre la velocidad, marcha, batería y recorrido",
    v71b: "el cual muestra información sobre la velocidad, marcha,",
    v71a: " batería y recorrido.",
    m71a: "el cual muestra información sobre la velocidad,",
    m71b: " marcha, batería y recorrido.",
    v72: "Las acciones están bien documentadas y en su mente.",
    v73: "Control del modo de asistencia",
    v74: "Detección de datos de ciclismo",
    v75: "Conéctese a la aplicación QiCYCLE <br/> para la conducción inteligente",
    v76: "La aplicación muestra y registra datos como velocidad, distancia recorrida, frecuencia cardíaca, cadencia <sup style='margin:0 -0.1rem 0 -0.1rem' class='font15  color3A'>「3」</sup>, etc. y los sincroniza con la nube a través del teléfono móvil, lo que permite conocer los datos en cualquier momento y guía científicamente su conducción.",
    v76m: "La aplicación muestra y registra datos como velocidad, distancia recorrida, frecuencia cardíaca, cadencia <sup style='margin:0 -0.1rem 0 -0.1rem' class='font15  color3A'>「3」</sup>, etc. y los sincroniza con la nube a través del teléfono móvil, lo que permite conocer los datos en cualquier momento y guía científicamente su conducción.",
    v76b: " etc. y los sincroniza con la nube a través del teléfono móvil,",
    v76a: "lo que permite conocer los datos en cualquier momento y guía científicamente su conducción.",
    m76a: "La aplicación muestra y registra datos como velocidad, distancia recorrida, frecuencia cardíaca, cadencia , etc.",
    m76b: " y los sincroniza con la nube a través del teléfono móvil, lo que permite conocer los datos en cualquier momento y guía",
    m76c: " científicamente su conducción.",
    v77: "1",
    v78: "Conéctate a la aplicación Ride Notes",
    v79: "Diseño ingenioso, detalles minuciosos",
    v79m: "Diseño ingenioso<br/>detalles minuciosos",
    v80: "Faros LED brillantes",
    v81: "Iluminan el camino por la noche",
    v82: "Luces traseras de anillo únicas",
    v83: "Protegen tu seguridad durante la conducción",
    v84: 'Ruedas de 16" para la ciudad',
    v85: "Ayudarle a avanzar con firmeza",
    v86: 'Freno de rodillo <img style="width: 2.02rem; height: 0.32rem" src="https://image.iriding.cc/f2/m-2.png">',
    v86m: "Freno de rodillo ",
    v87: "Sin necesidad de mantenimiento de por vida",
    v88: "Marco forjado de aleación de aluminio ultraligero",
    v89: "Pequeño y flexible, logrando una larga duración de la batería",
    v90: 'Vehículo IPX4 a prueba de agua <sup v-if="index === 5"style="margin-top: -0.05rem; margin-left: -0.05rem"class="font15 positionA">「4」</sup>',
    v90m: "Vehículo IPX4 a prueba de agua<sup style='margin-top: -0.03rem; margin-left: -0.1rem' class='font15 positionA'>「4」</sup>",
    v91: "Sin miedo al viento y la lluvia.",
    v92: "Guardabarros telescópicos",
    v93: "Fácil de plegar",
    v94: "Uso del nuevo nombre de la patente :",
    v95: "Bastidor plegable vertical giratorio",
    v96: "[1]      Uso del nuevo nombre de la patente : Bastidor plegable vertical giratorio Número de patente: 201420243615.2",
    v97: "[2]      Autonomía de hasta 60 km: circulando en modo de asistencia 2, con una carga de 75 kg y a velocidad constante de 20 km/h en carreteras pavimentadas urbanas.La duración real de la batería variará ligeramente según factores como la carga, temperatura, condiciones de la carretera y los hábitos de funcionamiento.",
    v98: "[3]      Para obtener datos de frecuencia cardíaca y cadencia, debe conectar un dispositivo externo con Bluetooth 5.0, como un cinturón de frecuencia cardíaca y cadencia, por favor cómprelo por separado",
    v99: "[4]      Toda la bicicleta pasó la prueba de impermeabilidad IPX4, con lo que la pulverización directa de agua en cualquier dirección no afectará el rendimiento de trabajo del vehículo. No sumerja el vehículo en agua ni use una pistola de agua a alta presión para lavarlo. Al circular bajo la lluvia, no conduzca con el motor sumergido en agua, nunca permita que el nivel del agua supere la posición del eje del motor. Número de informe de prueba: TH60150A/2019, agencia de prueba: SGS Taiwan Ltd.",
    v99a: "Advertencia: ",
    v100: "1.El dibujo de los neumáticos mostrado en esta página es solo de referencia. Debido a los diferentes lotes de neumáticos, puede haber ligeras diferencias.Por favor, consulte el artículo real.",
    v106a:
      "  2.Los datos en esta página, a menos que se especifique lo contrario,son de los laboratorios de iRiding.",
    v107: "Qicycle Bicicleta plegable con asistencia eléctrica",
    v108: "Parámetros del producto",
    v109: "Parámetros del producto",
    v110: "Nombre del producto",
    v111: "Bicicletas eléctricas",
    v112: "Tipo de producto",
    v113: "TDR08Z",
    v114: "Dimensiones del producto (antes de doblar)",
    v115: "1290 × 540 × 950mm",
    v116: "Dimensiones del producto (plegado)",
    v117: "1000 × 450 × 650mm",
    v118: "Masa total del vehículo",
    v119: "Aproximadamente 14,5 kg",
    v120: "Tamaño de las ruedas",
    v121: "16 pulgadas",
    v122: "Capacidad de peso",
    v123: "100kg",
    v124: "Capacidad de la batería",
    v125: "6Ah",
    v126: "Tiempo de carga",
    v127: "Aproximadamente 4,5 horas",
    v128: "Grado de impermeabilidad",
    v129: "IPX4",
    v130: "Tamaño de la pantalla",
    v131: "1,77 pulgadas",
    v132: "Conectividad inalámbrica",
    v133: "Bluetooth 5.0",
    v134: "Lista de embalaje",
    v135: "Bicicleta de asistencia plegable x 1",
    v136: "4, 5, 6 mm <br/> Llave hexagonal interior de  x 1",
    v136a: "Llave hexagonal interior de  x 1",
    v136c: "4, 5, 6 mm Llave hexagonal interior de x 1",
    v137: "Reposapiés izquierdo y derecho x 1",
    v138: "Adaptador de corriente x 1",
    v139: "Manual de instrucciones x 1",
    // v140: "1",
  },
};
