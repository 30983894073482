/**
 * 香港
 */
module.exports = {
  lang: {
    lang: {
      v0: "EN",
      v1: "简体中文-ZH",
      v2: "繁體中文-ZH",
      v3: "English-EN",
      v4: "Deutsch-DE",
      v5: "Español-ES",
      v6: "Français-FR",
      v7: "한국어 - KO",
    },
    contact: {
      v0: "联系我们",
      v1: "联系我们",
      v2: "商务合作",
      v3: "邮箱地址：",
      v4: "销售团队",
      v5: "国内销售：",
      v6: "海外销售：",
      v7: "客户服务",
      v8: "售后服务热线：",
      v9: "数据保护",
      v10: "邮箱地址：",
    },
    law: {
      1: "隱私與法律",
      2: "更新日期",
      3: "2021年02月12日",
      4: "生效日期",
      5: "2021年03月12日",
      6: "引言",
      7: "騎記（廈門）科技有限公司及關聯公司（以下簡稱「騎記APP」或「我們」）非常重視您的個人信息，並讓您對個人信息擁有控制權，我們會使用強大的加密技術來保護您的隱私，同時制定嚴格的政策來管理所有數據。當您訪問騎記APP時，或使用我們提供的服務前，您需要同意本政策中關於我們如何收集、使用、儲存和分享您的相關信息的規定。如果您不同意本隱私政策中的任何內容，請立即停止使用或訪問我們的產品和服務。若您對本政策有任何問題，請聯繫：privacy@miriding.com。本隱私政策要點如下：",
      8: "1.我們將逐一說明我們收集的您的個人信息類型及其對應的用途，以便您瞭解我們針對某一特定功能所收集的具體個人信息的類別、使用理由及收集方式。",
      9: "2.當您使用一些功能時，我們會在獲得您的同意後，收集您的一些敏感信息，除非按照相關法律法規要求必須收集，拒絕提供這些信息僅會使您無法使用相關特定功能，但不影響您正常使用騎記APP的其他功能。",
      10: "3.目前，騎記APP不會主動共享或轉讓您的個人信息至騎記APP外的第三方，如存在其他共享或轉讓您的個人信息或您需要我們將您的個人信息共享或轉讓至騎記APP外的第三方情形時，我們會直接或確認第三方徵得您對上述行爲的明示同意，此外，我們會對對外提供信息的行爲進行風險評估。",
      11: "4.目前，騎記APP不會主動從騎記APP外的第三方獲取您的個人信息。如未來爲業務發展需要從第三方間接獲取您的個人信息，我們會在獲取前向您明示您個人信息的來源、類型及使用範圍，如騎記APP開展業務需進行的個人信息處理活動超出您原本向第三方提供個人信息時的授權同意範圍，我們將在處理您的該等個人信息前，徵得您的明示同意；此外，我們也將會嚴格遵守相關法律法規的規定，並要求第三方保障其提供的信息的合法性。",
      12: "5.您可以通過本指引所列途徑訪問、更正、刪除您的個人信息，撤銷同意、註銷帳號、投訴舉報以及設置隱私功能。",
      13: "如您想了解更加詳盡的信息，請根據以下索引閱讀相應章節：",
      14: "1.我們如何蒐集和使用您的信息",
      15: "2.我們如何使用 Cookie 和同類技術",
      16: "3.如何更新、變更、導出、刪除您的信息",
      17: "4.我們如何保護您的個人信息",
      18: "5.我們如何共享、轉讓、公開披露您的個人信息",
      19: "6.信息的存儲",
      20: "7.第三方服務提供商",
      21: "8.本政策的更新",
      22: "9.適用範圍",
      23: "10.如何聯繫我們",
      24: "一. 我們如何蒐集和使用您的信息？",
      25: "1. 我們如何蒐集您的信息？",
      26: "我們提供服務時，可能會收集、儲存和使用下列信息：",
      27: "1.1 您提供的信息",
      28: "1.1.1 您在註冊賬戶或使用我們的服務時，向我們提供的可用於識別用戶的個人身份的信息，例如姓名、電子郵件地址、電話號碼、銀行卡號、身份證號碼、住址、通訊記錄等信息，或我們可以正當地通過鏈接找到此類信息的其他數據。如果您想充分使用我們提供的各種分享功能，可能還需要創建公開顯示的個人資料，其中可能會包含您的姓名和照片。",
      29: "1.1.2 您通過我們的服務向其他方提供的共享信息，以及您使用我們的服務時上傳、提交、存儲、發送或接收的信息。",
      30: "1.2 您在使用服務過程中，我們獲取的信息",
      31: "我們會收集您使用的服務以及使用方式的信息，如您使用騎記APP運動、在騎記社區發表評論或參與打卡動態、參與騎記的賽事及挑戰、加入騎記會員和訓練營以及在使用騎記會員和訓練營服務時可能產生的相關信息。此類信息包括：",
      32: "1.2.1 設備信息",
      33: "我們會根據您在軟件安裝及/或使用授權的具體權限，接收並記錄您所使用的設備相關信息，例如您的設備型號、操作系統版本信息、設備設置、移動設備版本、設備識別碼（如 IMEI/android id/IDFA/OPENUDID/GUID/OAID、SIM卡IMSI、ICCID信息 等）、屏幕分表率、設備環境等軟硬件特徵信息、設備所在位置相關信息，例如IP地址、GPS位置以及能夠提供相關信息的WLAN接入點、藍牙和基站等傳感器信息。如果您在安裝及/或使用過程中拒絕授予我們相應權限的，我們並不會記錄您上述對應的信息。",
      34: "1.2.2 日誌信息",
      35: "(1) 設備或軟件信息，例如您的移動設備、網頁瀏覽器或用於接入我們服務的其他程序所提供的配置信息、您的IP地址和移動設備所用的版本和設備識別碼（如 IMEI/android id/IDFA/OPENUDID/GUID/OAID、SIM卡IMSI、ICCID信息 等）；",
      36: "(2) 在使用我們服務時搜索或瀏覽的信息，例如您使用的網頁搜索詞語、訪問的社交媒體頁面URL地址，以及您在使用我們服務時瀏覽或要求提供的其他信息和內容詳情；",
      37: "1.2.3 IP地址",
      38: "(1) 有關您曾使用的移動應用（APP）和其他軟件的信息，以及您曾經使用該等移動應用和軟件的信息；",
      39: "(2) 您通過我們的服務進行通訊的信息，例如曾通訊的賬號，以及通訊時間、數據和時長；",
      40: "(3) 您通過我們的服務分享的內容所包含的信息（元數據），例如拍攝或上傳的共享照片或錄像的日期、時間或地點等。",
      41: "1.2.4 相機、麥克風與設備中的圖片等",
      42: "爲了給您提供全方位的運動體驗，經過您的授權，我們的服務器會提取您設備中的視頻、圖片、軟件名、版本號等，並通過加密上傳到服務器進行比對，用於匹配與該視頻、圖片、軟件相對應的顯示功能。同時，您也可以隨時通過相關功能設置拒絕/取消該權限。拒絕提供該權限僅會使您無法使用上述功能，但不影響騎記APP其他功能與服務的正常使用；但當您取消該授權後，我們將不再收集該信息，也無法再爲您提供上述與之對應的服務；但除非您依照法律的規定刪除了您的信息，否則您的取消行爲不會影響我們基於您之前的授權進行的信息的處理、存儲。",
      43: "當您使用拍攝照片、錄製視頻功能，我們將訪問您的設備相機、麥克風相關權限，並收集您提供的基於拍攝照片、錄製視頻後向我們上傳的圖片、視頻信息。如您拒絕提供權限和內容的，僅會使您無法使用該功能，但並不影響您正常使用騎記的其他功能。同時，您也可以隨時通過相關功能設置取消該權限。當您取消該授權後，我們將不再收集該信息，也無法再爲您提供上述與之對應的服務；但除非您依照法律的規定刪除了您的信息，否則您的取消行爲不會影響我們基於您之前的授權進行的信息的處理、存儲。",
      44: "1.2.5 位置信息",
      45: "我們會在您開啓位置權限後訪問獲取您的位置信息，根據您的位置信息爲您提供更個性化的產品及/或服務，比如用來記錄您的GPS運動數據、還可查看周邊運動路線等。當然，您也可以通過關閉定位功能隨時停止我們對您的地理位置信息的收集。您可以允許我們訪問您的活動與體能訓練記錄，通過讀取您的活動與體能訓練，記錄步頻和步數信息。同時，您也可以隨時通過相關功能設置取消該權限。當您取消該授權後，我們將不再收集該信息，也無法再爲您提供上述與之對應的服務；但除非您依照法律的規定刪除了您的信息，否則您的取消行爲不會影響我們基於您之前的授權進行的信息的處理、存儲。",
      46: "我們提供運動社區服務，當您使用運動音樂功能時，我們會請求您授權讀取本地音樂文件，用於在運動中播放音樂。",
      47: "1.2.6 來源於第三方的信息",
      48: "在一些法律允許的情況下，我們可能從第三方處獲得您的個人信息。例如：",
      49: "(1) 出於安全和防欺詐的目的，針對特定賬號、金融交易等服務，在您授權的前提下，通過合法來源覈實您的信息（如電話號碼）；",
      50: "(2) 通過指定的唯一標識符（如 IMEI/android id/IDFA/OPENUDID/GUID/OAID、SIM卡IMSI、ICCID信息 等）爲您對應提供廣告服務；",
      51: "(3) 我們還可能從第三方社交網絡服務中獲取某些信息，例如賬戶、暱稱（例如，當您使用社交網絡賬戶登錄騎記APP 服務時）；",
      52: "(4) 他人提供的與您有關的信息，例如其他用戶在使用騎記APP商城時填寫的您的收貨地址。",
      53: "1.2.7 非個人信息",
      54: "我們還可能收集其他無法識別到特定個人、根據當地適用法律不屬於個人信息的信息，例如您使用特定服務時產生的統計類數據，如匿名設備相關信息、日活事件、頁面訪問事件、頁面訪問時長事件、會話事件；網絡監控數據如請求時長、請求與錯誤請求數等；以及應用崩潰事件。收集此類信息的目的在於改善我們向您提供的服務。所收集信息的類別和數量取決於您如何使用我們產品或服務。",
      55: "我們會收集諸如唯一設備標識符、設備型號、系統版本號等與您在我們的網站或移動應用中的活動有關的信息。我們會將此類信息彙總，用於幫助我們向客戶提供更有用的信息，瞭解客戶對我們網站、產品或服務中的哪些部分最感興趣。就本隱私政策而言，彙總數據被視爲非個人信息。如果我們將非個人信息與個人信息結合使用，則在結合使用期間，此類信息將被視爲個人信息。",
      56: "2. 我們如何使用您的信息",
      57: "收集個人信息的目的在於向您提供產品或服務，並且保證我們遵守適用的相關法律、法規及其他規範性文件。我們可能將上述收集的信息用作以下用途：",
      58: "2.1 向您提供、處理、維護、改善、開發我們的產品或提供給您的服務， 例如交付、驗證、售後、客戶支持和廣告宣傳；",
      59: "2.2 在我們提供服務時，用於身份驗證、客戶服務、安全防範、詐騙監測、存檔和備份用途，確保我們向您提供的產品和服務的安全性；",
      60: "2.3 內部目的，如數據分析、研究和開發與我們產品或服務的使用相關的統計信息，以更好地改進我們的產品或服務；",
      61: "2.4 幫助我們設計新服務，改善我們現有服務；",
      62: "2.5 向您提供與您更加相關的活動等信息推送，您可根據喜好自行決定開啓或關閉；",
      63: "2.6 評估我們服務中的廣告和其他促銷及推廣活動的效果，並加以改善；",
      64: "2.7 執行軟件驗證、升級服務；",
      65: "2.8 應用戶特殊要求而提供特定服務時，需要將信息提供給我們的關聯公司、第三方或其他用戶；",
      66: "2.9 其他不違反任何強制性法律法規的情況；",
      67: "2.10 儲存並維護與您相關的信息，用於我們的業務運營 （例如業務統計）或履行法律義務；",
      68: "2.11 其他徵得您同意的目的。",
      69: "關於我們如何使用您的信息（其中可能包含個人信息），下面提供了更多詳細示例：",
      70: "(1) 登錄使用騎記APP產品或服務；",
      71: "(2) 創建及維護您的騎記APP賬號。您通過網站或移動設備創建騎記APP賬號時提供的個人信息可用於建立您個人騎記APP賬號和個人資料；",
      72: "(3) 參與社區。 與社區或騎記APP其他類似社交平臺有關的個人信息可能被用於個人資料頁面展示、與其他用戶進行互動，參與發帖；",
      73: "(4) 提供基於位置的服務。在使用騎記APP服務時，我們或第三方服務提供商與業務合作伙伴（更多詳細信息請參閱下方“我們如何共享，轉移和公開披露您的個人信息”）可能會使用位置信息以便爲您提供服務，並且基於準確的位置提供更好的用戶體驗，例如獲取準確的跑步軌跡，您可以隨時在設置中關閉騎記APP 位置服務；",
      74: "(5) 改善用戶體驗。某些選擇性加入功能，例如用戶體驗計劃，允許我們分析用戶如何使用手機和騎記APP服務的數據，以改善用戶體驗，例如發送崩潰報告；",
      75: "(6) 收集用戶反饋。 您選擇提供的反饋對幫助騎記APP改進服務非常重要。爲了處理您提供的反饋，騎記APP可能會使用您所提供的個人信息與您聯繫，並保留記錄用於解決問題及改進服務；",
      76: "(7) 發送通知。 我們可能會將您的個人信息用於發送重要通知，例如有關我們的條款、條件和政策的變更；",
      77: "(8) 進行促銷活動。 如果您通過騎記APP參與了抽獎、比賽，我們可能會使用您提供的個人信息以爲您發送此類獎勵；",
      78: "(9) 進行設備的分析以提供更好的用戶體驗。騎記APP將會進行分析，以進一步提升設備的使用體驗。",
      79: "您有權拒絕以直接營銷目的進行的個人信息處理以及自動化決策等。爲了行使上述權利，您可以發送郵件至 privacy@miriding.com 或者參考各產品或服務的單獨隱私政策中說明的控制機制。",
      80: "3. 我們承諾",
      81: "3.1 我們不會根據敏感類別（例如種族、宗教、性取向或健康狀況）向您展示個性化廣告；",
      82: "3.2 我們不會與廣告主分享可用於識別您個人身份的信息，例如您的姓名或電子郵件地址（除非經您授權同意）；",
      83: "3.3 在任何時候、任何情況下都不會向任何第三方出售您的個人信息，我們只會在法律允許的範圍內使用根據本協議獲得的信息。我們會制定嚴格的政策保護您的個人信息，除非事先獲得您的授權或本聲明另有規定之外，不會將您的這些信息對外公開或向第三方提供。",
      84: "4. 關聯公司或其他可信合作單位",
      85: "爲保證服務質量，我們可能會由關聯公司或其他可信合作單位向您提供您所要求的服務或您可能感興趣的內容。經您授權同意後，我們將向關聯公司或合作單位分享該產品、服務所必須的個人信息。我們會要求關聯公司及合作單位按照我們的說明、隱私政策以及任何其他適當的保密和安全措施來保證您的個人信息安全。除此之外，未經您的授權同意，我們不會向任何第三方提供或分享您的信息。",
      86: "5. HeathKit",
      87: "若您使用的是蘋果手機，爲了計算運動消耗量、爲您記錄有關運動數據，經過您的授權，我們將從蘋果公司的 HeathKit 接收並向其發送您的運動數據。 爲了簡化您的輸入流程，我們從 HeathKit 中讀取您的運動數據。同時您在騎記APP生成的訓練數據將與蘋果公司的 HeathKit 同步。任何通過使用 HeathKit 而獲得的信息，如步數和心率數據等，將不會分享或銷售給任何第三方，包括廣告商及其他代理商。我們將不會使用 HeathKit 的信息用於市場、廣告及類似服務中。",
      88: "6. 第三方主體",
      89: "騎記APP服務中的第三方服務由外部第三方主體提供，騎記APP無法獲得您在使用該類第三方服務時產生的信息。但是，如果您已明示同意該第三方獲得您的地理位置信息，該第三方將通過騎記APP接口獲得您的終端地理位置信息。該信息屬於敏感信息，拒絕提供該信息僅會使您無法使用上述第三方服務，但不影響您正常使用騎記APP的功能。",
      90: "另外，根據相關法律法規及國家標準，以下情形中，我們可能會收集、使用您的相關個人信息無需徵求您的授權同意：",
      91: "6.1 與國家安全、國防安全等國家利益直接相關的；與公共安全、公共衛生、公衆知情等重大公共利益直接相關的；",
      92: "6.2 與犯罪偵查、起訴、審判和判決執行等直接相關的；",
      93: "6.3 出於維護您或其他個人的生命、財產、聲譽等重大合法權益但又很難得到本人同意的；",
      94: "6.4 所收集的個人信息是您自行向社會公衆公開的；",
      95: "6.5 從合法公開披露的信息中收集個人信息的，如合法的新聞報道、政府信息公開等渠道；",
      96: "6.6 根據您要求籤訂和履行合同所必需的；",
      97: "6.7 用於維護所提供的產品或服務的安全穩定運行所必需的，例如發現、處置產品或服務的故障；",
      98: "6.8 爲開展合法的新聞報道所必需的；",
      99: "6.9 出於公共利益開展統計或學術研究所必要，且其對外提供學術研究或描述的結果時，對結果中所包含的個人信息進行去標識化處理的；",
      100: "6.10 爲完成合並、分立、收購或資產轉讓而轉移；",
      101: "6.11 法律法規規定的其他情形。",
      102: "請您理解，我們向您提供的功能和服務是不斷更新和發展的，如果某一功能或服務未在前述說明中且收集了您的信息，我們會通過頁面提示、交互流程、網站公告等方式另行向您說明信息收集的內容、範圍和目的，以徵得您的同意。",
      103: "請您注意，目前騎記APP不會主動從騎記APP外的第三方獲取您的個人信息。如未來爲業務發展需要從第三方間接獲取您個人信息，我們會在獲取前向您明示您個人信息的來源、類型及使用範圍，如騎記APP開展業務需進行的個人信息處理活動超出您原本向第三方提供個人信息時的授權同意範圍，我們將在處理您的該等個人信息前，徵得您的明示同意；此外，我們也將會嚴格遵守相關法律法規的規定，並要求第三方保障其提供的信息的合法性。",
      104: "7. 敏感個人信息",
      105: "除非經過您的同意，否則我們不會向任何人提供您的敏感個人信息。",
      106: "8. 個人信息的匿名化處理",
      107: "在收集到您的個人信息後，我們將通過技術手段及時對數據進行匿名化處理。在不泄露您個人信息的前提下，騎記APP有權對匿名化處理後的用戶數據庫進行挖掘、分析和利用（包括商業性使用），有權對產品/服務使用情況進行統計並與公衆/第三方共享脫敏的統計信息。",
      108: "二. 我們如何使用 Cookie 和同類技術",
      109: "Cookie 是包含字符串的小文件，會在您訪問網站時發送到您的計算機上。當您再次訪問網站時，Cookie 使該網站可以識別您的瀏覽器。Cookie 還能存儲用戶使用偏好及其他信息。您可以重置您的瀏覽器拒絕所有的Cookie ，或者在發送 Cookie 時提示。但如果沒有 Cookie ，某些網站功能或服務可能無法正常工作。",
      110: "Cookie 和同類型技術是互聯網中的通用常用技術。當您使用騎記相關服務時，我們可能會使用相關技術向您的設備發送一個或多個 Cookie 或匿名標識符，以收集和存儲您訪問、使用騎記時的信息。我們將通過Cookie和其他技術收集的信息視爲非個人信息。但是，如果當地法律將互聯網協議 (IP) 地址或類似識別標記視爲個人信息，我們亦將此等識別標記視爲個人信息。我們使用 Cookie 和同類技術主要爲了實現以下功能或服務：",
      111: "1. 保障產品與服務的安全、高效運轉",
      112: "我們可能會設置認證與保障安全性的 Cookie 或匿名標識符，使我們確認您是否安全登錄服務，或者是否遇到盜用、欺詐等不法行爲。這些技術還會幫助我們改進服務效率，提升登錄和響應速度。",
      113: "2. 幫助您獲得更輕鬆的訪問體驗",
      114: "使用此類技術可以幫助您省去重複填寫個人信息、輸入搜索內容的步驟和流程，例如實現一鍵登錄流程、記錄搜索歷史。",
      115: "3. 爲您推薦、展示、推送您可能感興趣的內容",
      116: "我們可能會利用 Cookie 和同類技術瞭解您的偏好和使用習慣，進行諮詢或數據分析，以改善產品服務及用戶體驗。",
      117: "4. 日誌文件",
      118: "和大部分網站一樣，我們收集特定信息並保存在日誌文件中。此類信息可能包括互聯網協議 (IP) 地址、瀏覽器類型、互聯網服務供應商 (ISP)、引用/退出頁面、操作系統、日期/時間戳和/或點擊流數據。我們不會把自動收集的日誌數據與我們收集的其他信息進行關聯。",
      119: "5. 移動分析",
      120: "在某些移動應用中，我們使用移動分析軟件，以更好地瞭解我們的移動軟件在您的手機中的功能。此款軟件可能記錄以下信息，如您使用該應用程序的頻率、該應用程序內發生的事件、累計使用、性能數據及應用程序崩潰發生的位置。我們不會將存儲於分析軟件內的信息與您在移動應用程序中提交的任何個人信息相關聯。",
      121: "6. 本地存儲- HTML5/Flash",
      122: "我們使用本地存儲對象 (LSO)，例如HTML5或Flash，來存儲內容。與我們合作在騎記APP上提供某些功能，或根據您的瀏覽行爲投放廣告的第三方也會通過HTML5或Flash Cookie來收集和存儲相關信息。各種瀏覽器提供了各自的管理工具來刪除HTML5本地存儲對象。",
      123: "三. 如何更新、變更、導出、刪除您的信息",
      124: "依據某些司法管轄區的法律規定：",
      125: "1. 提供真實的個人信息",
      126: "您在使用我們的產品和服務時，會被要求提供您真實的個人信息，您應當對您所提供信息的真實性、合法性、有效性及完整性負責，並及時更新和維護您的個人信息，以保證信息的真實、合法和有效。您可以隨時管理您的個人信息，例如您的個人賬戶註冊信息。",
      127: "2. 刪除內容或個人信息",
      128: "您可以自行從騎記賬號中刪除您的內容或個人信息，您可以通過「我」-「設置」-「賬號相關」-「註銷賬號」進行賬號的註銷申請。在15個工作日內，我們將刪除或匿名化處理您的個人信息。",
      129: "3. 有限時間的數據保留",
      130: "在有些情況下，我們會出於法律目的或合法業務的需要將數據保留有限的一段時間。我們會盡力確保騎記的服務能夠保護信息免遭意外或惡意刪除。因此，如果您刪除了某些內容，我們可能會在15個工作日內從我們現用的和備用的系統中刪除其副本。",
      131: "4. 備份內容或用於非騎記服務",
      132: "您可以隨時備份個人帳戶名下的內容或將其用於非騎記服務，您可以發郵件到 privacy@miriding.com 與我們聯絡並提出您的要求。",
      133: "5. 撤銷同意",
      134: "您可以通過提交請求撤銷之前出於特定目的授予我們的同意，包括收集、使用和/或披露我們掌握或控制的您的個人信息。根據您所使用的具體服務，您可以通過訪問騎記APP賬號管理中心或通過發送郵件到 privacy@miriding.com 進行相關操作。我們將會在您做出請求後的合理時間內處理您的請求，並且會根據您的請求，此後不再收集、使用和/或披露您的個人信息。",
      135: "取決於您撤銷同意的範圍，其可能導致您不能繼續享受騎記APP的產品或服務。但您撤回同意或授權的決定，不會影響我們此前基於您的同意而開展的個人信息處理活動的有效性。",
      136: "6. 註銷服務或賬號",
      137: "如您希望註銷具體產品或服務，您可以通過「我的」-「設置」-「賬號安全」-「註銷賬號」進行賬號的註銷。當您註銷賬號後，我們將刪除或匿名化處理您的個人信息。",
      138: "在15個工作日內，我們將刪除或匿名化處理您的個人信息。騎記賬戶一旦被註銷將不可恢復，請您在操作之前自行備份騎記賬戶相關的所有信息和數據。",
      139: "四. 我們如何保護您的個人信息",
      140: "1. 嚴格的信息管理政策",
      141: "我們制定了嚴格的信息管理政策，配備專業的技術團隊，採取了一系列合理的預防措施，以保護您的個人信息不會遭受未經授權的瀏覽、披露、濫用、變更、破壞以及損失，其中包括：",
      142: "(1) 使用加密技術來確保您的數據在傳輸過程中保持私密性；",
      143: "(2) 提供多種安全功能來協助您保護自己的帳號安全；",
      144: "(3) 審查我們在收集、存儲和處理信息方面的做法（包括實體安全措施），以防未經授權的人員訪問我們的系統；",
      145: "(4) 所有因提供服務而必須接觸個人信息的騎記員工、承包商和代理商等，都需要遵守合同中規定的嚴格保密義務，否則可將被處分或被解約。",
      146: "2. 技術限制及惡意手段",
      147: "儘管已經採取了上述合理有效措施，並已經遵守了相關法律規定要求的標準，但請您理解，由於技術的限制以及可能存在的各種惡意手段，在互聯網行業，即便竭盡所能加強安全措施，也不可能始終保證信息百分之百的安全，我們將盡力確保您提供給我們的個人信息的安全性。您確認知悉並理解，您接入我們的服務所用的系統和通訊網絡，有可能因我們可控範圍外的因素而出現問題。因此，我們強烈建議您採取積極措施保護個人信息的安全，包括但不限於使用複雜密碼、定期修改密碼、不將自己的賬號密碼等個人信息透露給他人。",
      148: "3. 應急處理預案",
      149: "我們會制定應急處理預案，並在發生用戶信息安全事件時立即應急預案，努力阻止該等安全事件的影響和後果擴大。在不幸發生用戶信息安全事件（泄露、丟失等）後，我們將按照法律法規的要求，及時向您告知：安全事件的基本情況和可能的影響、我們已採取或將要採取的處置措施、您可自主防範和降低風險的建議、對您的補救措施等。事件相關情況我們將以郵件、信函、電話、推送通知等方式告知您，難以逐一告知個人信息主體時，我們會採取合理、有效的方式發佈公告。同時，我們還將按照監管部門要求，上報個人信息安全事件的處置情況。",
      150: "4. 您能做什麼？",
      151: "您可以通過不向任何人（除非此人經您正式授權）披露您的登錄密碼或賬號信息，您可以爲騎記APP設置唯一的密碼，以防止其他網站密碼泄露危害您在騎記APP的賬號安全。無論何時，請不要向任何人（包括自稱是騎記APP客服的人士）透露您收到的驗證碼。",
      152: "騎記APP不對因您未能保持個人信息的私密性而導致第三方訪問您的個人信息進而造成的安全疏漏承擔責任。儘管有上述規定，如果發生其他任何互聯網用戶未經授權使用您賬號的情況或其他任何安全漏洞，您應當立即通知我們。",
      153: "您的協助將有助於我們保護您個人信息的私密性。",
      154: "五. 我們如何共享、轉讓、公開披露您的個人信息",
      155: "1. 共享",
      156: "我們不會將任何個人信息出售給第三方。",
      157: "我們有時可能會向第三方共享您的個人信息，以便提供或改進我們的產品或服務，包括根據您的要求提供產品或服務。如果您不再希望我們共享這些信息，請發送電子郵件到 privacy@miriding.com 聯繫我們。",
      158: "1.1 您主動選擇的共享",
      159: "在獲得您的明確同意或主動選擇的情況下，與您指定的第三方共享您授權/請求範圍內的信息。例如，您使用騎記APP賬號快捷登錄第三方網站或者APP。",
      160: "我們會提供社交網絡服務，您在使用這一類型服務時，您所上傳或發佈的信息（包括您公開的個人信息、您的通訊錄、好友圈）、您對他人上傳或發佈的信息作出的迴應，以及與這些信息有關的日誌信息等，只要您不刪除這些信息，它們會一直留存在公共領域，並被使用我們服務的其他用戶分享、瀏覽。即使您刪除這些信息，有關信息仍可能由其他用戶或不受我們控制的非關聯第三方獨立地緩存、複製或儲存，或由其他用戶或第三方在公共領域保存。",
      161: "請您謹慎考慮通過我們的服務上傳、發佈和交流的信息內容。在一些情況下，您可通過我們某些服務的隱私設定來控制有權瀏覽您共享信息的用戶範圍。您可以自行刪除您上傳、發佈或分享的信息，如要求我們爲您刪除您的相關信息，請發送郵件到 {emialadds} 與我們聯絡並提出您的要求，我們會在收到後15個工作日內完成並覈查和處理。",
      162: "1.2 與我們的關聯公司共享信息",
      163: "爲了順利地從事商業經營，以向您提供產品或服務的全部功能，我們可能不時與其他的騎記APP關聯公司共享您的個人信息。",
      164: "1.3 與第三方服務提供商與業務合作伙伴共享",
      165: "爲保證向您提供本隱私政策目的所述的服務，我們可能會向第三方服務提供商與業務合作伙伴等第三方共享必要的個人信息。",
      166: "這包括我們的數據中心、數據存儲設施、廣告和營銷服務供應商以及其他業務合作伙伴。這些第三方可能代表騎記APP或出於本隱私政策的一項或多項目的處理您的個人信息。我們保證僅出於正當、合法、必要、特定、明確的目的共享爲您提供服務所必要的個人信息。騎記APP將進行盡職調查並簽訂合同，以確保第三方服務提供商遵守您所屬司法管轄區中適用的隱私法律。第三方服務提供商也可能擁有其子處理者。",
      167: "爲提供成效衡量、分析和其他商業服務，我們還可能以彙總的形式與第三方（例如向我們的廣告商）共享信息（非個人信息）。我們使用所掌握的信息來幫助廣告主和其他合作伙伴評估他們廣告和服務的成效及覆蓋情況，並瞭解使用他們服務的人羣類型以及人們如何與其網站、應用和服務互動。我們也可能與其共享我們服務的一般使用趨勢，例如購買某些產品或從事某些交易的特定人羣中的客戶數量。",
      168: "第三方SDK名稱",
      169: "所屬公司",
      170: "信息獲取",
      171: "使用目的",
      172: "隱私協議鏈接/官網",
      173: "微博 SDK",
      174: "新浪",
      175: "外部存儲狀態，最近一次地理位置信息，持續定位，網絡類型，網絡服務運營商，IMEI，MAC地址，WiFi 信息",
      176: "微博登錄、分享",
      177: "https://open.weibo.com/wiki/%E5%BC%80%E5%8F%91%E8%80%85%E5%8D%8F%E8%AE%AE#.E9.9A.90.E7.A7.81.E7.9B.B8.E5.85.B3.E6.9D.A1.E6.AC.BE",
      178: "小米賬號開放平臺 SDK",
      179: "小米",
      180: "外部存儲狀態，網絡類型，網絡服務運營商，IMEI，MAC地址，WiFi 信息",
      181: "小米賬號登錄",
      182: "https://dev.mi.com/console/",
      183: "友盟+ SDK",
      184: "友盟同欣（北京）科技有限公司",
      185: "設備狀態和身份並獲取您的IMEI、IMSI 信息",
      186: "App統計分析場景",
      187: "https://www.umeng.com/",
      188: "Bugly SDK",
      189: "騰訊",
      190: "設備狀態和身份並獲取您的IMEI、IMSI 信息，ANDROID ID",
      191: "異常上報",
      192: "https://bugly.qq.com/v2/index",
      193: "七牛雲 SDK",
      194: "上海七牛信息技術有限公司",
      195: "設備狀態和身份並獲取您的IMEI、IMSI 信息，外部存儲狀態",
      196: "網絡請求、DNS解析",
      197: "https://www.qiniu.com/?ref=developer.qiniu.com",
      198: "高德地圖 SDK",
      199: "高德",
      200: "外部存儲狀態，最近一次地理位置信息，持續定位,網絡類型，網絡服務運營商，IMEI，MAC 地址，WiFi 信息",
      201: "定位、地圖展示及poi查詢",
      202: "https://developer.amap.com/",
      203: "微信 SDK",
      204: "騰訊",
      205: "外部存儲狀態，最近一次地理位置信息，持續定位，網絡類型，網絡服務運營商，IMEI，MAC地址，WiFi 信息",
      206: "微信登錄、分享",
      207: "https://developers.weixin.qq.com/doc/oplatform/Mobile_App/WeChat_Login/Development_Guide.html",
      208: "小米推送 SDK",
      209: "小米",
      210: "設備狀態和身份並獲取您的IMEI、IMSI 信息",
      211: "推送/下發通知欄消息",
      212: "https://dev.mi.com/console/",
      213: "QQ SDK",
      214: "騰訊",
      215: "系統語言，外部存儲狀態，最近一次地理位置信息，網絡位置信息，持續定位，網絡類型，手機號碼，SIM卡序列號，網絡服務運營商，IMEI，MAC地址，ANDROID ID，IP 地址，WiFi 信息，應用安裝列表，讀取剪切板",
      216: "QQ登錄、分享",
      217: "https://connect.qq.com/",
      218: "接入第三方服務的情形，我們可能會接入第三方SDK服務，並將我們依照本政策收集的您某些信息共享給該等第三方服務商，以便提高更好的客戶服務和用戶體驗。",
      219: "目前，我們接入的第三方服務商主要包括以下幾種類型：",
      220: "(1) 用於消息推送功能，您在使用我們的產品或服務時，小米推送可能會訪問您的設備狀態和身份及獲取您的IMEI、IMSI信息，用於推送下發通知欄消息。這些信息是推送功能所必需的信息，拒絕提供將導致您無法使用該功能，但不影響其他功能的正常使用；",
      221: "(2) 用於在您同意的情況下獲取設備位置權限、蒐集設備信息和日誌信息等；",
      222: "(3) 用於第三方授權服務，包括第三方賬號登陸、將有關內容分享至第三方產品等，QQ SDK、微信SDK可能會訪問您的 ANDROID_ID 等信息；",
      223: "(4) 用於支持產品功能模塊，包括內容存儲、定位等，可能會蒐集您的設備外部存儲狀態、地理位置信息等數據；",
      224: "(5) 用於優化產品性能，包括提高硬件配網能力、減低服務器成本、應用異常統計上報等，可能會蒐集您的設備信息、網絡位置信息等數據；",
      225: "(6) 用於賬號安全、產品加固相關服務，包括網絡監測、域名解析、防劫持、反垃圾反作弊、加解密服務等。",
      226: "我們僅會出於正當、必要、特定的目的共享您的信息。對我們與之共享信息的第三方服務商，我們會要求他們履行相關保密義務並採取相應的安全措施。",
      227: "第三方社交媒體或其他服務由相關的第三方負責運營。您使用該等第三方的社交媒體服務或其他服務（包括您向該等第三方提供的任何信息），須受第三方自己的服務條款及信息保護聲明（而非本政策）約束，您需要仔細閱讀其條款。本政策僅適用於我們所收集的個人信息，並不適用於任何第三方提供的服務或第三方的信息使用規則。如您發現這些第三方社交媒體或其他服務存在風險時，建議您終止相關操作以保護您的合法權益。",
      228: "1.4 其他",
      229: "根據法律法規規定要求、法律程序、訴訟和/或公共機構和政府部門依法提出的要求，騎記APP可能有必要披露您的個人信息。如果我們確定就國家安全、執法或具有公衆重要性的其他事宜而言，披露是必須的或適當的，我們也可能會披露關於您的信息。",
      230: "如果我們確定爲了執行我們的條款或保護我們的經營、權利、資產或產品，或保護用戶，或對於達成以下目的（檢測、預防並解決欺詐、對產品的未授權使用、對我們條款或政策的違反或其他有害或非法活動）披露是合理必要的，我們也可能會披露關於您的信息。（爲避免疑問，在當地數據保護法律明確許可的範圍內且僅限在此情況下，騎記APP纔會在未經您同意的情況下收集、使用或披露您的個人信息。）這可能包括向公共或政府機構提供信息；與第三方合作伙伴交流有關您賬戶可靠性方面的信息，以防止在我們產品內外所進行的欺詐、違規等其他有害行爲。",
      231: "此外，我們可能與下列人員共享您的個人信息：",
      232: "(1) 我們的會計師、審計師、律師或類似的顧問，當我們要求他們提供專業建議時；以及",
      233: "(2) 投資者以及其他相關第三方，如果發生實際或潛在的出售或其他公司交易，且與騎記APP實體相關時；以及",
      234: "(3) 其他任何第三方，如經您授權就特定信息進行披露共享。",
      235: "2. 轉讓",
      236: "除以下情形外，騎記APP不會將您的信息轉讓給任何主體：",
      237: "(1) 獲得您的明確同意；",
      238: "(2) 如果騎記APP參與兼併、收購或出售其資產的全部或部分，我們將通過電子郵件和/或在我們網站上發佈醒目通知，告知對您個人信息所有權、使用方面的任何變化，以及您可能擁有的關於您個人信息的任何選擇。",
      239: "3. 公開披露",
      240: "除在公佈中獎活動名單時會脫敏展示騎記APP賬號、用戶名外，騎記APP僅會在以下情況下，公開披露您的個人信息：",
      241: "(1) 獲得您的明確同意；",
      242: "(2) 基於法律或合理依據的公開披露：包括法律法規規範、法律程序、訴訟或應政府主管部門要求。",
      243: "六. 信息的存儲",
      244: "一般而言，我們僅爲實現目的所必需的時間保留您的個人信息，例如：",
      245: "手機號碼：若您需要使用騎記APP服務，我們需要一直保存您的手機號碼，以保證您正常使用該服務，當您註銷騎記帳戶後，我們將刪除相應的信息；",
      246: "社區信息：當您發送了社區分享，我們需要保存您的社區信息，以保證您正常使用社區功能，當您刪除您的社區信息後，我們將刪除相應的信息。",
      247: "當我們的產品或服務發生停止運營的情形時，我們將以推送通知、公告等形式通知您，並在合理的期限內刪除您的個人信息或進行匿名化處理。",
      248: "由於騎記是個全球化的產品，我們的部分活動包括了全球範圍的步數比拼，我們會把部分相關數據存儲在海外。",
      249: "七. 第三方服務提供商",
      250: "爲方便您的訪問並豐富您的體驗，可能會有第三方提供的產品或服務。您可以選擇是否訪問這類內容或鏈接，或是否使用該第三方的產品或服務。但我們對於第三方提供的產品或服務沒有控制權。我們無法控制第三方掌握的您的任何個人信息。您在使用第三方產品或服務過程中的信息保護問題，不適用於本政策的管理。本政策也不適用於您自行選擇提供給第三方的任何信息。請您查看該第三方的隱私保護政策。",
      251: "參加營銷推廣活動。當您選擇參加我們舉辦的有關營銷活動時，根據活動需要您需要提供姓名、通信地址、聯繫方式、銀行賬號等信息，以便第三方能及時向您提供獎品。當您選擇參加我們舉辦的有關營銷活動時，根據活動需要您需要提供姓名、通信地址、聯繫方式、銀行賬號等信息。經過您的明示同意，我們會將上述信息與第三方共享，以便我們能委託第三方及時向您提供獎品。",
      252: "八. 本政策的更新",
      253: "我們可能會不定期修改、更新本隱私政策，有關隱私政策的更新，我們會在騎記APP 中以通知公告等形式發佈，您可以訪問騎記APP查詢最新版本的隱私政策。對於重大變更，我們會向您發出鄭重通知（包括對於某些服務，我們會通過電子郵件發送通知，說明對隱私政策進行的更改）。",
      254: "九. 適用範圍",
      255: "我們的隱私政策適用於由騎記（廈門）科技有限公司及其關聯公司提供的所有服務，包括騎記APP及 www.iriding.cc 上提供的服務，但是不包括附有獨立隱私政策的服務（如第三方提供的產品和服務）。",
      256: "我們的隱私權政策不適用於第三方提供的產品和服務，例如在騎記APP及 www.iriding.cc 上由第三方提供的產品和服務，以及在我們的服務中鏈接到的其他網站，這些產品、服務或網站會有獨立的隱私政策予以規範，請另行查閱相應的政策規定。",
      257: "對於爲我們的服務進行廣告宣傳，以及可能使用我們的 Cookie 或其他日誌信息來投放和使用廣告的其他公司和組織，我們的隱私權政策並未涵蓋其信息處理政策。",
      258: "十. 如何聯繫我們",
      259: "您可通過以下方式與我們聯繫：",
      260: "如對本政策內容有任何疑問、意見或建議，您可通過騎記APP後臺反饋，騎記微信公衆號後臺與我們聯繫；",
      261: "如果發現個人信息可能被泄露，您可以通過騎記APP投訴舉報，我們將在15個工作日內聯絡您並完成核查和處理；",
      262: "我們還設立了意見、建議反饋郵箱，您可以通過 support@miriding.com 與我們聯繫，我們將在15個工作日內聯絡您並完成核查和處理。",
      263: "若您對本政策有任何問題，請聯繫：privacy@miriding.com，我們將在15個工作日內聯絡您並完成核查和處理。",
    },
  },
  downLoad: {
    v1: "騎記APP",
    v2: "科 技 之 美， 成 就 騎 行 樂 趣",
    v3: "適用於中國大陸地區",
    v4: "適用於其他地區",
    v5: "應用商店 ",
    v6: "本地下載",
  },
  index: {
    v1: {
      text0: "騎記",
      text1: "首頁",
      text2: "電助力車",
      text3: "配件",
      text4: "APP",
      text5: "支援",
      text6: "公司簡介",
      text7: "誕生",
      text8: "2011年，騎記（廈門）科技有限公司在廈門成立。",
      text9: "奔跑",
      text10: "2013年，騎行運動社交軟體騎記APP1.0正式上線。",
      text11:
        "歷時兩年，使用者裝機量和註冊量超越百萬，成為騎行使用者首選的騎行軟體。",
      text12: "截止目前，使用者騎行總里程共1.3億km，可自地球往返月球175次。",
      text13: "提速",
      text14: "2014年9月，成為小米生態鏈企業。",
      text15:
        "騎記投身硬體研發，陸續推出環法級別的R1碳纖維公路車，米家騎記電助力折疊車，米兔兒童車，米家充氣寶等多款產品。",
      text16: "揚帆",
      text17: "2019年，小米電助力折疊腳踏車，米家充氣寶陸續出海。",
      text18:
        "目前騎記產品在亞洲，歐洲，大洋洲，南美洲四大洲的十餘個國家和地區銷售。",
      text19: "2020年，推出騎記電助力腳踏車和配件產品，開啟自有品牌的新征程。",
      text20: "沉澱",
      text21:
        "未來，騎記將持續專注於研發面向未來的運動出行產品，透過扎實的產品研發和設計實力，為使用者帶來更好的出行體驗。",
      text22: "發展歷程",
      text23: "臺灣產新歐標電助力折疊腳踏車完成認證，8月份在歐洲開售",
      text24:
        "EF1成為國內最早完成EN15194-2017認證並在歐洲銷售的中國品牌電助力腳踏車",
      text25:
        "受疫情和歐洲購物補貼影響，腳踏車銷售火爆，小米電助力折疊腳踏車訂單逐月增加",
      text26: "售價199元人民幣的米家充氣寶在小米網上線銷售",
      text27:
        "「米兔兒童車14寸」，在小米商城上線銷售，並斬獲2020德國紅點設計獎",
      text28:
        "新國標騎記電動助力腳踏車小米網上線銷售，並獲取腳踏車車架工藝等相關專利18項",
      text29:
        "米家騎記電助力腳踏車國內售出51800台，累計銷售額1.35億元，在國內電商開創了電助力腳踏車的新品類",
      text30: "連續兩年單品銷量第一，2017年市場佔有率高達91.3%",
      text31: "iFixit拆解米家電助力腳踏車給予高度讚揚",
      text32: "騎記公司被廈門智慧產權局認定為市工業設計智慧產權優勢企業",
      text33: "開發售後APP，解決備件，維修指導，費用結算等實際需求",
      text34: "透過簽約272家騎記城市合夥人為騎記產品提供售後服務",
      text35:
        "米家電助力折疊腳踏車由小米聯合創始人劉德發佈，產品獲得小米CEO雷軍點贊",
      text36: "鋁合金鍛造技術在腳踏車上的首次應用",
      text37: "Can匯流排技術在腳踏車上的首次應用",
      text38: "獲取縱向折疊等腳踏車相關專利11項",
      text39: "TMM力矩感測器首次大規模量產",
      text40: "QiCYCLE R1 碳纖維功率腳踏車小米網眾籌",
      text41: "QiCYCLE R1 斬獲兩項紅點設計獎",
      text42: "R1因具備完整的運動數據獲取能力獲得臺北腳踏車創新獎",
      text43:
        "騎記成為小米生態鏈的一員，負責生活出行方向的智能硬體產品研發和生態佈局",
      text44: "騎記公司更名為騎記（廈門）科技有限公司",
      text45: "騎記獲得小米天使輪融資",
      text46: "騎記加入小米生態鏈",
      text47: "開發騎行軟體「騎記」APP並上線營運",
      text48: "騎記APP使用者量突破124萬",
      text49: "騎記使用者累積騎行軌跡達到6500萬km，成為全國最大騎行社區",
      text50: "騎記（廈門）科技有限公司於廈門成立",
      text51: "匠心產品",
      text52: "創新設計",
      text53: "產品斬獲德國紅點設計獎，設計iF獎等獎項，並獲得多項發明專利",
      text54: "專注研發",
      text55: "由業內專家級成員帶隊的研發團隊關注細節，持續推進關鍵技術創新",
      text56: "嚴格品控",
      text57: "多道品質檢測流程，層層把關整車品質，向使用者提供高品質的產品",
      text58: "高效售後",
      text59: "不斷探索更貼心的線下服務模式，讓使用者獲得更優質的產品體驗",
      text60: "辦公地點",
      text61: "下列城市",
      text62: "北京",
      text63: "北京市海淀區安寧莊東路16號 融科融智·蜂巢工場東區2號樓",
      text64: "常州",
      text65: "江蘇省常州市新北區創新大道89號",
      text66: "上海",
      text67: "上海市徐匯區田林路142號G棟104B",
      text68: "深圳",
      text69:
        "深圳市南山區粵海街道高新區社區粵興四道1號中山大學深圳產學研大廈17層",
      text70: "法蘭克福",
      text71:
        "Adam-Opel-Str. 10 im Hof， Geb. A, bei eKey 60386 Frankfurt am Main, Germany",
      text72: "廈門",
      text73: "福建省廈門市湖裏區岐山北路768號403室",
      text74: "香港",
      text75: "香港九龍長沙灣青山道339號恆生青山道大廈1102室",
      text76: "蘇州",
      text77: "江蘇省蘇州市高新區獅山路28號蘇州高新國際商務廣場606",
      text78: "社交媒體",
      text79: "售後服務熱線",
      text80: "聯絡我們",
      text81: "商務合作",
      text82: "國內銷售",
      text83: "海外銷售",
      text84: "客戶服務",
      text85: "數據保護",
      text86: "騎記 © 2013-2021 閩ICP備13003500號",
      text87: "隱私和法律",
      text88: "您的Cookies偏好",
      text89: "歡迎來到騎記！",
      text90: "為給您提供更好的網站體驗，我們希望使用Cookies來改善我們的網站。",
      text91: "Cookies收集的資訊不會識別到您個人。",
      text92:
        "有關我們使用的Cookies的類別及關於我們Cookies的更多迅息，請閱讀我們的Cookies政策。",
      text93: "拒絕",
      text94: "接受",
      text100:
        "為給您提供更好的網站體驗，我們希望使用Cookies來改善我們的網站。Cookies收集的資訊不會識別到您個人。有關我們使用的Cookies的類別及關於我們Cookies的更多迅息，請閱讀我們的",
      text101: "Cookies政策",
      text102: "騎記",
      text103: "法律資訊",
      text104: "騎記",
    },
  },
  product: {
    v1: {
      productMove: "探索更多",
      productCpd: "騎記充氣寶 P1",
    },
  },
  contact: {
    // 繁體中文
    v1: "聯繫我們",
    v2: "商務合作",
    v3: "郵箱地址：market@iriding.com",
    v4: "銷售團隊",
    v5: "國內銷售：sales@iriding.com",
    v6: "海外銷售：sales@iriding.com",
    v7: "客戶服務",
    v8: "售後服務熱線：400-1144-177",
    v9: "數據保護",
    v10: "郵箱地址：dpo@iriding.com",
  },
  details: {
    v1: "騎記充氣寶P1",
    v2: "顏色",
    v3: "立即購買",
    v4: "概述",
    v5: "規格參數",
    v6: "技術支援",
    v7: "精准充氣的迅猛龍",
    v8: "快速補氣約60秒",
    v9: "高壓可達150psi",
    v10: "整胎充氣約5分鐘",
    v11: "預設壓力自動停",
    v12: "5種使用模式",
    v13: "應急充電寶",
    v14: "強勁動力",
    v998: "突破充氣想像",
    v15: "高性能機芯和大功率電機加持，",
    v16: "零胎壓充滿約5分鐘",
    v16a: "5分鐘整胎充氣",
    v1611: "滿電可充約",
    v1611a: "滿電可充約",
    v1631b: "條汽車輪胎",
    v1621: "5",
    v1631: "分鐘",
    v1711: "約",
    v1711a: "約",
    v1721: "分鐘/條",
    v1721a: "分鐘/條",
    v17: "滿電可為約5條轎車輪胎充氣，約5分鐘/條",
    v999: "約5分鐘/條",
    v18: "體型小巧易收納",
    v19: "機身高度約20cm",
    v20: "單手可操控，",
    v21: "體型小巧收納靈活",
    v22: "騎行自駕，輕裝上陣",
    v23: "高精度氣缸",
    v231: "速度快氣壓高",
    v24: "壓力輕鬆達到高至150psi，",
    v24a: "壓力輕鬆達到150psi",
    v25: "滿足公路車輪胎等所需的高氣壓，",
    v26: "充氣不費「吹」灰之力",
    v261: "滿電可為",
    v262: "16",
    v263: "條公路自行車輪胎充氣",
    v2631: "條公路自行車輪胎",
    v27: "滿電可為16條公路自行車輪胎充氣，約63秒/條",
    v9971: "約",
    v9972: "63",
    v9973: "秒/條",
    v997: "約63秒/條",
    v272: "預設壓力值",
    v271: "精准充好自動停",
    v28: "按需設定氣壓值",
    v29: "充氣完成自動停止",
    v30: "隨時隨地精准測壓",
    v30a: "按需設定氣壓值充氣完成自動停止",
    v30b: "隨時隨地精准測壓",
    v31: "高精度氣壓傳感器",
    v311: "",
    v312: "氣壓傳感器精度±1psi",
    v32: "5種使用模式，自由切換",
    v33: "汽車、摩托車、自行車、球類、專業模式，",
    v34: "限定氣壓範圍",
    v35: "選定模式充氣，操作更安全",
    v36: "默認2.5bar",
    v361: "汽車模式",
    v3611: "默認壓力：2.5bar",
    v3612: "默認壓力：2.4bar",
    v362: "默認壓力",
    v37: "可調整範圍：1.8-3.5bar",
    v371: "1.8-3.5bar",
    v3711: "可调整范围：1.8-3.0bar",
    v38: "摩托車模式",
    v381: "2.4bar",
    v39: "可調整範圍",
    v391: "1.8-3.0bar",
    v40: "自行車模式",
    v41: "默認",
    v411: "45psi",
    v581: "无负载连续工作时长可达35分钟",
    v451: "可調整範圍：3-150psi",
    v451a: "3-150psi",
    v451b: "0.2-10.3bar",
    v2121: "嚴苛測試",
    v2122: "打造耐久品質",
    v4111: "默認壓力：45psi",
    v412: "可調整範圍",
    v4121: "可调整范围：30-145psi",
    v413: "30-145psi",
    v42: "球類模式",
    v462: "0.2-10.3bar",
    v423: "可調整範圍：4-16psi",
    v422: "默認壓力：8psi",
    v421: "8psi",
    v43: "4-16psi",
    v44: "專業模式",
    v45: "3-150psi",
    v46: "0.2-10.3bar",
    v461: "3-150psi / 0.2-10.3bar",
    v47: "內置18650鋰電池",
    v48: "操作不受“線”",
    v49: "無論您想去山間探索還是海邊踏浪，",
    v50: "無需外接電源",
    v51: "充氣無拘無束",
    v52: "精緻細節，為你的生活打氣",
    v53: "自帶照明，方便易用",
    v54: "LED照明，夜間操作不怕黑",
    v55: "SOS爆閃，危機時刻更從容",
    v56: "雙重散熱，連續工作",
    v57: "大功率風扇配合機身散熱孔",
    v58: "無負載連續工作時長可達40分鐘",
    v59: "是打氣筒，也是充電寶",
    v60: "全球通用Type-C接口，充電無憂",
    v61: "USB-A接口可為手機充電",
    v611: "USB適配器充電",
    v612: "車載充電器充電",
    v613: "充電寶充電",
    v62: "數碼大屏，實時顯示",
    v63: "4.4英寸高清大屏實時顯示",
    v631: "當前胎壓、預設胎壓、使用模式等數據",
    v64: "配件齊全，貼心收納",
    v65: "收納袋保護機身",
    v66: "夾層可存放氣嘴、氣針、充電線",
    v67: "通過多項嚴苛測試",
    v68: "過壓力保護功能測試",
    v69: "氣管拉力強度測試",
    v70: "電氣強度試驗",
    v71: "氣管低溫冷彎折試驗",
    v72: "自由跌落測試",
    v73: "持續工作時間測試",
    v74: "抗壓測試",
    v75: "高/低溫充電測試",
    v76: "騎記充氣寶P1性能參考",
    v77: "1bar=14.5psi=100kPa",
    v78: "充氣對象",
    v79: "汽車輪胎",
    v80: "175/70 R14（補氣）",
    v81: "五菱宏光",
    v82: "（175/70 R14）",
    v83: "別克英朗",
    v84: "（195/65 R16）",
    v85: "大眾高爾夫",
    v86: "（205/55 R16）",
    v87: "豐田凱美瑞",
    v88: "（215/55 R17）",
    v89: "路虎攬勝",
    v90: "（255/55 R20）",
    v91: "寶馬730",
    v92: "（275/40 R19）",
    v93: "摩托車輪胎",
    v94: "（150/60 R17）",
    v95: "摩托車輪胎",
    v96: "（110/70 R17）",
    v97: "公路自行車輪胎",
    v98: "（700 × 25C）",
    v99: "山地自行車輪胎",
    v100: "（27.5 × 1.95）",
    v101: "電動滑板車輪胎",
    v102: "（8 1/2 x 2）",
    v103: "平衡車輪胎",
    v104: "（9 x 3.0-6）",
    v105: "7號籃球",
    v106: "5號足球",
    v107: "5號排球",
    v108: "充氣壓力",
    v109: "2.0-2.5bar",
    v110: "0-2.5bar",
    v111: "0-2.4bar",
    v112: "0-120psi",
    v113: "0-50psi",
    v114: "0-40psi",
    v115: "0-30psi",
    v116: "0-9psi",
    v117: "0-11psi",
    v118: "0-6psi",
    v119: "滿電可充數量",
    v120: "≈ 18條",
    v121: "≈ 5條",
    v122: "≈ 4條",
    v123: "≈ 3條",
    v124: "≈ 2條",
    v125: "≈ 7條",
    v126: "≈ 15條",
    v127: "≈ 16條",
    v128: "≈ 20條",
    v129: "≈ 201條",
    v130: "≈ 97條",
    v131: "≈ 92個",
    v132: "≈ 86個",
    v133: "≈ 197個",
    v134: "充氣速度",
    v135: "≈ 60秒/條",
    v136: "≈ 4分58秒/條",
    v137: "≈ 6分3秒/條",
    v138: "≈ 5分52秒/條",
    v139: "≈ 7分20秒/條",
    v140: "≈ 12分30秒/條",
    v141: "≈ 9分10秒/條",
    v142: "≈ 2分50秒/條",
    v143: "≈ 1分30秒/條",
    v144: "≈ 63秒/條",
    v145: "≈ 58秒/條",
    v146: "≈ 10秒/條",
    v147: "≈ 14秒/條",
    v148: "≈ 18秒/個",
    v149: "≈ 20秒/個",
    v150: "≈ 10秒/個",
    v1510: "[1] ",
    v1520: "[2] ",
    v1530: "[3] ",
    v1540: "[4] ",
    v1550: "[5] ",
    v1560: "[6] ",
    v1570:
      "本產品滿電狀態下，可將18條175/70 R14汽車輪胎從2.0bar補氣至2.5bar，約60秒補滿一條輪胎。",
    v1580:
      "本產品滿電狀態下，可將約5條175/70 R14汽車輪胎從0bar充氣至2.5bar，約5分鐘充滿一條輪胎。",
    v1590: "保壓模式需配合模型噴漆筆、PA壺、氣囊千斤頂等工具使用，請自行購買。",
    v1591: "USB-A接口輸出參數為5V⎓2A，可作為5000mAh移動電源使用。",
    v1592:
      "此八項測試資料來自南德認證檢測（中國）有限公司深圳分公司及騎記實驗室，測試報告編號：QJSZ20210731.01，QJSZ20210806.01，QJSZ20210729.01，QJSZ20210831.01，QJSZ20210519.01，QJSZ20210803.01，68.189.21.0074.01。",
    v1593:
      "表格中資料均為滿電狀態下的測試資料，實際值與環境、溫度、電量、使用習慣等因素不同可能略有差異。",
    v1594: "注：本頁面其他資料如無特殊說，均來源於騎記實驗室。",
    v151: "本產品滿電狀態下",
    v152: "可將18條175/70 R14汽車輪胎從2.0bar補氣至2.5bar",
    v153: "約60秒補滿一條輪胎",
    v154: "本產品滿電狀態下",
    v155: "可將約5條175/70 R14汽車輪胎從0bar充氣至2.5bar",
    v156: "約5分鐘充滿一條輪胎",
    v157: "保壓模式需配合模型噴漆筆、PA壺、氣囊千斤頂等工具使用",
    v158: "請自行購買",
    v159: "USB-A接口輸出參數為5V⎓2A",
    v160: "可作為5000mAh移動電源使用",
    v161: "此八項測試資料來自南德認證檢測（中國）有限公司深圳分公司及騎記實驗室",
    v162: "測試報告編號",
    v163: "表格中資料均為滿電狀態下的測試資料",
    v164: "實際值與環境、溫度、電量、使用習慣等因素不同可能略有差異",
    v165: "注：",
    v166: "本頁面其他資料如無特殊說，均來源於騎記實驗室。",
    v167: "均來源於騎記實驗室",
    v168: "説明書×1",
    v169: "收納袋×1",
    v170: "高壓氣管（含美式氣嘴）×1",
    v171: "充電綫×1",
    v172: "氣針×1",
    v173: "法式氣嘴轉換頭×1",
    v174: "騎記充氣寶P1×1",
    v175: "包裝清單",
    v176: "可自動加壓",
    v177: "創新保壓模式",
    v178: "低於設置值立即開始加壓，自動保持容器壓力，噴漆，澆花，洗車，輕鬆省事",
    v191: "開機后按住燈光鍵盤",
    v192: "並按壓「+」鍵盤，直到",
    v193: "圖標出現",
    v194: "產品名稱",
    v195: "騎記充氣寶P1",
    v196: "產品型號",
    v197: "QJCQBP1",
    v198: "產品尺寸",
    v199a: "200.5 × 63.5 × 63.5mm",
    v199b: "（裸机尺寸，不含气管）",
    v200: "充氣壓力範圍",
    v201: "3-150psi / 0.2-10.3bar",
    v202: "電池種類",
    v203: "鋰離子電池",
    v204: "電池容量",
    v2041: "28.86 Wh / 11.1V",
    v205: "28.86Wh/11.1V",
    v206: "傳感器精度",
    v207: "±1.5 psi",
    v208: "充電用時",
    v209: "小於4小時",
    v210: "輸入參數",
    v211: "5V ⎓ 3A",
    v212: "嚴苛測試，打造耐久品質",
    v213: "場景多樣，自由切換",
    v2131: "场景多样",
    v2132: "自由切换",
    v214: "擺脫牽絆，",
    v214a: "擺脫牽絆",
    v215: "經典黑",
    v216: "顔值高",
    v217: "，告別繁雜的脚踩手捏檢查。",
  },
  C2: {
    v0: "QiCYCLE C2",
    v1: "電動助力自行車      C2",
    v2: "城市穿行&nbsp;&nbsp;&nbsp;&nbsp;自由超乎想像",
    v3: "顏色",
    v4: "黑色",
    v5: "輕便小巧",
    v6: "智能大燈",
    v7: "超長續航",
    v8: "強勁鋰電",
    v9: "八擋變速",
    v10: "光感大屏",
    v11: "折疊頭管",
    v12: "新國標",
    v13: "概述",
    v14: "規格參數",
    v14a: "產品說明書",
    v15: "支持",
    v16: "外觀時尚顏值高",
    v17: "到哪都是C位",
    v18: "車身比例經典、造型優雅，出街利器，一路吸睛。",
    v19: "騎行體驗享舒適",
    v20: "人體工學設計",
    v21: "符合人體工學的車架結構，舒適省力，助你人车合一。",
    m21a: "符合人體工學的車架結構，舒適省力，助你人車合",
    m21b: "一",
    v22: "*電動自行車上路行駛需申領牌照，請遵守交通法規，注意騎行安全。",
    v23: "超輕車體，操控靈活",
    v24: "航空級鋁合金打造的多型腔車架",
    v24a: "，轉向靈活、操控輕盈，",
    v24b: "小空間也能輾轉騰挪。",
    m24a: "航空級鋁合金打造的多型腔車架，轉向靈活、操",
    m24b: "控輕盈，小空間也能輾轉騰挪。",
    v25: "輕量級車身，重量級承載",
    v26: "整車約重20kg, 可安全承重120kg, 男女老少均可駕馭",
    v26d: "  。",
    v26c: "*前車筐、後貨架、海綿坐墊、兒童座椅等配件請根據實際需要另行選購。",
    m26a: "整車約重20kg, 可安全承重120kg, 男女老少均可",
    m26b: "駕馭",
    v27: "高效助力，如虎添翼",
    v27a: "尽享騎行樂趣",
    v28: "高精度傳感器",
    v29: "懂你的每一次踩踏",
    v30: "內置中軸力矩傳感器，敏銳捕捉蹬踏數據，<br/>提速高效，車隨心動，任意而行。",
    m30a: "敏銳捕捉蹬踏數據，提速高效，車隨心動，",
    m30b: "任意而行。",
    v31: "C2 PRO",
    v32: "力矩傳感器",
    v33: "C2",
    v34: "速度傳感器",
    v35: "1.顯示騎行數據",
    v36: "2.控制助力輸出",
    v37: "3.感應踩踏力度",
    v38: "4.驅動電機行駛",
    v39: "多擋位選擇，多場景兼顧",
    v40: "三擋助力模式自由切換，超長續航約60km",
    v40c: "。",
    v41: "人力：",
    v42: "電助力：",
    v43: "電助力輸出",
    v44: "1擋",
    v45: "耗電低，長續航",
    v46: "2擋",
    v47: "兼顧續航與動力",
    v48: "3擋",
    v49: "動力強，加速快",
    v50: "助推模式",
    v51: "路遇上坡，下車推行不費力。",
    v52: "八擋變速器",
    v52c: "，",
    v52a: "精準流暢",
    v53: "八個擋位自由調節，配合電助力，迎風挑戰“魔鬼”上坡。",
    m53a: "八個擋位自由調節，配合電助力，迎風挑戰",
    m53b: "“魔鬼”上坡。",
    v54: "八擋變速",
    v55: "暢騎這座城市",
    v56: "你的安全我放心上",
    v57: "鋰電黑科技",
    v57a: "電池倉獨立",
    v58: "內置30節動力鋰電池，線路與電池安全隔離，",
    v58a: "保障用電安全。",
    v59: "*本品為內置電池，",
    v60: "請勿自行拆卸。",
    v61: "汽車級電源管理",
    v62: "汽車級BMS安全管理系統，充滿即停，保障充電安全。",
    m62a: "汽車級BMS安全管理系統，充滿即停，保障充",
    m62b: "電安全。",
    v63: "低溫容量測試",
    v64: "高溫容量測試",
    v65: "IPX5測試",
    v66: "自由跌落測試",
    v67: "過充保護",
    v68: "過放保護",
    v69: "加速度衝擊測試",
    v70: "短路保護",
    v71: "高低溫衝擊測試",
    v72: "耐振動測試",
    v73: "130℃高溫測試",
    v74: "荷電保持能力",
    v75: "按鍵、指撥，就在手邊",
    v76: "功能清晰，操控簡單，長輩也能輕鬆掌握。",
    v77: "中央光感顯示屏",
    v78: "自動化智能調節屏幕亮度，擋位、電量、速度數",
    v78a: "據盡現眼前，白天看得清，夜晚不刺眼。",
    m78a: "自動化智能調節屏幕亮度，擋位、電量、速度",
    m78b: "數據盡現眼前，白天看得清，夜晚不刺眼。",
    v79: "燈光自動感應",
    v80: "光感控制前後燈開關，",
    v81: "自动点亮或熄灭。",
    m81a: "自动点亮或熄灭。剎車",
    v82: "剎車尾燈高亮，",
    m82a: "尾燈高亮，",
    v83: "騎行不分心，",
    v84: "行車更安全。",
    v85: "增減配件 ",
    v85a: "打造百變的你",
    v86: "職場達人、家居能手、時尚辣媽......增減配件即可完成角色轉變。",
    m86a: "職場達人、家居能手、時尚辣媽......增減配件即",
    v86b: "可完成角色轉變。",
    v87: "增減配件",
    v88: "騎記App",
    v89: "記錄生活點滴",
    v90: "接入騎記App，呈現並記錄速度、里程等數據，分享騎行生活",
    v90a: "生活，一鍵升級固件。",
    m90a: "接入騎記App，呈現並記錄速度、里程等數據，分",
    v90b: "享騎行生活，一鍵升級固件。",
    v91: "安裝騎記App並綁定車輛，可延長一年保修服務",
    v92: "連接App",
    v93: "匠心設計，創造「騎記」",
    v94: "永磁無刷電機",
    v95: "觸發澎湃動力",
    v96: "20英寸輪徑",
    v97: "享靈活騎趣",
    v98: "頭管可折疊",
    v99: "做後備箱的旅行家",
    v100: "前後碟剎",
    v101: "跑多快都剎得穩",
    v102: "快調座管夾",
    v103: "高低你說了算",
    v104: "精選充氣輪胎",
    v105: "穩如泰山",
    v106: "[1]    CCC認證機構：中國品質認證中心，证书编号：2021011119391871",
    v107: "[2]    多型腔自行車車架，獲得多項實用新型專利，專利編號分別為：ZL201820720214.X；ZL201822033605.7；ZL201821913718.X。",
    v108: "[3]    本產品適用身高155-185cm。",
    v109: "[4]    續航60km測試條件：2擋模式下，關閉照明燈，承重70kg，勻速20km/h在都市鋪裝路面上騎行。 實際續航會因載重、溫度、路面情况、操作習慣等因素不同略有差异。   ",
    v110: "[5]    數據來源於福建飛毛腿動力科技有限公司檢測中心，報告編號：DC210073",
    v111: "[6]    騎行狀態下，前後燈可根據環境光線變化自動開/關。 ",
    v112: "[7]    前車筐、後貨架、海綿坐墊、兒童座椅等配件請根據實際需要另行選購。 ",
    v113: "",
    v114: "[8]    請在座管最高及最低安全線之間調節座墊高度。",
    v115: "[9]  本頁面中產品輪胎紋路僅供參考，由於輪胎批次不同，實物可能略有差异，請以實物為准。",
    v116: "注：本頁面所涉及的數據如無特殊說明，均來源於騎記實驗室。",
    v120: "騎記電動助力自行車 參數",
    v121: "產品參數",
    v121a: "產品名稱",
    v122: "電助力自行車",
    v123: "最高設計車速",
    v124: "25Km/h",
    v125: "產品型號",
    v126: "TDN09Z",
    v127: "剎車方式",
    v128: "機械碟剎",
    v129: "產品尺寸（折疊前）",
    v130: "1530×600×1060 mm",
    v131: "牙盤",
    v132: "56T×170mm 鋁曲柄",
    v133: "產品尺寸（折疊後）",
    v134: "1530×430×725 mm",
    v135: "花鼓",
    v136: "鋁花鼓",
    v137: "整車質量",
    v138: "約20 kg",
    v139: "充電時間",
    v140: "約5.5小時",
    v141: "輪組尺寸",
    v142: "20英寸",
    v143: "車身防水等級",
    v144: "IPX 3",
    v145: "載重量",
    v146: "120 kg",
    v147: "屏幕尺寸",
    v148: "53×32 mm",
    v149: "電池容量",
    v150: "7.5 Ah",
    v151: "無線連接",
    v152: "藍牙 5.0",
    v153: "包裝清單",
    v154: "電助力自行車×1",
    v155: "4、5、6 mm <br/> 内六扳手×1",
    v155a: "内六扳手×1",
    v155c: "4、5、6 mm 内六扳手×1",
    v156: "左右腳踏×1",
    v157: "電源適配器×1",
    v158: "牌照架×1",
    v159: "M5螺絲×4",
    v160: "使用說明書×1",
    v161: "產品合格證×1",
    v162: "三包憑證×1",
  },
  F2: {
    v0: "QiCYCLE F2",
    v1: "騎記電助力折疊自行車F2",
    v2: "展開折疊的城市",
    v3: "顏色",
    v4: "經典黑",
    v5: "力矩傳感",
    v6: "折疊便攜",
    v7: "電池可拆",
    v8: "超長續航",
    v9: "行車電腦",
    v10: "定速巡航",
    v11: "內三變速",
    v12: "16寸轮径",
    v13: "概述",
    v14: "規格參數",
    v15: "支持",
    v16: "助力隨叫隨到",
    v17: "懂你的每一次踩踏",
    v18: "力矩傳感器採集踩踏力度數據，控制助力大小。",
    v18a: "力矩傳感器採集踩踏力度數據，控制助力大小。神經般敏感",
    v18b: "的助力輸出助你人車合一。",
    m18a: "力矩傳感器採集踩踏力度數據，控制助力大小。神",
    m18b: "經般敏感的助力輸出助你人車合一。從此無懼崎",
    m18c: "嶇，爬坡如履平地。",
    v19: "神經般敏感的助力輸出助你人車合一",
    v20: "從此無懼崎嶇，爬坡如履平地。",
    v21: "力矩傳感系統原理",
    v22: "1.顯示騎行數據",
    v23: "2.控制助力輸出",
    v24: "3.感應踩踏力度",
    v25: "4.驅動電機行駛",
    v26: "折叠收纳更小巧",
    v27: "后备箱里的常备神器",
    v27a: "盡享騎行樂趣",
    v28: "縱向折疊專利設計",
    v29: "， 主梁完整也能實現折疊",
    m29a: "主梁完整也能實現折疊，鋁",
    v30: "鋁合金鍛造前、後叉輕量又堅固，",
    v30a: "，鋁合金鍛造前、",
    v30b: "後叉輕量又堅固，",
    m30a: "合金鍛造前",
    v31: "承重高達100kg，",
    v32: "騎行更安全。",
    v33: "專利號：201420243615.2",
    v34: "折疊後體積",
    m34a: "≈0.25m",
    v35: "電池可拆卸",
    v36: "充電更方便",
    v37: "車架內置20節18650動力鋰電池。",
    v38: "汽車級BMS安全管理系統，",
    m38b: "全管理系統，",
    m38a: "汽車級BMS安",
    v38a: "充滿即停，確保用電安全。",
    v39: "電池拆裝簡單，",
    v40: "既能整車直充，",
    v41: "也能電池單充",
    v42: "電池可拆",
    v43: "SHIMANO內三速系統，",
    v43a: "內三速系統，變速精準",
    v44: "變速精準",
    v45: "禧瑪諾內三速變速系統，",
    v46: "精準變速，",
    v47: "堅固耐用。",
    m47a: "堅固耐用。配",
    m47b: "合電助力，",
    m47c: "挑戰“魔鬼”上坡。",
    v48: "配合電助力，",
    v49: "迎風挑戰“魔鬼”上坡。",
    v50: "三種騎行模式",
    v51: "更快、更遠盡在掌控",
    v52: "定速模式，",
    v53: "慵懶輕鬆",
    v54: "一鍵鎖定騎行速度，無需踩踏，騎行更輕鬆。",
    v55: "定速模式",
    v56: "助力模式，",
    v57: "省力省電",
    v58: "三擋助力模式自由切換，超長續航約60km",
    v58c: "。",
    v59: "2擋助力續航約60km[2]。",
    v60: "人力",
    v61: "電助力",
    v62: "1擋",
    v62a: "耗電低，長續航",
    v63: "2擋",
    v63a: "兼顧續航與動力",
    v64: "3擋",
    v64a: "動力強，加速快",
    v65: "電助力輸出",
    v66: "單車模式，",
    v67: "強身健體",
    v68: "N擋零助力，適合騎行健身、鍛煉，就像自行車一樣輕鬆好騎。",
    v68a: "N擋零助力，適合騎行健身、鍛煉，就像自行車一",
    v68b: "樣輕鬆好騎。",
    m68a: "N擋零助力，適合騎行健身、鍛煉，就像自行車",
    m68b: "一樣輕鬆好騎。",
    v69: "行車電腦，掌控騎行數據",
    v69a: "掌控騎行數據",
    v69b: "行車電腦",
    v70: "騎行擋位輕鬆切換，彩屏行車電腦顯示速度、擋位、電量、里程信息。",
    v71: "彩屏行車電腦顯示速度、擋位、電量、里程信息。",
    v71b: "彩屏行車電腦顯示速度、擋位、電量、里",
    v71a: "程信息。",
    m71a: "彩屏行車電腦顯示速度、",
    m71b: "擋位、電量、里程信息。",
    v72: "行之有據，心中有底。",
    v73: "助力模式控制",
    v74: "騎行數據檢測",
    v75: "連接騎記App",
    v75a: "指導科學騎行",
    v76: "App呈現並記錄速度、里程、心率、踏頻",
    v76a: "機同步到雲端，方便隨時查看，科學指導你的騎行。",
    v76b: "等數據，通過手",
    m76a: "App呈現並記錄速度、里程、心率、踏頻等數據，",
    m76b: "通過手機同步到雲端，方便隨時查看，科學指導你",
    m76c: "的騎行。",
    v77: "安裝騎記App並綁定車輛，可延長一年保修服務。",
    v78: "連接App",
    v79: "匠心設計，精彩細節",
    v80: "高亮LED前大燈",
    v81: "照亮夜行路",
    v82: "獨特環形尾燈",
    v83: "守護騎行安全",
    v84: "適合城市騎行的16寸車輪",
    v85: "助你穩步前行",
    v86: "羅拉剎車",
    v87: "終身免維護",
    v88: "超輕鋁合金鍛造車架",
    v89: "小巧靈活，成就超強續航",
    v90: "整車IPX4級防水",
    v91: "無懼風吹雨打",
    v92: "伸縮擋泥板",
    v93: "方便整車折疊",
    v94: "實用新型專利名稱：",
    v95: "可縱向旋轉的折疊車架，",
    v96: "[1]      實用新型專利名稱：可縱向旋轉的折疊車架，專利號：201420243615.2。",
    v97: "[2]      續航60km條件：助力為2擋模式下，承重75kg，勻速20km/h在城市鋪裝路面上騎行。實際續航會因載重、溫度、路面情況、操作習慣等因素不同略有差異。",
    v98: "[3]      獲取心率、踏頻數據需要連接心率帶、踏頻器等符合藍牙5.0協議的外接設備，請另行購買。",
    v99: "[4]      整車通過IPX4防水測試，任意方向直接受到水的濺射不影響車輛工作性能。請勿將整車在水里浸泡或使用高壓水槍沖洗。雨天騎行時，禁止讓電機在深水中行駛，嚴禁讓水面超過電機輪軸位置。測試報告編號：TH60150A/2019，檢測機構：SGS Taiwan Ltd.。",
    v100: "注：1.本頁面中，產品輪胎紋路僅供參考，由於輪胎批次不同，實物可能略有差異，請以實物為準。",
    v106a: "2.本頁面數據如無特殊說明，均來源於騎記實驗室。",
    v107: "騎記電助力折疊自行車",
    v108: "參數",
    v109: "產品參數",
    v110: "產品名稱",
    v111: "電動自行車",
    v112: "產品型號",
    v113: "TDR08Z",
    v114: "產品尺寸（折疊前）",
    v115: "1290 × 540 × 950mm",
    v116: "產品尺寸（折疊後）",
    v117: "1000 × 450 × 650mm",
    v118: "整車質量",
    v119: "約14.5kg",
    v120: "輪組尺寸",
    v121: "16英寸",
    v122: "載重量",
    v123: "100kg",
    v124: "電池容量",
    v125: "6Ah",
    v126: "充電時間",
    v127: "約4.5小時",
    v128: "防水等級",
    v129: "IPX4",
    v130: "屏幕尺寸",
    v131: "1.77英寸",
    v132: "無線連接",
    v133: "藍牙5.0",
    v134: "包裝清單",
    v135: "折疊助力自行車x 1",
    v136: "4、5、6mm",
    v136a: " 內六角扳手x 1",
    v136c: "4、5、6mm 内六角扳手x 1",
    v137: "左、右腳踏 x 1",
    v138: "電源適配器 x 1",
    v139: "使用說明書 x 1",
    v140: "產品合格證 x 1",
  },
};
