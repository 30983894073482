<template>
  <div v-if="isRouterAlive">
    <div>
      <phone-header v-if="isShowHeader"></phone-header>
    </div>
    <div class="phone">
      <router-view />
    </div>
    <div>
      <phone-footer v-show="isShowFooter"></phone-footer>
    </div>
  </div>
</template>

<script>
import phoneHeader from "./component/header/phoneHeader";
import phoneFooter from "./component/footer/phoneFooter";
export default {
  name: "adminRouter",
  components: {
    phoneHeader,
    phoneFooter,
  },
  data() {
    return {
      isRouterAlive: true,
    };
  },
  provide() {
    return {
      reload: this.reload,
    };
  },
  computed: {
    isShowHeader() {
      return this.$store.state.isShowHeader;
    },
    isShowFooter() {
      return this.$store.state.isShowFooter;
    },
  },
  methods: {
    reload(route, value) {
      let jg = "";
      let router = "";
      jg = route.split("/");
      console.log(8, route.split("/"));
      if (value != "zh-cn" && value != "zh-hk" && jg[3] === "p1") {
        value = "zh-cn";
      }
      jg[1] = value;
      jg = jg.join("/");
      router = jg;
      this.isRouterAlive = false;
      this.$router.replace({ path: router });
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
  },
};
</script>

<style scoped></style>
