import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    lang: "zh-cn",
    imgurl: "",
    index: "",
    scroll: "",
    index2: "",
    scroll2: "",
    isShowFooter: "true",
    isShowHeader: "true",
    record: "粤ICP备2021130651号",
  },
  mutations: {
    changeLang(state, lang) {
      state.lang = lang;
    },
  },
  actions: {},
  modules: {},
});
