/**
 * 英语
 */
module.exports = {
  lang: {
    lang: {
      v0: "EN",
      v1: "简体中文-ZH",
      v2: "繁體中文-ZH",
      v3: "English-EN",
      v4: "Deutsch-DE",
      v5: "Español-ES",
      v6: "Français-FR",
      v7: "한국어 - KO",
    },
    contact: {
      v0: "Contact us",
      v1: "Contact us",
      v2: "Business cooperation",
      v3: "E-mail: ",
      v4: "Sales team",
      v5: "Domestic sales: ",
      v6: "Overseas sales: ",
      v7: "Customer service",
      v8: "After-sales service hotline: ",
      v9: "Data protection",
      v10: "E-mail: ",
    },
    law: {
      0: "Privacy and Laws",
      1: "Privacy Policy",
      2: "Date of update",
      3: "February 12, 2021",
      4: "Effective date",
      5: "March 12, 2021",
      6: "Preface",
      7: 'iRiding (Xiamen) Technology Co., Ltd. and its affiliated companies (hereinafter referred to as "QiCYCLE APP" or "We") take your personal information seriously and ensure that you have control over your personal information. We will use powerful encryption technology to protect your privacy and formulate strict policies to manage all your data. Before you visit QiCYCLE APP or use our services, you shall agree to the provisions of this policy on how we collect, use, store and share your relevant information. If you do not agree on any content in this privacy policy, you are requested to stop using or accessing our products and services immediately. If you have any questions about this policy, please contact us by emailing to privacy@miriding.com. The key points of this privacy policy are as follows:',
      8: "1.We will explain the types of your personal information we collect and their corresponding usage one by one, so that you can understand the categories, reasons for use and collection methods of the specific personal information we collect for a specific function.",
      9: "2.When you are using some functions, we will collect your sensitive information after obtaining your consent, unless it shall be collected pursuant to relevant laws and regulations. Refusing to provide such information will only make you unable to use some specific functions, but will not affect your normal use of the other functions of QiCYCLE APP.",
      10: "3.QiCYCLE APP will not share or transfer your personal information actively to a third party other than QiCYCLE APP. If there are other situations of sharing or transferring your personal information or if you need us to share or transfer your personal information to a third party other than QiCYCLE APP, we will obtain your express consent directly or confirm that the third party obtains your express consent for the above behaviors. In addition, we will conduct a risk assessment of our act of providing information to the outside world.",
      11: "4.QiCYCLE APP will not obtain your personal information actively from a third party other than QiCYCLE APP. If you need to obtain your personal information indirectly from a third party for business development in the future, we will explain to you the source, type and scope of use of your personal information before obtaining it. For example, if the personal information processing activities required by QiCYCLE APP for business purposes exceed the scope of your original authorization and consent upon providing personal information to a third party, we will obtain your express consent before processing such personal information of yours. In addition, we will strictly abide by relevant laws and regulations, and require the third party to guarantee the legality of the information that is provided.",
      12: "5.You can access, correct and delete your personal information, cancel your consent, cancel your account, complain and report, and set up privacy functions through the channels listed in this guide.",
      13: "If you want to know more detailed information, you are requested to read the corresponding chapters according to the following index:",
      14: "1.How do we collect and use your information",
      15: "2.How do we use Cookies and similar technologies",
      16: "3.How to update, change, export and delete your information",
      17: "4.How do we protect your personal information",
      18: "5.How do we share, transfer and publicly disclose your personal information",
      19: "6.Storage of information",
      20: "7.Third-party service provider",
      21: "8.Update of this policy",
      22: "9.Applicable scope",
      23: "10.How to contact us",
      24: "I. How do we collect and use your information?",
      25: "1. How do we collect your information?",
      26: "When we provide the services, we may collect, store and use the following information:",
      27: "1.1 The information you provide",
      28: "1.1.1 The information provided to us that can be used to identify the users' personal identity when you register or use our services, such as name, e-mail address, telephone number, bank card number, ID number, address, communication record, or other data that we can find through such links lawfully. If you want to make the most use of the sharing features we offer, you may also need to create publicly displayed personal data that may contain your name and photos.",
      29: "1.1.2 The shared information you provide to other parties through our services, and the information that you upload, submit, store, send or receive when you are using our services.",
      30: "1.1.2 The shared information you provide to other parties through our services, and the information that you upload, submit, store, send or receive when you are using our services.",
      31: "We will collect the information about the services you use and how you use them, such as related information about your using QiCYCLE APP sports, commenting in iRiding community or participating in clock-in movement, participating in iRiding events and challenges, becoming iRiding members and joining training camps, as well as that that may be generated when you use services of iRiding members and training camps. Such information includes:",
      32: "1.2.1 Equipment information",
      33: "We will receive and record the information related to the device you use according to your specific rights in the software installation and/or use authorization. For example, hardware and software characteristics such as your device model, operating system version information, device settings, mobile device version, device ID (such as IMEI/ Android ID/IDFA/OPENUDID/GUID/OAID, SIM card IMSI, ICCID information, etc.), screen resolution, environment of equipment,and relevant information regarding location of device, such as IP address, GPS location and the WLAN access points that can provide relevant information and the information for Bluetooth, base station and sensor. If you refuse to grant us the corresponding permissions during installation and/or use, we will not record the above kinds of information.",
      34: "1.2.21.2.2 Log information",
      35: "(1) Information for device or software, such as the configuration information for your mobile device, web browser or other programs used to access our services, your IP address and the version and device ID used by your mobile device (such as IMEI/ Android ID/IDFA/OPENUDID/GUID/OAID, SIM card IMSI, ICCID information, etc.);",
      36: "(2) The information that you search for or browse when you are using our services, such as web search terms you use, URL addresses of social media pages you visit, and other information and details that you browse or request to provide when you are using our services.",
      37: "1.2.3 IP address",
      38: "(1) The information about the mobile applications (APPs) and other software you have used, and the information about your having used such mobile applications and software;",
      39: "(2) The information you communicate through our services, such as the account number you have communicated with, as well as the communication time, data and duration;",
      40: "(3) The information (metadata) contained in the content you share through our services, such as the date, time or place of the shared photos or videos taken or uploaded.",
      41: "1.2.4 Pictures in cameras, microphones, devices, etc",
      42: "In order to provide you with a full range of sports experience, after you have authorized, our server will extract the video, picture, software name, version number, etc. from your device, and upload them to the server after encryption for comparison, so as to match the display function corresponding to the video, picture and software. At the same time, you can also refuse/cancel the permission at any time through relevant function settings. Refusing to provide this permission will only make you unable to use the above functions, but will not affect the normal use of other functions and services of QiCYCLE APP. However, after you cancel the authorization, we will no longer collect the information, nor can we provide you with the above services. However, unless you delete your information in accordance with the law, your cancellation will not affect our processing and storage of information based on your previous authorization.",
      43: "When you use the function of taking photos and recording videos, we will access the permissions of your devices such as camera and microphone, and collect the pictures and videos you provide to us after taking photos and recording videos. If you refuse to provide the permissions and contents, you will not be able to use this function, but it will not affect your normal use of other functions of iRiding. At the same time, you can cancel the permissions at any time through relevant function settings. After you cancel the authorization, we will no longer collect the information, nor can we provide you with the above services. However, unless you delete your information in accordance with the law, your cancellation will not affect our processing and storage of information based on your previous authorization.",
      44: "1.2.5 Location information",
      45: "We will access your location information after you enable the location permission, and provide you with more personalized products and/or services based on your location information, such as recording your GPS movement data and viewing the surrounding movement route. Of course, you can also stop us from collecting your geographic location information at any time by disabling the location function. You can give us access to your activity and physical training records and record your step frequency and step count information by reading your activity and physical training. At the same time, you can also cancel this permission at any time through relevant function settings. After you cancel the authorization, we will no longer collect the information, nor can we provide you with the above services. However, unless you delete your information in accordance with the provisions of the law, your cancellation will not affect the processing and storage of your information based on your previous authorization.",
      46: "We provide sports community service. When you use sports music function, we will request your authorization to read local music files for playing music while you are doing sports.",
      47: "1.2.6 The information from third parties",
      48: "Under some circumstances permitted by law, we may obtain your personal information from a third party. For example:",
      49: "(1) For the purpose of security and fraud prevention, verify your information (such as telephone number) from legitimate sources with your authorization for specific account number, financial transaction, etc;",
      50: "(2) Provide you with corresponding advertising services by a specified unique identifier (such as IMEI / Android / IDFA / OPENUDID / GUID / OAID, SIM CARD IMSI, ICCID information and so on) ;",
      51: "(3) We may also get some information from third-party social network services, such as accounts, nicknames (for example, when you log in to QiCYCLE APP service with a social network account);",
      52: "(4) The information related to you provided by others, such as your receiving address filled by other users while using QiCYCLE APP mall.",
      53: "1.2.7 Non-personal information",
      54: "We may also collect other information that cannot identify a specific individual and does not belong to personal information pursuant to local applicable laws, such as statistical data generated when you use a specific service, anonymous device related information, daily life events, page access events, page access duration events and session events,network monitoring data such as request duration, number of requests and wrong requests, etc, as well as application crash events. The purpose of collecting such information is to improve our services for you. The type and amount of information collected depends on how you use our products or services.",
      55: "We will collect information about your activities on our website or mobile app, such as unique device identifier, device model, system version number, etc. We will aggregate this information to help us provide customers with more useful information so that we can learn which parts of our website, products or services that they are most interested in. Aggregate data is treated as non-personal information as far as this privacy policy is concerned. If we combine non-personal information with personal information, such information will be regarded as personal information during the period of combination.",
      56: "2. How do we use your information",
      57: "The purpose of our collecting your personal information is to provide you with products or services and to ensure that we comply with applicable laws, regulations and other normative documents. We may use the information collected above for the following purposes:",
      58: "2.1 ovide, handle, maintain, improve and develop our products or services for you, such as delivery, verification, after-sales, customer support and advertising service;",
      59: "2.2 When we provide services, use it for authentication, customer service, security protection, fraud monitoring, archiving and backup purposes to ensure the security of the products and services we provide to you;",
      60: "2.3 Internal purposes, such as data analysis, research and development of statistical information related to the use of our products or services so that we can improve our products or services;",
      61: "2.4 Help us design new services and improve our existing services;",
      62: "2.5 rovide you with information push of more relevant activities, and you can choose to enable or disable it according to your preference;",
      63: "2.6 Evaluate and improve the effectiveness of advertising and other promotional and publicity activities in our services;",
      64: "2.7 Perform software verification and upgrade services;",
      65: "2.8 Provide information to our affiliated companies, third parties or other users when we provide specific services according to users' special requirements;",
      66: "2.9 Other situations that do not violate any mandatory laws and regulations;",
      67: "2.10 Store and maintain the information related to you for our business operation (e.g. business statistics) or fulfillment of legal obligations;",
      68: "2.11 Other purposes with your consent.",
      69: "More detailed examples are provided below on how we use your information (which may include personal information):",
      70: "(1) Log in and use QiCYCLE APP products or services;",
      71: "(2) Create and maintain your QiCYCLE APP account. The personal information provided when you create a QiCYCLE APP account through the website or a mobile device can be used to create your personal QiCYCLE APP account and personal data;",
      72: "(3) Participate in the community activities. Personal information related to the community or other similar social platforms of QiCYCLE APP may be used to display personal data pages, interact with other users, and participate in posting;",
      73: '(4) Provide location-based services. When you are using QiCYCLE APP service, we or third-party service providers and business partners (please refer to "How do we share, transfer and publicly disclose your personal information" below for more details) may use location information to provide services for you, and provide better user experience based on the accurate location, such as obtaining accurate running track, and you can disable the location based service in the settings of QiCYCLE APP at any time;',
      74: "(5) Improve the user experience. Some optional add-in features, such as user experience plans, allow us to analyze how users use mobile phones and QiCYCLE APP services to improve user experience, as well as sending crash reports;",
      75: "(6) Collect user feedback. The feedback you choose to provide is important to help QiCYCLE APP to improve its services. To handle the feedback you provide, QiCYCLE APP may contact you with the personal information you provide, and keep records for problem solving and improvement of services;",
      76: "(7) Send a notification. We may use your personal information to send important notices, such as changes to our terms, conditions and policies;",
      77: "(8) Carry out promotional activities. If you participate in the prize-drawing activity and competition through QiCYCLE APP, we may send such rewards to you using the personal information you provide;",
      78: "(9) Analyze the device so as to provide a better user experience. QiCYCLE APP will analyze devices to further enhance the experience of them.",
      79: "You have the right to refuse personal information processing and automated decision-making for direct marketing purposes. In order to exercise the above rights, you can send an email to privacy@miriding.com or refer to the control mechanism described in the separate privacy policy for each product or service.",
      80: "3. Our commitment",
      81: "3.1 We will not present personalized advertisements to you based on sensitive categories (such as race, religion, sexual orientation or health status);",
      82: "3.2 e will not share with advertisers the information that can be used to identify you, such as your name or e-mail address (unless authorized and consented by you);",
      83: "3.3 We will not sell your personal information to any third party at any time and under any circumstance. We will only use the information obtained under this Agreement to the extent permitted by the law. We will formulate strict policies to protect your personal information, and will not disclose your personal information to the public or provide it to any third party unless your prior authorization is obtained or otherwise specified in this statement.",
      84: "4. Affiliated companies or other trusted cooperative partners",
      85: "To ensure the quality of service, we may provide you with the services you require or the content you may be interested in by our affiliated companies or other trusted partners. With your authorization and consent, we will share the personal information necessary for the products and services with our affiliated companies or partners. We will require our affiliated companies and partners to ensure the security of your personal information in accordance with our instructions, privacy policy and any other appropriate confidentiality and security measures. Except for this, we will not provide or share your information to any third party without your authorization and consent.",
      86: "5. HeathKit",
      87: "If you are using an Apple mobile phone, in order to calculate exercise consumption and record relevant exercise data for you, with your authorization, we will receive your exercise data from Apple's Heathkit and send to it. To simplify your input process, we will read your motion data from Heathkit. At the same time, the training data generated by your QiCYCLE APP will be synchronized with Apple's Heathkit. Any information obtained by using Heathkit, such as steps and heart rate data, will not be shared or sold to any third party, including advertisers and other agents. We will not use Heathkit's information in marketing, advertising and similar services.",
      88: "6. Third-party entities",
      89: "The third-party service in QiCYCLE APP services is provided by an external third-party entity. QiCYCLE APP cannot obtain the information generated when you use this kind of third-party service. However, if you have explicitly agreed that the third party can obtain your geographic location information, the third party will obtain your terminal geographic location information through the QiCYCLE APP interface. This information is sensitive. Refusing to provide this information will only make you unable to use the above third-party services, but will not affect your normal use of QiCYCLE APP.",
      90: "Apart from these, pursuant to relevant laws, regulations and national standards, we may collect and use your personal information in the following situations without obtaining your authorization and consent:",
      91: "6.1 That directly related to national interests including national security and national defense security and that directly related to major public interests such as public security, public health, public knowledge and so on;",
      92: "6.2 That directly related to criminal investigation, prosecution, trial and execution of judgment;",
      93: "6.3 That for the purpose of safeguarding personal life, property and reputation of yours or others’ and it is difficult to obtain your consent;",
      94: "6.4 The collected personal information is disclosed to the public by yourself;",
      95: "6.5 Where personal information is collected from the lawfully disclosed information, such as lawful news coverage, disclosure of government information and so forth;",
      96: "6.6 That necessary for signing and performing the contract according to your requirements;",
      97: "6.7 That necessary to maintain the safe and stable operation of the products or services provided, such as finding and handling the faults of products or services;",
      98: "6.8 That necessary for lawful news coverage;",
      99: "6.9 That necessary to carry out statistical or academic research out of public interest, and when the results of academic research or description are provided to the outside world, the personal information contained in the results is to be de-identified.",
      100: "6.10 Transfer for the purpose of completing merger, division, acquisition or asset transfer;",
      101: "6.11 Other circumstances stipulated by laws and regulations.",
      102: "You are requested to understand that the functions and services we provide to you are constantly updated and developed. If a certain function or service is not included in the above description and your information is collected, we will explain the content, scope and purpose of information collection to you through page prompt, interactive process and website announcement separately so as to obtain your consent.",
      103: "Please note that QiCYCLE APP will not obtain your personal information from a third party other than QiCYCLE APP actively at present. If you need to obtain your personal information indirectly from a third party for business development in the future, we will explain to you the source, type and scope of use of your personal information before obtaining it. If the personal information processing activities required by QiCYCLE APP to carry out business exceed the scope of your original authorization and consent when your personal information is provided to a third party, we will obtain your express consent before processing your personal information. In addition, we will strictly abide by relevant laws and regulations, and require the third party to guarantee the legality of the information provided.",
      104: "7. Sensitive personal information",
      105: "We will not provide your sensitive personal information to anyone except with your prior consent.",
      106: "8. Anonymization of personal information",
      107: "After collecting your personal information, we will anonymize the data through technical means in a timely manner. On the condition that your personal information is not to be leaked, QiCYCLE APP has the right to mine, analyze and utilize the anonymized user database (including for commercial use), to make statistics on the use of products/services and share desensitization statistics with the public/third parties.",
      108: "II. How do we use Cookie and similar technologies",
      109: "Cookies are small files containing character strings sent to your computer when you visit the website. When you visit the website again, the Cookie enables the site to identify your browser. Cookies also store user preferences and other information. You can reset your browser to reject all cookies, or prompt when cookies are being sent. But without cookies, some web features or services may not work properly.",
      110: "Cookie and the same type of technologies are common technologies in the Internet. When you use related services of iRiding, we may use relevant technologies to send one or more cookies or anonymous identifiers to your device to collect and store information when you access and use iRiding. We treat information collected through cookies and other technologies as non-personal information. However, if the local law treats an Internet Protocol (IP) address or similar identification marks as personal information, we also treat such identification marks as personal information. We use cookies and similar technologies to achieve the following functions or services:",
      111: "1. Ensure safe and efficient operation of the products and services",
      112: "We may set cookies or anonymous identifiers for authentication and security purposes, so that we can confirm whether you are safe to log in to the service, or whether you encounter illegal acts such as embezzlement and fraud. These technologies will also help us improve service efficiency and login and response speed.",
      113: "2. Help you acquire a more relaxed visit experience",
      114: "Using this kind of technology can help you avoid the steps and processes of repeated completion of personal information and entering search content, and achieve one-click login and record search history.",
      115: "3. Recommend, display and push the contents you may be interested in",
      116: "We may use cookies and similar technologies to learn about your preferences and use habits, and conduct consultation or data analysis to improve product services and user experience.",
      117: "4. Log files",
      118: "As most websites do, we collect specific information and keep it in a log file. Such information may include Internet Protocol (IP) address, browser type, Internet service provider (ISP), reference/exit page, operating system, date/time stamp and/or clickstream data. We don't associate automatically collected log data with the other information we collect.",
      119: "5. Mobile analysis",
      120: "In some mobile applications, we use mobile analysis software to learn better about the functions of our mobile software in your mobile phone. This software may record information such as how often you use the application, events within the application, cumulative usage, performance data, and where the application crash occurs. We will not associate the information stored in the analysis software with any personal information you submit in your mobile application.",
      121: "6. Local storage - HTML5/Flash",
      122: "We use local storage objects (LSO), such as HTML5 or flash, to store contents. The third parties that cooperate with us to provide some functions on QiCYCLE APP, or to advertise according to your browsing behaviors, will also collect and store relevant information through HTML5 or Flash cookie. Various browsers provide their own management tools to delete HTML5 local storage objects.",
      123: "III. How do we update, modify, export and delete your information",
      124: "Pursuant to the laws of some jurisdictions:",
      125: "1. Provide true personal information",
      126: "Prior to your using our products and services, you will be required to provide your true personal information. You should be responsible for the authenticity, legality, validity and integrity of the information you provide, and update and maintain it in time to ensure its authenticity, legality and effectiveness. You can manage your personal information, such as your personal account registration information, at any time.",
      127: "2. Delete content or personal information",
      128: "You can delete your content or personal information from iRiding account by yourself. You can apply for account cancellation through [My]-[Settings] - [About the account] -[Account cancellation]. Within fifteen (15) working days, we will delete or anonymize your personal information.",
      129: "3. Data retention within limited time",
      130: "In some cases, we will keep the data for a limited period of time for legal purposes or legitimate business needs. We will do our best to ensure that the services of iRiding can protect the information from accidental or malicious deletion. Therefore, if you delete something, we may delete its copy from our current and standby systems within fifteen (15) working days.",
      131: "4. Back up content or use it for non-iRiding services",
      132: "You can always back up the contents in your personal account or use it for non-iRiding services. You can contact us by email at privacy@miriding.com and make your request.",
      133: "5. Withdrawal of consent",
      134: "You may withdraw your consent previously granted to us by submitting a request for specific purposes, including collection, use and/or disclosure of your personal information in our possession or control. Depending on the specific service you use, you can visit the account management center of the QiCYCLE APP or send an email to privacy@miriding.com for related operations. We will process your request within a reasonable period of time after you make the request and will not collect, use and/or disclose your personal information upon your request thereafter.",
      135: "Depending on the extent to which you withdraw your consent, you may not be able to continue to enjoy QiCYCLE APP's products or services. However, your decision to withdraw your consent or authorization will not affect the effectiveness of our previous personal information processing activities based on your consent.",
      136: "6. Cancellation of the service or account",
      137: "If you want to cancel a specific product or service, you can cancel your account through [My]- [Settings] - [Account security] - [Cancel account]. After you cancel your account, we will delete or anonymize your personal information.",
      138: "Within fifteen (15) business days, we will delete or anonymize your personal information. Once the account is canceled, it will not be recoverable. You are requested to back up all the information and data related to your iRiding account by yourself before the operation.",
      139: "IV. How do we protect your personal information",
      140: "1. Strict information management policy",
      141: "We have drawn up strict information management policies, equipped with professional technical teams, and have adopted a series of reasonable precautions to protect your personal information from unauthorized browsing, disclosure, abuse, change, destruction and loss, including:",
      142: "(1) Use encryption technology to ensure that your data is kept private during transmission;",
      143: "(2) Provide a variety of security functions to help you protect your account security;",
      144: "(3) Review our practices in the collection, storage and processing of information, including physical security measures, in order to prevent unauthorized access to our systems;",
      145: "(4) All the iRiding employees, contractors and agents who have to contact personal information for the purpose of providing services shall abide by the strict confidentiality obligations stipulated in the contract. Otherwise, they may be punished or fired.",
      146: "2. Technical limitation and malicious means",
      147: "Although the above reasonable and effective measures have been taken and the standards required by relevant laws and regulations have been followed, you are requested to understand that it is impossible to guarantee 100% security of information in the Internet industry all the time due to the limitations of technologies and various malicious means that may exist even if we do our best to strengthen our security measures. We will try our best to ensure the security of the personal information you provide us. You shall confirm that you know and understand that the system and communication network you use to access our services may have problems due to factors beyond our control. Therefore, we strongly recommend that you take positive measures to protect the security of your personal information, including but not limited to using complex passwords, changing passwords regularly, and not disclosing your own account password and other personal information to others.",
      148: "3. Emergency plan",
      149: "We will work out an emergency plan and trigger it immediately in case of any user information security incident, so as to prevent the expansion of the impact and consequences of such security incident. After the unfortunate occurrence of a user information security incident (leakage, loss, etc.), we will inform you of the basic situation and possible impact of the security incident, the measures we have taken or will take to cope with it, the suggestions that you can prevent and reduce the risks on your own, and the remedial measures for you in accordance with the requirements of laws and regulations in time. We will inform you of relevant information of the incident by mail, letter, telephone, push notice, etc. And when it is difficult to inform the subject of personal information one by one, we will take reasonable and effective ways to issue an announcement. At the same time, we will report the handling of the personal information security incident in accordance with the requirements of regulatory authorities.",
      150: "4. What can you do?",
      151: "You shall not disclose your login password or account information to anyone (unless this person is officially authorized by you) and you can set a unique password for QiCYCLE APP to prevent other websites from leaking your password that may endanger the security of your account in QiCYCLE APP. At any time, be sure not to disclose the verification code you receive to anyone (including those who claim to be customer service personnel of QiCYCLE APP).",
      152: "QiCYCLE APP will not be responsible for the security negligence caused by any third party's access to your personal information due to your failure to maintain the privacy of your personal information. Notwithstanding the above provisions, in the event of any other Internet user’s use of your account without authorization or having any other security vulnerabilities, you should immediately inform us.",
      153: "Your assistance will help us protect the privacy of your personal information.",
      154: "V. How do we share, transfer and disclose your personal information",
      155: "1. Sharing",
      156: "We will not sell any personal information to a third party.",
      157: "Sometimes we may share your personal information with third parties in order to provide or improve our products or services, including providing products or services according to your requirements. If you do not want us to share this information any longer, you are requested to send an email to privacy@miriding.com and contact us.",
      158: "1.1 Share out of your active choice",
      159: "Share the information to the extent of your authorization/request with the third party designated by you after your explicit consent or active choice. For example, you can quickly log in to a third-party website or APP by using your QiCYCLE APP account.",
      160: "We will provide social networking services. When you use this type of service, the information you upload or publish (including your public personal information, your address book, your circle of friends), your response to the information uploaded or published by others, as well as the log information related to these information, etc. So long as you do not delete the information, it will always be kept in the public domain, and shared and browsed by other users of our service. Even if you delete the information, it may still be independently cached, copied or stored by other users or unrelated third parties not under our control, or stored in the public domain by other users or third parties.",
      161: "Please consider carefully the information uploaded, published and exchanged through our service. In some cases, you can control the range of users who have the right to browse your shared information through the privacy settings of some of our services. You can delete the information you upload, publish or share by yourself. If you want us to delete your relevant information for you, please contact us by sending an email to privacy@miriding.com and put forward your request, we will finish checking and handling it within fifteen (15) working days after receiving it.",
      162: "1.2 Share information with our affiliated companies",
      163: "We may share your personal information with other related companies of QiCYCLE APP from time to time in order that we can be engaged in our business operation smoothly and provide you with all the functions of products or services.",
      164: "1.3 Share with third party service providers and business partners",
      165: "In order to provide you with the services described in this privacy policy, we may share necessary personal information with third parties such as third party service providers and business partners.",
      166: "They include our data center, data storage facilities, advertising and marketing service providers and other business partners. These third parties may process your personal information on behalf of QiCYCLE APP or for one or more items of this privacy policy. We guarantee to share the personal information necessary for your service only for justifiable, legitimate, necessary, specific and definite purposes. QiCYCLE APP will conduct due diligence and sign a contract to ensure that the third party service provider complies with applicable privacy laws in your jurisdiction. Third party service providers may also have their sub-processors.",
      167: "In order to provide performance measurement, analysis and other commercial services, we may also share information (non-personal information) with third parties (such as our advertisers) in the form of summary. We will use the information that we grasp to help advertisers and other partners assess the effectiveness and coverage of their advertising and services, and understand the types of people who use their services and how people interact with their websites, apps and services. We may also share with them general trends in the use of our services, such as the number of customers in a particular human group who purchase certain products or engage in certain transactions.",
      168: "Third-party SDK name",
      169: "Owned Company",
      170: "Data Collection",
      171: "Purpose of Usage",
      172: "Privacy Policy/Official Website",
      173: "weibo.com SDK",
      174: "sina.com",
      175: "External storage status, latest geographic location information, continuous positioning, network type, network service operator, IMEI, MAC address, WiFi information",
      176: "Weibo login, share",
      177: "https://open.weibo.com/wiki/%E5%BC%80%E5%8F%91%E8%80%85%E5%8D%8F%E8%AE%AE#.E9.9A.90.E7.A7.81.E7.9B.B8.E5.85.B3.E6.9D.A1.E6.AC.BE",
      178: "Xiaomi account open platform SDK ",
      179: "Xiaomi",
      180: "External storage status, network type, network service operator, IMEI, MAC address, WiFi information",
      181: "Xiaomi account login",
      182: "https://dev.mi.com/console/",
      183: "Umeng + SDK",
      184: "Youmeng Tongxin (Beijing) Technology Co., Ltd.",
      185: "Device status and identity and get your IMEI, IMSI information",
      186: "App statistical analysis scenario ",
      187: "https://www.umeng.com/",
      188: "Bugly SDK",
      189: "Tencent",
      190: "Device status and identity and get your IMEI, IMSI information, ANDROID ID",
      191: "Exception report",
      192: "https://bugly.qq.com/v2/index",
      193: "Qiniu Cloud SDK ",
      194: "Shanghai Qiniu Information Technology Co., Ltd.",
      195: "Device status and identity and get your IMEI, IMSI information, external storage status",
      196: "Amap Map SDK",
      197: "https://www.qiniu.com/?ref=developer.qiniu.com",
      198: "Amap Map SDK",
      199: "AMAP",
      200: "External storage status, latest geographic location information, continuous positioning, network type, network service operator, IMEI, MAC address, WiFi information",
      201: "Positioning, map display and poi query",
      202: "https://developer.amap.com/",
      203: "Wechat SDK",
      204: "Tencent",
      205: "External storage status, latest geographic location information, continuous positioning, network type, network service operator, IMEI, MAC address, WiFi information",
      206: "WeChat login, share",
      207: "https://developers.weixin.qq.com/doc/oplatform/Mobile_App/WeChat_Login/Development_Guide.html",
      208: "Xiaomi Push SDK",
      209: "Xiaomi",
      210: "Device status and identity and get your IMEI, IMSI information",
      211: "Push/send notification message",
      212: "https://dev.mi.com/console/",
      213: "QQ SDK",
      214: "Tencent",
      215: "System language, external storage status, latest geographic location information, network location information, continuous positioning, network type, mobile phone number, SIM card serial number, network service operator, IMEI, MAC address, ANDROID ID, IP address, WiFi information, Application installation list, read clipboard",
      216: "QQ login, share",
      217: "https://connect.qq.com/",
      218: "Regarding access to third-party services, we may access to third-party SDK services and share some of your information collected in accordance with this policy with such third-party service providers, so as to improve our customer service and user experience.",
      219: "Currently, the main types of third-party service providers we have access to include the following:",
      220: "(1) Used for message push. When you use our products or services,Xiaomi Push may access your device status and identity and get your IMEI and IMSI information, for pushing/sending notification messages. This information is necessary for the push function,and refusal to provide it will result in your inability to use the function,but will not affect the normal use of other functions;",
      221: "(2) Used to obtain device location permissions and collect device information and log information with your consent;",
      222: "(3) Used for third-party authorization services,including login using third-party accounts and sharing related content to third-party products.，QQ and WeChat SDKs may access your ANDROID_ID and other information;",
      223: "(4) including content storage and positioning.including hardware distribution capability enhancement, server cost reduction, and application exception statistics reporting.Data such as your device information and network location information may be collected;",
      224: "(5) Used to optimize product performance,including hardware distribution capability enhancement, server cost reduction, and application exception statistics reporting. Data such as your device information and network location information may be collected",
      225: "(6) Used for services related to account security and product reinforcement,including network monitoring, domain name resolution, anti-hijacking, anti-spam/anti-cheating, and encryption/decryption services.",
      226: "We will only share your information for justifiable, necessary and specific purposes. For the third-party service providers with whom we share information, we will require them to perform relevant confidentiality obligations and take corresponding security measures.",
      227: "Third-party social media or other services are operated by relevant third parties. Your use of such third party's social media services or other services (including any information you provide to such third party) is subject to the third party's own terms of service and information protection statement (other than this policy), and you shall read the terms carefully. This policy only applies to the personal information we collect, and does not apply to the services provided by any third party or the information use rules of any third party. If you find that there are risks in these third-party social media or other services, you are recommended to terminate relevant operations to protect your legitimate rights and interests.",
      228: "1.4 Miscellaneous",
      229: "QiCYCLE APP may have to disclose your personal information in accordance with the requirements of laws and regulations, legal procedures, lawsuits and/or legal requirements of public institutions and government departments. We may also disclose the information about you if we determine that such disclosure is necessary or appropriate for national security, law enforcement or other matters of public importance.",
      230: "If we determine that the disclosure is reasonably necessary to enforce our terms or to protect our operations, rights, assets or products, or to protect users, or achieve the following purposes (detection, prevention and resolving of fraud, unauthorized use of products, violation of our terms or policies, or other harmful or illegal activities), we may also disclose information about you.(For the avoidance of doubt, QiCYCLE APP will only collect, use or disclose your personal information without your consent to the extent and only under such circumstances as expressly permitted by local data protection laws.) This may include providing information to public or government authorities, exchanging information about the reliability of your account with third-party partners to prevent fraud, violations and other harmful behaviors inside and outside our products.",
      231: "In addition, we may share your personal information with the following persons:",
      232: "(1) Our accountants, auditors, lawyers or similar advisers when we require them to give professional advice;",
      233: "(2) and Investors and other relevant third parties if there is an actual or potential sale or transaction of any other company, and it is related to the QiCYCLE APP entity;",
      234: "(3) and Any other third party, who discloses and shares specific information as authorized by you.",
      235: "2. Transfer",
      236: "Except for the following circumstances, QiCYCLE APP will not transfer your information to any entity:",
      237: "(1) Your express consent has been obtained;",
      238: "(2)(2) If QiCYCLE APP is involved in any merger, acquisition or sale of all or part of its assets, we will notify you of any change in the ownership and use of your personal information, as well as any options you may have regarding your personal information by e-mail and/or on our website in an eye-catching way.",
      239: "3. Public disclosure",
      240: "Except for desensitized display of your QiCYCLE APP account and user name when the list of award-winning activities is announced, QiCYCLE APP will only publicly disclose your personal information under the following circumstances:",
      241: "(1) Your express consent has been obtained;",
      242: "(2) Public disclosure based on laws or reasonable grounds: including laws, regulations, legal procedures, lawsuits or at the request of the competent government authorities.",
      243: "VI. Storage of information",
      244: "In general, we will only retain your personal information for so long as is necessary for our purposes, such as:",
      245: "Mobile phone number: If you need to use QiCYCLE APP services, we need to keep your mobile phone number all the time to ensure your normal use of the services. After you cancel your account, we will delete the corresponding information;",
      246: "Community information: when you send a community share, we need to save your community information so as to ensure that you use the community function normally, and we will delete the corresponding information after you delete your community information.",
      247: "When the operation of our products or services is stopped, we will notify you in the form of push notification, announcement, etc., and delete your personal information or anonymize it within a reasonable period.",
      248: "Since QiCYCLE APP is a global product, some of our activities include a global race of cycling, and we will store some of the data overseas.",
      249: "VII. Third-party service provider",
      250: "1.To facilitate your visit and enrich your experience, there may be products or services provided by third parties. You can choose whether to access such contents or links, or whether to use the third party's products or services. But we have no control over the products or services provided by third parties. We have no control over any of your personal information held by a third party. The information protection problems in the process of using the third party products or services does not apply to the management of this policy. This policy does not apply to any information you choose to provide to third parties, either. Please check the privacy policy of this third party. ",
      251: "2.Participate in marketing activities. When you choose to participate in our relevant marketing activities, you need to provide your name, address, contact information, bank account number and so on according to the needs of the activities, so that the third party can provide you with prizes in time. When you choose to participate in our marketing activities, you need to provide your name, address, contact information, bank account number and so on according to the needs of the activities. With your express consent, we will share the above information with a third party, so that we can entrust the third party to provide you with prizes in time.",
      252: "VIII. Update of the policy",
      253: "1.We may modify and update this Privacy Policy from time to time. We will release the update of the Privacy Policy via notice or announcement in QiCYCLE APP, and you can visit QiCYCLE APP to search for the latest version of the Privacy Policy. For major changes, we will send you a formal notice (for some services, we will also send a notice by email to elaborate the changes to the Privacy Policy).",
      254: "IX. Applicable scope",
      255: "1.Our privacy policy applies to all the services provided by iRiding (Xiamen) Technology Co., Ltd. and its affiliated companies, including the services provided by QiCYCLE APP and at www.iriding.cc, except for the services with independent privacy policies (such as products and services provided by third parties).",
      256: "2.Our privacy policy does not apply to products and services provided by third parties, such as the products and services provided by third parties in QiCYCLE APP and/or at www.iriding.cc, as well as the other websites linked to our services, which will be regulated by independent privacy policies. Please refer to the corresponding policies and regulations separately.",
      257: "3.Regarding other companies and organizations conducting advertising and publicity for our services and may use our Cookies or other log information to launch and use advertisements, our Privacy Policy does not cover their information processing policies.",
      258: "X. How to Contact Us",
      259: "You can contact us in the following ways:",
      260: "1.If you have any question, opinion or suggestion about this policy, you can feed back to us through the background of QiCYCLE APP and contact us through the background of WeChat's official account of iRiding.",
      261: "2.If you find that your personal information may be leaked, you can complain and report through QiCYCLE APP, and we will contact you and complete the verification and handling within fifteen (15) working days;",
      262: "3.We have also set up a feedback email for comments and suggestions, and you can contact us by emailing to support@miriding.com, and we will contact you within fifteen (15) working days and complete the verification and handling.",
      263: "4.If you have any question about this policy, please contact us by emailing to privacy@miriding.com, and we will contact you and complete the verification and handling within fifteen (15) working days.",
    },
  },
  downLoad: {
    v1: "The QiCYCLE APP",
    v2: "High tech makes for cycling fun",
    v3: "Applicable to mainland China",
    v4: "Applicable to other regions",
    v5: "App Store  ",
    v6: " Local Download",
  },
  index: {
    v1: {
      text0: "iRiding",
      text1: "Home",
      text2: "Pedelec",
      text3: "Accessory",
      text4: "APP",
      text5: "Support",
      text6: "Company Profile",
      text7: "Establishment",
      text8:
        "iRiding (Xiamen) Technology Co., Ltd. was established in Xiamen in 2011.",
      text9: "Development",
      text10:
        "QiCYCLE APP1.0, a social software for riding, was officially launched in 2013.",
      text11:
        "With installed users and registered users exceeding one million in two years, QiCYCLE APP1.0 became the first choice of users for riding. ",
      text12:
        "So far, users' total mileage of riding reached 130 million kilometers, 350 times the distance between the Earth and the Moon.",
      text13: "Acceleration",
      text14:
        "iRiding became one of Xiaomi Ecosystem enterprises in September 2014.",
      text15:
        "Committed to the research and development of hardware, iRiding has successively launched many products, including R1 Carbon Fiber Road Bicycle meeting Standard of Tour of France, Mi Smart Electric Folding Bike, Mitu Children's Bike, and Mi Portable Electric Air Compressor.",
      text16: "Expansion",
      text17:
        "In 2019, Mi Smart Electric Folding Bike and Mi Portable Electric Air Compressor were marketed overseas.",
      text18:
        "At present, products of iRiding are sold in over ten countries/regions covering the four continents of Asia, Europe, Oceania and South America.",
      text19:
        "In 2020, iRiding introduced QiCYCLE Pedelec and accessories, embarking on a new journey of building its own brand.",
      text20: "Prospect",
      text21:
        "Looking ahead, iRiding will continue to focus on developing future-oriented products for sports and travel, and providing better travel experience for users leveraging its competitive edges in product research, development and design.",
      text22: "Development History",
      text23:
        "Mi Smart Electric Folding Bike meeting new European standards and made in Taiwan passed the certification and went on sale in Europe in August.",
      text24:
        "EF1 became China's first Pedelec that completed EN15194-2017 certification and was sold in Europe.",
      text25:
        "Driven by the pandemic and European shopping allowance, the sales of Mi Smart Electric Folding Bike surged, with orders increasing month by month.",
      text26:
        "The CN¥199 Xiaomi Portable Electric Air Compressor is now available for online purchase at mi.com",
      text27:
        '"Mitu Children’s Bike (14-inch)" was sold on Xiaomi Mall and won the German Red Dot Design Award 2020.',
      text28:
        "QiCYCLE Pedelec meeting new national standards was sold on Xiaomi Mall and obtained 18 patents for bicycle frame technology.",
      text29:
        "The sales volume of Mi Smart Electric Folding Bike, a new category of Pedelec for E-commerce in China, was 51,800, with cumulative sales amount reaching RMB 135 million.",
      text30:
        "Mi Smart Electric Folding Bike ranked the top for two consecutive years in terms of sales volume, with market share up to 91.3% in 2017.",
      text31: "iFixit Mi Smart Electric Folding Bike was highly praised.",
      text32:
        "iRiding was determined as an advantageous enterprise for industrial design intellectual property in Xiamen by Xiamen Intellectual Property Office.",
      text33:
        "Developing after-sales app and addressing the practical needs of spares, maintenance instructions, and payment settlement.",
      text34:
        "Providing after-sales services for products by signing contracts with 272 iRiding urban partners.",
      text35:
        "Mi Smart Electric Folding Bike was launched by Liu De, the co-founder of Xiaomi, and was praised by Lei Jun, the CEO of Xiaomi.",
      text36: "First application of aluminum forging in the bike",
      text37: "First application of CAN bus in the bike",
      text38: "Obtaining 11 patents for longitudinal folding",
      text39: "First mass production of TMM torque sensor",
      text40:
        "QiCYCLE R1 Carbon Fiber Road Bicycle went live in Xiaomi Crowdfunding.",
      text41: "QiCYCLE R1 won two Red Dot Design Awards.",
      text42:
        "QiCYCLE R1 won Innovative IF Design Award of Taipei Cycle for acquisition of complete sports data.",
      text43:
        "iRiding became a member of Xiaomi Ecosystem and was responsible for research and development and ecological layout of intelligent hardware products in life and travel.",
      text44:
        "iRiding changed its name to iRiding (Xiamen) Technology Co., Ltd.",
      text45: "iRiding obtained angel round financing.",
      text46: "iRiding joined in Xiaomi Ecosystem.",
      text47:
        "Developed iRiding APP, a software for riding, and put it into operation",
      text48: "iRiding App users exceeded 1.24 million.",
      text49:
        "iRiding users have a cumulative riding mileage of 65 million kilometers, becoming the largest riding community in China.",
      text50:
        "iRiding (Xiamen) Technology Co., Ltd. was established in Xiamen.",
      text51: "Ingenious Products",
      text52: "Innovative Design",
      text53:
        "Products won German Red Dot Design Award and iF Design Award, and obtained several patents for invention.",
      text54: "Dedicated Research and Development",
      text55:
        "A research and development team led by industry experts and focusing on details continues to advance key technology innovation.",
      text56: "Strict Quality Control",
      text57:
        "Multiple strict quality inspection processes for quality assurance of finished bicycle to provide superior products for users",
      text58: "Efficient After-sales Service",
      text59:
        "Continuous exploration of more thoughtful offline service model to offer better product experience for users",
      text60: "Company Address",
      text61: "Cities",
      text62: "Beijing",
      text63: "Building 2, East District, Rongke Rongzhi·Honeycomb Factory, No.16 Anningzhuang East Road, Haidian District, Beijing",
      text64: "Changzhou",
      text65:
        "No. 89 Chuangxin Avenue, Xinbei District, Changzhou City, Jiangsu Province, China",
      text66: "Shanghai",
      text67:
        "Room 104B, G Floor, No. 142 Tianlin Road, Xuhui District, Shanghai, China",
      text68: "Shenzhen",
      text69:
        "Room 1708, 17th Floor, Zhongshan University Industry and Research Building, No. 1 Yuexing 4th Road, (Yuehai Sub-district,) Nanshan District, Shenzhen, China",
      text70: "Frankfurt",
      text71:
        "Adam-Opel-Str. 10 im Hof, Geb. A, bei eKey 60386 Frankfurt am Main,Germany",
      text72: "Xiamen",
      text73:
        "Address: Room 403, No.768 Qishan North Road, Huli District, Xiamen City, Fujian Province, P.R. CHINA",
      text74: "Hong Kong",
      text75:
        "Room 1102, Hang Seng Castle Peak Road Building, 339 Castle Peak Road, Cheung Sha Wan, Kowloon, Hongkong",
      text76: "Suzhou",
      text77:
        "Room 606,Suzhou Gaoxin International Business Plaza, NO.28 Shishan road, Gaoxin district, Suzhou, China",
      text78: "Social media",
      text79: "After-sales service hotline",
      text80: "Contact us",
      text81: "Business cooperation",
      text82: "Domestic sales",
      text83: "Overseas sales",
      text84: "Customer service",
      text85: "Data protection",
      text86: "QiCYCLE©2013-2021 MICPB No.13003500",
      text87: "Privacy and laws",
      text88: "Your Cookies Preference",
      text89: "Welcome to iRiding!",
      text90:
        "To provide you with better website experience, we hope to use Cookies to improve our website.",
      text91:
        "The information collected by Cookies will not identify you personally.",
      text92:
        "For the types of Cookies we use and more information on our Cookies, please read our Cookies Policy.",
      text93: "Reject",
      text94: "Accept",
      text100:
        "To provide you with better website experience, we hope to use Cookies to improve our website.The information collected by Cookies will not identify you personally.For the types of Cookies we use and more information on our Cookies, ",
      text101: "please read our Cookies Policy",
      text102: "QiCYCLE",
      text103: "Legal information",
      text104: "QiCYCLE",
    },
  },
  product: {
    v1: {
      productMove: "Explore more",
      productCpd: "QiCYCLE electric air pump P1",
    },
  },
  contact: {
    // 英文
    v1: "Contact us",
    v2: "Business cooperation",
    v3: "E-mail: market@iriding.com",
    v4: "Sales team",
    v5: "Domestic sales: sales@iriding.com",
    v6: "Overseas sales: sales@iriding.com",
    v7: "Customer service",
    v8: "After-sales service hotline: 400-1144-177",
    v9: "Data protection",
    v10: "E-mail: dpo@iriding.com",
  },
  details: {
    v1: "QiCYCLE Electric Air Pump P1",
    v2: "Color",
    v3: "Buy now",
    v4: "Overview",
    v5: "Specifications",
    v6: "Technical Support",
    v7: "Precise and fast inflation",
    v8: "Quickly inflates tire in 60 seconds",
    v9: "Air Pressure easily reaches 150psi",
    v10: "Inflates tire in about 5 minutes",
    v11: "Preset pressure, auto stop setting",
    v12: "5 usage modes",
    v611: "USB adapter charge",
    v612: "Car charger charging",
    v613: "Rechargeable battery",
    v13: "Emergency power bank",
    v14: "Strong power",
    v998: "exceeds your imagination",
    v15: "High-performance movement and high-power motor.",
    v16: "Flat tire fills up in 5 minutes",
    v16a: "Flat tire fills up in 5 minutes",
    v1611: "When fully charged it can inflate about",
    v1611a: "When fully charged it can inflate about",
    v1621: "5",
    v1631: "minutes",
    v1631a: "bicycle tires[2], about 5 minutes per tire",
    v1631b: "bicycle tires",
    v1711: " about ",
    v1711a: "about",
    v1721: "minutes per tire",
    v1721a: " minutes per tire",
    v17: "When fully charged it can inflate about 5 bicycle tires, ",
    v999: "about 5 minutes per tire,",
    v18: "Small and easy to store",
    v19: "Pump size about 20cm",
    v20: "Easy control with just one hand.",
    v21: "Small and flexible for easy storage ",
    v217: "",
    v22: "Allows for a light-weight ride.",
    v23: "High-precision air cylinder,",
    v231: " fast speed and high air pressure.",
    v24: "Inflation pressure up to 150psi,",
    v24a: "Inflation pressure up to 150psi ",
    v25: "Reaches high air pressure required for road tires",
    v26: "Effortless Inflation.",
    v261: "A full charge can inflate",
    v262: "16",
    v263: "bicycle tires,",
    v27: "A full charge can inflate 16  bicycle tires, in about 63 seconds per tire",
    v9971: "in about ",
    v9972: "63",
    v9973: " seconds per tire.",
    v997: "in about 63 seconds per tire.",
    v272: "Preset pressure",
    v271: "accurate charging and auto-stop system.",
    v28: "Set air pressure as needed.",
    v29: "Auto-stop will activate when inflation is complete.",
    v30: "Accurate pressure measurement anytime, anywhere.",
    v31: "Air pressure sensor accuracy ",
    v311: "±1psi",
    v312: "Air pressure sensor accuracy±1psi",
    v32: "6. Switch easily between 5 usage modes",
    v33: "Car, Motorcycle, Bicycle,  Ball, Pro Mode ",
    v34: "Limited air pressure limit ",
    v35: "Choose inflation mode for safer operation",
    v362: " Default",
    v363: "2.5bar",
    v364: "2.4bar",
    v361: "Car Mode",
    v3611: "Default：2.5bar",
    v3612: "Default：2.4bar",
    v37: "Adjustable Range:1.8-3.5bar",
    v371: "1.8-3.5bar",
    v3711: "Adjustable Range:1.8-3.0bar",
    v38: "Motorcycle Mode",
    v381: "default 2.4bar",
    v39: "Adjustable range",
    v391: "1.8-3.0bar",
    v40: "Bicycle Mode",
    v41: "Adjustable Range",
    v411: "45psi",
    v4111: "Default：45psi",
    v581: "Does not support continuous use for more than 35 minutes.",
    v412: "Adjustable Range：",
    v2121: "Rigorous testing,",
    v2631: "bicycle tires",
    v2122: "to create durable quality",
    v4121: "Adjustable Range:30-145psi",
    v462: "Adjustable Range: 0.2-10.3bar",
    v451: "Default:3-150psi",
    v451a: "3-150psi",
    v451b: "0.2-10.3bar",
    v413: "30-145psi",
    v42: "Ball Mode",
    v423: "Adjustable Range: 4-16 psi",
    v422: "Default:8psi",
    v421: "8psi",
    v43: "4-16psi",
    v44: "Professional Mode",
    v45: "Adjustable range",
    v9990: "3-150psi",
    v46: "0.2-10.3 bar",
    v461: "3-150psi / 0.2-10.3bar",
    v47: "Built-in 18650 lithium battery",
    v48: "untethered control",
    v49: "It can take you to the mountains or to the seaside.",
    v50: "No external power supply required,",
    v51: "hassle-free inflation",
    v52: "Delicate details, to cheer you up",
    v53: "Self-contained lighting, easy to use",
    v54: "LED lighting, easy to use even during night time.",
    v55: "SOS flashlight, allows you to be ready for any emergency.",
    v56: "Double heat dissipation, uninterrupted power",
    v57: "High-power fan, design with cooling holes.",
    v58: "Does not support continuous use for more than 35 minutes.",
    v59: "It is a pump and a power bank",
    v60: "Universal USB Type-C port, worry-free charging.",
    v61: "USB-A port for charging your phone.",
    v62: "Digital large screen, real-time display",
    v63: "The 4.4-inch high-definition large screen displays the current tire pressure. ",
    v631: "preset tire pressure, usage mode and other data in real time.",
    v64: "Accessories included, friendly storage",
    v65: "Storage bag to protect the pump.",
    v66: "The bag can store air nozzles. needles, and charging cables.",
    v67: "9. Passed a number of rigorous tests [5]",
    v68: "Overpressure protection test",
    v69: "Air hose tension strength test",
    v70: "Electric strength test",
    v71: "Low Temperature hose bending test",
    v72: "Free fall drop test",
    v73: "Continuous working time test",
    v74: "Compression test",
    v75: "High/low temperature charging test",
    v76: "QiCYCLE Electric Air Pump P1 performance reference",
    v77: "1bar=14.5psi=100kPa",
    v78: "Inflatable object",
    v79: "Bicycle tires",
    v80: "175/70 R14 (inflation)",
    v81: "Wuling Hongguang",
    v82: "(175/70 R14)",
    v83: "Buick Hinglang",
    v84: "(195/65 R16)",
    v85: "Volkswagen Golf",
    v86: "(205/55 R16)",
    v87: "Toyota Camry",
    v88: "(215/55 R17)",
    v89: "Land Rover Range Rover",
    v90: "(255/55 R20)",
    v91: "BMW 730",
    v92: "(275/40 R19)",
    v93: "Motorcycle tires",
    v94: "(150/60 R17)",
    v95: "Motorcycle tires",
    v96: "(110/70 R17)",
    v97: "Road bike tires",
    v98: "(700 × 25C)",
    v99: "Mountain bike tires",
    v100: "(27.5 × 1.95)",
    v101: "Electric scooter tires",
    v102: "(8 1/2 x 2)",
    v103: "Balance car tires",
    v104: "(9 x 3.0-6)",
    v105: "Basketball No. 7",
    v106: "Football No. 5",
    v107: "Volleyball No. 5",
    v108: "Inflation pressure",
    v109: "2.0-2.5bar",
    v110: "0-2.5bar",
    v111: "0-2.4bar",
    v112: "0-120psi",
    v113: "0-50psi",
    v114: "0-40psi",
    v115: "0-30psi",
    v116: "0-9psi",
    v117: "0-11psi",
    v118: "0-6psi",
    v119: "Fully charged range",
    v120: "≈ 18",
    v121: "≈ 5",
    v122: "≈ 4",
    v123: "≈ 3",
    v124: "≈ 2",
    v125: "≈ 7",
    v126: "≈ 15",
    v127: "≈ 16",
    v128: "≈ 20",
    v129: "≈ 201",
    v130: "≈ 97",
    v131: "≈ 92",
    v132: "≈ 86",
    v133: "≈ 197",
    v134: "Inflation speed",
    v135: "≈ 60 seconds/tire",
    v136: "≈ 4 minutes 58 seconds/tire",
    v137: "≈ 6 minutes 3 seconds/tire",
    v138: "≈ 5 minutes 52 seconds/tire",
    v139: "≈ 7 minutes 20 seconds/tire",
    v140: "≈ 12 minutes 30 seconds/tire",
    v141: "≈ 9 minutes 10 seconds/tire",
    v142: "≈ 2 minutes 50 seconds/tire",
    v143: "≈ 1 minute 30 seconds/tire",
    v144: "≈ 63 seconds/tire",
    v145: "≈ 58 seconds/tire",
    v146: "≈ 10 seconds/tire",
    v147: "≈ 14 seconds/tire",
    v148: "≈ 18 seconds/ball",
    v149: "≈ 20 seconds/ball",
    v150: "≈ 10 seconds/ball",
    v1510: "[1] ",
    v1520: "[2] ",
    v1530: "[3] ",
    v1540: "[4] ",
    v1550: "[5] ",
    v1560: "[6] ",
    v1560a: "[7]  ",
    v1560b: "[8]  ",
    v1560c: "[9]  ",
    v1560d: "[10]  ",
    v1570:
      "When the product is fully charged, can inflate 18 175/70 R14 car tires from 2.0bar to 2.5bar. Fill up a tire in about 60 seconds.",
    v1580:
      "When the product is fully charged, can inflate about 5 175/70 R14 car tires from 0bar to 2.5bar. About 5 minutes to fill a tire.",
    v1590:
      "The pressure maintaining mode must be used with tools ,such as model spray paint pen, a PA pot, the air bag jack, etc. Please purchase separately.",
    v1591:
      "The output parameter of the USB-A port is 5V⎓2A. Can be used as 5000mAh power bank.",
    v1592:
      "These eight data tests are from the Shenzhen Branch of SUD Certification and Testing (China) Co. Ltd. and iRiding. Laboratory Test report number:QJSZ20210731.01,QJSZ20210806.01,QJSZ20210729.01,QJSZ20210831.01,QJSZ20210519.01,QJSZ20210803.01,68.189.21.0074.01.",
    v1593:
      "The data in the table is the test data in the full load condition. Actual results may vary slightly depending on factors such as environment, temperature, power consumption and usage habits.",
    v1594:
      "Note Unless: otherwise specified, the data on this page, comes from iRiding Laboratory.",
    v151: "When the product is fully charged,",
    v152: "can inflate 18 175/70 R14 car tires from 2.0bar to 2.5bar.",
    v153: "Fill up a tire in about 60 seconds.",
    v154: "When the product is fully charged,",
    v155: "can inflate about 5 175/70 R14 car tires from 0bar to 2.5bar.",
    v156: "About 5 minutes to fill a tire.",
    v157: "The pressure maintaining mode must be used with tools ,such as model spray paint pen, a PA pot, the air bag jack, etc.",
    v158: "Please purchase separately.",
    v159: "The output parameter of the USB-A port is 5V⎓2A.",
    v160: "Can be used as 5000mAh power bank.",
    v161: "These eight data tests are from the Shenzhen Branch of SUD Certification and Testing (China) Co., Ltd. and iRiding. Laboratory",
    v162: "Test report number:QJSZ20210731.01,QJSZ20210806.01,QJSZ20210729.01,QJSZ20210831.01,QJSZ20210519.01,QJSZ20210803.01,68.189.21.0074.01",
    v163: "The data in the table is the test data in the full load condition",
    v164: "Actual results may vary slightly depending on factors such as environment, temperature, power consumption and usage habits",
    v165: "Note",
    v166: "Unless otherwise specified, the data on this page, ",
    v167: "comes from iRiding Laboratory.",
    v168: "Instruction Manual×1",
    v169: "Storage Pouch×1",
    v170: "High-Pressure Air Hose×1",
    v171: "Charging Cable×1",
    v172: "Needle Valve Adapter×1",
    v173: "Presta Valve Adapter×1",
    v174: "QiCYCLE Portable Electric Air Inflator P1×1",
    v175: "Package List",
    v176: "Automatic pressurization",
    v177: "Innovative pressure retention model",
    v178: "Pressurization starts immediately below the set value.Automatic vessel pressure maintenance,Paint, watering, car wash.Easy and hassle-free.",
    v191: "Press and hold the light button after powering on",
    v192: "and press「+」key，until",
    v193: "icons appears.",
    v194: "Product Name",
    v195: "QiCYCLE Electric Air Pump P1",
    v196: "Product Model",
    v197: "QJCQBP1",
    v198: "Product Size",
    v199a: "200.5 × 63.5 × 63.5 mm",
    v199b: "(bare metal size, without air hose)",
    v200: "Inflation Pressure Range",
    v201: "3-150psi / 0.2-10.3bar",
    v202: "Battery Type",
    v203: "Li-Ion",
    v204: "Battery Capacity",
    v2041: "28.86 Wh / 11.1V",
    v205: "28.86Wh/11.1V",
    v206: "Sensor Accuracy",
    v207: "±1.5 psi",
    v208: "Charging Time",
    v209: "< 4 hours",
    v210: " ",
    v211: "",
    v212: "Rigorous testing to create durable quality",
    v213: "Variety of scenes, free to switch",
    v2131: "Variety of scenes, ",
    v2132: "free to switch",
    v214: "Free from ties,",
    v214a: "Free from ties",
    v215: "Classic black",
    v216: "Good apperarence",
  },
  C2: {
    v0: "QiCYCLE C2",
    v1: "QiCYCLE electric bicycle C2",
    v1m: "QiCYCLE electric bicycle C2",
    v2: "Walking through the city freedom beyond imagination",
    v3: "Colors",
    v4: "Black",
    v5: "Light and compact design",
    v6: "Smart lights",
    v7: "Long battery life",
    v8: "Powerful lithium battery",
    v9: "8-speed gear",
    v10: "Large screen",
    v11: "Folding headtube",
    v12: "",
    v13: "Overview",
    v14: "Specifications",
    v14a: "Product Manual",
    v15: "Support",
    v16: "Modern design and top quality <br/> Everywhere is the C position",
    v16m: "Modern design and top quality Everywhere is the C position",
    v18: "Classic and elegant shape, an eye-catching bike for the road.",
    v19: "Enjoy your riding experience",
    v19m: "Enjoy your <br/> riding experience",
    v20: "Ergonomic Design",
    v21: "Ergonomic frame structure, comfortable and functional, helping you to connect with the cycling experience.",
    m21a: "Ergonomic frame structure, comfortable and functional, helping you to connect with",
    m21b: "the cycling experience.",
    v22: "*Electric bicycles on the road need to apply for a photo, please comply with traffic regulations, pay attention to riding safety.",
    v23: "Ultra-light and  easy handle",
    v24: "Multi-cavity frame",
    v24p: "made of aircraft grade aluminum,with flexible steering lightweight handling material,great for small spaces and tight turns.",
    v24a: ",with flexible steering, lightweight handling material,",
    v24b: "great for small spaces and tight turns.",
    v24c: "Multi-cavity frame &nbsp;&nbsp;<sup style='position: absolute;color: rgb(255, 255, 255) !important;margin-left: -0.3rem;margin-top: -0.05rem;'class='font15'>「1」</sup>&nbsp;made of aircraft grade aluminum,with flexible steering lightweight handling material,great for small spaces and tight turns.",
    v25: "A Light structure yet <br/> can carry heavyweight",
    v25m: "A Light structure yet can carry heavyweight",
    v26: "Approximate weight of the bicycle is 20kg.  Can safely carry a person up to 120kg.  Can be used by men, women and children .<sup style='font-size:0.15rem' :style=' ios? 'position: absolute;margin-top: -0.05rem;margin-left: -0.1rem;': 'position: absolute;margin-top: -0.05rem;margin-left: 0;''>「2」</sup>",
    v26c: "*Please purchase accessories such as front basket, rear shelf, sponge cushion and child seat according to your personal needs.",
    m26a: "Approximate weight of the bicycle is 20kg.  Can safely carry a person up to 120kg.  Can be used by men,",
    m26b: "women and children .",
    v27: "With efficient powerful assist,",
    v27a: "enjoy your riding experience",
    v27b: "With efficient powerful assist<br>enjoy your riding experience",
    v28: "High precision sensor",
    v29: "Understand your every pedal",
    v30: "Built-in axial torque sensor to capture pedaling data for better speeding up, effective, convenient and easy",
    v30a: "Accurately captures cycling, pedaling and speed data, improves acceleration and efficiency",
    m30a: "Accurately captures cycling, pedaling and speed data,",
    m30b: "improves acceleration and efficiency.",
    m31: "Sensor Principle",
    v31: "C2 PRO",
    v32: "Torque Sensor",
    v33: "C2",
    v34: "Speed sensors",
    v35: "1.Show ride data",
    v36: "2.Control booster output",
    v37: "3.Sensing pedaling force",
    v38: "4.Drive motor driving",
    v39: "Multi-gear selection, multi-scene balance",
    v40: "Three-speed boost mode can be switched freely. Ultra-long battery life endures about 60km",
    v40m: "Three-speed boost mode can be switched freely. Ultra-long battery life endures about 60km<sup style='margin: -0.05rem 0 0 -0.1rem' class='font15 positionA color3A'>「3」</sup>",
    v41: "Manpower:",
    v42: "Electric Power:",
    v43: "Electric power output",
    v44: "1st gear",
    v45: "Low power consumption and long battery life",
    v46: "2nd gear",
    v47: "Combining range and power",
    v48: "3rd gear",
    v49: "Strong power and fast acceleration",
    v50: "Walk assist button",
    v51: "Easy walk even in case of slope.",
    v52: "8-speed gear is precise",
    v52a: "and smooth",
    v52b: "8-speed gear is precise and smooth",
    v53: "There are 8 gears that are  freely adjustable. With the help of electric assist, you can challenge monstrous hills even in the face of wind.",
    v53a: "There are 8 gears that are  freely adjustable. With the help of electric assist, ",
    m53a: "There are 8 gears that are  freely adjustable.With the help of electric assist,",
    m53b: "you can challenge monstrous hills even in the face of wind.",
    v54: "8-speed gear is precise and smooth",
    v55: "Riding this city",
    v56: "Your safety is my concern",
    v57: "Lithium battery technology <br/> with an independent battery compartment",
    v57m: "Lithium battery technology with an independent battery compartment",
    v58: "Built-in 30ah power lithium batteries, with the circuit and battery safely isolated to ensure electrical safety.",
    v58m: "Built-in 30ah power lithium batteries, with the circuit and battery safely isolated to ensure electrical safety.",
    v59: "*This product has a built-in battery.",
    v60: "Please do not disassemble it by yourself.",
    v61: "Automotive Grade Power Management",
    v62: "Automotive Grade BMS Safety Management System, automatically stops charging when fully charged,  to ensure charging safety.",
    m62a: "Automotive Grade BMS Safety Management System, automatically stops charging when fully charged, ",
    m62b: " to ensure charging safety.",
    v63: "Low temperature capacity test",
    v64: "High Temperature Capacity Test",
    v65: "IPX5 test",
    v66: "Free fall test",
    v67: "Overcharge protection",
    v68: "Over-discharge protection",
    v69: "Acceleration shock test",
    v70: "Short circuit protection",
    v71: "High and low temperature impact test",
    v72: "Vibration resistance test",
    v73: "130℃ high temperature test",
    v74: "Charge retention capacity",
    v75: "Buttons and dials are at hand",
    v76: "Clear functions, simple to operate, easy for the  elderly to use.",
    v77: "Central light-sensitive display",
    v78: "Automated intelligent adjustment of screen brightness, gear, power, speed data in front of the eyes, clear in the daytime, not harsh at night.",
    v78b: "Automated intelligent adjustment of screen brightness, gear, power, speed data in front of the eyes, clear in the daytime, not harsh at night.",
    v78a: "speed data in front of the eyes, clear in the daytime, not harsh at night.",
    m78a: "Automated intelligent adjustment of screen brightness, gear, power,",
    m78b: "speed data in front of the eyes, clear in the daytime,",
    v79: "Automatic induction light<sup style='position: absolute;color: rgb(255, 255, 255) !important;margin-top: 0.05rem;'class='font15'>「5」</sup>",
    v79a: "Automatic induction light<sup style='position: absolute;margin-left: -0.05rem;margin-top: 0.05rem;'class='font15'>「5」</sup>",
    v80: "Light-sensitive control of front and rear light switches.",
    v81: "Automatically lights up or goes out.",
    m81a: "Automatically lights up or goes out.Brake",
    v82: "Brake tail light on high.",
    m82a: "light on high.",
    v83: "Riding without distractions.",
    v84: "Safer driving.",
    v85: "Add or remove accessories ",
    v85m: "Add or remove accessories to create a personalized experience and look",
    v85a: "to create a personalized experience and look",
    v86: "Entrepreneurs, decorators, fashion moms…...<br/>add or remove accessories to complete your own look.",
    v86a: "Entrepreneurs, decorators, fashion moms…...add or remove accessories to complete your own look.",
    m86a: "Entrepreneurs, decorators, fashion moms…...add or remove accessories",
    v86b: "to complete your own look.",
    v87: "Adding and removing accessories",
    v88: "Connect to the Ride Notes App",
    v89: "Documenting life",
    v90: "Access to QiCYCLE App. App collects data such as speed, mileage, etc. and share cycling life.",
    m90a: "Access to QiCYCLE App. App collects data such as speed, mileage, etc. and share cycling life.",
    v90c: "Access to QiCYCLE App. App collects data such as speed, mileage, etc. and share cycling life.",
    v91: "",
    v92: "Connect to the Ride Notes App",
    v93: "Ingenious design, create new memories",
    v94: "Permanent-magnet brushless motor",
    v95: "Trigger surging power",
    v96: "20 inch wheel diameter",
    v97: "Enjoy flexible riding fun",
    v98: "The headtube is foldable",
    v99: "Be the traveler in the trunk",
    v100: "Front and rear disc brakes",
    v101: "Run more fast are brakes stable",
    v102: "Quick Adjust Seatpost Clamp ",
    v103: "You decide the high and low",
    v104: "Featured Pneumatic Tire ",
    v105: "Stable as a mountain",
    //v106: "[1]    CCC certification institution:  China Quality Certification Center,  Certificate Number:2021011119391871",
    v107: "[1]    Multi-cavity bicycle frame, Obtained a number of utility model patents, The patent numbers are: ZL201820720214.X,ZL201822033605.7,ZL201821913718.X.",
    v108: "[2]    This product is suitable for height 155-185cm.",
    v109: "[3]    Endurance test conditions at 60 km: in assistance mode 2, with the lights off, a load of 70kg, driving on urban pavement at an average speed of 20 km/h.The actual range will vary slightly depending on the load, temperature, road conditions, operating habits and other factors.",
    v110: "[4]    The data comes from the testing center of Fujian Scud Power Technology Co., Ltd.Report No DC210073",
    v111: "[5] While riding, front and rear lights can be automatically turned on/off according to ambient light changes. ",
    v112: "[6] Please purchase accessories such as front basket, rear shelf, sponge cushion and child seat according to your personal needs. ",
    // v113: "[8] 延保服务详情请拨打售后热线 400-1144-177。",
    v114: "[7]    Please adjust the seat height between the highest and lowest safety lines of the seat tube.",
    v115: "[8]    The tire pattern on this page is for reference only. Due to different batches of tires.  The actual item may be slightly different. Please refer to the actual product.",
    v116: "Note:unless otherwise specified,The data on this page,comes from iRiding Laboratory", //
    v117: "",
    v120: "Electric power-assisted bicycle parameters",
    v121: "Product parameters",
    v121a: "Product Name",
    v122: "Electric Assist Bicycle",
    v123: "Maximum design speed",
    v124: "25Km/h",
    v125: "Product Model",
    v126: "TDN09Z",
    v127: "Braking method",
    v128: "Mechanical disc brakes",
    v129: "Product size (before folding)",
    v130: "1530×600×1060 mm",
    v131: "Dental Discs",
    v132: "56T×170mm Aluminum crank",
    v133: "Product size (after folding)",
    v134: "1530×430×725 mm",
    v135: "flower-drum",
    v136: "Aluminum hubs",
    v137: "Overall vehicle mass",
    v138: "About 20 kg",
    v139: "Charging time",
    v140: "About 5.5 hours",
    v141: "Wheelset size",
    v142: "20 inches",
    v143: "Waterproof rating of the body",
    v144: "IPX 3",
    v145: "Weight capacity",
    v146: "120 kg",
    v147: "Screen size",
    v148: "53×32 mm",
    v149: "Battery Capacity",
    v150: "7.5 Ah",
    v151: "Wireless connection",
    v152: "Bluetooth 5.0",
    v153: "Packing list",
    v154: "Electric power-assisted bicycle × 1",
    v155: "4、5、6 mm <br/> inner six wrenches×1",
    v155c: "4、5、6 mm inner six wrenches×1",
    v156: "Left and right footrest × 1",
    v157: "Power adapter × 1",
    //v158: "",
    v159: "M5 screws×4",
    v160: "User's manual×1",
    //v161: "",
    //v162: "",
  },
  F2: {
    v0: "QiCYCLE F2",
    v1: "QiCYCLE Electric Folding Bike F2",
    v1m: "QiCYCLE Electric Folding <br/> Bike F2",
    v2: "Start unfolding the city",
    v3: "Colors",
    v4: "Black",
    v5: "Torque sensing",
    v6: "Foldable and portable",
    v7: "Removable battery",
    v8: "Long battery life",
    v9: "Driving computer",
    v10: "Cruise-control",
    v11: "3-speed",
    v12: "16 inch wheel diameter",
    v13: "Overview",
    v14: "Specifications",
    v15: "Support",
    v16: "Boost mode ready anytime",
    v17: "takes in consideration your every move",
    v18: "The torque sensor collects pedaling force data and controls the power assist level.",
    v18a: "The torque sensor collects pedaling force data and controls the power assist level.ltra sensitive boost system ",
    v18b: "providing you with a personal and unique experience.",
    m18a: "The torque sensor collects pedaling force data and controls the power assist level.",
    m18b: "The torque sensor collects pedaling force data and controls the power assist level.No more fear of rugged terrain,",
    m18c: "going up slopes as easy as biking on flat ground.",
    v19: "Ultra sensitive boost system providing you with a personal and unique experience.",
    v20: "No more fear of rugged terrain, going up slopes as easy as biking on flat ground.",
    v21: "Principle of torque sensing system",
    v22: "1.Show ride data",
    v23: "2.Control booster output",
    v24: "3.Sensing pedaling strength",
    v25: "4.Drive motor driving",
    v26: "Compact folding",
    v27: "save space in the trunk",
    v27m: "Compact folding <br/>save space in the trunk",
    v28: "Vertical folding patented design",
    v28m: "Vertical folding patented design<sup style='font-size:0.15rem;margin-top: -1.2rem; margin-left:-0.05rem' :style='ios13? 'margin-top: -0.5rem; margin-left: 2.2rem': ios15? 'margin-top: -0.5rem; margin-left: 2.4rem': 'margin-top: -0.5rem; margin-left: 2.1rem''class='font15 positionA color3A'>「1」</sup>",
    v29: "the main frame can also be folded forged Aluminum alloy front and rear forks are light and strong load-bearing up to 100kg makes it safe to ride",
    v30: "forged Aluminum alloy front and rear forks are light and strong",
    v30a: ",forged Aluminum alloy front and",
    v30b: " rear forks are light and strong",
    m30a: "forged Aluminum alloy front ",
    v31: "load-bearing up to 100kg ",
    v32: "makes it safe to ride.",
    v33: "Patent number: 201420243615.2",
    v34: "Folded volume ",
    m34a: "about 0.25m",
    v35: "Detachable battery",
    v36: "more convenient to charge",
    v37: "The frame has 20 built-in 18650 power lithium batteries.",
    v38: "Automotive-grade BMS safety management system, ",
    m38b: " management system,",
    m38a: "Automotive-grade BMS safety",
    v38a: "full and stop, to ensure the safety of electricity.",
    v39: "Easy battery disassembly,",
    v40: "can charge the bike directly",
    v41: " or has the option to charge the battery separately .",
    v42: "Battery detachable",
    v43: "SHIMANO 3-speed system,",
    v43a: "3-speed transmission <br/> system,precise shifting.",
    v43m: "3-speed transmission system,precise shifting",
    v44: "precise shifting.",
    v45: "Shimano 3-speed transmission system,",
    v46: "enables precise shifting.",
    v47: "Robust and durable.",
    m47a: "Robust and durable.With the",
    m47b: "strong and resistant electric boost,",
    m47c: "you can challenge the wind or the most 'monstrous' hills.",
    v48: "With the strong and resistant electric boost,",
    v49: "you can challenge the wind or the most 'monstrous' hills.",
    v50: "Three riding modes",
    v51: "fast mode, long distance or cruise-control",
    v51m: "fast mode, long <br/> distance or cruise-control",
    v52: "Cruise-control mode <br/> Laid-back and relaxed",
    v52m: "Cruise-control mode <br/> Laid-back and relaxed",
    v53: "Laid-back and relaxed",
    v54: "One-click locks bike speed, no need for pedaling<br/>which allows for a relaxed ride.",
    v54m: "One-click locks bike speed, no need for pedaling which allows for a relaxed ride.",
    v55: "Fixed speed mode",
    v56: "Assist modes",
    v56m: "Assist modes <br/> Save energy and power",
    v57: "Save energy and power",
    v58: "3-speed boost mode can be switched easily The 2nd gear assist has a range of about 60km <sup style='margin-left: -0.05rem; margin-top: -0.05rem'class='font15 color3A positionA'>「2」</sup>",
    v58m: "3-speed boost mode can be switched easily The 2nd gear assist has a range of about 60km",
    v58c: ".",
    v59: "The 2nd gear assist has a range of about 60km<sup style='margin-left: -0.1rem; margin-top: 0.1rem'class='font15 color3A positionA'>「2」</sup>",
    v60: "Self-peddle:",
    v61: "Electric-assist:",
    v62: "1st gear: ",
    v62a: "low power consumption, long battery life.",
    v63: "2nd gear: ",
    v63a: "balance battery life and power.",
    v64: "3rd gear: ",
    v64a: "higher power, fast acceleration.",
    v65: "Electric-assist output.",
    v66: "Bicycle mode.",
    v67: "Strengthening the body",
    v68: "N gear: zero electric assist, perfect for cycling fitness and exercising, which makes it easy to ride like a bike.",
    v68a: "N gear: zero electric assist, perfect for cycling fitness and exercising, which makes it easy",
    v68b: "to ride like a bike.",
    m68a: "N gear: zero electric assist, perfect for cycling fitness and exercising, which makes it easy",
    m68b: "to ride like a bike.",
    v69: "Travel data computer, control your riding data",
    v69b: "Travel data computer",
    v69a: "control your riding data",
    v70: "Easy to switch gears color screen travel data computer displays speed, gear, battery and mileage information.The line is well documented and in your mind.",
    v70m: "Easy to switch gears The line is well documented and in your mind.",
    v71: "color screen travel data computer displays speed, gear, battery and mileage information.",
    v71b: "color screen travel data computer displays speed, gear, battery",
    v71a: "and mileage information.",
    m71a: "color screen travel data computer displays speed,",
    m71b: " gear, battery and mileage information.",
    v72: "The line is well documented and in your mind.",
    v73: "Assist mode control",
    v74: "Cycling data detection",
    v75: "Connect to QiCYCLE App <br/> improve your experience with cycling intelligence",
    v75a: "improve your experience with cycling intelligence.",
    v76: "App collects cycling data such as speed, mileage, heart rate and other data <sup style='margin:0 -0.1rem 0 -0.1rem' class='font15  color3A'>「3」</sup> etc., sync the data to the cloud just by using your phone, which makes it easy to check any time and guides your ride intelligently.",
    v76m: "App collects cycling data such as speed, mileage, heart rate and other data <sup style='margin:0 -0.1rem 0 -0.1rem' class='font15  color3A'>「3」</sup> etc., sync the data to the cloud just by using your phone, which makes it easy to check any time and guides your ride intelligently.",
    v76b: "etc., sync the data to the cloud just by using your phone,",
    v76a: "which makes it easy to check any time and guides your ride intelligently.",
    m76a: "App collects cycling data such as speed, mileage, heart rate and other data etc.,",
    m76b: "sync the data to the cloud just by using your phone,which makes it easy to check any time and guides your",
    m76c: "ride intelligently.",
    v77: "",
    v78: "Connect to the Ride Notes App",
    v79: "Ingenious design, wonderful details",
    v79m: "Ingenious design<br/>wonderful details",
    v80: "Bright LED headlights",
    v81: "illuminate the road at night",
    v82: "Unique ring shaped tail lights",
    v83: "Safe riding",
    v84: "16-inch wheels suitable for urban cycling",
    v85: "Helping you move steadily forward",
    v86: '<img style="width: 2.02rem; height: 0.32rem" src="https://image.iriding.cc/f2/m-2.png"> roller brakes',
    v86m: "roller brakes",
    v87: "Free lifetime maintenance",
    v88: "Ultra-light forged aluminum alloy frame",
    v89: "Small and flexible, maximizing battery life",
    v90: 'Waterproof IPX4 bike  <sup v-if="index === 5"style="margin-top: -0.05rem; margin-left: -0.05rem"class="font15 positionA">「4」</sup>',
    v90m: "Waterproof IPX4 bike<sup style='margin-top: -0.05rem; margin-left: -0.08rem' class='font15 positionA'>「4」</sup>",
    v91: "wind and rain resistant",
    v92: "Adjustable mudguards",
    v93: "Easy to fold",
    v94: "Utility model patent:",
    v95: "vertical swivel folding frame",
    v96: "[1]      Utility model patent: vertical swivel folding frame Patent number: 201420243615.2",
    v97: "[2]      60km riding range: electric-assist in 2nd gear mode, 75kg weight-bearing, able to ride on pavement at a constant speed of 20km/h.Battery life may vary depending on factors such as load, temperature, road conditions, and operating habits.",
    v98: "[3]      To obtain heart rate and other data, you need to connect an external device that conforms to the Bluetooth 5.0 protocol, such as a heart rate band, please purchase separately.",
    v99: "[4]      This bike passed the IPX4 waterproof test, If the bike gets splashed with water, this will not affect its working performance. Do not immerse the bike in water or use a high-pressure water gun to wash it. When riding in the rain, do not drive through deep water, under no circumstances should you allow the water level to  exceed the motor axle. Test report number: TH60150A/2019, Testing agency: SGS Taiwan Ltd.",
    v99a: "Note: ",
    v100: "1.Product tire pattern on this page is for reference only.  Due to different batches of tires, actual product may vary.Please refer to the actual product.",
    v106a:
      "2.Unless otherwise specified, the data on this page,Unless otherwise specified, the data on this page,comes from iRiding Laboratory.",
    v107: "Qicycle Electric power-assisted folding bicycle",
    v108: "parameters",
    v109: "Product parameters",
    v110: "Product Name",
    v111: "Electric Bicycle",
    v112: "Product Model",
    v113: "TDR08Z",
    v114: "Product size (before folding)",
    v115: "1290 × 540 × 950mm",
    v116: "Product size (after folding)",
    v117: "1000 × 450 × 650mm",
    v118: "Overall vehicle mass",
    v119: "About 14.5kg",
    v120: "Wheelset size",
    v121: "16 inches",
    v122: "Weight capacity",
    v123: "100kg",
    v124: "Battery Capacity",
    v125: "6Ah",
    v126: "Charging time",
    v127: "About 4.5 hours",
    v128: "Waterproof grade",
    v129: "IPX4",
    v130: "Screen size",
    v131: "1.77 inches",
    v132: "Wireless connection",
    v133: "Bluetooth 5.0",
    v134: "Packing list",
    v135: "Folding power bicycle x 1",
    v136: "4、5、6 mm <br/> inner six wrenches×1",
    v136a: "inner six wrenches×1",
    v136c: "4、5、6 mm inner six wrenches×1",
    v137: "Left and right footrest × 1",
    v138: "Power adapter × 1",
    v139: "User's manual×1",
    //v140: "",
  },
};
