/**
 * 法语
 */
module.exports = {
  lang: {
    lang: {
      v0: "FR",
      v1: "简体中文-ZH",
      v2: "繁體中文-ZH",
      v3: "English-EN",
      v4: "Deutsch-DE",
      v5: "Español-ES",
      v6: "Français-FR",
      v7: "한국어 - KO",
    },
    contact: {
      v0: "Contactez-nous",
      v1: "Contactez-nous",
      v2: "La coopération commerciale",
      v3: "E-mail: ",
      v4: "Équipe de vente",
      v5: "Ventes intérieures: ",
      v6: "Ventes à l'étranger: ",
      v7: "Service Clients",
      v8: "Hotline de service après-vente: ",
      v9: "Protection des données",
      v10: "E-mail: ",
    },
    law: {
      0: "Privacy and Laws",
      1: "Privacy Policy",
      2: "Date of update",
      3: "February 12, 2021",
      4: "Effective date",
      5: "March 12, 2021",
      6: "Preface",
      7: 'iRiding (Xiamen) Technology Co., Ltd. and its affiliated companies (hereinafter referred to as "QiCYCLE APP" or "We") take your personal information seriously and ensure that you have control over your personal information. We will use powerful encryption technology to protect your privacy and formulate strict policies to manage all your data. Before you visit QiCYCLE APP or use our services, you shall agree to the provisions of this policy on how we collect, use, store and share your relevant information. If you do not agree on any content in this privacy policy, you are requested to stop using or accessing our products and services immediately. If you have any questions about this policy, please contact us by emailing to privacy@miriding.com. The key points of this privacy policy are as follows:',
      8: "1.We will explain the types of your personal information we collect and their corresponding usage one by one, so that you can understand the categories, reasons for use and collection methods of the specific personal information we collect for a specific function.",
      9: "2.When you are using some functions, we will collect your sensitive information after obtaining your consent, unless it shall be collected pursuant to relevant laws and regulations. Refusing to provide such information will only make you unable to use some specific functions, but will not affect your normal use of the other functions of QiCYCLE APP.",
      10: "3.QiCYCLE APP will not share or transfer your personal information actively to a third party other than QiCYCLE APP. If there are other situations of sharing or transferring your personal information or if you need us to share or transfer your personal information to a third party other than QiCYCLE APP, we will obtain your express consent directly or confirm that the third party obtains your express consent for the above behaviors. In addition, we will conduct a risk assessment of our act of providing information to the outside world.",
      11: "4.QiCYCLE APP will not obtain your personal information actively from a third party other than QiCYCLE APP. If you need to obtain your personal information indirectly from a third party for business development in the future, we will explain to you the source, type and scope of use of your personal information before obtaining it. For example, if the personal information processing activities required by QiCYCLE APP for business purposes exceed the scope of your original authorization and consent upon providing personal information to a third party, we will obtain your express consent before processing such personal information of yours. In addition, we will strictly abide by relevant laws and regulations, and require the third party to guarantee the legality of the information that is provided.",
      12: "5.You can access, correct and delete your personal information, cancel your consent, cancel your account, complain and report, and set up privacy functions through the channels listed in this guide.",
      13: "If you want to know more detailed information, you are requested to read the corresponding chapters according to the following index:",
      14: "1.How do we collect and use your information",
      15: "2.How do we use Cookies and similar technologies",
      16: "3.How to update, change, export and delete your information",
      17: "4.How do we protect your personal information",
      18: "5.How do we share, transfer and publicly disclose your personal information",
      19: "6.Storage of information",
      20: "7.Third-party service provider",
      21: "8.Update of this policy",
      22: "9.Applicable scope",
      23: "10.How to contact us",
      24: "I. How do we collect and use your information?",
      25: "1. How do we collect your information?",
      26: "When we provide the services, we may collect, store and use the following information:",
      27: "1.1 The information you provide",
      28: "1.1.1 The information provided to us that can be used to identify the users' personal identity when you register or use our services, such as name, e-mail address, telephone number, bank card number, ID number, address, communication record, or other data that we can find through such links lawfully. If you want to make the most use of the sharing features we offer, you may also need to create publicly displayed personal data that may contain your name and photos.",
      29: "1.1.2 The shared information you provide to other parties through our services, and the information that you upload, submit, store, send or receive when you are using our services.",
      30: "1.1.2 The shared information you provide to other parties through our services, and the information that you upload, submit, store, send or receive when you are using our services.",
      31: "We will collect the information about the services you use and how you use them, such as related information about your using QiCYCLE APP sports, commenting in iRiding community or participating in clock-in movement, participating in iRiding events and challenges, becoming iRiding members and joining training camps, as well as that that may be generated when you use services of iRiding members and training camps. Such information includes:",
      32: "1.2.1 Equipment information",
      33: "We will receive and record the information related to the device you use according to your specific rights in the software installation and/or use authorization. For example, hardware and software characteristics such as your device model, operating system version information, device settings, mobile device version, device ID (such as IMEI/ Android ID/IDFA/OPENUDID/GUID/OAID, SIM card IMSI, ICCID information, etc.), screen resolution, environment of equipment,and relevant information regarding location of device, such as IP address, GPS location and the WLAN access points that can provide relevant information and the information for Bluetooth, base station and sensor. If you refuse to grant us the corresponding permissions during installation and/or use, we will not record the above kinds of information.",
      34: "1.2.21.2.2 Log information",
      35: "(1) Information for device or software, such as the configuration information for your mobile device, web browser or other programs used to access our services, your IP address and the version and device ID used by your mobile device (such as IMEI/ Android ID/IDFA/OPENUDID/GUID/OAID, SIM card IMSI, ICCID information, etc.);",
      36: "(2) The information that you search for or browse when you are using our services, such as web search terms you use, URL addresses of social media pages you visit, and other information and details that you browse or request to provide when you are using our services.",
      37: "1.2.3 IP address",
      38: "(1) The information about the mobile applications (APPs) and other software you have used, and the information about your having used such mobile applications and software;",
      39: "(2) The information you communicate through our services, such as the account number you have communicated with, as well as the communication time, data and duration;",
      40: "(3) The information (metadata) contained in the content you share through our services, such as the date, time or place of the shared photos or videos taken or uploaded.",
      41: "1.2.4 Pictures in cameras, microphones, devices, etc",
      42: "In order to provide you with a full range of sports experience, after you have authorized, our server will extract the video, picture, software name, version number, etc. from your device, and upload them to the server after encryption for comparison, so as to match the display function corresponding to the video, picture and software. At the same time, you can also refuse/cancel the permission at any time through relevant function settings. Refusing to provide this permission will only make you unable to use the above functions, but will not affect the normal use of other functions and services of QiCYCLE APP. However, after you cancel the authorization, we will no longer collect the information, nor can we provide you with the above services. However, unless you delete your information in accordance with the law, your cancellation will not affect our processing and storage of information based on your previous authorization.",
      43: "When you use the function of taking photos and recording videos, we will access the permissions of your devices such as camera and microphone, and collect the pictures and videos you provide to us after taking photos and recording videos. If you refuse to provide the permissions and contents, you will not be able to use this function, but it will not affect your normal use of other functions of iRiding. At the same time, you can cancel the permissions at any time through relevant function settings. After you cancel the authorization, we will no longer collect the information, nor can we provide you with the above services. However, unless you delete your information in accordance with the law, your cancellation will not affect our processing and storage of information based on your previous authorization.",
      44: "1.2.5 Location information",
      45: "We will access your location information after you enable the location permission, and provide you with more personalized products and/or services based on your location information, such as recording your GPS movement data and viewing the surrounding movement route. Of course, you can also stop us from collecting your geographic location information at any time by disabling the location function. You can give us access to your activity and physical training records and record your step frequency and step count information by reading your activity and physical training. At the same time, you can also cancel this permission at any time through relevant function settings. After you cancel the authorization, we will no longer collect the information, nor can we provide you with the above services. However, unless you delete your information in accordance with the provisions of the law, your cancellation will not affect the processing and storage of your information based on your previous authorization.",
      46: "We provide sports community service. When you use sports music function, we will request your authorization to read local music files for playing music while you are doing sports.",
      47: "1.2.6 The information from third parties",
      48: "Under some circumstances permitted by law, we may obtain your personal information from a third party. For example:",
      49: "(1) For the purpose of security and fraud prevention, verify your information (such as telephone number) from legitimate sources with your authorization for specific account number, financial transaction, etc;",
      50: "(2) Provide you with corresponding advertising services by a specified unique identifier (such as IMEI / Android / IDFA / OPENUDID / GUID / OAID, SIM CARD IMSI, ICCID information and so on) ;",
      51: "(3) We may also get some information from third-party social network services, such as accounts, nicknames (for example, when you log in to QiCYCLE APP service with a social network account);",
      52: "(4) The information related to you provided by others, such as your receiving address filled by other users while using QiCYCLE APP mall.",
      53: "1.2.7 Non-personal information",
      54: "We may also collect other information that cannot identify a specific individual and does not belong to personal information pursuant to local applicable laws, such as statistical data generated when you use a specific service, anonymous device related information, daily life events, page access events, page access duration events and session events,network monitoring data such as request duration, number of requests and wrong requests, etc, as well as application crash events. The purpose of collecting such information is to improve our services for you. The type and amount of information collected depends on how you use our products or services.",
      55: "We will collect information about your activities on our website or mobile app, such as unique device identifier, device model, system version number, etc. We will aggregate this information to help us provide customers with more useful information so that we can learn which parts of our website, products or services that they are most interested in. Aggregate data is treated as non-personal information as far as this privacy policy is concerned. If we combine non-personal information with personal information, such information will be regarded as personal information during the period of combination.",
      56: "2. How do we use your information",
      57: "The purpose of our collecting your personal information is to provide you with products or services and to ensure that we comply with applicable laws, regulations and other normative documents. We may use the information collected above for the following purposes:",
      58: "2.1 ovide, handle, maintain, improve and develop our products or services for you, such as delivery, verification, after-sales, customer support and advertising service;",
      59: "2.2 When we provide services, use it for authentication, customer service, security protection, fraud monitoring, archiving and backup purposes to ensure the security of the products and services we provide to you;",
      60: "2.3 Internal purposes, such as data analysis, research and development of statistical information related to the use of our products or services so that we can improve our products or services;",
      61: "2.4 Help us design new services and improve our existing services;",
      62: "2.5 rovide you with information push of more relevant activities, and you can choose to enable or disable it according to your preference;",
      63: "2.6 Evaluate and improve the effectiveness of advertising and other promotional and publicity activities in our services;",
      64: "2.7 Perform software verification and upgrade services;",
      65: "2.8 Provide information to our affiliated companies, third parties or other users when we provide specific services according to users' special requirements;",
      66: "2.9 Other situations that do not violate any mandatory laws and regulations;",
      67: "2.10 Store and maintain the information related to you for our business operation (e.g. business statistics) or fulfillment of legal obligations;",
      68: "2.11 Other purposes with your consent.",
      69: "More detailed examples are provided below on how we use your information (which may include personal information):",
      70: "(1) Log in and use QiCYCLE APP products or services;",
      71: "(2) Create and maintain your QiCYCLE APP account. The personal information provided when you create a QiCYCLE APP account through the website or a mobile device can be used to create your personal QiCYCLE APP account and personal data;",
      72: "(3) Participate in the community activities. Personal information related to the community or other similar social platforms of QiCYCLE APP may be used to display personal data pages, interact with other users, and participate in posting;",
      73: '(4) Provide location-based services. When you are using QiCYCLE APP service, we or third-party service providers and business partners (please refer to "How do we share, transfer and publicly disclose your personal information" below for more details) may use location information to provide services for you, and provide better user experience based on the accurate location, such as obtaining accurate running track, and you can disable the location based service in the settings of QiCYCLE APP at any time;',
      74: "(5) Improve the user experience. Some optional add-in features, such as user experience plans, allow us to analyze how users use mobile phones and QiCYCLE APP services to improve user experience, as well as sending crash reports;",
      75: "(6) Collect user feedback. The feedback you choose to provide is important to help QiCYCLE APP to improve its services. To handle the feedback you provide, QiCYCLE APP may contact you with the personal information you provide, and keep records for problem solving and improvement of services;",
      76: "(7) Send a notification. We may use your personal information to send important notices, such as changes to our terms, conditions and policies;",
      77: "(8) Carry out promotional activities. If you participate in the prize-drawing activity and competition through QiCYCLE APP, we may send such rewards to you using the personal information you provide;",
      78: "(9) Analyze the device so as to provide a better user experience. QiCYCLE APP will analyze devices to further enhance the experience of them.",
      79: "You have the right to refuse personal information processing and automated decision-making for direct marketing purposes. In order to exercise the above rights, you can send an email to privacy@miriding.com or refer to the control mechanism described in the separate privacy policy for each product or service.",
      80: "3. Our commitment",
      81: "3.1 We will not present personalized advertisements to you based on sensitive categories (such as race, religion, sexual orientation or health status);",
      82: "3.2 e will not share with advertisers the information that can be used to identify you, such as your name or e-mail address (unless authorized and consented by you);",
      83: "3.3 We will not sell your personal information to any third party at any time and under any circumstance. We will only use the information obtained under this Agreement to the extent permitted by the law. We will formulate strict policies to protect your personal information, and will not disclose your personal information to the public or provide it to any third party unless your prior authorization is obtained or otherwise specified in this statement.",
      84: "4. Affiliated companies or other trusted cooperative partners",
      85: "To ensure the quality of service, we may provide you with the services you require or the content you may be interested in by our affiliated companies or other trusted partners. With your authorization and consent, we will share the personal information necessary for the products and services with our affiliated companies or partners. We will require our affiliated companies and partners to ensure the security of your personal information in accordance with our instructions, privacy policy and any other appropriate confidentiality and security measures. Except for this, we will not provide or share your information to any third party without your authorization and consent.",
      86: "5. HeathKit",
      87: "If you are using an Apple mobile phone, in order to calculate exercise consumption and record relevant exercise data for you, with your authorization, we will receive your exercise data from Apple's Heathkit and send to it. To simplify your input process, we will read your motion data from Heathkit. At the same time, the training data generated by your QiCYCLE APP will be synchronized with Apple's Heathkit. Any information obtained by using Heathkit, such as steps and heart rate data, will not be shared or sold to any third party, including advertisers and other agents. We will not use Heathkit's information in marketing, advertising and similar services.",
      88: "6. Third-party entities",
      89: "The third-party service in QiCYCLE APP services is provided by an external third-party entity. QiCYCLE APP cannot obtain the information generated when you use this kind of third-party service. However, if you have explicitly agreed that the third party can obtain your geographic location information, the third party will obtain your terminal geographic location information through the QiCYCLE APP interface. This information is sensitive. Refusing to provide this information will only make you unable to use the above third-party services, but will not affect your normal use of QiCYCLE APP.",
      90: "Apart from these, pursuant to relevant laws, regulations and national standards, we may collect and use your personal information in the following situations without obtaining your authorization and consent:",
      91: "6.1 That directly related to national interests including national security and national defense security and that directly related to major public interests such as public security, public health, public knowledge and so on;",
      92: "6.2 That directly related to criminal investigation, prosecution, trial and execution of judgment;",
      93: "6.3 That for the purpose of safeguarding personal life, property and reputation of yours or others’ and it is difficult to obtain your consent;",
      94: "6.4 The collected personal information is disclosed to the public by yourself;",
      95: "6.5 Where personal information is collected from the lawfully disclosed information, such as lawful news coverage, disclosure of government information and so forth;",
      96: "6.6 That necessary for signing and performing the contract according to your requirements;",
      97: "6.7 That necessary to maintain the safe and stable operation of the products or services provided, such as finding and handling the faults of products or services;",
      98: "6.8 That necessary for lawful news coverage;",
      99: "6.9 That necessary to carry out statistical or academic research out of public interest, and when the results of academic research or description are provided to the outside world, the personal information contained in the results is to be de-identified.",
      100: "6.10 Transfer for the purpose of completing merger, division, acquisition or asset transfer;",
      101: "6.11 Other circumstances stipulated by laws and regulations.",
      102: "You are requested to understand that the functions and services we provide to you are constantly updated and developed. If a certain function or service is not included in the above description and your information is collected, we will explain the content, scope and purpose of information collection to you through page prompt, interactive process and website announcement separately so as to obtain your consent.",
      103: "Please note that QiCYCLE APP will not obtain your personal information from a third party other than QiCYCLE APP actively at present. If you need to obtain your personal information indirectly from a third party for business development in the future, we will explain to you the source, type and scope of use of your personal information before obtaining it. If the personal information processing activities required by QiCYCLE APP to carry out business exceed the scope of your original authorization and consent when your personal information is provided to a third party, we will obtain your express consent before processing your personal information. In addition, we will strictly abide by relevant laws and regulations, and require the third party to guarantee the legality of the information provided.",
      104: "7. Sensitive personal information",
      105: "We will not provide your sensitive personal information to anyone except with your prior consent.",
      106: "8. Anonymization of personal information",
      107: "After collecting your personal information, we will anonymize the data through technical means in a timely manner. On the condition that your personal information is not to be leaked, QiCYCLE APP has the right to mine, analyze and utilize the anonymized user database (including for commercial use), to make statistics on the use of products/services and share desensitization statistics with the public/third parties.",
      108: "II. How do we use Cookie and similar technologies",
      109: "Cookies are small files containing character strings sent to your computer when you visit the website. When you visit the website again, the Cookie enables the site to identify your browser. Cookies also store user preferences and other information. You can reset your browser to reject all cookies, or prompt when cookies are being sent. But without cookies, some web features or services may not work properly.",
      110: "Cookie and the same type of technologies are common technologies in the Internet. When you use related services of iRiding, we may use relevant technologies to send one or more cookies or anonymous identifiers to your device to collect and store information when you access and use iRiding. We treat information collected through cookies and other technologies as non-personal information. However, if the local law treats an Internet Protocol (IP) address or similar identification marks as personal information, we also treat such identification marks as personal information. We use cookies and similar technologies to achieve the following functions or services:",
      111: "1. Ensure safe and efficient operation of the products and services",
      112: "We may set cookies or anonymous identifiers for authentication and security purposes, so that we can confirm whether you are safe to log in to the service, or whether you encounter illegal acts such as embezzlement and fraud. These technologies will also help us improve service efficiency and login and response speed.",
      113: "2. Help you acquire a more relaxed visit experience",
      114: "Using this kind of technology can help you avoid the steps and processes of repeated completion of personal information and entering search content, and achieve one-click login and record search history.",
      115: "3. Recommend, display and push the contents you may be interested in",
      116: "We may use cookies and similar technologies to learn about your preferences and use habits, and conduct consultation or data analysis to improve product services and user experience.",
      117: "4. Log files",
      118: "As most websites do, we collect specific information and keep it in a log file. Such information may include Internet Protocol (IP) address, browser type, Internet service provider (ISP), reference/exit page, operating system, date/time stamp and/or clickstream data. We don't associate automatically collected log data with the other information we collect.",
      119: "5. Mobile analysis",
      120: "In some mobile applications, we use mobile analysis software to learn better about the functions of our mobile software in your mobile phone. This software may record information such as how often you use the application, events within the application, cumulative usage, performance data, and where the application crash occurs. We will not associate the information stored in the analysis software with any personal information you submit in your mobile application.",
      121: "6. Local storage - HTML5/Flash",
      122: "We use local storage objects (LSO), such as HTML5 or flash, to store contents. The third parties that cooperate with us to provide some functions on QiCYCLE APP, or to advertise according to your browsing behaviors, will also collect and store relevant information through HTML5 or Flash cookie. Various browsers provide their own management tools to delete HTML5 local storage objects.",
      123: "III. How do we update, modify, export and delete your information",
      124: "Pursuant to the laws of some jurisdictions:",
      125: "1. Provide true personal information",
      126: "Prior to your using our products and services, you will be required to provide your true personal information. You should be responsible for the authenticity, legality, validity and integrity of the information you provide, and update and maintain it in time to ensure its authenticity, legality and effectiveness. You can manage your personal information, such as your personal account registration information, at any time.",
      127: "2. Delete content or personal information",
      128: "You can delete your content or personal information from iRiding account by yourself. You can apply for account cancellation through [My]-[Settings] - [About the account] -[Account cancellation]. Within fifteen (15) working days, we will delete or anonymize your personal information.",
      129: "3. Data retention within limited time",
      130: "In some cases, we will keep the data for a limited period of time for legal purposes or legitimate business needs. We will do our best to ensure that the services of iRiding can protect the information from accidental or malicious deletion. Therefore, if you delete something, we may delete its copy from our current and standby systems within fifteen (15) working days.",
      131: "4. Back up content or use it for non-iRiding services",
      132: "You can always back up the contents in your personal account or use it for non-iRiding services. You can contact us by email at privacy@miriding.com and make your request.",
      133: "5. Withdrawal of consent",
      134: "You may withdraw your consent previously granted to us by submitting a request for specific purposes, including collection, use and/or disclosure of your personal information in our possession or control. Depending on the specific service you use, you can visit the account management center of the QiCYCLE APP or send an email to privacy@miriding.com for related operations. We will process your request within a reasonable period of time after you make the request and will not collect, use and/or disclose your personal information upon your request thereafter.",
      135: "Depending on the extent to which you withdraw your consent, you may not be able to continue to enjoy QiCYCLE APP's products or services. However, your decision to withdraw your consent or authorization will not affect the effectiveness of our previous personal information processing activities based on your consent.",
      136: "6. Cancellation of the service or account",
      137: "If you want to cancel a specific product or service, you can cancel your account through [My]- [Settings] - [Account security] - [Cancel account]. After you cancel your account, we will delete or anonymize your personal information.",
      138: "Within fifteen (15) business days, we will delete or anonymize your personal information. Once the account is canceled, it will not be recoverable. You are requested to back up all the information and data related to your iRiding account by yourself before the operation.",
      139: "IV. How do we protect your personal information",
      140: "1. Strict information management policy",
      141: "We have drawn up strict information management policies, equipped with professional technical teams, and have adopted a series of reasonable precautions to protect your personal information from unauthorized browsing, disclosure, abuse, change, destruction and loss, including:",
      142: "(1) Use encryption technology to ensure that your data is kept private during transmission;",
      143: "(2) Provide a variety of security functions to help you protect your account security;",
      144: "(3) Review our practices in the collection, storage and processing of information, including physical security measures, in order to prevent unauthorized access to our systems;",
      145: "(4) All the iRiding employees, contractors and agents who have to contact personal information for the purpose of providing services shall abide by the strict confidentiality obligations stipulated in the contract. Otherwise, they may be punished or fired.",
      146: "2. Technical limitation and malicious means",
      147: "Although the above reasonable and effective measures have been taken and the standards required by relevant laws and regulations have been followed, you are requested to understand that it is impossible to guarantee 100% security of information in the Internet industry all the time due to the limitations of technologies and various malicious means that may exist even if we do our best to strengthen our security measures. We will try our best to ensure the security of the personal information you provide us. You shall confirm that you know and understand that the system and communication network you use to access our services may have problems due to factors beyond our control. Therefore, we strongly recommend that you take positive measures to protect the security of your personal information, including but not limited to using complex passwords, changing passwords regularly, and not disclosing your own account password and other personal information to others.",
      148: "3. Emergency plan",
      149: "We will work out an emergency plan and trigger it immediately in case of any user information security incident, so as to prevent the expansion of the impact and consequences of such security incident. After the unfortunate occurrence of a user information security incident (leakage, loss, etc.), we will inform you of the basic situation and possible impact of the security incident, the measures we have taken or will take to cope with it, the suggestions that you can prevent and reduce the risks on your own, and the remedial measures for you in accordance with the requirements of laws and regulations in time. We will inform you of relevant information of the incident by mail, letter, telephone, push notice, etc. And when it is difficult to inform the subject of personal information one by one, we will take reasonable and effective ways to issue an announcement. At the same time, we will report the handling of the personal information security incident in accordance with the requirements of regulatory authorities.",
      150: "4. What can you do?",
      151: "You shall not disclose your login password or account information to anyone (unless this person is officially authorized by you) and you can set a unique password for QiCYCLE APP to prevent other websites from leaking your password that may endanger the security of your account in QiCYCLE APP. At any time, be sure not to disclose the verification code you receive to anyone (including those who claim to be customer service personnel of QiCYCLE APP).",
      152: "QiCYCLE APP will not be responsible for the security negligence caused by any third party's access to your personal information due to your failure to maintain the privacy of your personal information. Notwithstanding the above provisions, in the event of any other Internet user’s use of your account without authorization or having any other security vulnerabilities, you should immediately inform us.",
      153: "Your assistance will help us protect the privacy of your personal information.",
      154: "V. How do we share, transfer and disclose your personal information",
      155: "1. Sharing",
      156: "We will not sell any personal information to a third party.",
      157: "Sometimes we may share your personal information with third parties in order to provide or improve our products or services, including providing products or services according to your requirements. If you do not want us to share this information any longer, you are requested to send an email to privacy@miriding.com and contact us.",
      158: "1.1 Share out of your active choice",
      159: "Share the information to the extent of your authorization/request with the third party designated by you after your explicit consent or active choice. For example, you can quickly log in to a third-party website or APP by using your QiCYCLE APP account.",
      160: "We will provide social networking services. When you use this type of service, the information you upload or publish (including your public personal information, your address book, your circle of friends), your response to the information uploaded or published by others, as well as the log information related to these information, etc. So long as you do not delete the information, it will always be kept in the public domain, and shared and browsed by other users of our service. Even if you delete the information, it may still be independently cached, copied or stored by other users or unrelated third parties not under our control, or stored in the public domain by other users or third parties.",
      161: "Please consider carefully the information uploaded, published and exchanged through our service. In some cases, you can control the range of users who have the right to browse your shared information through the privacy settings of some of our services. You can delete the information you upload, publish or share by yourself. If you want us to delete your relevant information for you, please contact us by sending an email to privacy@miriding.com and put forward your request, we will finish checking and handling it within fifteen (15) working days after receiving it.",
      162: "1.2 Share information with our affiliated companies",
      163: "We may share your personal information with other related companies of QiCYCLE APP from time to time in order that we can be engaged in our business operation smoothly and provide you with all the functions of products or services.",
      164: "1.3 Share with third party service providers and business partners",
      165: "In order to provide you with the services described in this privacy policy, we may share necessary personal information with third parties such as third party service providers and business partners.",
      166: "They include our data center, data storage facilities, advertising and marketing service providers and other business partners. These third parties may process your personal information on behalf of QiCYCLE APP or for one or more items of this privacy policy. We guarantee to share the personal information necessary for your service only for justifiable, legitimate, necessary, specific and definite purposes. QiCYCLE APP will conduct due diligence and sign a contract to ensure that the third party service provider complies with applicable privacy laws in your jurisdiction. Third party service providers may also have their sub-processors.",
      167: "In order to provide performance measurement, analysis and other commercial services, we may also share information (non-personal information) with third parties (such as our advertisers) in the form of summary. We will use the information that we grasp to help advertisers and other partners assess the effectiveness and coverage of their advertising and services, and understand the types of people who use their services and how people interact with their websites, apps and services. We may also share with them general trends in the use of our services, such as the number of customers in a particular human group who purchase certain products or engage in certain transactions.",
      168: "Third-party SDK name",
      169: "Owned Company",
      170: "Data Collection",
      171: "Purpose of Usage",
      172: "Privacy Policy/Official Website",
      173: "weibo.com SDK",
      174: "sina.com",
      175: "External storage status, latest geographic location information, continuous positioning, network type, network service operator, IMEI, MAC address, WiFi information",
      176: "Weibo login, share",
      177: "https://open.weibo.com/wiki/%E5%BC%80%E5%8F%91%E8%80%85%E5%8D%8F%E8%AE%AE#.E9.9A.90.E7.A7.81.E7.9B.B8.E5.85.B3.E6.9D.A1.E6.AC.BE",
      178: "Xiaomi account open platform SDK ",
      179: "Xiaomi",
      180: "External storage status, network type, network service operator, IMEI, MAC address, WiFi information",
      181: "Xiaomi account login",
      182: "https://dev.mi.com/console/",
      183: "Umeng + SDK",
      184: "Youmeng Tongxin (Beijing) Technology Co., Ltd.",
      185: "Device status and identity and get your IMEI, IMSI information",
      186: "App statistical analysis scenario ",
      187: "https://www.umeng.com/",
      188: "Bugly SDK",
      189: "Tencent",
      190: "Device status and identity and get your IMEI, IMSI information, ANDROID ID",
      191: "Exception report",
      192: "https://bugly.qq.com/v2/index",
      193: "Qiniu Cloud SDK ",
      194: "Shanghai Qiniu Information Technology Co., Ltd.",
      195: "Device status and identity and get your IMEI, IMSI information, external storage status",
      196: "Amap Map SDK",
      197: "https://www.qiniu.com/?ref=developer.qiniu.com",
      198: "Amap Map SDK",
      199: "AMAP",
      200: "External storage status, latest geographic location information, continuous positioning, network type, network service operator, IMEI, MAC address, WiFi information",
      201: "Positioning, map display and poi query",
      202: "https://developer.amap.com/",
      203: "Wechat SDK",
      204: "Tencent",
      205: "External storage status, latest geographic location information, continuous positioning, network type, network service operator, IMEI, MAC address, WiFi information",
      206: "WeChat login, share",
      207: "https://developers.weixin.qq.com/doc/oplatform/Mobile_App/WeChat_Login/Development_Guide.html",
      208: "Xiaomi Push SDK",
      209: "Xiaomi",
      210: "Device status and identity and get your IMEI, IMSI information",
      211: "Push/send notification message",
      212: "https://dev.mi.com/console/",
      213: "QQ SDK",
      214: "Tencent",
      215: "System language, external storage status, latest geographic location information, network location information, continuous positioning, network type, mobile phone number, SIM card serial number, network service operator, IMEI, MAC address, ANDROID ID, IP address, WiFi information, Application installation list, read clipboard",
      216: "QQ login, share",
      217: "https://connect.qq.com/",
      218: "Regarding access to third-party services, we may access to third-party SDK services and share some of your information collected in accordance with this policy with such third-party service providers, so as to improve our customer service and user experience.",
      219: "Currently, the main types of third-party service providers we have access to include the following:",
      220: "(1) Used for message push. When you use our products or services,Xiaomi Push may access your device status and identity and get your IMEI and IMSI information, for pushing/sending notification messages. This information is necessary for the push function,and refusal to provide it will result in your inability to use the function,but will not affect the normal use of other functions;",
      221: "(2) Used to obtain device location permissions and collect device information and log information with your consent;",
      222: "(3) Used for third-party authorization services,including login using third-party accounts and sharing related content to third-party products.，QQ and WeChat SDKs may access your ANDROID_ID and other information;",
      223: "(4) including content storage and positioning.including hardware distribution capability enhancement, server cost reduction, and application exception statistics reporting.Data such as your device information and network location information may be collected;",
      224: "(5) Used to optimize product performance,including hardware distribution capability enhancement, server cost reduction, and application exception statistics reporting. Data such as your device information and network location information may be collected",
      225: "(6) Used for services related to account security and product reinforcement,including network monitoring, domain name resolution, anti-hijacking, anti-spam/anti-cheating, and encryption/decryption services.",
      226: "We will only share your information for justifiable, necessary and specific purposes. For the third-party service providers with whom we share information, we will require them to perform relevant confidentiality obligations and take corresponding security measures.",
      227: "Third-party social media or other services are operated by relevant third parties. Your use of such third party's social media services or other services (including any information you provide to such third party) is subject to the third party's own terms of service and information protection statement (other than this policy), and you shall read the terms carefully. This policy only applies to the personal information we collect, and does not apply to the services provided by any third party or the information use rules of any third party. If you find that there are risks in these third-party social media or other services, you are recommended to terminate relevant operations to protect your legitimate rights and interests.",
      228: "1.4 Miscellaneous",
      229: "QiCYCLE APP may have to disclose your personal information in accordance with the requirements of laws and regulations, legal procedures, lawsuits and/or legal requirements of public institutions and government departments. We may also disclose the information about you if we determine that such disclosure is necessary or appropriate for national security, law enforcement or other matters of public importance.",
      230: "If we determine that the disclosure is reasonably necessary to enforce our terms or to protect our operations, rights, assets or products, or to protect users, or achieve the following purposes (detection, prevention and resolving of fraud, unauthorized use of products, violation of our terms or policies, or other harmful or illegal activities), we may also disclose information about you.(For the avoidance of doubt, QiCYCLE APP will only collect, use or disclose your personal information without your consent to the extent and only under such circumstances as expressly permitted by local data protection laws.) This may include providing information to public or government authorities, exchanging information about the reliability of your account with third-party partners to prevent fraud, violations and other harmful behaviors inside and outside our products.",
      231: "In addition, we may share your personal information with the following persons:",
      232: "(1) Our accountants, auditors, lawyers or similar advisers when we require them to give professional advice;",
      233: "(2) and Investors and other relevant third parties if there is an actual or potential sale or transaction of any other company, and it is related to the QiCYCLE APP entity;",
      234: "(3) and Any other third party, who discloses and shares specific information as authorized by you.",
      235: "2. Transfer",
      236: "Except for the following circumstances, QiCYCLE APP will not transfer your information to any entity:",
      237: "(1) Your express consent has been obtained;",
      238: "(2)(2) If QiCYCLE APP is involved in any merger, acquisition or sale of all or part of its assets, we will notify you of any change in the ownership and use of your personal information, as well as any options you may have regarding your personal information by e-mail and/or on our website in an eye-catching way.",
      239: "3. Public disclosure",
      240: "Except for desensitized display of your QiCYCLE APP account and user name when the list of award-winning activities is announced, QiCYCLE APP will only publicly disclose your personal information under the following circumstances:",
      241: "(1) Your express consent has been obtained;",
      242: "(2) Public disclosure based on laws or reasonable grounds: including laws, regulations, legal procedures, lawsuits or at the request of the competent government authorities.",
      243: "VI. Storage of information",
      244: "In general, we will only retain your personal information for so long as is necessary for our purposes, such as:",
      245: "Mobile phone number: If you need to use QiCYCLE APP services, we need to keep your mobile phone number all the time to ensure your normal use of the services. After you cancel your account, we will delete the corresponding information;",
      246: "Community information: when you send a community share, we need to save your community information so as to ensure that you use the community function normally, and we will delete the corresponding information after you delete your community information.",
      247: "When the operation of our products or services is stopped, we will notify you in the form of push notification, announcement, etc., and delete your personal information or anonymize it within a reasonable period.",
      248: "Since QiCYCLE APP is a global product, some of our activities include a global race of cycling, and we will store some of the data overseas.",
      249: "VII. Third-party service provider",
      250: "1.To facilitate your visit and enrich your experience, there may be products or services provided by third parties. You can choose whether to access such contents or links, or whether to use the third party's products or services. But we have no control over the products or services provided by third parties. We have no control over any of your personal information held by a third party. The information protection problems in the process of using the third party products or services does not apply to the management of this policy. This policy does not apply to any information you choose to provide to third parties, either. Please check the privacy policy of this third party. 。",
      251: "2.Participate in marketing activities. When you choose to participate in our relevant marketing activities, you need to provide your name, address, contact information, bank account number and so on according to the needs of the activities, so that the third party can provide you with prizes in time. When you choose to participate in our marketing activities, you need to provide your name, address, contact information, bank account number and so on according to the needs of the activities. With your express consent, we will share the above information with a third party, so that we can entrust the third party to provide you with prizes in time.",
      252: "VIII. Update of the policy",
      253: "1.We may modify and update this Privacy Policy from time to time. We will release the update of the Privacy Policy via notice or announcement in QiCYCLE APP, and you can visit QiCYCLE APP to search for the latest version of the Privacy Policy. For major changes, we will send you a formal notice (for some services, we will also send a notice by email to elaborate the changes to the Privacy Policy).",
      254: "IX. Applicable scope",
      255: "1.Our privacy policy applies to all the services provided by iRiding (Xiamen) Technology Co., Ltd. and its affiliated companies, including the services provided by QiCYCLE APP and at www.iriding.cc, except for the services with independent privacy policies (such as products and services provided by third parties).",
      256: "2.Our privacy policy does not apply to products and services provided by third parties, such as the products and services provided by third parties in QiCYCLE APP and/or at www.iriding.cc, as well as the other websites linked to our services, which will be regulated by independent privacy policies. Please refer to the corresponding policies and regulations separately.",
      257: "3.Regarding other companies and organizations conducting advertising and publicity for our services and may use our Cookies or other log information to launch and use advertisements, our Privacy Policy does not cover their information processing policies.",
      258: "X. How to Contact Us",
      259: "You can contact us in the following ways:",
      260: "1.If you have any question, opinion or suggestion about this policy, you can feed back to us through the background of QiCYCLE APP and contact us through the background of WeChat's official account of iRiding.",
      261: "2.If you find that your personal information may be leaked, you can complain and report through QiCYCLE APP, and we will contact you and complete the verification and handling within fifteen (15) working days;",
      262: "3.We have also set up a feedback email for comments and suggestions, and you can contact us by emailing to support@miriding.com, and we will contact you within fifteen (15) working days and complete the verification and handling.",
      263: "4.If you have any question about this policy, please contact us by emailing to privacy@miriding.com, and we will contact you and complete the verification and handling within fifteen (15) working days.",
    },
  },
  downLoad: {
    v1: "The QiCYCLE APP",
    v2: "La beauté de la technologie rend le cyclisme amusant",
    v3: "Applicable à la Chine continentale",
    v4: "Applicable aux autres régions",
    v5: "Application Mall",
    v6: "Téléchargements locaux",
  },
  index: {
    v1: {
      text0: "iRiding",
      text1: "Page d'Accueil",
      text2: "Vélo à assistance électrique",
      text3: "Accessoires",
      text4: "APP",
      text5: "Supports",
      text6: "Profil de la société",
      text7: "Naissance",
      text8:
        "En 2011, iRiding (Xiamen) Technologie Co., Ltd. a été créée à Xiamen.",
      text9: "Courir",
      text10:
        "En 2013, le logiciel social de cyclisme sur les sports APP QiCYCLE 1.0 a été officiellement lancé.",
      text11:
        "Après deux ans, le nombre d'utilisateurs installés et enregistrés a dépassé le million, ce qui en fait le premier choix des utilisateurs de cyclisme.",
      text12:
        "Jusqu'à présent, les utilisateurs ont parcouru un total de 130 millions de kilomètres et peuvent voyager 175 fois de la Terre à la Lune.",
      text13: "Accélérer",
      text14:
        "En septembre 2014, elle est devenue une entreprise de la chaîne écologique Xiaomi.",
      text15:
        "QiCYCLE s'est consacré à la recherche et au développement de matériel et a successivement lancé une variété de produits tels que les vélos route en fibre de carbone R1, les vélos pliants à assistance électrique Mi QiCYCLE , les vélos enfant Mitu et les gonfleurs d'air Mi.",
      text16: "Expansion",
      text17:
        "En 2019, les vélos pliants à assistance électrique Xiaomi et les les gonfleurs d'air Mi ont lancé au marché les uns après les autres.",
      text18:
        "À l'heure actuelle, les produits QiCYCLE sont vendus dans plus de dix pays et régions d'Asie, d'Europe, d'Océanie et d'Amérique du Sud.",
      text19:
        "En 2020, lancement des  les vélos à assistance électrique QiCYCLE, initiant un nouveau voyage pour sa propre marque.",
      text20: "Vision",
      text21:
        "À l'avenir, QiCYCLE continuera de se concentrer sur le développement de produits de déplacements sportifs orientés vers l'avenir, et grâce à de solides capacités de développement et de conception de produits, pour offrir aux utilisateurs une meilleure expérience de déplacement.",
      text22: "Historique du développement",
      text23:
        "Les nouveaux vélos pliants à assistance électrique standard européens fabriqués à Taïwan ont obtenu la certification et seront mis en vente en Europe en août",
      text24:
        "EF1 est devenu le premier vélo à assistance électrique de marque chinoise à avoir obtenu la certification EN15194-2017 et vendu en Europe",
      text25:
        "Affectées par l'épidémie et les subventions aux achats européens, les ventes de vélos sont en plein essor et les commandes de vélos pliants à assistance électrique Xiaomi augmentent de mois en mois",
      text26:
        "La pompe à air de MIJIA est en vente en ligne au prix de 199 Rmb",
      text27:
        "« Vélo enfant Mitu 14 pouces » a été lancée sur le centre commercial Xiaomi et a remporté remporté le Prix du Design Red Dot allemand 2020",
      text28:
        "Le nouveau vélo à assistance électrique QiCYCLE standard national est vendu en ligne de Xiaomi, et 18 brevets liés à la technologie des cadres de vélo ont été obtenus",
      text29:
        "Les vélos à assistance électrique Mi QiCYCLE se sont vendus à 51 800 unités en Chine, avec un chiffre d'affaires cumulé de 135 millions RMB, créant une nouvelle catégorie de vélos à assistance électrique dans le commerce électronique national",
      text30:
        "N°1 des ventes de produits uniques pendant deux années consécutives, avec une part de marché de 91.3 % en 2017",
      text31:
        "iFixit qui démonte les vélos à assistance électrique Mi en a fait l'éloge",
      text32:
        "La société QiCYCLE a été reconnue par l'Office de la propriété intellectuelle de Xiamen comme l'entreprise d'avantages en matière de propriété intellectuelle de conception industrielle de la ville",
      text33:
        "Développer des applications après-vente pour répondre aux besoins réels tels que les pièces de rechange, les conseils de maintenance et le règlement des coûts",
      text34:
        "Fournir un service après-vente pour les produits QiCYCLE en signant 272 partenaires de la ville d'iRiding",
      text35:
        "Le vélo pliant à assistance électrique Mi a été publié par le co-fondateur de Xiaomi, Liu De, et le produit a été personnellement félicité par le PDG de Xiaomi, Lei Jun.",
      text36:
        "La première application de la technologie de forgeage d'alliages d'aluminium sur les vélos",
      text37:
        "La première application de la technologie Can(Réseau de zone de contrôleur, CAN) sur les vélos",
      text38:
        "Obtention de 11 brevets liés au vélo tels que le pliage vertical",
      text39:
        "Le capteur de couple TMM est produit en série pour la première fois",
      text40:
        "Le vélo de fitness en fibre de carbone QiCYCLE R1 fait l'objet d'un financement participatif sur Xiaomi.com",
      text41: "QiCYCLE R1 a remporté deux Prix du Design Red Dot",
      text42:
        "Le vélo R1 a remporté le Prix du design innovant au Taipei Cycle pour ses capacités complètes d'acquisition de données sportives",
      text43:
        "QiCYCLE est devenu membre de la chaîne écologique de Xiaomi et est responsable du développement et de la disposition écologique des produits matériels intelligents dans le sens de la vie et des déplacements.",
      text44:
        "La société QiCYCLE a changé son nom pour iRiding (Xiamen) Technologie Co., Ltd.",
      text45: "QiCYCLE a obtenu le financement de Xiaomi Angel Round",
      text46: "QiCYCLE a rejoint dans la chaîne écologique de Xiaomi",
      text47:
        "Développer l'application QiCYCLE et le mettre en  service sur Internet",
      text48:
        "Le nombre d'utilisateurs de l'application QiCYCLE a dépassé 1.24 million",
      text49:
        "La trajectoire cycliste cumulée des utilisateurs d'APP QiCYCLE a atteint 65 millions de kilomètres, ce qui en fait la plus grande communauté cycliste du pays",
      text50: "iRiding (Xiamen) Technologie Co., Ltd. a été créée à Xiamen",
      text51: "Produits ingénieux",
      text52: "Conception innovante",
      text53:
        "Le produit a remportéle Prix du Design Red Dot allemand, le Prix iF Design, etc. et a obtenu un certain nombre de brevets d'invention",
      text54: "Se concentrer sur la R&D",
      text55:
        "L'équipe de R&D dirigée par des experts de l'industrie est attentive aux détails et continue de promouvoir l'innovation technologique clé",
      text56: "Contrôle de qualité strict",
      text57:
        "Plusieurs processus d'inspection de la qualité, vérifient la qualité de l'ensemble du véhicule à tous les niveaux et fournissent aux utilisateurs des produits de haute qualité",
      text58: "Après-vente efficace",
      text59:
        "Continuez à explorer des modèles de service hors ligne plus intimes pour permettre aux utilisateurs d'obtenir une meilleure expérience de produit",
      text60: "Adresse de la société",
      text61: "Villes suivantes",
      text62: "Pékin",
      text63:
        "No. 16, Anningzhuang East Road, Haidian District, Beijing, Building 2, East District, Rongke Rongzhi · Honeycomb Factory",
      text64: "Changzhou",
      text65:
        "89, avenue Chuangxin, district de Xinbei, ville de Changzhou, province du Jiangsu",
      text66: "Shanghai",
      text67:
        "104B, Bâtiment G, No.142 Tianlin Road, District de Xuhui, Shanghai",
      text68: "Shenzhen",
      text69:
        "Salle 1708, 17ième étage, bâtiment de l'industrie et de la recherche de l'Université Sun Yat-sen, n° 1 Yuexing 4th Road, (sous-district de Yuehai), district de Nanshan, Shenzhen, Chine",
      text70: "Francfort",
      text71:
        "Adam-Opel-Str. 10 im Hof, Geb. A, bei eKey 60386 Francfort-sur-le-Main, Allemagne",
      text72: "Xiamen",
      text73:
        "Salle 403, No.768 Qishan North Road, district de Huli, ville de Xiamen, province du Fujian",
      text74: "Hong Kong",
      text75:
        "Salle 1102, Bâtiment Hang Seng Castle Peak Road, 339 Castle Peak Road, Cheung Sha Wan, Kowloon, Hong Kong",
      text76: "Suzhou",
      text77:
        "Salle 606, Place d'affaires internationale Gaoxin de Suzhou, 28 rue Shishan, Gaoxin Zone, Suzhou,Chine",
      text78: "Réseaux sociaux",
      text79: "Hotline de service après-vente",
      text80: "Contactez-nous",
      text81: "Coopération commerciale",
      text82: "Ventes nationales",
      text83: "Ventes à l'étranger",
      text84: "Service client",
      text85: "Protection des données",
      text86: "QiCYCLE ©2013-2021  MICPB No. 13003500",
      text87: "Confidentialité et droit",
      text88: "Vos préférences en matière de cookies",
      text89: "Bienvenue à QiCYCLE !",
      text90:
        "Afin de vous offrir une meilleure expérience de site Web, nous espérons utiliser des cookies pour améliorer notre site Web.",
      text91:
        "Les informations collectées par les cookies ne vous identifieront pas personnellement.",
      text92:
        "Pour plus d'informations sur les types de cookies que nous utilisons et sur nos cookies, veuillez lire nos politiques de cookies.",
      text93: "Refuser",
      text94: "Accepter",
      text100:
        "Afin de vous offrir une meilleure expérience de site Web, nous espérons utiliser des cookies pour améliorer notre site Web. Les informations collectées par les cookies ne vous identifieront pas personnellement.Pour plus d'informations sur les types de cookies que nous utilisons et sur nos cookies, ",
      text101: "veuillez lire nos politiques de cookies ",
      text102: "QiCYCLE",
      text103: "Information légale",
      text104: "QiCYCLE",
    },
  },
  product: {
    v1: {
      productMove: "Explorer plus",
      productCpd: "Pompe à air QICYCLE P1",
    },
  },
  contact: {
    // 法文
    v1: "Contactez-nous",
    v2: "La coopération commerciale",
    v3: "E-mail: market@iriding.com",
    v4: "Équipe de vente",
    v5: "Ventes intérieures: sales@iriding.com",
    v6: "Ventes à l'étranger: sales@iriding.com",
    v7: "Service Clients",
    v8: "Hotline de service après-vente: 400-1144-177",
    v9: "Protection des données",
    v10: "E-mail: dpo@iriding.com",
  },
  details: {
    v1: "Gonfleur électrique portable QiCYCLE P1",
    v2: "Couleur",
    v3: "Acheter maintenant",
    v4: "Aperçu",
    v5: "Caractéristiques techniques",
    v6: "Support technique",
    v7: "Petit et gonflé à bloc",
    v8: "Ajustement de la pression rapide en environ 60 secondes",
    v9: "Pression de gonflage jusqu'à 150 psi",
    v10: "Gonflage total d’un pneu en 5 minutes environ",
    v11: "Niveau de pression prédéfini avec arrêt automatique",
    v12: "5 modes d'utilisation",
    v13: "Banque d'alimentation d'urgence",
    v14: "Puissance ",
    v998: "au-delà de l’imagination",
    v15: "Mécanisme super performant et moteur grande puissance.",
    v16: "Gonflage total d’un pneu en 5 minutes environ.",
    v16a: "Gonflage total d’un pneu en 5 minutes environ.",
    v1611: "La batterie permet de gonfler jusqu’à ",
    v1611a: "La batterie permet de gonfler jusqu’à",
    v1621: "5",
    v1631: "minutes environ.",
    v1631b: "pneus de voiture",
    v1711: "La batterie permet de gonfler jusqu’à",
    v1711a: "à raison d’environ",
    v1721: "pneus de voiture, à raison d’environ 5 minutes par pneu,",
    v1721a: "minutes par pneu",
    v17: "La batterie permet de gonfler jusqu’à 5 pneus de voiture, à raison d’environ 5 minutes par pneu   ",
    v999: "à raison d’environ 5 minutes par pneu",
    v18: "Petit et facile à ranger",
    v19: "Hauteur du boitier environ 20 cm",
    v20: "Utilisation facile d'une seule main",
    v21: "Petit et pratique à manipuler,",
    v22: "Vous permet de voyagez léger",
    v23: "Tuyau d’air de haute précision",
    v231: "gonflage rapide à haute pression",
    v24: "Pression de gonflage jusqu'à 150 psi,",
    v24a: "Pression de gonflage jusqu'à 150 psi",
    v25: "compatible avec la haute pression d'air requise pour les pneus de vélo de route.",
    v26: "Gonflage sans effort.",
    v261: "La batterie permet de gonfler jusqu’à",
    v262: "16",
    v263: "pneus de vélo de route,",
    v2631: "pneus de vélo de route,",
    v27: "La batterie permet de gonfler jusqu’à 16 pneus de vélo de route, ",
    v9971: "à raison d’environ ",
    v9972: "63",
    v9973: " secondes par pneu",
    v997: "à raison d’environ 63 secondes par pneu",
    v272: "Niveau de pression pré-défini,",
    v271: "charge précise et arrêt automatique",

    v28: "Pré-définissez la pression d'air voulue,",
    v29: "e gonflage s’arrête automatiquement quand la pression voulue est atteinte",
    v30: "Mesurez la pression avec précision à tout moment",
    v31: "Précision du capteur de pression ",
    v311: "±1psi",
    v312: "Précision du capteur de pression ±1psi",
    v32: "Choisissez librement parmi les 5 modes d’utilisations",
    v33: "Coches, motos, bicicletas, balones, modelos profesionales,",
    v34: "Rango de presión de aire restringido",
    v35: "Choisissez le mode de gonflage pour un fonctionnement plus sûr",
    v362: "par défaut",
    v363: "2.5bar",
    v364: "2.4bar",
    v361: "Mode voiture",
    v3611: "par défaut：2.5bar",
    v3612: "par défaut：2.4bar",
    v37: "Plage de réglage:1.8-3.5bar",
    v371: "1.8-3.5bar",
    v3711: "Plage de réglage:1.8-3.0bar",
    v38: "Mode moto",
    v381: "2.4bar défaut",
    v39: "Plage de réglage",
    v391: "1.8-3.0bar",
    v40: "Mode vélo",
    v41: "Plage de réglage",
    v411: "45psi",
    v451: "par défaut:3-150psi",
    v451a: "3-150psi",
    v451b: "0.2-10.3bar",
    v4111: "par défaut：45psi",
    v611: "Carga del adaptador USB",
    v612: "Carga del cargador de coche",
    v613: "Batería recargable",
    v412: "Plage de réglage：",
    v581: "Temps d’utilisation continu sans recharge jusqu'à 40 minutes",
    v4121: "Plage de réglage:30-145psi",
    v2121: "Des tests rigoureux pour,",
    v2122: "une qualité durable",
    v413: "30-145psi",
    v42: "Mode ballon",
    v423: "Plage de réglage:4-16psi",
    v422: "par défaut:8psi",
    v462: "Plage de réglage: 0.2-10.3bar",
    v421: "8psi",
    v43: "4-16psi",
    v44: "Mode pro",
    v45: "Plage de réglage",
    v46: "0.2–10.3bar",
    v461: "3-150psi / 0.2-10.3bar",
    v47: "Batteries au lithium 18650",
    v48: "Libre comme l’air",
    v49: "Que vous soyez en pleine montagne ou au bord de la mer,",
    v50: "aucune alimentation externe requise,",
    v51: "pour un gonflage sans contrainte",
    v52: "Des détails exquis,pour égayer votre vie",
    v53: "Éclairage autonome, facile à utiliser",
    v54: "Éclairage LED, pratique pour une utilisation nocturne.",
    v55: "Clignotement SOS, prêt en cas d’urgence.",
    v56: "Double dissipation thermique, pour un usage continu",
    v57: "Ventilateur haute puissance et trous de refroidissement.",
    v58: "Temps d’utilisation continu sans recharge jusqu'à 35 minutes.",
    v59: "Une pompe à air et aussi une banque d'alimentation",
    v60: "Connecteur USB type-c pour une recharge sans souci.",
    v61: "Port USB-A pour charger votre téléphone.",
    v62: "Grand écran numérique, affichage en temps réel",
    v63: "Le grand écran haute définition de 4,4 pouces affiche.",
    v631: "la pression actuelle des pneus, la pression prédéfinie, le mode d'utilisation et d'autres données en temps réel.",
    v64: "Set d'accessoires complet, rangement pratique",
    v65: "La pochette de rangement peut contenir",
    v66: "une aiguille de gonflage et un câble de recharge.",
    v67: "De nombreux tests rigoureux passés haut la main",
    v68: "Test de la protection contre la surpression",
    v69: "Test de résistance de tension du tuyau à air",
    v70: "Test de résistance électrique",
    v71: "Test de torsion à froid du tuyau à air",
    v72: "Test de chute",
    v73: "Test de la durée d’utilisation en continu",
    v74: "Test de compression",
    v75: "Test de recharge de la batterie à haute/basse température",
    v76: "Référence de performance de la Gonfleur électrique portable QiCYCLE P1",
    v77: "1 bar = 14,5 psi = 100 kPa",
    v78: "Objet gonflable",
    v79: "Pneus de voiture",
    v80: "175/70 R14 (ajustement de la pression)",
    v81: "Wuling Hong Guang",
    v82: "(175/70 R14)",
    v83: "Buick Hinglang",
    v84: "(195/65R16)",
    v85: "Volkswagen Golf",
    v86: "(205/55 R16)",
    v87: "Toyota Camry",
    v88: "(215/55 R17)",
    v89: "Land Rover Range Rover",
    v90: "(255/55R20)",
    v91: "BMW 730",
    v92: "(275/40R19)",
    v93: "Pneus moto",
    v94: "(150/60 R17)",
    v95: "Pneus moto",
    v96: "(110/70 R17)",
    v97: "Pneus de vélo de route",
    v98: "(700 × 25C)",
    v99: "Pneus VTT",
    v100: "(27,5 × 1,95)",
    v101: "Pneus de trottinette électrique",
    v102: "(8 1/2 x 2)",
    v103: "Pneus de scooter auto-équilibré",
    v104: "(9 × 3,0-6)",
    v105: "Ballon de basket taille 7",
    v106: "Ballon de football taille 5",
    v107: "Ballon de volley-ball taille 5",
    v108: "Pression de gonflage",
    v109: "2.0-2.5bar",
    v110: "0-2.5bar",
    v111: "0-2.4bar",
    v112: "0-120psi",
    v113: "0-50psi",
    v114: "0-40psi",
    v115: "0-30psi",
    v116: "0-9psi",
    v117: "0-11psi",
    v118: "0-6psi",
    v119: "Quantité d'inflation",
    v120: "≈ 18",
    v121: "≈ 5",
    v122: "≈ 4",
    v123: "≈ 3",
    v124: "≈ 2",
    v125: "≈ 7",
    v126: "≈ 15",
    v127: "≈ 16",
    v128: "≈ 20",
    v129: "≈ 201",
    v130: "≈ 97",
    v131: "≈ 92",
    v132: "≈ 86",
    v133: "≈ 197",
    v134: "Vitesse de gonflage",
    v135: "≈ 60s/pneu",
    v136: "≈ 4min58s/pneu",
    v137: "≈ 6min3s/pneu",
    v138: "≈ 5min52s/pneu",
    v139: "≈ 7min20s/pneu",
    v140: "≈ 12min30s/pneu",
    v141: "≈ 9min10s/pneu",
    v142: "≈ 2min50s/pneu",
    v143: "≈ 1min30s/pneu",
    v144: "≈ 63s/pneu",
    v145: "≈ 58s/pneu",
    v146: "≈ 10s/pneu",
    v147: "≈ 14s/pneu",
    v148: "≈ 18s/ballon",
    v149: "≈ 20s/ballon",
    v150: "≈ 10s/ballon",
    v1510: "[1] ",
    v1520: "[2] ",
    v1530: "[3] ",
    v1540: "[4] ",
    v1550: "[5] ",
    v1560: "[6] ",
    v1570:
      "Lorsque la batterie est complètement chargée, la pompe peut gonfler jusqu’à 18 pneus de voiture 175/70 R14 de 2,0 bar à 2,5 bar,à raison d’environ 60 secondes par pneu.",
    v1580:
      "Lorsque la batterie est complètement chargée,la pompe peut gonfler jusqu’à 5 pneus de voiture 175/70 R14 de 0 bar à 2,5 bar,à raison d’environ 5 minutes par pneu.",
    v1590:
      "Le mode de pression constant doit être utilisé avec des outils tels qu'un aérographe,une lance à mousse, un cric pneumatique, à acheter séparément.",
    v1591:
      "Le port USB-A (5V⎓2A) peut être utilisé comme banque d'alimentation (5000 mAh).",
    v1592:
      "Ces huit données de test proviennent de la succursale de Shenzhen de TUV SUD et du laboratoire Iriding Numéro de rapport de test:QJSZ20210731.01,QJSZ20210806.01,QJSZ20210729.01,QJSZ20210831.01,QJSZ20210519.01,QJSZ20210803.01,68.189.21.0074.01.",
    v1593:
      "Les données du tableau proviennent de tests fait avec une batterie chargée à 100%, La valeur réelle peut être légèrement différente en raison de facteurs tels que l'environnement, la température, l'électricité, les habitudes d'utilisation, etc.",
    v1594:
      "Note: Sauf indication contraire, les données de cette page proviennent du laboratoire de iRiding",
    v151: "Lorsque la batterie est complètement chargée,",
    v152: "Lorsque la batterie est complètement chargée, la pompe peut gonfler jusqu’à 18 pneus de voiture 175/70 R14 de 2,0 bar à 2,5 bar,",
    v153: "à raison d’environ 60 secondes par pneu",
    v154: "Lorsque la batterie est complètement chargée,",
    v155: "la pompe peut gonfler jusqu’à 5 pneus de voiture 175/70 R14 de 0 bar à 2,5 bar,",
    v156: "à raison d’environ 5 minutes par pneu",
    v157: "Le mode de pression constant doit être utilisé avec des outils tels qu'un aérographe,",
    v158: "une lance à mousse, un cric pneumatique, à acheter séparément.",
    v159: "Le port USB-A (5V⎓2A) peut être utilisé",
    v160: "comme banque d'alimentation (5000 mAh)",
    v161: "Ces huit données de test proviennent de la succursale de Shenzhen de TUV SUD et du laboratoire QICYCLE",
    v162: "Numéro de rapport de test",
    v163: "Les données du tableau proviennent de tests fait avec une batterie chargée à 100%,",
    v164: "La valeur réelle peut être légèrement différente en raison de facteurs tels que l'environnement, la température, l'électricité, les habitudes d'utilisation, etc.",
    v165: "Note:",
    v166: ":Sauf indication contraire, ",
    v167: "les données de cette page proviennent du laboratoire de iRiding",
    v168: "Manuel d'instruction×1",
    v169: "Pochette de rangement×1",
    v170: "Tuyau d'air haute pression×1",
    v171: "Câble de Recharge×1",
    v172: "Adaptateur de Valve A Aiguille×1",
    v173: "Adaptateur de la Valve Presta×1",
    v174: "Gonfleur électrique portable QiCYCLE P1×1",
    v175: "Liste des paquets",
    v176: "Pressurisation automatique",
    v177: "Modèles innovants de maintien de la pression",
    v178: "La pressurisation commence immédiatement en dessous de la valeur réglée,Maintien automatique de la pression de la cuve,Peinture, arrosage, lavage de voitures.Facile et sans tracas.",
    v191: "Appuyez et maintenez le bouton d'éclairage après l'allumage",
    v192: "et appuyez sur「+」Clé，Jusqu'à",
    v193: "Icônes Apparitions",
    v194: "Nom du produit",
    v195: "Gonfleur électrique portable QiCYCLE P1",
    v196: "Type de produit",
    v197: "QJCQBP1",
    v198: "Taille du produit",
    v199a: "200.5 × 63.5 × 63.5mm",
    v199b: "(Taille nue, sans tuyau d'air)",
    v200: "Plage de pression de gonflage",
    v201: "3-150psi / 0.2-10.3bar",
    v202: "Types de piles",
    v203: "Piles lithium-ion",
    v204: "Capacite de la Batterie",
    v2041: "28.86 Wh / 11.1V",
    v205: "28.86Wh/11.1V",
    v206: "Précision du Capteur",
    v207: "±1.5 psi",
    v208: "Temps de Charge",
    v209: "Moins de 4 heures",
    v210: " ",
    v211: " ",
    v212: "Des tests rigoureux pour une qualité durable",
    v213: "Variété de scènes,liberté de changement",
    v2131: "Variété de scènes,",
    v2132: "liberté de changement",
    v214: "Libre de tout lien,",
    v214a: "Libre de tout lien",
    v215: "Noir classique",
    v216: "Belle apparence",
    v217: " ",
  },
  C2: {
    v0: "QiCYCLE C2",
    v1: "Vélo à assistance électrique QiCYCLE C2",
    v1m: "Vélo à assistance électrique <br/> QiCYCLE C2",
    v2: "Traversée de la ville <br/> La liberté au-delà de l'imagination",
    v2a: "La liberté au-delà de l'imagination",
    v3: "Couleur",
    v4: "Noir",
    v5: "Léger et compact",
    v6: "phares intelligents",
    v7: "Super autonomie",
    v8: "Batterie lithium puissante",
    v9: "8 vitesses",
    v10: "grand écran lumineux",
    v11: "Tube de direction pliable",
    v12: "1",
    v13: "Vue d'ensemble",
    v14: "Spécifications",
    v14a: "Manuel du produit",
    v15: "Soutien",
    v16: "moderne et chic <br/> C-suite partout",
    v17: "C-suite partout",
    v18: "Proportions classiques, forme élégante <br/> finition parfaite, design accrocheur",
    v19: "Une expérience de conduite unique",
    v20: "Design ergonomique",
    v21: "Le design ergonomique du cadre offre confort et aisance d’utilisation. Vous ne faites qu’un avec le vélo.",
    m21a: "Le design ergonomique du cadre offre confort et aisance d’utilisation. ",
    m21b: "Vous ne faites qu’un avec le vélo.",
    v22: "*Les vélos électriques doivent être photographiés pour être utilisés sur la route. Veuillez respecter le code de la route et rouler en toute sécurité.",
    v23: "Ultra léger et maniable",
    v24: "Cadre en alliage d'aluminium allégé qualité aéronautique",
    v24p: "direction souple et maniable, idéal pour les petits espaces et les virages serrés.",
    v24c: "Cadre en alliage d'aluminium allégé qualité aéronautique &nbsp;&nbsp;<sup style='position: absolute;color: rgb(255, 255, 255) !important;margin-left: -0.3rem;margin-top: -0.05rem;'class='font15'>「1」</sup>&nbsp;direction souple et maniable, idéal pour les petits espaces et les virages serrés.",
    v25: "Structure légère et robuste",
    v25m: "Structure légère et robuste",
    v26: "Le vélo pèse environ 20 kg et peut supporter en toute sécurité une charge allant jusqu’à 120 kg, et peut être utilisé par les hommes, les femmes et les enfants.<sup style='font-size:0.15rem' :style=' ios? 'position: absolute;margin-top: -0.05rem;margin-left: -0.1rem;': 'position: absolute;margin-top: -0.05rem;margin-left: 0;''>「2」</sup>",
    v26d: " .",
    v26c: "*Les accessoires tels que le panier avant, la tablette arrière, la selle en mousse et le siège enfant sont à acheter séparément en fonction de vos besoins.",
    m26a: "Le vélo pèse environ 20 kg et peut supporter en toute sécurité une charge allant jusqu’à 120 kg, et peut être utilisé par les hommes,",
    m26b: " les femmes et les enfants .",
    v27: "Assistance électrique efficace et puissante ",
    v27a: "pour le plaisir de conduire",
    v28: "Capteur de haute précision",
    v29: "Connaître chaque coup de pédale",
    v30: "Grâce au capteur de couple intégré à l'arbre central, qui est sensible à capturer les données de pédalage, l'accélération efficace vous permet de faire déplacer votre vélo à votre guise et sans entrave.",
    m30a: "Le capteur de haute précision détecte vos mouvements, et accélère instantanément, le vélo suit votre instinct ",
    m30b: "et vous obéit au doigt et à l’œil.",
    m31: "Principe du capteur",
    v31: "C2 PRO",
    v32: "Capteurs de couple",
    v33: "C2",
    v34: "Capteurs de vitesse",
    v35: "1.Montrer les données du trajet",
    v36: "2.Contrôle de la sortie du booster",
    v37: "3.Détection de la force de pédalage",
    v38: "4.Course du moteur d'entraînement",
    v39: "Sélection de plusieurs vitesses <br/> pour de multiples scénarios",
    v40: "Changez de mode a tout moment,Super autonomie d'environ 60 km",
    v40m: "Changez de mode a tout moment,Super autonomie d'environ 60 km<sup style='margin: -0.05rem 0 0 -0.1rem' class='font15 positionA color3A'>「3」</sup>",
    v40c: ".",
    v41: "Main d'œuvre:",
    v42: "Assistance électrique:",
    v43: "Puissance électrique",
    v44: "1ère vitesse",
    v45: "Faible consommation d'énergie et longue durée de vie de la batterie",
    v46: "2ère vitesses",
    v47: "Combiner gamme et puissance",
    v48: "3ère vitesses",
    v49: "Accélération puissante et rapide",
    v50: "Bouton d'aide à la marche",
    v51: "Vous serez à l'aise lorsque vous devrez marcher avec votre vélo en montée.",
    v52: "Dérailleur 8 vitesses fluide et précis",
    v52d: "Dérailleur 8 vitesses",
    v52c: ".",
    v52a: " fluide et précis",
    v53: "Passez librement d’une vitesse a l’autre. Grâce aux 8 vitesses disponibles et a l’assistance électrique vous serez prêt à défier n’importe quelle montagne.",
    m53a: "Passez librement d’une vitesse a l’autre. Grâce aux 8 vitesses disponibles et a l’assistance électrique",
    m53b: "vous serez prêt à défier n’importe quelle montagne.",
    v54: "Dérailleur 8 vitesses fluide et précis",
    v55: "Parcourir la ville",
    v56: "Votre sécurité est mon souci",
    v57: "Technologie de batterie au lithium <br/> la batterie est installée dans <br/> un compartiment séparé",
    v57m: "Technologie de batterie au lithium la batterie est installée dans un compartiment séparé",
    v58: "Les 30 Batteries au lithium intégrées dans le cadre sont isolées du reste du circuit pour une sécurité accrue.",
    v58m: "Les 30 Batteries au lithium intégrées dans le cadre sont isolées du reste du circuit pour une sécurité accrue.",
    v59: "*Ce produit est équipé d'une batterie intégrée.",
    v60: "Ne pas démonter soi-même.",
    v61: "système de gestion de batterie qualité automobile",
    v62: "Le système de gestion de batterie (BMS) conforme aux normes automobiles assure la protection de la batterie et arrête automatiquement la recharge lorsque la batterie est complètement chargée.Assurer la sécurité de la charge",
    m62a: "Le système de gestion de batterie (BMS) conforme aux normes automobiles assure la protection de la batterie et arrête automatiquement la recharge lorsque la batterie est complètement chargée.",
    m62b: "Assurer la sécurité de la charge.",
    v63: "Essai de capacité à basse température",
    v64: "Test de capacité à haute température",
    v65: "Test IPX5",
    v66: "Test de chute libre",
    v67: "Protection contre la surcharge",
    v68: "Protection contre la surdécharge",
    v69: "Essai de choc d'accélération",
    v70: "Protection contre les courts-circuits",
    v71: "Essais d'impact à haute et basse température",
    v72: "Test de résistance aux vibrations",
    v73: "Essai à haute température de 130°C",
    v74: "Capacité de rétention des charges",
    v75: "Les boutons et les cadrans sont à portée de main",
    v76: "fonctions claires et simples à utiliser même pour les personnes âgées .",
    v77: "Écran central à luminosité automatique",
    v78: "Le réglage intelligent et automatique de la luminosité de l'écran, la vitesse, la puissance et les données de vitesse sont tous visibles, clairs le jour et non aveuglants la nuit.",
    v78a: "la puissance et les données de vitesse sont tous visibles, clairs le jour et non aveuglants la nuit",
    m78a: "Le réglage intelligent et automatique de la luminosité de l'écran, la vitesse",
    m78b: "la puissance et les données de vitesse sont tous visibles, clairs le jour et non aveuglants la nuit",
    v79: "Phares intelligents avec ajustement <br/> automatique de la luminosité <sup style='position: absolute;color: rgb(255, 255, 255) !important;margin-top: 0.05rem;'class='font15'>「5」</sup>",
    v80: "Commande photosensible des interrupteurs des feux avant et arrière.",
    v81: "S'allume ou s'éteint automatiquement.",
    m81a: "S'allume ou s'éteint automatiquement.Feu arrière de",
    v82: "Feu arrière de freinage allumé en haut.",
    m82a: "freinage allumé en haut.",
    v83: "Rouler sans distractions.",
    v84: "Une conduite plus sûre.",
    v85: "Ajoutez ou retirez des accessoires ",
    v85a: "pour une expérience personnalisée",
    v85m: "Ajoutez ou retirez des accessoires pour une expérience personnalisée",
    v86: "Que vous soyez en costume cravate, en bleu de travail ou en tenue élégante…  Choisissez les accessoires qui accompagnent votre style.",
    m86a: "Que vous soyez en costume cravate, en bleu de travail ou en tenue élégante… ",
    v86b: " Choisissez les accessoires qui accompagnent votre style.",
    v87: "Ajout et retrait d'accessoires",
    v88: "Connectez-vous à l'application Ride Notes",
    v89: "Documenter les moments de la vie",
    v90: "L’application QiCYCLE enregistre et affiche des données telles que la vitesse, le kilométrage et vous permet de partager vos trajets à vélo",
    m90a: "L’application QiCYCLE enregistre et affiche des données telles que la vitesse, le kilométrage et vous permet de partager vos trajets à vélo",
    v91: "",
    v92: "Connectez-vous à l'application Ride Notes",
    v93: "Conception ingénieuse",
    v94: "« moteur brushless » à aimants permanents",
    v95: "Déclencher une montée en puissance",
    v96: "Roues 20 pouces",
    v97: "Profitez d'une conduite flexible",
    v98: "Tube de direction pliable",
    v99: "Soyez le voyageur de la malle",
    v100: "Freins à disque avant et arrière",
    v101: "Les freins sont stables à toutes les vitesses",
    v102: "Tige de selle avec collier de réglage rapide ",
    v103: "C'est vous qui décidez de la hauteur ou de la profondeur",
    v104: "Pneumatique de choix",
    v105: "aussi stable qu'une montagne",
    v106: "[1]    Organisme de certification CCC :Centre de certification de la qualité en Chine,Numéro de certificat:2021011119391871",
    v107: "[1]    Cadre de vélo à multi-cavités.Obtention de plusieurs certificats d’utilité.Les numéros de certificats sont ZL201820720214.X,ZL201822033605.7,ZL201821913718.X.",
    v108: "[2]    Ce produit convient pour une personne de 155 à 185 cm.",
    v109: "[3]    Conditions d'essai d'endurance de 60 km:Mode assistance 2, lumières éteintes, charge de 70kg, en roulant à une vitesse constante de 20km/h sur une voie de circulation.L’autonomie réelle de la batterie variera légèrement en fonction de facteurs tels que la charge, la température, les conditions routières et les habitudes de fonctionnement.",
    v110: "[4]    Les données proviennent du centre de test de SCUD (Fujian) Co., Ltd.Rapport numéro DC210073",
    v111: "[5]    Lorsque le vélo est en mouvement les phares avant et arrière ajustent automatiquement la luminosité en fonction de la lumière ambiante.",
    v112: "[6]    Les accessoires tels que le panier avant, la tablette arrière, la selle en mousse et le siège enfant sont à acheter séparément en fonction de vos besoins.",
    //v113:'',
    v114: "[7]    Veuillez régler la hauteur du siège entre les lignes de sécurité minimum et maximum de la tige de selle.",
    v115: "[8]    Le modèle de pneu visible sur cette page est pour référence seulement. En raison de différents lots de pneus votre achat peut s’avérer légèrement différent.",
    v116: "Note:Sauf indication contraire,les données de cette page proviennent du laboratoire de iRiding.",
    v120: "Paramètres des bicyclettes à assistance électrique",
    v121: "Paramètres du produit",
    v121a: "Nom du produit",
    v122: "Vélos à assistance électrique",
    v123: "Vitesse maximale de conception",
    v124: "25Km/h",
    v125: "Type de produit",
    v126: "TDN09Z",
    v127: "Méthode de freinage",
    v128: "Freins à disque mécaniques",
    v129: "Dimensions du produit (avant pliage)",
    v130: "1530×600×1060 mm",
    v131: "Disques",
    v132: "56T x 170mm Manivelle en aluminium",
    v133: "Dimensions du produit (plié)",
    v134: "1530×430×725 mm",
    v135: "Tambour à fleurs",
    v136: "Moyeux en aluminium",
    v137: "Masse totale du véhicule",
    v138: "Environ 20 kg",
    v139: "Tiempo de carga",
    v140: "Aproximadamente 5,5 horas",
    v141: "Taille de la roue",
    v142: "20 pouces",
    v143: "Niveau d'étanchéité du corps",
    v144: "IPX 3",
    v145: "Capacité de poids",
    v146: "120 kg",
    v147: "Taille de l'écran",
    v148: "53×32 mm",
    v149: "Capacité de la batterie",
    v150: "7.5 Ah",
    v151: "Connectivité sans fil",
    v152: "Bluetooth 5.0",
    v153: "Liste d'emballage",
    v154: "Vélo à assistance électrique x 1",
    v155: "4, 5, 6 mm<br/>Clé hexagonale interne de  x 1",
    v155c: "Clé hexagonale interne de 4, 5, 6 mm x 1",
    v156: "Repose-pieds gauche et droit x 1",
    v157: "Adaptateur secteur x 1",
    //v158:	"1",
    v159: "Vis M5 x 4",
    v160: "Manuel d'instruction x 1",
    //v161:	"1",
    //v162:	"1",
  },
  F2: {
    v0: "QiCYCLE F2",
    v1: "Vélo pliant à assistance électrique QICYCLE F2",
    v1m: "Vélo pliant à assistance <br/> électrique QICYCLE F2",
    v2: "Prêt pour l'exploration",
    v3: "Couleur",
    v4: "Noir",
    v5: "Détection de couple",
    v6: "Pliable et facile à transporter",
    v7: "Batterie amovible",
    v8: "Super autonomie",
    v9: "Ordinateur de bord",
    v10: "Régulateur de vitesse",
    v11: "3 vitesses",
    v12: "Roues 16 pouces",
    v13: "Vue d'ensemble",
    v14: "Spécifications",
    v15: "Soutien",
    v16: "Assistance instantanée",
    v17: "la maîtrise totale du rythme",
    v18: "Le capteur de couple collecte les données relatives à la force de pédalage et contrôle la quantité de boost.",
    v18a: "Le capteur de couple collecte les données relatives à la force de pédalage et contrôle la quantité de boost.L’assistance instantanée vous permet ",
    v18b: "de ne faire qu’un avec votre vélo.",
    m18a: "Le capteur de couple collecte les données relatives à la force de pédalage et contrôle la quantité de boost.",
    m18b: "L’assistance instantanée vous permet de ne faire qu’un avec votre vélo.",
    m18c: "Les pentes ne sont plus à craindre.",
    v19: "L’assistance instantanée vous permet de ne faire qu’un avec votre vélo.",
    v20: "Les pentes ne sont plus à craindre.",
    v21: "Principe des systèmes de détection de couple",
    v22: "1.Afficher les données du trajet",
    v23: "2.Contrôle de la sortie du booster",
    v24: "3.Détection de la force de pédalage",
    v25: "4.Course du moteur d'entraînement",
    v26: "Pliable et compact dans le coffre",
    v27: "toujours prêt pour l’action",
    v27m: "Pliable et compact dans le coffre <br/> toujours prêt pour l’action",
    v28: "Grâce au pliage vertical breveté",
    v28m: "Grâce au pliage vertical breveté<sup style='font-size:0.15rem;margin-left: -0.04rem' :style='ios13? 'margin-top: -0.45rem; margin-left: 2.4rem': ios15? 'margin-top: -0.5rem; margin-left: 2.4rem': 'margin-top: -0.45rem; margin-left: 2.3rem''class='font15 positionA color3A'>「1」</sup>",
    v29: "le cadre principal reste tel quel. Les fourches avant et arrière forgées en alliage d'aluminium sont légères et solides.Pour votre sécurité, cette construction robuste est conçu pour supporter une charge allant jusqu’à 100 kg.",
    v30: "Les fourches avant et arrière forgées en alliage d'aluminium sont légères et solides.",
    v30a: ",Les fourches avant et arrière forgées en alliage",
    v30b: " d'aluminium sont légères et solides.",
    m30a: "Les fourches avant et arrière forgées en alliage",
    v31: "Pour votre sécurité,",
    v32: "cette construction robuste est conçu pour supporter une charge allant jusqu’à 100 kg.",
    v33: "Numéro de brevet : 201420243615.2",
    v34: "Volume plié ",
    m34a: "environ 0.25m",
    v35: "Batterie amovible",
    v36: "plus pratique à charger",
    v37: "Le cadre est équipé de 20 piles au lithium de puissance 18650.",
    v38: "Le système de gestion de la sécurité BMS de qualité automobile,",
    m38b: "de qualité automobile,",
    m38a: "Le système de gestion de la sécurité BMS",
    v38a: "à plein régime et à l'arrêt, garantit une utilisation sûre de l'électricité.",
    v39: "La batterie est facilement démontable ",
    v40: "et peut être rechargée directement sur le vélo ",
    v41: "ou séparément.",
    v42: "Batterie amovible",
    v43: "Moyeu à 3 vitesses intégrées,",
    v43a: "La transmission à trois <br/> vitesses pour un changement <br/> de vitesse fiable et précis",
    v43m: "La transmission à trois vitesses pour un changement de vitesse fiable et précis",
    v44: "pour un changement de vitesse fiable et précis",
    v45: "La transmission à trois vitesses shimano",
    v46: "est robuste et permet un changement de vitesse fiable et précis.",
    v47: "Robuste et durable.",
    m47a: "Robuste et durable.Ajoutez-y",
    m47b: "l’assistance électrique,",
    m47c: "et vous serez prêt à défier n’importe quelle montagne.",
    v48: "Ajoutez-y l’assistance électrique",
    v49: "et vous serez prêt à défier n’importe quelle montagne.",
    v50: "Trois modes d’assistance:",
    v51: "plus vite, plus loin, plus de contrôle",
    v51m: "plus vite, plus<br/> loin, plus de contrôle",
    v52: "Mode régulateur de vitesse <br/> Détente et relaxation",
    v52m: "Mode régulateur de vitesse <br/> Détente et relaxation",
    v53: "Détente et relaxation",
    v54: "Vitesse de conduite verrouillée en un clic<br/> aucun pédalage requis, zéro effort",
    v54m: "Vitesse de conduite verrouillée en un clic aucun pédalage requis, zéro effort",
    v55: "Mode vitesse fixe",
    v56: "modes d’assistance <br/> Économiser l'énergie et l'électricité",
    v56m: "modes d’assistance Économiser <br/> l'énergie et l'électricité",
    v57: "Économiser l'énergie et l'électricité",
    v58: "Changez librement la proportion de l’assistance parmi les 3 modes disponibles L'assistance du mode 2 a une autonomie d'environ 60 km <sup style='margin-left: -0.05rem; margin-top: -0.05rem'class='font15 color3A positionA'>「2」</sup>",
    v58m: "Changez librement la proportion de l’assistance parmi les 3 modes disponibles L'assistance du mode 2 a une autonomie d'environ 60 km",
    v58c: ".",
    v59: "L'assistance du mode 2 a une autonomie d'environ 60 km[2]",
    v60: "Effort:",
    v61: "Assistance électrique:",
    v62: "Mode 1 : ",
    v62a: "faible consommation, longue autonomie",
    v63: "Mode 2 : ",
    v63a: "équilibre entre autonomie et effort",
    v64: "Mode 3 : ",
    v64a: "forte puissance, accélération rapide",
    v65: "Assistance électrique.",
    v66: "Mode vélo.",
    v67: "Renforcez votre corps",
    v68: "Le mode N, à assistance neutre, est parfait pour l’exercice physique et est similaire à la conduite d’un vélo habituel",
    v68a: "Le mode N, à assistance neutre, est parfait pour l’exercice physique et est similaire ",
    v68b: "à la conduite d’un vélo habituel.",
    m68a: "Le mode N, à assistance neutre, est parfait pour l’exercice physique et est similaire ",
    m68b: "à la conduite d’un vélo habituel.",
    v69: "Ordinateur de bord, tout est sous contrôle",
    v69b: "Ordinateur de bord",
    v69a: "tout est sous contrôle",
    v70: "Changement de mode facile L'ordinateur de bord à écran couleur affiche la vitesse, le mode, le niveau de la batterie et les informations sur le kilométrage Les actions sont bien documentées et dans votre esprit.",
    v71: "L'ordinateur de bord à écran couleur affiche la vitesse, le mode, le niveau de la batterie et les informations sur le kilométrage",
    v71b: "L'ordinateur de bord à écran couleur affiche la vitesse, le mode, le niveau de la batterie et les informations sur",
    v71a: "le kilométrage.",
    m71a: "L'ordinateur de bord à écran couleur affiche la vitesse,",
    m71b: "le mode, le niveau de la batterie et les informations sur le kilométrage",
    v72: "Les actions sont bien documentées et dans votre esprit.",
    v73: "Contrôle du mode d'assistance",
    v74: "Détection des données du cycle",
    v75: "Connectez-vous à l' application QiCYCLE pour obtenir un rapport intelligent",
    v75a: "obtenir un rapport intelligent",
    v76: "L'application enregistre et affiche des données telles que la vitesse, le kilométrage, la fréquence cardiaque et la cadence <sup style='margin:0 -0.1rem 0 -0.1rem' class='font15  color3A'>「3」</sup> etc. Les données sont synchronisées dans le cloud via votre téléphone et sont faciles à consulter à tout moment. La science améliore vos balades.",
    v76m: "L'application enregistre et affiche des données telles que la vitesse, le kilométrage, la fréquence cardiaque et la cadence <sup style='margin:0 -0.1rem 0 -0.1rem' class='font15  color3A'>「3」</sup> etc. Les données sont synchronisées dans le cloud via votre téléphone et sont faciles à consulter à tout moment. La science améliore vos balades.",
    v76b: "etc.Les données sont",
    v76a: "synchronisées dans le cloud via votre téléphone et sont faciles à consulter à tout moment. La science améliore vos balades.",
    m76a: "L'application enregistre et affiche des données telles que la vitesse, le kilométrage, la fréquence cardiaque et la cadence etc.",
    m76b: "Les données sont synchronisées dans le cloud via votre téléphone et sont faciles à consulter à tout moment. La science",
    m76c: " améliore vos balades.",
    v77: "",
    v78: "Connectez-vous à l'application Ride Notes",
    v79: "Design ingénieux détails merveilleux",
    v79m: "Design ingénieux <br/> détails merveilleux",
    v80: "Phare LED lumineux",
    v81: "illumine la route de nuit",
    v82: "Feu arrière circulaire au design unique",
    v83: "Pour conduire en toute sécurité",
    v84: "Roues de 16 pouces pour les déplacements en ville",
    v85: "Vous aider à progresser de manière régulière",
    v86: 'Frein à tambour <img style="width: 2.02rem; height: 0.32rem" src="https://image.iriding.cc/f2/m-2.png">',
    v86m: "Frein à tambour",
    v87: "Sans entretien à vie",
    v88: "Cadre ultra-léger forgé en alliage d'aluminium",
    v89: "Petit et flexible, pour maximiser l’autonomie de la batterie",
    v90: 'Étanchéité classé IPX4  <sup v-if="index === 5"style="margin-top: -0.05rem; margin-left: -0.05rem"class="font15 positionA">「4」</sup>',
    v90m: "Étanchéité classé IPX4<sup style='margin-top: -0.05rem; margin-left: -0.08rem' class='font15 positionA'>「4」</sup>",
    v91: "La pluie et le vent ne sont plus à craindre",
    v92: "Garde-boue télescopique",
    v93: "Pour faciliter le pliage du vélo",
    v94: "Nouvelle nomenclature du brevet:",
    v95: "Vélo à pliage vertical",
    v96: "[1]      Nouvelle nomenclature du brevet: Vélo à pliage vertical Numéro de brevet : 201420243615.2",
    v97: "[2]      Conditions pour une endurance de 60km: Assistance en mode 2, charge de 75kg, vitesse constante de 20km/h sur une voie de circulation.La durée de vie réelle de la batterie variera légèrement en fonction de facteurs tels que la charge, la température, les conditions routières et les habitudes de fonctionnement.",
    v98: "[3]      Pour obtenir les données de fréquence cardiaque et de cadence, vous devez connecter un moniteur de fréquence cardiaque et de cadence externe conforme au protocole Bluetooth 5.0.  A acheter séparément.",
    v99: "[4]      PL'ensemble du vélo a passé le test d'étanchéité IPX4. La pulvérisation directe d'eau dans n'importe quelle direction n'affectera pas les performances du vélo. Cependant, veuillez ne pas plonger le vélo dans l'eau et n'utilisez pas un pistolet à eau à haute pression pour le laver. Lorsque vous roulez sous la pluie, évitez les flaques profondes et surtout faites attention à ne jamais laisser le niveau de l’eau dépasser la position de l'axe du moteur. Numéro de rapport de test : TH60150A/2019. Agence de test : SGS Taiwan Ltd.",
    v99a: "Note: ",
    v100: "1.Sur cette page, le modèle de pneu du produit n'est qu'une référence. En raison des différents lots de pneus, le produit réel peut varier légèrement.Veuillez vous référer à l'article réel.",
    v106a:
      " 2.Sauf indication contraire,les données de cette page proviennent du laboratoire de Qicycle.",
    v107: "Qicycle Vélo pliant à assistance électrique",
    v108: "Paramètres du produit",
    v109: "Paramètres du produit",
    v110: "Nom du produit",
    v111: "Bicyclettes électriques",
    v112: "Type de produit",
    v113: "TDR08Z",
    v114: "Dimensions du produit (avant pliage)",
    v115: "1290 × 540 × 950mm",
    v116: "Dimensions du produit (plié)",
    v117: "1000 × 450 × 650mm",
    v118: "Masse totale du véhicule",
    v119: "Environ 14,5 kg",
    v120: "Taille de la roue",
    v121: "16 pouces",
    v122: "Capacité de poids",
    v123: "100kg",
    v124: "Capacité de la batterie",
    v125: "6Ah",
    v126: "Tiempo de carga",
    v127: "Environ 4,5 heures",
    v128: "Indice d'imperméabilité",
    v129: "IPX4",
    v130: "Taille de l'écran",
    v131: "1.77 pouces",
    v132: "Connectivité sans fil",
    v133: "Bluetooth 5.0",
    v134: "Liste d'emballage",
    v135: "Vélo d'assistance pliable x 1",
    v136: " 4, 5, 6 mm <br/> Clé hexagonale interne dex 1",
    v136a: " Clé hexagonale interne de x 1",
    v136c: "4, 5, 6 mm Clé hexagonale interne dex 1",
    v137: "Repose-pieds gauche et droit x 1",
    v138: "Adaptateur secteur x 1",
    v139: "Manuel d'instruction x 1",
    //v140: "1",
    v141: "Principe du capteur",
  },
};
