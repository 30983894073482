<template>
  <div>
    <div class="footerIsPC">
      <footer class="footer">
        <div class="footer-header">
          <div>
            <span style="position: relative; top: 3px; z-index: 0">
              {{ $t(datas.text78) }}</span
            >
            <div class="footerUrl">
              <div>
                <img
                  class="showImg"
                  src="@/assets/newQJ/index/image/wx@2x.png"
                  alt=""
                  @click.stop="showCode = !showCode"
                />
                <img
                  class="ere"
                  v-show="showCode"
                  src="@/assets/newQJ/index/image/gzh.png"
                  alt=""
                />
              </div>
              <a
                href="https://weibo.com/iriding"
                target="_blank"
                data-url="https://weibo.com/iriding"
              >
                <img src="@/assets/newQJ/index/image/wb@2x.png" alt="" />
              </a>
              <a
                href="https://www.facebook.com/iriding.qi"
                target="_blank"
                data-url="https://www.facebook.com/iriding.qi"
              >
                <img src="@/assets/newQJ/index/image/facebook@2x.png" alt="" />
              </a>
              <a
                target="_blank"
                href="http://instgram.com/qicycleoffcial"
                data-url="http://instgram.com/qicycleoffcial"
              >
                <img src="@/assets/newQJ/index/image/instagram@2x.png" alt="" />
              </a>
              <a
                target="_blank"
                href="https://v.douyin.com/R67rovV/"
                data-url="https://v.douyin.com/R67rovV/"
              >
                <img src="@/assets/newQJ/index/image/dy@2x.png" alt="" />
              </a>
            </div>
          </div>
          <div>
            <span class="footer-phone-title">{{ $t(datas.text79) }}</span
            ><span class="footer-phone">400-1144-177</span>
          </div>
        </div>
        <div class="footer-list">
          <div class="footer-list-left">
            <div class="footer-list-left-item">
              <div>
                <router-link :to="`/${value}/contact`" target="_blank">{{
                  $t(datas.text80)
                }}</router-link>
              </div>
              <ul>
                <li>
                  <router-link :to="`/${value}/contact`" target="_blank">{{
                    $t(datas.text81)
                  }}</router-link>
                </li>
                <li>
                  <router-link :to="`/${value}/contact`" target="_blank">{{
                    $t(datas.text82)
                  }}</router-link>
                </li>
                <li>
                  <router-link :to="`/${value}/contact`" target="_blank">{{
                    $t(datas.text83)
                  }}</router-link>
                </li>
                <li>
                  <router-link :to="`/${value}/contact`" target="_blank">{{
                    $t(datas.text84)
                  }}</router-link>
                </li>
              </ul>
            </div>
            <div class="footer-list-left-item">
              <div>{{ $t(datas.text103) }}</div>
              <ul>
                <li>
                  <router-link :to="`/${value}/contact`" target="_blank">{{
                    $t(datas.text85)
                  }}</router-link>
                </li>
                <li>
                  <router-link :to="`/${value}/privacy`" target="_blank">{{
                    $t(datas.text87)
                  }}</router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="footer-list-right">
            <img src="@/assets/newQJ/index/image/logo@2x.png" alt="" />
            <a target="_blank" href="https://beian.miit.gov.cn"
              ><span>{{ $t(datas.text0) }}</span> © 2013-2022
              <span class="qiji-bah">{{icp}}</span></a
            >
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "pcFooter",
  data() {
    return {
      showCode: false,
      isShow: true,
      icp: this.$store.state.record,
      up: require("@/assets/newQJ/index/image/xiangshang.png"),
      down: require("@/assets/newQJ/index/image/xiangxia.png"),
      activeList: [],
      list: [
        {
          id: 0,
          title: this.$t("index.v1.text80"),
          list: [
            { id: 1000, title: this.$t("index.v1.text81") },
            { id: 1001, title: this.$t("index.v1.text82") },
            { id: 1002, title: this.$t("index.v1.text83") },
            { id: 1003, title: this.$t("index.v1.text84") },
          ],
        },
        {
          id: 1,
          title: this.$t("index.v1.text103"),
          list: [
            { id: 2000, title: this.$t("index.v1.text85") },
            { id: 2001, title: this.$t("index.v1.text87") },
          ],
        },
      ],
      datas: {
        text78: "index.v1.text78",
        text79: "index.v1.text79",
        text80: "index.v1.text80",
        text81: "index.v1.text81",
        text82: "index.v1.text82",
        text83: "index.v1.text83",
        text84: "index.v1.text84",
        text85: "index.v1.text85",
        text87: "index.v1.text87",
        text103: "index.v1.text103",
        text0: "index.v1.text0",
      },
      value: "",
    };
  },
  computed: {
    ...mapState(["lang"]),
  },
  created() {
    this.value = localStorage.getItem("lans")
      ? localStorage.getItem("lans")
      : "zh-cn";
    this.icp = this.$store.state.record;
  },
  mounted() {
    window.addEventListener("click", this.allClick);
  },
  methods: {
    changeImg(id) {
      if (this.activeList.includes(id)) {
        let index = this.activeList.findIndex((x) => x === id);
        this.activeList.splice(index, 1);
      } else {
        this.activeList.push(id);
      }
    },
    allClick() {
      this.showCode = false;
    },
  },
  beforeDestroy() {
    window.removeEventListener("click", this.allClick);
  },
};
</script>

<style scoped>
.footer {
  width: 1550px;
  margin: 0 auto 0;
  padding-bottom: 40px;
  padding-top: 40px;
  color: #333333;
}
#footer {
  color: #333333 !important;
}
.footerIsPC {
  background-color: #fbfbfb;
}
.footerIsPhone {
  display: none;
  background-color: #fbfbfb;
}
.footer-header {
  font-size: 16px;
  font-weight: normal;
  color: #666666;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer-header > div {
  display: flex;
  align-items: center;
}
.footer-header > div > div {
  display: inline-flex;
  margin-left: 20px;
}
.footer-header > div > div > div {
  display: inline-block;
  position: relative;
}
.footer-header img.ere {
  width: 200px;
  height: 200px;
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translate(-50%, 0);
}

.footer-header img {
  width: 22px;
  margin-left: 16px;
}
.footer-phone {
  color: #666;
  margin-left: 13px;
}
.footer-list {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}
.footer-list-left {
  display: flex;
}
.footer-list-left-item > div {
  margin-bottom: 16px;
  font-size: 16px;
  color: #666666;
}
.footer-list-left-item > div > a {
  color: #666666;
}
.footer-list-left-item {
  display: inline-block;
  margin-right: 100px;
}

a {
  text-decoration: none;
}
.footer-list-left-item ul li {
  font-size: 14px;
  font-weight: normal;
  color: #666666;
  margin-bottom: 10px;
  cursor: pointer;
}
.footer-list-left-item ul li > a {
  color: #666666;
}
.footer-list-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  place-self: flex-end;
}
.footer-list-right img {
  width: 155px;
  margin-bottom: 15px;
}
.footer-list-right a {
  color: #999999;
  font-size: 14px;
}

@media screen and (max-width: 1550px) {
  .footer {
    width: 1165px;
  }
  .footer-header {
    font-size: 16px;
  }
  .footer-list-right img {
    width: 103px;
  }
  .footer-header img {
    width: 16px;
    margin-left: 10px;
    display: inline-block;
  }
  .footer-phone {
    font-size: 16px;
  }
  .footer-phone-title {
    font-size: 16px;
    position: relative;
    top: -1px;
  }
  .footer-list-left-item > div > a {
    font-size: 16px;
  }
  .footer-list-left-item ul li {
    font-size: 14px;
  }
}
@media screen and (max-width: 1280px) {
  .footer {
    width: 960px;
  }
  .footer-list-right img {
    width: 79px;
    height: 29px;
  }
  .footer-phone-title {
    font-size: 16px;
  }
  .footer-header {
    font-size: 16px;
  }
}
@media screen and (max-width: 980px) {
  .footer {
    width: 700px;
  }
}
</style>
