<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  metaInfo() {
    return {
      meta: this.metaData,
      title: this.title,
    };
  },

  data() {
    return {
      langs:{
        cn: {
          home:{
            title: 'QiCYCLE，骑记，iriding.com，iriding',
            keywords: '"QiCYCLE，骑记，骑记官网，骑记电动助力自行车C2，骑记电动助力自行车F2，骑记充气宝P1，充气宝，充气泵，无线充气泵，电动充气泵，iriding.com，iriding，QiCYCLE Electric Power Assistant Bicycle C2，QiCYCLE Electric Folding Bike F2，Portable Electric Air Inflator P1，Portable Electric Air Inflator P1airmoto，tire inflator，portable tire inflator，air compressor portable，portable，tire inflator for car，airmoto tire inflator 120 psi，tire air compressor，air compressor，air compressor for car tires' +
                'cordless tire inflator，Electric bike，Electric bicycle，E-bike，electric air pump，portable electric pump，e bike ' +
                'ebike，electricbicycle"',
            description: '骑记(深圳)科技有限公司是一家专注于出行创新的企业。骑记专注于设计、制造电助力自行车及周边智能产品。骑记以国际化视野立足全球市场，用心塑造每一款产品，让科技之美成就骑行乐趣。科技之美，成就骑行乐趣。QiCYCLE is deeply engaged in electric bicycle system solutions and is committed to creating a future-oriented low-carbon transportation vehicle. We have earned our reputation in the fields of electric bicycles and riding-related smart products. Mi Smart Electric Folding Bike, Xiaomi Portable Electric Air Compressor and QiCYCLE APP with millions of users are the star products of the company. QiCYCLE is based on the global market with an international vision and intentions to create every product.High tech makes for cycling fun.'
          },
          c2:{
            title: '骑记电动助力自行车C2，QiCYCLE Electric Power Assistant Bicycle C2',
            keywords: '骑记电动助力自行车C2，骑记，iriding.com，iriding，QiCYCLE Electric Power Assistant Bicycle C2',
            description: '骑记电动助力自行车C2车身比例经典、造型优雅，出街利器，一路吸睛。符合人体工学的车架结构，舒适省力，助你人车合一。航空级铝合金打造的多型腔车架， 转向灵活、操控轻盈，' +
                '小空间也能辗转腾挪。整车约重20kg, 可安全承重120kg, 男女老少均可驾驭。敏锐捕捉蹬踏数据，提速高效，车随心动，任意而行。三挡助力模式自由切换，超长续航约60km。锁定骑行速度，无需踩踏，放松身心。八个挡位自由调节，配合电助力，迎风挑战“魔鬼”上坡。内置30节动力锂电池，线路与电池安全隔离，' +
                '保障用电安全。汽车级BMS安全管理系统，充满即停，保障充电安全。功能清晰，操控简单，长辈也能轻松掌握。功能清晰，操控简单，长辈也能轻松掌握。光感控制前后灯开关，自动点亮或熄灭。刹车尾灯高亮，骑行不分心，行车更安全。职场达人、家居能手、时尚辣妈......增减配件即可完成角色转变。接入骑记App，呈现并记录速度、里程等数据，分享骑行生活，一键升级固件。'
          },
          f2:{
            title: '骑记电动助力自行车F2，QiCYCLE Electric Folding Bike F2',
            keywords: '骑记电动助力自行车F2，骑记，iriding.com，iriding，QiCYCLE Electric Folding Bike F2',
            description: '骑记电动助力自行车F2力矩传感器采集踩踏力度数据，控制助力大小。神经般敏感的助力输出助你人车合一。 从此无惧崎岖，爬坡如履平地。纵向折叠专利设计，主梁完整也能实现折叠 ， 铝合金锻造前、后叉轻量又坚固，承重高达100kg，骑行更安全。车架内置20节18650动力锂电池。汽车级BMS安全管理系统，充满即停，确保用电安全。电池拆装简单，既能整车直充，也能电池单充。禧玛诺内三速变速系统，精准变速，坚固耐用。配合电助力，挑战“魔鬼”上坡。一键锁定骑行速度，无需踩踏，骑行更轻松。三挡助力模式自由切换，超长续航约60km。N挡零助力，适合骑行健身、锻炼，就像自行车一样轻松好骑。骑行挡位轻松切换，彩屏行车电脑显示速度、挡位、电量、里程信息。行之有据，心中有底。App呈现并记录速度、里程、心率、踏频等数据，通过手机同步到云端，方便随时查看，科学指导你的骑行。'
          },
          p1:{
            title: '骑记充气宝P1，Portable Electric Air Inflator P1',
            keywords: '骑记充气宝P1，骑记，iriding.com，iriding，Portable Electric Air Inflator P1',
            description: '骑记充气宝P1高性能机芯和大功率电机加持，零胎压充满约5分钟。单手可操控，体型小巧收纳灵活骑行自驾，轻装上阵。压力轻松达到150psi，满足公路车轮胎等所需的高气压，充气不费「吹」灰之力。按需设定气压值充气完成自动停止。' +
                '随时随地精准测压。低于设置值立即开始加压，自动保持容器压力，喷漆、浇花、洗车，轻松省事。无论您想去山间探索还是海边踏浪，无需外接电源，充气无拘无束。'
          },
          download:{
            title: 'QiCYCLE APP，骑记APP',
            keywords: 'QiCYCLE APP，骑记APP',
            description: 'QiCYCLE APP，骑记APP，骑记软件，iriding.com，iriding'
          }
        },
        hk: {
          home:{
            title: 'QiCYCLE，騎記，iriding.com，iriding',
            keywords: '騎記官網，騎記，騎記電助力自行車，電助力自行車，電動自行車，騎記電動助力自行車C2，騎記充氣寶P1，充氣泵，充氣寶，無線充氣泵，電動充氣泵，QiCYCLE Electric Power Assistant Bicycle C2，QiCYCLE Electric Folding Bike F2，Portable Electric Air Inflator P1airmoto，tire inflator，portable tire inflator，air compressor portable，portable，tire inflator for car，airmoto tire inflator 120 psi，tire air compressor，air compressor，air compressor for car tires cordless tire inflator，Electric bike，Electric bicycle，E-bike，electric air pump，portable electric pump，e bike ebike，electricbicycle',
            description: '騎記(深圳)科技有限公司是一家專注於出行創新的企業。騎記專注於設計、製造電助力自行車及周邊智能產品。騎記以國際化視野立足全球市場，用心塑造每一款產品，讓科技之美成就騎行樂趣。科技之美，成就騎行樂趣。QiCYCLE is deeply engaged in electric bicycle system solutions and is committed to creating a future-oriented low-carbon transportation vehicle. We have earned our reputation in the fields of electric bicycles and riding-related smart products. Mi Smart Electric Folding Bike, Xiaomi Portable Electric Air Compressor and QiCYCLE APP with millions of users are the star products of the company. QiCYCLE is based on the global market with an international vision and intentions to create every product.High tech makes for cycling fun.'
          },
          c2:{
            title: '騎記電動助力自行車C2，QiCYCLE Electric Power Assistant Bicycle C2',
            keywords: '騎記電動助力自行車C2，騎記，iriding.com，iriding，QiCYCLE Electric Power Assistant Bicycle C2',
            description: '騎記電動助力自行車C2車身比例經典、造型優雅，出街利器，一路吸睛。符合人體工學的車架結構，舒適省力，助你人車合一。航空級鋁合金打造的多型腔車架， 轉向靈活、操控輕盈，小空間也能輾轉騰挪。整車約重20kg, 可安全承重120kg, 男女老少均可駕馭。敏銳捕捉蹬踏數據，提速高效，車隨心動，任意而行。三擋助力模式自由切換，超長續航約60km。鎖定騎行速度，無需踩踏，放鬆身心。八個擋位自由調節，配合電助力，迎風挑戰“魔鬼”上坡。內置30節動力鋰電池，線路與電池安全隔離，保障用電安全。汽車級BMS安全管理系統，充滿即停，保障充電安全。功能清晰，操控簡單，長輩也能輕鬆掌握。功能清晰，操控簡單，長輩也能輕鬆掌握。光感控制前後燈開關，自動點亮或熄滅。剎車尾燈高亮，騎行不分心，行車更安全。職場達人、家居能手、時尚辣媽......增減配件即可完成角色轉變。接入騎記App，呈現並記錄速度、里程等數據，分享騎行生活，一鍵升級固件。'
          },
          f2:{
            title: '騎記電動助力自行車F2，QiCYCLE Electric Folding Bike F2',
            keywords: '騎記電動助力自行車F2，騎記，iriding.com，iriding，QiCYCLE Electric Folding Bike F2',
            description: '騎記電動助力自行車F2力矩傳感器採集踩踏力度數據，控制助力大小。神經般敏感的助力輸出助你人車合一。從此無懼崎嶇，爬坡如履平地。縱向折疊專利設計，主梁完整也能實現折疊 ， 鋁合金鍛造前、後叉輕量又堅固，承重高達100kg，騎行更安全。車架內置20節18650動力鋰電池。汽車級BMS安全管理系統，充滿即停，確保用電安全。電池拆裝簡單，既能整車直充，也能電池單充。禧瑪諾內三速變速系統，精準變速，堅固耐用。配合電助力，挑戰“魔鬼”上坡。一鍵鎖定騎行速度，無需踩踏，騎行更輕鬆。三擋助力模式自由切換，超長續航約60km。 N擋零助力，適合騎行健身、鍛煉，就像自行車一樣輕鬆好騎。騎行擋位輕鬆切換，彩屏行車電腦顯示速度、擋位、電量、里程信息。行之有據，心中有底。 App呈現並記錄速度、里程、心率、踏頻等數據，通過手機同步到雲端，方便隨時查看，科學指導你的騎行。'
          },
          p1:{
            title: '騎記充氣寶P1，Portable Electric Air Inflator P1',
            keywords: '騎記充氣寶P1，騎記，iriding.com，iriding，Portable Electric Air Inflator P1',
            description: '騎記充氣寶P1高性能機芯和大功率電機加持，零胎壓充滿約5分鐘。單手可操控，體型小巧收納靈活騎行自駕，輕裝上陣。壓力輕鬆達到150psi，滿足公路車輪胎等所需的高氣壓，充氣不費「吹」灰之力。按需設定氣壓值充氣完成自動停止。隨時隨地精準測壓。低於設置值立即開始加壓，自動保持容器壓力，噴漆、澆花、洗車，輕鬆省事。無論您想去山間探索還是海邊踏浪，無需外接電源，充氣無拘無束。'
          },
          download:{
            title: 'QiCYCLE APP，騎記APP',
            keywords: 'QiCYCLE APP，騎記APP，騎記，iriding.com，iriding',
            description: 'QiCYCLE APP，騎記APP，騎記軟件，iriding.com，iriding'
          }
        },
        en: {
          home:{
            title: 'QiCYCLE，iriding.com，iriding',
            keywords: 'QiCYCLE，iriding.com，iriding，QiCYCLE Electric Power Assistant Bicycle C2，Portable Electric Air Inflator P1airmoto，tire inflator，portable tire inflator，air compressor portable，portable，tire inflator for car，airmoto tire inflator 120 psi，tire air compressor，air compressor，air compressor for car tires\n' +
                'cordless tire inflator，Electric bike，Electric bicycle，E-bike，electric air pump，portable electric pump，e bike，ebike，electricbicycle,Electric bike，Electric bicycle，E-bike，Portable Electric Air Inflator P1airmoto，tire inflator，portable tire inflator，air compressor portable，portable，tire inflator for car，airmoto tire inflator 120 psi，tire air compressor，air compressor，air compressor for car tires cordless tire inflator，electric air pump，portable electric pump',
            description: '"QiCYCLE is deeply engaged in electric bicycle system solutions and is committed to creating a future-oriented low-carbon transportation vehicle. We have earned our reputation in the fields of electric bicycles and riding-related smart products. Mi Smart Electric Folding Bike, Xiaomi Portable Electric Air Compressor and QiCYCLE APP with millions of users are the star products of the company. QiCYCLE is based on the global market with an international vision and intentions to create every product.' +
                'We are innovative company who focus on travel and sports products. QiCYCLE stands on the global market with an international perspective, we mainly works on designing, manufacturing and selling Electric Power Assistant bicycle, together with bicycle accessory products\' development and production, QiCYCLE has opened up supply and marketing systems in China and overseas market. Our company\'s philosophy is to use technology to change people\'s travel experience. In future, QiCYCLE will continuly commit to designing smart travel products. making life more relax, travel more relax and interesting. Technology makes a better Cycling.' +
                'High tech makes for cycling fun.'
          },
          c2:{
            title: 'QiCYCLE Electric Power Assistant Bicycle C2',
            keywords: 'QiCYCLE，iriding.com，iriding，QiCYCLE Electric Power Assistant Bicycle C2，Electric bike，Electric bicycle，e bike，ebike，electricbicycle,Electric bike，Electric bicycle，E-bike，',
            description: '"The QiCYCLE Electric Bike C2 is a perfect option if you’re looking for a reliable E-bike for your daily commute or for an exercising cycling solution. With a 270Wh battery offering up to 65 kilometres range of mode power assistance on a fully charge, this bike would easily cover the user\'s average commute. The Torque sensor monitors the speed and understands your intention while riding, driving the motor to provide suitable assisted power to your will. It makes the ride easier and more enjoyable.' +
                'Plus, with the battery hidden into the bike frame, the QiCYCLE Electric bike has such a simple and elegent design that many people won’t even notice it’s an electric bike."'
          },
          f2:{
            title: 'QiCYCLE Electric Folding Bike F2',
            keywords: 'QiCYCLE，iriding.com，iriding，QiCYCLE Electric Folding Bike F2，Electric bike，Electric bicycle，e bike，ebike，electricbicycle,Electric bike，Electric bicycle，E-bike，',
            description: '"QiCYCLE Electric Folding Bike F2.Boost mode ready anytime' +
                'takes in consideration your every move.Principle of torque sensing system.Compact folding save space in the trunk.Detachable battery ' +
                'more convenient to charge.3-speed transmission' +
                'system,precise shifting.Three riding modes fast mode, long distance or cruise-control.Cruise-control mode Laid-back and relaxed.Assist modes' +
                'Save energy and power.Bicycle mode.Strengthening the body.Travel data computer control your riding data.Connect to QiCYCLE App improve your experience with cycling intelligence.'
          },
          p1:{
            title: 'Portable Electric Air Inflator P1',
            keywords: 'Portable Electric Air Inflator P1，airmoto，tire inflator，portable tire inflator，air compressor portable，portable，tire inflator for car，airmoto tire inflator 120 psi，tire air compressor，air compressor，air compressor for car tires,cordless tire inflator，electric air pump，portable electric pump',
            description: 'Super motor with excellent move-ment.175/70 R14 car tire can be inflated from 0 bar to2.5 bar in just 5 minutes.fully charged works for 4 tires.' +
                'No need external power supply inflation anytime anywhere.Single-handedcontrol space-saving,vertical height approx.only 20cm.150 psi for road and racing bikes63 secs per tire.Fully chargedto inflate16 tires in a row.Preset values for daily inflation demand,quick and convenient to start.accurately inflate and stop automatically.'
          },
          download:{
            title: 'QiCYCLE APP',
            keywords: 'QiCYCLE APP,QiCYCLE',
            description: 'QiCYCLE APP,QiCYCLE'
          }
        },
        de: {
          home:{
            title: 'QiCYCLE，iriding.com，iriding',
            keywords: 'QiCYCLE，iriding.com，iriding,ebike，elektrofahrrad，e bike，e-bike，ebike klapprad，electric bicycle，QiCYCLE C2 Fahrrad mit elektronischer Unterstützung,luftpumpe elektrisch,kompressor akku,luftkompressor,reifenfüller,fahrradpumpe elektrisch,akku luftpumpe fahrrad,akku luftpumpe,elektrische fahrradpumpe,akku kompressor',
            description: 'QiCYCLE (Shenzhen) Technologie GmbH ist ein Unternehmen, das sich auf Fahrinnovation konzentriert. QiCYCLE beschäftigt sich mit Entwicklung und Herstellung von Elektrofahrrädern und intelligenten Peripheriegeräten. Mit einer internationaler Vision basiert QiCYCLE auf dem globalen Markt und erzeugt aufmerksam jedes Produkt, damit Radfahren mit der Technologie mehr Spaß macht.Die technische Neuerung für maximalen Fahrradspaß'
          },
          c2:{
            title: 'QiCYCLE C2 Fahrrad mit elektronischer Unterstützung',
            keywords: 'ebike，elektrofahrrad，e bike，e-bike，ebike klapprad，electric bicycle，QiCYCLE C2 Fahrrad mit elektronischer Unterstützung',
            description: 'Das QiCYCLE Electric Bike C2 ist eine perfekte Option, wenn Sie ein zuverlässiges E-Bike für Ihren täglichen Weg zur Arbeit oder eine Lösung für das Radfahren suchen. Mit einem 270-Wh-Akku, der bei voller Ladung eine Reichweite von bis zu 65 Kilometern im Modus Energieunterstützung bietet, würde dieses Fahrrad den durchschnittlichen Arbeitsweg des Benutzers problemlos bewältigen. Der Drehmomentsensor überwacht die Geschwindigkeit und versteht Ihre Absicht während der Fahrt, indem er den Motor antreibt, um Ihrem Willen die geeignete unterstützte Kraft zu verleihen. Es macht die Fahrt einfacher und angenehmer. Da der Akku im Fahrradrahmen versteckt ist, hat das QiCYCLE-Elektrofahrrad ein so einfaches und elegantes Design, dass viele Menschen nicht einmal bemerken, dass es sich um ein Elektrofahrrad handelt.'
          },
          f2:{
            title: 'QiCYCLE F2 klappbares Fahrrad mit Elektrounterstützung',
            keywords: 'ebike，elektrofahrrad，e bike，e-bike，ebike klapprad，electric bicycle，QiCYCLE F2 klappbares Fahrrad mit Elektrounterstützung',
            description: 'Hilfe auf Abruf versteht jeden Ihrer Pedaltritte.Prinzip der Drehmomentmesssysteme.zusammengeklappt und kompakter zu verstauen ein ständiger Begleiter im Kofferraum.Abnehmbarer Akku bequemeres Aufladen.Drei-Gang-Schaltsystem für präzise Schaltvorgänge.Modus mit konstanter Geschwindigkeit Ungezwungen und entspannt.Unterstützungs-Modus Energie und Strom sparen.Fahrrad-Modus.Stärken Sie Ihren Körper.Bordcomputer Kontrolle der Fahrdaten.Verbindung zur QiCYCLE-Anwendung für eine intelligente Fahrt.'
          },
          p1:{
            title: 'QiCYCLE Tragbarer elektrischer Inflator P1',
            keywords: 'luftpumpe elektrisch,kompressor akku,luftkompressor,reifenfüller,fahrradpumpe elektrisch,akku luftpumpe fahrrad,akku luftpumpe,elektrische fahrradpumpe,akku kompressor,QiCYCLE Tragbarer elektrischer Inflator P1',
            description: 'Supermotor mit hervorragender Laufruhe. 175/70 R14 Autoreifen lässt sich in nur 5 Minuten von 0 bar auf 2,5 bar aufpumpen. Voll aufgeladen reicht für 4 Reifen. Kein Aufpumpen mit externer Stromversorgung erforderlich, jederzeit und überall. Platzsparende Einhandsteuerung, vertikale Höhe ca. 20 cm. 150 psi für Straßen- und Rennräder, 63 Sekunden pro Reifen und bequem zu starten. Genau aufblasen und automatisch stoppen.'
          },
          download:{
            title: 'QiCYCLE APP',
            keywords: 'QiCYCLE APP,QiCYCLE',
            description: 'QiCYCLE APP,QiCYCLE'
          }
        },
        es: {
          home:{
            title: 'QiCYCLE，iriding.com，iriding',
            keywords: 'QiCYCLE，iriding.com，iriding,Tesoro inflable, bomba de aire, bomba de aire eléctrica, bicicleta eléctrica, bicicleta eléctrica',
            description: 'QiCYCLE (Shenzhen) Technology Co., Ltd. es una empresa especializada en la innovación de viajes. Se enfoca en el diseño y fabricación de bicicletas eléctricas y los productos inteligentes al respecto. Con una visión internacional en el mercado mundial, Qiji presta tanta atención al hacer cada producto, para que se obtenga la diversión de viajes con tecnología.Tecnología logra el placer de ciclismo.'
          },
          c2:{
            title: 'Bicicleta con asistencia eléctrica QiCYCLE C2',
            keywords: 'Bicicleta con asistencia eléctrica QiCYCLE C2,bomba de aire eléctrica, bicicleta eléctrica, bicicleta eléctrica',
            description: 'La bicicleta eléctrica QiCYCLE C2 es una opción perfecta si está buscando una bicicleta eléctrica confiable para su viaje diario al trabajo o para una solución de ciclismo para hacer ejercicio. Con una batería de 270 Wh que ofrece hasta 65 kilómetros de autonomía en modo de asistencia eléctrica con una carga completa, esta bicicleta cubriría fácilmente el trayecto medio del usuario. El sensor de par controla la velocidad y comprende su intención mientras conduce, impulsando el motor para proporcionar la potencia asistida adecuada a su voluntad. Hace que el viaje sea más fácil y agradable.' +
                'Además, con la batería escondida en el cuadro de la bicicleta, la bicicleta eléctrica QiCYCLE tiene un diseño tan simple y elegante que muchas personas ni siquiera notarán que es una bicicleta eléctrica.'
          },
          f2:{
            title: 'Bicicleta con asistencia eléctrica plegable QiCYCLE F2',
            keywords: 'Bicicleta con asistencia eléctrica plegable QiCYCLE F2,bomba de aire eléctrica, bicicleta eléctrica, bicicleta eléctrica',
            description: 'Ayuda de guardia conoce cada paso que des.Principio de los sistemas de detección de par.Plegable, fácil de almacenar' +
                'puedes llevarlo en el maletero.Batería desmontable más fácil de cargar.Sistema de cambio de tres velocidades para un cambio preciso.Modo de velocidad constante.Relajado y sin prisas.Modos de asistencia.Ahorro de energía y potencia.Modo bicicleta.Fortalece tu cuerpo.Ordenador de a bordo controla los datos de conducción.Conéctese a la aplicación QiCYCLE para la conducción inteligente.'
          },
          p1:{
            title: 'Inflador eléctrico portátil QiCYCLE P1',
            keywords: 'Inflador eléctrico portátil QiCYCLE P1,Tesoro inflable, bomba de aire',
            description: 'Súper motor con excelente movimiento. El neumático de automóvil 175/70 R14 se puede inflar de 0 bar a 2,5 bar en solo 5 minutos. Cargado completamente, funciona para 4 neumáticos.' +
                'No es necesario inflar la fuente de alimentación externa en ningún momento y en ningún lugar. Control con una sola mano para ahorrar espacio, altura vertical aproximada de solo 20 cm. 150 psi para bicicletas de carretera y de carrera 63 segundos por neumático. y conveniente para comenzar. Inflar con precisión y detenerse automáticamente.'
          },
          download:{
            title: 'QiCYCLE APP',
            keywords: 'QiCYCLE APP,QiCYCLE',
            description: 'QiCYCLE APP,QiCYCLE'
          }
        },
        fr: {
          home:{
            title: 'QiCYCLE，iriding.com，iriding',
            keywords: 'QiCYCLE，iriding.com，iriding,Trésor gonflable, pompe à air, pompe à air électrique, vélo électrique, vélo électrique',
            description: 'QiCYCLE (Shenzhen) Technology Co.,Ltd. est une société axée sur la capacité d\'innovation en mobilité. Elle est notamment engagée dans la conception et la fabrication de vélos électriques et leurs accessoires intelligents. Grâce à une perspective internationale basée sur le marché global, QiCycle élabore minutieusement chaque produit, en rendant à tous une technologie accessible et ludique.La haute technologie rend le vélo amusant'
          },
          c2:{
            title: 'Vélo à assistance électrique QiCYCLE C2',
            keywords: 'Vélo à assistance électrique QiCYCLE C2, pompe à air électrique, vélo électrique, vélo électrique',
            description: "Le vélo électrique QiCYCLE C2 est une option parfaite si vous recherchez un vélo électrique fiable pour vos déplacements quotidiens ou pour une solution de cyclisme d'exercice. Avec une batterie de 270 Wh offrant jusqu'à 65 kilomètres d'autonomie en mode d'assistance électrique sur une charge complète, ce vélo couvrirait facilement le trajet moyen de l'utilisateur. Le capteur de couple surveille la vitesse et comprend votre intention pendant la conduite, entraînant le moteur pour fournir une puissance assistée appropriée à votre volonté. Cela rend la conduite plus facile et plus agréable." +
                "De plus, avec la batterie cachée dans le cadre du vélo, le vélo électrique QiCYCLE a un design si simple et élégant que beaucoup de gens ne remarqueront même pas qu'il s'agit d'un vélo électrique."
          },
          f2:{
            title: 'Vélo pliant à assistance électrique QICYCLE F2',
            keywords: 'Vélo pliant à assistance électrique QICYCLE F2, pompe à air électrique, vélo électrique, vélo électrique',
            description: "Assistance instantanée la maîtrise totale du rythme.Principe des systèmes de détection de couple.Pliable et compact dans le coffre toujours prêt pour l’action.Batterie amovible plus pratique à charger.La transmission à trois vitesses pour un changement de vitesse fiable et précis.Mode régulateur de vitesse Détente et relaxation.modes d’assistance Économiser l'énergie et l'électricité.Mode vélo.Renforcez votre corps.Ordinateur de bord" +
                "tout est sous contrôle.Connectez-vous à l' application QiCYCLE pour obtenir un rapport intelligent."
          },
          p1:{
            title: 'Gonfleur électrique portable QiCYCLE P1',
            keywords: 'Gonfleur électrique portable QiCYCLE P1,Trésor gonflable, pompe à air',
            description: "Super moteur avec un excellent mouvement. Le pneu de voiture 175/70 R14 peut être gonflé de 0 bar à 2,5 bar en seulement 5 minutes. Fonctionne entièrement chargé pour 4 pneus." +
                "Pas besoin de gonflage d'alimentation externe n'importe quand et n'importe où. Contrôle à une main peu encombrant, hauteur verticale d'environ 20 cm seulement. 150 psi pour les vélos de route et de course 63 secondes par pneu. Entièrement chargé pour gonfler 16 pneus d'affilée. et pratique pour démarrer. Gonfler avec précision et s'arrêter automatiquement."
          },
          download:{
            title: 'QiCYCLE APP',
            keywords: 'QiCYCLE APP,QiCYCLE',
            description: 'QiCYCLE APP,QiCYCLE'
          }
        },
        ko: {
          home:{
            title: 'QiCYCLE，iriding.com，iriding',
            keywords: 'QiCYCLE，iriding.com，iriding,전기 어시스트 자전거,전기 자전거',
            description: '당사는 모빌리티 혁신에 중점을 둔 기업입니다. 당사는 전기 자전거 및 주변 지능형 제품의 설계 및 제조에 중점을 둡니다.당사는 국제 비전을 가진 세계 시장을 기반으로 하며 각각을 신중하게 형성합니다. 기술의 아름다움이 라이딩을 즐겁게 해드립니다.기술의 아름다움은 사이클링을 즐겁게 합니다.'
          },
          c2:{
            title: 'QiCYCLE 전기 자전거 C2',
            keywords: 'QiCYCLE 전기 자전거 C2,전기 어시스트 자전거,전기 자전거',
            description: "QiCYCLE Electric Bike C2는 일상적인 출퇴근을 위한 신뢰할 수 있는 E-자전거 또는 자전거 운동 솔루션을 찾고 있다면 완벽한 선택입니다. 완전 충전 시 최대 65km 범위의 전력 지원을 제공하는 270Wh 배터리를 갖춘 이 자전거는 사용자의 평균 통근을 쉽게 처리할 수 있습니다. 토크 센서는 주행 중 속도를 모니터링하고 사용자의 의도를 이해하여 모터를 구동하여 사용자의 의지에 적합한 보조 동력을 제공합니다. 보다 쉽고 즐거운 라이딩이 가능합니다. 또한 자전거 프레임에 배터리가 숨겨져 있는 QiCYCLE 전기 자전거는 단순하고 우아한 디자인으로 많은 사람들이 전기 자전거라는 사실조차 인지하지 못할 것입니다."
          },
          f2:{
            title: '접이식 전동 자전거 QICYCLE F2',
            keywords: '접이식 전동 자전거 QICYCLE F2,전기 어시스트 자전거,전기 자전거',
            description: "통화 중 도움말 당신의 모든 단계를 이해.토크 감지 시스템의 원리.더 컴팩트해진 접이식 수납공간 트렁크에 서 있는 인공물.분리형 배터리 더욱 편리한 충전.시마노 내장 3단 변속 시스템 변속이 정확하며 튼튼합니다.정속 모드 게으르고 여유로운.전동 어시스트 모드 에너지 절약.N단 제로 어시스트 모드는 사이클링, 피트니스, 운동에 적합하며 자전거처럼 편안하게 탈 수 있습니다.주행 데이터가 표시되는 계기판.QICYCLE 어플리케이션과 연동하는 과학적 주행."
          },
          p1:{
            title: 'QiCYCLE 휴대용 전기 공기 팽창기 P1',
            keywords: 'QiCYCLE 휴대용 전기 공기 팽창기 P1,팽창식 보물, 공기 펌프, 전기 공기 펌프',
            description: "움직임이 뛰어난 슈퍼 모터.175/70 R14 자동차 타이어는 단 5분 만에 0bar에서 2.5bar까지 팽창할 수 있습니다. 완전히 충전된 타이어는 4개입니다. 언제 어디서나 외부 전원 공급 장치 인플레이션이 필요하지 않습니다. 한 손으로 제어할 수 있는 공간 절약형, 수직 높이 약 20cm. 도로 및 경주용 자전거의 경우 150psi 타이어당 63초. 16개 타이어를 연속으로 팽창시키기 위해 완전히 충전됨. 일일 인플레이션 수요에 대한 사전 설정 값, 빠른 시작하기에 편리합니다. 정확하게 팽창하고 자동으로 정지합니다."
          },
          download:{
            title: 'QiCYCLE APP',
            keywords: 'QiCYCLE APP,QiCYCLE',
            description: 'QiCYCLE APP,QiCYCLE'
          }
        },
      },
      metaData: [
        {
          name: "keywords",
          content: '',
        },
        {
          name: "description",
          content: '',
        },
      ],
      title: '',
    };
  },

  components: {},

  async mounted() {
    let url = window.location.href;
    if (url.indexOf("miriding.com") != -1) {
      this.$store.state.record = '闽ICP备13003500号-4';
    }
    else if (url.indexOf("localhost") != -1) {
      this.$store.state.record = '闽ICP备test';
    }
    else if (url.indexOf("qicyc.cn") != -1) {
      this.$store.state.record = '粤ICP备2021130651号-1';
    }
    else if (url.indexOf("qicycle.com") != -1) {
      this.$store.state.record = '粤ICP备2021130651号-3';
    }

  },

  methods: {
    getTitle(path) {
      if (path.indexOf('/en') != -1) {
        return 'High tech makes for cycling fun';
      }
      else if (path.indexOf('/de') != -1) {
        return 'Die technische Neuerung für maximalen Fahrradspaß';
      }
      else if (path.indexOf('/ko') != -1) {
        return '기술의 아름다움은 사이클링을 즐겁게 합니다';
      }
      else if (path.indexOf('/fr') != -1) {
        return 'La haute technologie rend le vélo amusant';
      }
      else if (path.indexOf('/es') != -1) {
        return 'Tecnología logra el placer de ciclismo';
      }
      else if (path.indexOf('/zh-hk') != -1) {
        return '科技之美，成就騎行樂趣';
      }
      else{
        return  '科技之美，成就骑行乐趣';
      }
    },
    getContent(path) {
      if (path.indexOf('/en') != -1) {
        return 'QiCYCLE is deeply engaged in electric bicycle system solutions and is committed to creating a future-oriented low-carbon transportation vehicle. We have earned our reputation in the fields of electric bicycles and riding-related smart products. Mi Smart Electric Folding Bike, Xiaomi Portable Electric Air Compressor and QiCYCLE APP with millions of users are the star products of the company. QiCYCLE is based on the global market with an international vision and intentions to create every product';
      }
      else if (path.indexOf('/de') != -1) {
        return 'QiCYCLE (Shenzhen) Technologie GmbH ist ein Unternehmen, das sich auf Fahrinnovation konzentriert. QiCYCLE beschäftigt sich mit Entwicklung und Herstellung von Elektrofahrrädern und intelligenten Peripheriegeräten. Mit einer internationaler Vision basiert QiCYCLE auf dem globalen Markt und erzeugt aufmerksam jedes Produkt, damit Radfahren mit der Technologie mehr Spaß macht.';
      }
      else if (path.indexOf('/ko') != -1) {
        return '당사는 모빌리티 혁신에 중점을 둔 기업입니다. 당사는 전기 자전거 및 주변 지능형 제품의 설계 및 제조에 중점을 둡니다.당사는 국제 비전을 가진 세계 시장을 기반으로 하며 각각을 신중하게 형성합니다. 기술의 아름다움이 라이딩을 즐겁게 해드립니다.';
      }
      else if (path.indexOf('/fr') != -1) {
        return 'QiCYCLE (Shenzhen) Technology Co.,Ltd. est une société axée sur la capacité d\'innovation en mobilité. Elle est notamment engagée dans la conception et la fabrication de vélos électriques et leurs accessoires intelligents. Grâce à une perspective internationale basée sur le marché global, QiCycle élabore minutieusement chaque produit, en rendant à tous une technologie accessible et ludique.';
      }
      else if (path.indexOf('/es') != -1) {
        return 'QiCYCLE (Shenzhen) Technology Co., Ltd. es una empresa especializada en la innovación de viajes. Se enfoca en el diseño y fabricación de bicicletas eléctricas y los productos inteligentes al respecto. Con una visión internacional en el mercado mundial, Qiji presta tanta atención al hacer cada producto, para que se obtenga la diversión de viajes con tecnología.';
      }
      else if (path.indexOf('/zh-hk') != -1) {
        return '騎記(深圳)科技有限公司是一家專注於出行創新的企業。騎記專注於設計、製造電助力自行車及周邊智能產品。騎記以國際化視野立足全球市場，用心塑造每一款產品，讓科技之美成就騎行樂趣。';
      }
      else{
        return  '骑记(深圳)科技有限公司是一家专注于出行创新的企业。骑记专注于设计、制造电助力自行车及周边智能产品。骑记以国际化视野立足全球市场，用心塑造每一款产品，让科技之美成就骑行乐趣';
      }
    },
  },

  watch: {
    $route(to, from) {
      let path = to.fullPath;
      let obj = {};
      let lang = 'cn';
      let page = 'home';

      if (path.indexOf('/en') != -1) {
        lang = 'en';
      }
      else if (path.indexOf('/zh-hk') != -1) {
        lang = 'hk';
      }
      else if (path.indexOf('/de') != -1) {
        lang = 'de';
      }
      else if (path.indexOf('/es') != -1) {
        lang = 'es';
      }
      else if (path.indexOf('/fr') != -1) {
        lang = 'fr';
      }
      else if (path.indexOf('/ko') != -1) {
        lang = 'ko';
      }

      if (path.indexOf("/f2") != -1) {
        page = 'f2'
      }
      else if (path.indexOf("/c2") != -1) {
        page = 'c2'
      }
      else if (path.indexOf("/accessories") != -1) {
        page = 'p1'
      }
      else if (path.indexOf("/download") != -1) {
        page = 'download'
      }

      obj = this.langs[lang][page];

      this.metaData = [
          {
            name: "keywords",
            keywords: obj.keywords,
          },
          {
            name: "description",
            description: obj.description,
          },
      ];
      this.title = obj.title;
      console.log('toto:', to.fullPath, lang, page, obj)
    },
  }
}
</script>
