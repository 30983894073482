import Vue from "vue";
import VueRouter from "vue-router";
import PC from "./pc";
import phone from "./phone";
Vue.use(VueRouter);

//const routes = [...PC, ...phone];
let routes = "";
const uu = document.documentElement.clientWidth < 751;
if (uu) {
  routes = [...phone];
} else {
  routes = [...PC];
}
const router = new VueRouter({
  mode: "history",
  routes,
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

// 前置拦截
router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  let path = to.path.slice(to.path.lastIndexOf("/"));
  if (to.path.split("/")[1] == "zh") {
    path = "/zh-cn";
    localStorage.setItem("lans", to.path.split("/")[1]);
    next({ path: path });
  } else if (to.fullPath.indexOf("?plan") !== -1) {
    localStorage.setItem("lans", to.path.split("/")[1]);
    next();
  } else if (path == "/") {
    path = `/${
      localStorage.getItem("lans") ? localStorage.getItem("lans") : "zh-cn"
    }`;
    if (path.indexOf("pdf") != -1) {
      path = '/zh-cn';
    }
    else{
      localStorage.setItem("lans", to.path.split("/")[1]);
    }

    next({ path: path });
  } else {
    if (path.indexOf("pdf") == -1) {
      localStorage.setItem("lans", to.path.split("/")[1]);
    }
    next();
  }
});
export default router;
